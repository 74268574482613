import { Account } from './Account';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function AccountDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Account" />
      <Account />
    </DrawerContent>
  );
}
