import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks/useNewMtaDriverContext';
import { FormDatePicker } from '@/components/ui/forms/FormDatePicker';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import {
  FormTextField,
  capitalizeFirstLetter,
  removeInvalidNameCharacters,
  removeMultipleSpaces,
  trimWhitespaceStart,
} from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { YesNo } from '@/models/YesNo';
import { FormComboBox } from '@/components/ui/forms/FormComboBox';
import {
  EMPLOYED_STATUSES_CODES,
  getMaxBirthDate,
  getMaxResidentDate,
  getMinBirthDate,
  getMinResidentDate,
  STUDENT_STATUS_CODE,
  VOLUNTARY_WORKER_STATUS_CODE,
} from '@/features/NewMta/hooks/schemas/newMtaDriversSharedFields.schema';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';

export function DriverDetailsFormFields() {
  const { setValue, watch } = useFormContext<NewMtaFormSchema>();
  const {
    getFieldName,
    driverOptions,
    employmentStatuses,
    studentOccupations,
    employmentIndustries,
    occupations,
  } = useNewMtaDriverContext();
  const isUkResidentSinceBirth = watch(getFieldName('isUkResidentSinceBirth'));
  const employmentStatusField = watch(getFieldName('employmentStatus'));
  const isVoluntaryWorker = employmentStatusField === VOLUNTARY_WORKER_STATUS_CODE;
  const isStudent = employmentStatusField === STUDENT_STATUS_CODE;
  const isEmployed = EMPLOYED_STATUSES_CODES.includes(employmentStatusField);
  const isAnyOtherJob = watch(getFieldName('anyOtherJob')) === YesNo.Yes;
  const shouldShowAnyOtherJobField = isEmployed || isStudent || isVoluntaryWorker;

  useEffect(() => {
    if (!isEmployed) {
      setValue(getFieldName('jobTitle'), null);
      setValue(getFieldName('jobIndustry'), null);
    }
    if (!isStudent) {
      setValue(getFieldName('studentType'), null);
    }
  }, [employmentStatusField]);

  return (
    <>
      <FormTextField
        select
        label="Title"
        name={getFieldName('title')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(driverOptions?.titles ?? [])}
      </FormTextField>
      <FormTextField
        label="First name"
        name={getFieldName('firstName')}
        sx={{ ...commonSx, ...maxWidthSx }}
        transformers={[
          capitalizeFirstLetter,
          removeInvalidNameCharacters,
          removeMultipleSpaces,
          trimWhitespaceStart,
        ]}
      />
      <FormTextField
        label="Surname"
        name={getFieldName('surname')}
        sx={{ ...commonSx, ...maxWidthSx }}
        transformers={[
          capitalizeFirstLetter,
          removeMultipleSpaces,
          removeInvalidNameCharacters,
          trimWhitespaceStart,
        ]}
      />
      <FormDatePicker
        name={getFieldName('dateOfBirth')}
        label="Date of birth"
        maxDate={getMaxBirthDate()}
        minDate={getMinBirthDate()}
        sx={{ ...commonSx, ...maxWidthSx }}
      />
      <FormTextField
        select
        label="Marital status"
        name={getFieldName('maritalStatus')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(driverOptions?.maritalStatuses ?? [])}
      </FormTextField>
      <FormRadioGroup
        label="Lived in the UK continuously since birth"
        name={getFieldName('isUkResidentSinceBirth')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      {isUkResidentSinceBirth === YesNo.No ? (
        <FormDatePicker
          label="UK resident since"
          name={getFieldName('ukResidentSince')}
          sx={{ ...commonSx, ...maxWidthSx }}
          views={['year', 'month']}
          format="MM/yyyy"
          minDate={getMinResidentDate()}
          maxDate={getMaxResidentDate()}
        />
      ) : null}
      <FormTextField
        select
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Employment status"
        name={getFieldName('employmentStatus')}
      >
        {renderSelectOptionsByOptionsCodes(employmentStatuses ?? [])}
      </FormTextField>
      {isStudent ? (
        <FormTextField
          select
          sx={{ ...commonSx, ...maxWidthSx }}
          label="Student type"
          name={getFieldName('studentType')}
        >
          {renderSelectOptionsByOptionsCodes(studentOccupations ?? [])}
        </FormTextField>
      ) : null}
      {isEmployed ? (
        <>
          <FormComboBox
            label="Job title"
            name={getFieldName('jobTitle')}
            sx={{ ...commonSx, ...maxWidthSx }}
            options={occupations ?? []}
          />
          <FormComboBox
            label="Job industry"
            name={getFieldName('jobIndustry')}
            sx={{ ...commonSx, ...maxWidthSx }}
            options={employmentIndustries ?? []}
          />
        </>
      ) : null}
      {shouldShowAnyOtherJobField ? (
        <FormRadioGroup
          label="Any other job"
          name={getFieldName('anyOtherJob')}
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
          <FormRadio label={YesNo.No} value={YesNo.No} />
        </FormRadioGroup>
      ) : null}
      {isAnyOtherJob && shouldShowAnyOtherJobField ? (
        <>
          <FormComboBox
            label="Secondary job title"
            name={getFieldName('secondaryJobTitle')}
            options={occupations ?? []}
            sx={{ ...commonSx, ...maxWidthSx }}
          />
          <FormComboBox
            label="Secondary job industry"
            name={getFieldName('secondaryJobIndustry')}
            options={employmentIndustries ?? []}
            sx={{ ...commonSx, ...maxWidthSx }}
          />
        </>
      ) : null}
    </>
  );
}
