import { FormGroup, styled } from '@mui/material';

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(6),

  '.MuiFormControlLabel-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    width: '100%',
  },
  '.MuiFormControlLabel-label': {
    fontSize: theme.typography.fontSize,
  },
  '.MuiSvgIcon-root': {
    fontSize: theme.typography.body1.fontSize,
  },
  '.MuiButtonBase-root': {
    paddingRight: '5px',
  },
}));
