import { Alert } from '@mui/material';
import { fetchAuditMinutes } from '@/hooks/queries/useMotorPolicyQuery';
import { MotorPolicy } from '@/api/motorpolicy';

type OverviewAuditAlertProps = {
  policy: MotorPolicy;
};

export function OverviewAuditAlert({ policy }: OverviewAuditAlertProps) {
  const audit = policy.audit;

  if (!audit?.length) return null;

  const names = audit.map(a => a.name).join(', ');

  return (
    <Alert severity="warning">
      {`${names} ${
        audit.length > 1 ? 'have' : 'has'
      } viewed this policy in the last ${fetchAuditMinutes} minutes`}
    </Alert>
  );
}
