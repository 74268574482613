import { Box, css, styled } from '@mui/material';
import { withTransientProps } from '@/helpers/utils/componentHelpers';

export const StyledAdyenInputWrapper = styled(Box, withTransientProps)<{
  $isFocused?: boolean;
  $isError?: boolean;
}>`
  border-width: 1px;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  outline-style: solid;
  outline-width: ${({ $isFocused, $isError }) => {
    if ($isFocused && $isError) return '2px';
    if ($isFocused || $isError) return '1px';

    return 0;
  }};
  outline-color: ${({ theme, $isFocused, $isError }) => {
    if ($isError) return theme.palette.error.main;
    if ($isFocused) return theme.palette.primary.main;

    return 'none';
  }};

  ${({ $isError }) =>
    $isError &&
    css`
      border: none;
    `}

  &:hover {
    ${({ theme, $isFocused, $isError }) =>
      !$isFocused &&
      !$isError &&
      css`
        border-color: ${theme.palette.grey[700]};
      `};
  }

  .adyen-iframe-container {
    display: flex;
    padding: 0 ${({ theme }) => theme.spacing(3)};
  }

  iframe {
    height: ${({ theme }) => theme.spacing(9)};
  }
`;
