import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { Note } from '@/api/note';
import { sortByDateDesc } from '@/helpers/utils/arrayHelpers';
import { GetNotesResponse } from '@/api/note';

export function useNotesQuery(parentId: string) {
  const api = useApiClient();

  const fetchNotes = () => api.note.notes.getNotes({ parentId });
  const selectNotes = (response: GetNotesResponse) =>
    sortByDateDesc(response.notes ?? [], 'createdAtUtc') as Note[];

  return useQuery({
    queryKey: queryKeys.notes(parentId),
    queryFn: fetchNotes,
    select: selectNotes,
  });
}
