import { Button } from '@mui/material';
import { NoteAttachmentFormSubmitData } from '../hooks/useNoteAttachmentForm';
import { AddAttachmentForm } from './AddAttachmentForm';
import { Modal } from '@/components/ui/modals/Modal';

type AddAttachmentModalProps = {
  onSubmit: (attachment: NoteAttachmentFormSubmitData) => void;
  onClose: () => void;
};

export function AddAttachmentModal({ onSubmit, onClose }: AddAttachmentModalProps) {
  const formName = 'add-attachment-form';

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Add attachment</Modal.Header>
      <Modal.Content>
        <AddAttachmentForm formName={formName} onSubmit={onSubmit} />
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" form={formName} variant="contained" color="secondary">
          Add attachment
        </Button>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
