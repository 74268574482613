import { Stack, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { joinAddressLines } from '../../../helpers/joinAddressLines';
import {
  NewMtaFormSchema,
  PolicyholderDriverSchema,
  useNewMtaDriverContext,
} from '@/features/NewMta/hooks';
import { FormTextField, uppercase } from '@/components/ui/forms/FormTextField';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';

type FormattedAddressFields = NonNullable<PolicyholderDriverSchema['formattedAddress']>;

type PolicyholderHomeAddressFormProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export function PolicyholderHomeAddressForm({
  onConfirm,
  onCancel,
}: PolicyholderHomeAddressFormProps) {
  const { getFieldName } = useNewMtaDriverContext();
  const { setValue, getValues, trigger, watch } = useFormContext<NewMtaFormSchema>();
  const getFormattedAddressFieldName = (fieldName: keyof FormattedAddressFields) =>
    getFieldName(`formattedAddress.${fieldName}`);
  const postCodeSearchField = watch(getFieldName('postCodeForSearch'));

  const handleUseThisAddress = async () => {
    const isValid = await trigger(getFieldName('formattedAddress'));

    if (!isValid) return;

    const linesFieldNames: (keyof FormattedAddressFields)[] = [
      'line1',
      'line2',
      'line3',
      'line4',
      'line5',
    ];
    const addressLines = getValues(
      linesFieldNames.map(line => getFormattedAddressFieldName(line)),
    ) as unknown as Record<string, string>;

    const fullAddress = joinAddressLines(addressLines);

    setValue(getFieldName('_fullAddress'), fullAddress);
    setValue(getFormattedAddressFieldName('postCode'), postCodeSearchField);
    onConfirm();
  };

  return (
    <Stack direction="column">
      <FormTextField
        fullWidth
        sx={{ maxWidth: '240px', mb: 3 }}
        label="Home postcode"
        transformers={[uppercase]}
        name={getFieldName('postCodeForSearch')}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Address line 1"
        name={getFormattedAddressFieldName('line1')}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Address line 2"
        name={getFormattedAddressFieldName('line2')}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Address line 3"
        name={getFormattedAddressFieldName('line3')}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Address line 4"
        name={getFormattedAddressFieldName('line4')}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Address line 5"
        name={getFormattedAddressFieldName('line5')}
      />
      <Stack gap={4} my={3}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={handleUseThisAddress}
        >
          Use this address
        </Button>
        <Button size="small" variant="text" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
