import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { OptionCode } from '@/api/retail/v2';

type useDrivingQualificationsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useDrivingQualificationsQuery(
  {
    productName = 'motor',
    productVersion = 'latest',
  }: useDrivingQualificationsQueryProps,
  options?: Omit<UseQueryOptions<OptionCode[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchDrivingQualifications = () =>
    api.retailV2.product.getProductDrivingQualifications({
      productName,
      productVersion,
    });

  return useQuery({
    queryKey: queryKeys.drivingQualifications(productName, productVersion),
    queryFn: fetchDrivingQualifications,
    ...options,
  });
}
