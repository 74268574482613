import { HistoryReversalConfirmationModal } from './HistoryReversalConfirmationModal';
import { HistoryReversalQuoteCalculationCostSummaryItems } from './HistoryReversalQuoteCalculationCostSummaryItems';
import { HistoryReversalQuoteCalculationPriceBreakdown } from './HistoryReversalQuoteCalculationPriceBreakdown';
import { QuoteCalculation } from '@/features/PaymentActions/QuoteCalculation';
import { QuoteResult } from '@/helpers/data/quoteHelpers';
import { selectTotalPriceIncAddOns } from '@/helpers/selectors/quoteSelectors';
import { MotorQuote } from '@/api/motorquote';
import { FeeType } from '@/api/motorquote';

const alertCopyByQuoteResult: Record<QuoteResult, string> = {
  charge: 'Reversing back to this version means the cost of the policy will increase',
  refund: 'Reversing back to this version means the cost of the policy will decrease',
  no_charge_or_refund:
    'Reversing back to this version means the cost of the policy will remain the same',
};

type HistoryReversalQuoteCalculationProps = {
  quote: MotorQuote;
};

export function HistoryReversalQuoteCalculation({
  quote,
}: HistoryReversalQuoteCalculationProps) {
  const totalPrice = selectTotalPriceIncAddOns(quote);

  if (totalPrice === null) return <QuoteCalculation.Error />;

  return (
    <QuoteCalculation
      quote={quote}
      feeType={FeeType.MTAFEE}
      total={totalPrice}
      sx={{ px: 0 }}
    >
      <QuoteCalculation.Alert alertCopyByQuoteResult={alertCopyByQuoteResult} />
      <QuoteCalculation.CostSummary label="Cost for reversing">
        <HistoryReversalQuoteCalculationCostSummaryItems />
      </QuoteCalculation.CostSummary>
      <QuoteCalculation.PriceBreakdown>
        <HistoryReversalQuoteCalculationPriceBreakdown />
      </QuoteCalculation.PriceBreakdown>
      <QuoteCalculation.PaymentPlans />
      <QuoteCalculation.Actions>
        <QuoteCalculation.CreateOrderAction
          label="Reverse back to this version"
          successMsg="Policy was reversed successfully"
        >
          <QuoteCalculation.CreateOrderAction.Prompt>
            <HistoryReversalConfirmationModal />
          </QuoteCalculation.CreateOrderAction.Prompt>
        </QuoteCalculation.CreateOrderAction>
      </QuoteCalculation.Actions>
    </QuoteCalculation>
  );
}
