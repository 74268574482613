import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { OptionCode } from '@/api/retail/v2';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseStudentOccupationsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useStudentOccupationsQuery(
  { productName = 'motor', productVersion = 'latest' }: UseStudentOccupationsQueryProps,
  options?: Omit<UseQueryOptions<OptionCode[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();
  const fetchStudentOccupations = () =>
    api.retailV2.product.getProductStudentOccupations({
      productName,
      productVersion,
    });

  return useQuery({
    queryFn: fetchStudentOccupations,
    queryKey: queryKeys.studentOccupations(productName, productVersion),
    ...options,
  });
}
