import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { ContactUsRequest } from '@/api/support/models/ContactUsRequest';

export function useGenerateContactUsEmailMutation() {
  const api = useApiClient();

  return useMutation({
    mutationFn: async (body: ContactUsRequest) =>
      api.support.customers.postContactUs(body),
  });
}
