import { useContext } from 'react';
import { useDriverOptionsQuery } from '@/hooks/queries/useDriverOptionsQuery';
import { useEmploymentIndustriesQuery } from '@/hooks/queries/useEmploymentIndustriesQuery';
import { useEmploymentStatusesQuery } from '@/hooks/queries/useEmploymentStatusesQuery';
import { useOccupationsQuery } from '@/hooks/queries/useOccupationsQuery';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useDrivingQualificationsQuery } from '@/hooks/queries/useDrivingQualificationsQuery';
import { useMedicalConditionsQuery } from '@/hooks/queries/useMedicalConditionsQuery';
import { useLicenceDurationsQuery } from '@/hooks/queries/useLicenceDurationsQuery';
import { useOtherVehicleUsagesQuery } from '@/hooks/queries/useOtherVehicleUsagesQuery';
import { useClaimTypesQuery } from '@/hooks/queries/useClaimTypesQuery';
import { useConvictionTypesQuery } from '@/hooks/queries/useConvictionTypesQuery';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { getProductInfo } from '@/helpers/data/retailHelpers';
import { useStudentOccupationsQuery } from '@/hooks/queries/useStudentOccupationsQuery';

export function useDriversSelectOptions() {
  const parentId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(parentId);

  const isPolicy = Boolean(policy);
  const commonQueryArgs = getProductInfo(policy);
  const commonQueryOptions = { enabled: isPolicy };

  const { data: driverOptions, isPending: isDriverOptionsQueryPending } =
    useDriverOptionsQuery(commonQueryArgs, { enabled: isPolicy });
  const {
    data: employmentStatuses,
    isPending: isEmploymentStatusesQueryPending,
    isError: isEmploymentStatusesQueryError,
  } = useEmploymentStatusesQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: occupations,
    isPending: isOccupationsQueryPending,
    isError: isOccupationsQueryError,
  } = useOccupationsQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: employmentIndustries,
    isPending: isEmploymentIndustriesQueryPending,
    isError: isEmploymentIndustriesQueryError,
  } = useEmploymentIndustriesQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: drivingQualifications,
    isPending: isDrivingQualificationsQueryPending,
    isError: isDrivingQualificationsQueryError,
  } = useDrivingQualificationsQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: medicalConditions,
    isPending: isMedicalConditionsQueryPending,
    isError: isMedicalConditionsQueryError,
  } = useMedicalConditionsQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: licenceDurations,
    isPending: isLicenceDurationsQueryPending,
    isError: isLicenceDurationsQueryError,
  } = useLicenceDurationsQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: otherVehicleUsages,
    isPending: isOtherVehicleUsagesQueryPending,
    isError: isOtherVehicleUsagesQueryError,
  } = useOtherVehicleUsagesQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: claimTypes,
    isPending: isClaimTypesQueryPending,
    isError: isClaimTypesQueryError,
  } = useClaimTypesQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: convictionTypes,
    isPending: isConvictionTypesQueryPending,
    isError: isConvictionTypesQueryError,
  } = useConvictionTypesQuery(commonQueryArgs, commonQueryOptions);
  const {
    data: studentOccupations,
    isPending: isStudentOccupationsQueryPending,
    isError: isStudentOccupationsQueryError,
  } = useStudentOccupationsQuery(commonQueryArgs, commonQueryOptions);

  const isPending =
    isEmploymentStatusesQueryPending ||
    isDriverOptionsQueryPending ||
    isEmploymentIndustriesQueryPending ||
    isDrivingQualificationsQueryPending ||
    isOtherVehicleUsagesQueryPending ||
    isMedicalConditionsQueryPending ||
    isLicenceDurationsQueryPending ||
    isOccupationsQueryPending ||
    isClaimTypesQueryPending ||
    isConvictionTypesQueryPending ||
    isStudentOccupationsQueryPending;

  const isError =
    isEmploymentStatusesQueryError ||
    isEmploymentIndustriesQueryError ||
    isOccupationsQueryError ||
    isDrivingQualificationsQueryError ||
    isMedicalConditionsQueryError ||
    isLicenceDurationsQueryError ||
    isOtherVehicleUsagesQueryError ||
    isClaimTypesQueryError ||
    isConvictionTypesQueryError ||
    isStudentOccupationsQueryError;

  return {
    isPending,
    isError,
    driverOptions,
    employmentIndustries,
    occupations,
    employmentStatuses,
    drivingQualifications,
    medicalConditions,
    licenceDurations,
    otherVehicleUsages,
    claimTypes,
    convictionTypes,
    studentOccupations,
  };
}
