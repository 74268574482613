import { Car } from './Car';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function CarDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Car" />
      <Car />
    </DrawerContent>
  );
}
