import { type PropsWithChildren } from 'react';
import { FormControlProps } from '@mui/material';
import { StyledFormGroup } from './FormCheckboxGroup.styles';

type FormRadioGroupProps = PropsWithChildren<{
  name: string;
}> &
  FormControlProps;

export function FormCheckboxGroup({ name, children, ...props }: FormRadioGroupProps) {
  return (
    <StyledFormGroup {...props} aria-labelledby={name}>
      {children}
    </StyledFormGroup>
  );
}
