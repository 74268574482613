import { useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

type UseResetQuoteWhenFormChangesProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  isQuoteLoaded: boolean;
  resetQuote: () => void;
};

export function useResetQuoteWhenFormChanges<T extends FieldValues>({
  form,
  isQuoteLoaded,
  resetQuote,
}: UseResetQuoteWhenFormChangesProps<T>) {
  const { watch } = form;
  const formValues = watch();

  useEffect(() => {
    if (isQuoteLoaded) {
      resetQuote();
    }
    // Make sure it’s triggered only when a value changes (RHF sometimes triggers it unnecessarily)
  }, [JSON.stringify(formValues)]);
}
