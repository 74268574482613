import { Document, Page } from 'react-pdf';
import { Box } from '@mui/material';
import { useState } from 'react';
import { FileDetailContent } from './FileDetailContent';
import { DocumentPagination } from './controls/DocumentPagination';
import { ZoomControls } from './controls/ZoomControls';
import { RotateButton } from './controls/RotateButton';
import { DownloadButton } from './controls/DownloadButton';
import { Loading } from '@/components/ui/loaders/Loading';
import { FileDetailModalProps } from '@/components/ui/presentation/FileDetail/fileDetailTypes';
import {
  useContentHeight,
  useRotate,
  useScale,
} from '@/components/ui/presentation/FileDetail/hooks';
import { Modal } from '@/components/ui/modals/Modal';

export function FileDetailDocumentModal({
  fileName,
  content,
  title,
  onClose,
}: FileDetailModalProps) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotate, setRotate] = useRotate();
  const [scale, setScale] = useScale();
  const { contentContainerRef, contentHeight } = useContentHeight();

  const loading = (
    <Box sx={{ p: 6 }}>
      <Loading />
    </Box>
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <FileDetailContent ref={contentContainerRef} rotate={rotate} scale={scale}>
          <Document
            file={content}
            loading={loading}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              loading={loading}
              height={contentHeight}
              devicePixelRatio={15}
            />
          </Document>
        </FileDetailContent>
      </Modal.Content>
      <Modal.Actions stretchItems={false} gap={4}>
        <DocumentPagination
          pageNumber={pageNumber}
          numPages={numPages}
          onPageChange={setPageNumber}
        />
        <ZoomControls scale={scale} onScaleChange={setScale} />
        <RotateButton rotate={rotate} onRotateChange={setRotate} />
        <DownloadButton fileName={fileName} fileContent={content} />
      </Modal.Actions>
    </Modal>
  );
}
