import { NewMtaFormSchema } from '../../hooks';
import { NewMtaCarChange } from '../../newMtaTypes';
import { transformToOptionCode } from './mapFormValuesTemporaryHelpers';
import { Vehicle, VehicleUpdate, Currency } from '@/api/motorpolicy';
import { hasAnsweredYes } from '@/helpers/utils/formHelpers';
import { getDateOnly } from '@/helpers/utils/dateHelpers';
import { OptionCode } from '@/api/retail/v1';
import { isCarUsedInBusiness } from '@/helpers/data/carHelpers';
import { MotorPolicy } from '@/api/motorpolicy';

type CarPayload = {
  vehicleSwap?: Vehicle;
  vehicleUpdate?: VehicleUpdate;
};

export const mapCarFormValues = (
  car: NewMtaFormSchema['car'],
  dirtyFields: Partial<NewMtaFormSchema>,
  policy: MotorPolicy,
): CarPayload => {
  if (!dirtyFields.car) {
    return {};
  }

  const { whatNeedsChanging } = car;

  const sharedFields: VehicleUpdate = {
    registrationNumber: car.registration,
    steeringWheelSide: transformToOptionCode(car.steeringWheelSide),
    numberOfSeats: car.numberOfSeats,
    trackingDevice: hasAnsweredYes(car.trackingDevice)
      ? transformToOptionCode(car.trackingDeviceType)
      : undefined,
    alarmImmobiliser: {
      type: transformToOptionCode(car.alarmImmobiliser),
      model: 'foo',
      installationProofAvailable: false,
    },
    isImported: hasAnsweredYes(car.importedVehicle),
    selfDeclaredVehicleValue: {
      amount: Number(car.estimatedValue),
      currency: Currency.GBP,
    },
    modifications: hasAnsweredYes(car.modifiedVehicle)
      ? (car.modifications as OptionCode[])
      : undefined,
    ownership: {
      isPurchased: hasAnsweredYes(car.purchasedYet),
      selfDeclaredDateOfPurchase: hasAnsweredYes(car.purchasedYet)
        ? getDateOnly(car.datePurchased!)
        : undefined,
      legalOwner: transformToOptionCode(car.legalOwner),
      registeredKeeper: transformToOptionCode(car.registeredKeeper),
    },
    usage: {
      type: transformToOptionCode(car.usage),
      annualMileage: Number(car.personalMiles),
      businessMileage: isCarUsedInBusiness(car.usage)
        ? Number(car.businessMiles)
        : undefined,
    },
    location: {
      daytimeLocation: transformToOptionCode(car.keptDuringDay),
      overnightLocation: transformToOptionCode(car.keptAtNight),
      overnightLocationPostcode: hasAnsweredYes(car.keptAtHomeOvernight)
        ? policy.policyholder.address?.formattedAddress?.postCode
        : car.postCode,
    },
  };

  if (whatNeedsChanging === NewMtaCarChange.NewCar) {
    return {
      vehicleSwap: {
        ...sharedFields,
        type: { code: car.details.vehicleType! },
        abiCode: car.details.abiCode,
        manufactureYear: car.details.registrationYear,
        registrationNumber: car.registration,
        cubicCapicityInMillilitres: car.details.engineSize,
        make: car.details.make,
        model: car.details.model,
        transmission: car.details.transmission!,
        primaryFuelType: car.details.fuelType!,
        numberOfDoors: car.details.numberOfDoors,
      },
    };
  }

  return {
    vehicleUpdate: { ...sharedFields, id: policy.vehicle.id },
  };
};
