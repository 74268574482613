import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { BypassRefundReason } from '@/models/BypassRefundReason';

const bypassRefundFormSchema = z.object({
  reason: z
    .nativeEnum(BypassRefundReason)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
});

export type BypassRefundFormSchema = z.infer<typeof bypassRefundFormSchema>;

export function useBypassRefundForm() {
  return useForm<BypassRefundFormSchema>({
    defaultValues: {
      reason: null,
    },
    resolver: zodResolver(bypassRefundFormSchema),
  });
}
