import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  qualificationFormSchema,
  QualificationFormSchema,
} from '@/features/NewMta/hooks/schemas/newMtaDriversQualificationSchema';

export function useQualificationForm() {
  return useForm<QualificationFormSchema>({
    defaultValues: {
      type: '',
      date: null,
    },
    resolver: zodResolver(qualificationFormSchema),
    mode: 'onChange',
  });
}
