import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { claimFormSchema, ClaimFormSchema } from '@/features/NewMta/hooks';

export const SELF_DECLARED_CLAIM_CODE = 'customer';

export function useClaimForm() {
  return useForm<ClaimFormSchema>({
    defaultValues: {
      _isExisting: false,
      _source: {
        code: SELF_DECLARED_CLAIM_CODE,
        description: 'Self declared',
      },
      type: null,
      date: null,
      atFault: null,
      settled: null,
      damageAmount: '',
    },
    resolver: zodResolver(claimFormSchema),
    mode: 'onChange',
  });
}
