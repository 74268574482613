import type { CancelMotorPolicyRequest } from '../models/CancelMotorPolicyRequest';
import type { MotorPolicy } from '../models/MotorPolicy';
import type { ReversalQuoteMotorPolicyRequest } from '../models/ReversalQuoteMotorPolicyRequest';
import type { ReversalQuoteMotorPolicyResponse } from '../models/ReversalQuoteMotorPolicyResponse';
import type { CancelMotorPolicyResponse } from '../models/CancelMotorPolicyResponse';
import type { DebtResponse } from '../models/DebtResponse';
import type { PaymentRequiredResposne } from '../models/PaymentRequiredResposne';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class MotorPolicyService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getMotorpolicies({
    id,
    fetchAuditMinutes,
    policyVersion,
    skipFutureVersion = true,
  }: {
    id: string;
    fetchAuditMinutes?: number;
    policyVersion?: number;
    skipFutureVersion?: boolean;
  }): CancelablePromise<MotorPolicy> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{id}',
      path: {
        id: id,
      },
      query: {
        fetchAuditMinutes: fetchAuditMinutes,
        policyVersion: policyVersion,
        skipFutureVersion: skipFutureVersion,
      },
    });
  }

  postMotorpoliciesCancellationMotorQuote({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CancelMotorPolicyRequest;
  }): CancelablePromise<CancelMotorPolicyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/cancellation-motor-quote',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  postMotorpoliciesCancel({
    id,
    cancellationQuoteId,
    bypassPayment,
  }: {
    id: string;
    cancellationQuoteId?: string;
    bypassPayment?: boolean;
  }): CancelablePromise<PaymentRequiredResposne> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/cancel',
      path: {
        id: id,
      },
      query: {
        cancellationQuoteId: cancellationQuoteId,
        bypassPayment: bypassPayment,
      },
    });
  }

  postMotorpoliciesTransactionReversalQuote({
    policyId,
    transactionId,
    requestBody,
  }: {
    policyId: string;
    transactionId: string;
    requestBody?: ReversalQuoteMotorPolicyRequest;
  }): CancelablePromise<ReversalQuoteMotorPolicyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{policyId}/transaction/{transactionId}/reversal-quote',
      path: {
        policyId: policyId,
        transactionId: transactionId,
      },
      body: requestBody,
    });
  }

  getMotorpoliciesDebt({ id }: { id: string }): CancelablePromise<DebtResponse[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{id}/debt',
      path: {
        id: id,
      },
    });
  }

  getMotorpoliciesDocuments({
    id,
    policyVersion,
    skipFutureVersion = true,
  }: {
    id: string;
    policyVersion?: number;
    skipFutureVersion?: boolean;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{id}/documents',
      path: {
        id: id,
      },
      query: {
        policyVersion: policyVersion,
        skipFutureVersion: skipFutureVersion,
      },
    });
  }
}
