import { PropsWithChildren } from 'react';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

type ModalActionsProps = PropsWithChildren<{
  stretchItems?: boolean;
  gap?: number;
}>;

export function ModalActions({ stretchItems, gap, children }: ModalActionsProps) {
  return (
    <StyledButtonsFooter stretchItems={stretchItems} gap={gap}>
      {children}
    </StyledButtonsFooter>
  );
}
