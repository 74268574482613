export enum NoteType {
  GENERAL_NOTE = 'GeneralNote',
  CHARGE_BACKS = 'ChargeBacks',
  CLAIM_NOTIFICATION = 'ClaimNotification',
  CLAIM = 'Claim',
  COMPLAINT = 'Complaint',
  PAYMENT_DISPUTES = 'PaymentDisputes',
  CANCELLATION = 'Cancellation',
  POST_POLICY_DOCUMENTS = 'PostPolicyDocuments',
  RESTRICTION = 'Restriction',
  CORRESPONDENCE = 'Correspondence',
  MID_TERM_ADJUSTMENTS = 'MidTermAdjustments',
  NO_CLAIMS_BONUS_RELATED = 'NoClaimsBonusRelated',
  BEREAVEMENT_NOTIFICATION = 'BereavementNotification',
  AMENDMENT_TO_POLICY = 'AmendmentToPolicy',
  COOLING_OFF_PERIOD = 'CoolingOffPeriod',
  RENEWAL_ENQUIRY = 'RenewalEnquiry',
  QUOTE_ENQUIRY = 'QuoteEnquiry',
}
