import { useFormContext } from 'react-hook-form';
import { PaymentPlansFormSchema } from './usePaymentPlansForm';
import { useQuoteCalculationContext } from './useQuoteCalculationContext';
import { PaymentPlan } from '@/api/motorquote';

export function useSelectedPaymentPlan(): PaymentPlan | null {
  const { watch } = useFormContext<PaymentPlansFormSchema>();
  const paymentPlanCode = watch('paymentPlanCode');
  const { paymentPlans } = useQuoteCalculationContext();
  const selectedPaymentPlan = paymentPlans.find(
    paymentPlan => paymentPlan.type?.code === paymentPlanCode,
  );
  return selectedPaymentPlan ?? null;
}
