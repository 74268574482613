import { z } from 'zod';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { YesNo } from '@/models/YesNo';

export const COMPREHENSIVE_COVER_TYPE_CODE = 'COMP';

export const newMtaCoverFormSchema = z.object({
  type: z.string().refine(value => Boolean(value), validationMessages.required),
  voluntaryExcessAmount: z.number(),
  yearsOfNCB: z.number(),
  hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: z
    .nativeEnum(YesNo)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
});
