import { Button } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import invariant from 'tiny-invariant';
import { useQuoteCalculationContext } from '../../hooks/useQuoteCalculationContext';
import {
  ChangeFeesPremiumFormSchema,
  useChangeFeesPremiumForm,
} from './hooks/useChangeFeesPremiumForm';
import { mapChangeFeesPremiumFormValuesToPayload } from './helpers/changeFeesPremiumHelper';
import { patchFeeBandMaxDifferenceMap } from './helpers/changeFeesPremiumFormHelper';
import { useChangeFeesPremium } from './hooks/useChangeFeesPremium';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { useUpdateQuotePriceMutation } from '@/hooks/mutations/useUpdateQuotePriceMutation';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { commonSx, maxWidthSx, stopPropagate } from '@/helpers/utils/formHelpers';
import { useUser } from '@/hooks/useUser';
import { FormCheckbox } from '@/components/ui/forms/FormCheckbox';
import { Modal } from '@/components/ui/modals/Modal';

export type QuoteCalculationUpdateFeesPremiumModalProps = {
  defaultValues: Pick<ChangeFeesPremiumFormSchema, 'fee' | 'premium'>;
  originalFee: number;
  originalPremium: number;
  onSuccess: () => void;
  onClose: () => void;
};

export function QuoteCalculationUpdateFeesPremiumModal({
  defaultValues,
  originalFee,
  originalPremium,
  onSuccess,
  onClose,
}: QuoteCalculationUpdateFeesPremiumModalProps) {
  const { fee, quoteId } = useQuoteCalculationContext();
  const formName = 'change-fees-premium-form';
  const { userPermissions, hasPermission } = useUser();
  const {
    isError,
    isPending,
    mutate: changeFees,
  } = useUpdateQuotePriceMutation({ onSuccess, quoteId });
  const { changeFeesPremiumTitle } = useChangeFeesPremium();

  const form = useChangeFeesPremiumForm(
    defaultValues,
    originalFee,
    originalPremium,
    userPermissions,
  );
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, dirtyFields },
  } = form;

  const feeField = watch('fee');
  const isSubmitDisabled = !dirtyFields.fee && !dirtyFields.premium;
  const isFeeExceedingBandE = Number(feeField) > patchFeeBandMaxDifferenceMap.E;
  const cancelButton = isDirty
    ? { text: 'Clear form', handleCancel: () => reset() }
    : { text: 'Close form', handleCancel: () => onClose() };

  const handleValidSubmit: SubmitHandler<ChangeFeesPremiumFormSchema> = data => {
    invariant(fee.type, 'Fee type is required');
    changeFees(mapChangeFeesPremiumFormValuesToPayload(defaultValues, data, fee.type));
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Header>{changeFeesPremiumTitle}</Modal.Header>
      <Modal.Content>
        <FormProvider {...form}>
          {isError && <DrawerError />}
          <form id={formName} onSubmit={stopPropagate(handleSubmit(handleValidSubmit))}>
            {hasPermission('patchfees:motorquote') && (
              <FormNumberField
                name="fee"
                label="Fees"
                sx={{ ...commonSx, ...maxWidthSx }}
                symbol="money"
              />
            )}
            {hasPermission('patchpremium:motorquote') && (
              <FormNumberField
                name="premium"
                label="Premium"
                sx={{ ...commonSx, ...maxWidthSx }}
                symbol="money"
              />
            )}
            <FormTextField
              sx={commonSx}
              name="reason"
              label="Reason for cost changes"
              multiline
              rows={6}
            />
            {hasPermission('patchfeebandu:motorquote') && isFeeExceedingBandE && (
              <FormCheckbox
                sx={commonSx}
                name="isFeeChangePermissionGranted"
                label="As the value change is greater than £500, you must check the checkbox to confirm that you have been granted permission by a senior manager to perform this action"
              />
            )}
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          type="submit"
          form={formName}
          variant="contained"
          color="secondary"
          disabled={isSubmitDisabled}
          loading={isPending}
        >
          Confirm cost changes
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={cancelButton.handleCancel}>
          {cancelButton.text}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
