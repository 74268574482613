import { Button, Stack } from '@mui/material';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import invariant from 'tiny-invariant';
import { NewMtaFormSchema, useVehicleOptions } from '@/features/NewMta/hooks';
import { useVehicleMakes } from '@/features/NewMta/hooks/useVehicleMakes';
import { useVehicleModels } from '@/features/NewMta/hooks/useVehicleModels';
import { YEAR_OF_MANUFACTURE_OPTIONS } from '@/features/NewMta/helpers/newMtaConstants';
import { useNewMtaCarDetailsForm } from '@/features/NewMta/hooks/useNewMtaCarDetailsForm';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
  renderSelectOptionsByName,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { VehicleFuelType, VehicleTransmissionType } from '@/api/retail/v1';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';

type NewMtaCarDetailsFormProps = {
  onSubmitSuccess: (data: NewMtaFormSchema['car']['details']) => void;
  onCancel: () => void;
};

export function NewMtaCarDetailsForm({
  onSubmitSuccess,
  onCancel,
}: NewMtaCarDetailsFormProps) {
  const api = useApiClient();
  const { setAlert } = useSnackbarAlert();
  const { getValues: getMainFormValues } = useFormContext<NewMtaFormSchema>();
  const form = useNewMtaCarDetailsForm({
    registration: getMainFormValues('car.registration'),
  });
  const {
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = form;
  const { registrationYear, make, fuelType, transmission } = watch();
  const { data: vehicleOptions } = useVehicleOptions();
  const {
    vehicleMakes,
    isEnabled: areVehicleMakesEnabled,
    isPending: areVehicleMakesPending,
  } = useVehicleMakes({ registrationYear });
  const {
    vehicleModels,
    isEnabled: areVehicleModelsEnabled,
    isPending: areVehicleModelsPending,
  } = useVehicleModels({ make, registrationYear, fuelType, transmission });
  const vehicleMakeOptions = renderSelectOptionsByName(vehicleMakes);
  const vehicleModelOptions = renderSelectOptionsByOptionsCodes(vehicleModels);
  const hasSelectedInvalidConfiguration =
    !!registrationYear &&
    !!make &&
    !!fuelType &&
    !!transmission &&
    !areVehicleModelsPending &&
    !vehicleModels.length;

  useEffect(() => {
    setValue('make', null);
  }, [registrationYear]);

  useEffect(() => {
    setValue('model', null);
  }, [make, registrationYear, fuelType, transmission]);

  const handleSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      const formValues = getValues();
      invariant(
        formValues.make &&
          formValues.model &&
          formValues.registrationYear &&
          formValues.fuelType &&
          formValues.transmission,
        'Form data should be validated before submit',
      );

      try {
        const { detail } = await api.retailV1.abiVehicle.getAbiVehicle({
          abiCode: formValues.model,
        });
        invariant(detail, 'Abi vehicle should contain vehicle data');
        const fuelTypeOptionCode =
          vehicleOptions?.fuelTypes?.find(
            fuelType => fuelType.description === formValues.fuelType,
          ) ?? null;
        const transmissionOptionCode =
          vehicleOptions?.transmissions?.find(
            transmission => transmission.description === formValues.transmission,
          ) ?? null;
        const data: NewMtaFormSchema['car']['details'] = {
          abiCode: detail.abiCode!,
          vehicleType: detail.vehicleType!,
          registration: formValues.registration,
          make: detail.make!,
          model: detail.model!,
          engineSize: detail.engineSize!,
          registrationYear: formValues.registrationYear,
          fuelType: fuelTypeOptionCode,
          transmission: transmissionOptionCode,
          numberOfDoors: detail.doors!,
        };

        onSubmitSuccess(data);
      } catch (e) {
        setAlert({ message: GENERAL_ERROR_MSG, severity: 'error' });
      }
    }
  };

  return (
    <section aria-label="Car details manual form">
      <FormProvider {...form}>
        <FormTextField
          label="Registration"
          name="registration"
          sx={{ ...commonSx, ...maxWidthSx }}
        />
        <FormTextField
          select
          label="Year of manufacture"
          name="registrationYear"
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          {renderSelectOptionsByName(YEAR_OF_MANUFACTURE_OPTIONS)}
        </FormTextField>
        <FormTextField
          select
          label="Vehicle make"
          name="make"
          sx={{ ...commonSx, ...maxWidthSx }}
          disabled={!areVehicleMakesEnabled || !vehicleMakeOptions}
          loading={areVehicleMakesPending && areVehicleMakesEnabled}
        >
          {vehicleMakeOptions}
        </FormTextField>
        <FormTextField
          select
          label="Fuel type"
          name="fuelType"
          sx={{ ...commonSx, ...maxWidthSx }}
          disabled={!make}
        >
          {renderSelectOptionsByEnum(VehicleFuelType)}
        </FormTextField>
        <FormTextField
          select
          label="Gearbox type"
          name="transmission"
          sx={{ ...commonSx, ...maxWidthSx }}
          disabled={!fuelType}
        >
          {renderSelectOptionsByEnum(VehicleTransmissionType)}
        </FormTextField>
        <FormTextField
          select
          label="Vehicle"
          name="model"
          sx={{ ...commonSx, ...maxWidthSx }}
          disabled={!areVehicleModelsEnabled || hasSelectedInvalidConfiguration}
          loading={areVehicleModelsPending && areVehicleModelsEnabled}
          helperText={
            hasSelectedInvalidConfiguration
              ? 'No vehicles with this configuration'
              : errors.model?.message ?? undefined
          }
        >
          {vehicleModelOptions}
        </FormTextField>
        <Stack my={4} gap={4}>
          <Button size="small" variant="contained" onClick={handleSubmit}>
            Use these car details
          </Button>
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </FormProvider>
    </section>
  );
}
