import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { useApiClient } from '../contexts/useApiClient';
import {
  VehicleFuelType,
  VehicleTransmissionType,
  VehicleType,
  VehicleTypeModelsIAPIResult,
} from '@/api/retail/v1';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseVehicleModelQueryProps = {
  make?: string;
  vehicleType?: VehicleType;
  registrationYear?: number;
  fuelType?: VehicleFuelType;
  transmission?: VehicleTransmissionType;
};

export function useVehicleModelQuery(
  {
    vehicleType = VehicleType.MOTOR_CAR,
    make,
    registrationYear,
    fuelType,
    transmission,
  }: UseVehicleModelQueryProps,
  options?: Omit<UseQueryOptions<VehicleTypeModelsIAPIResult>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchVehicleModel = () => {
    invariant(make, 'Vehicle make is required to get vehicle model');
    return api.retailV1.vehicleType.getVehicleTypeMakeModel({
      vehicleType,
      make,
      registrationYear,
      fuelType,
      transmission,
    });
  };

  return useQuery({
    queryKey: queryKeys.vehicleModel(
      vehicleType,
      make,
      registrationYear,
      fuelType,
      transmission,
    ),
    queryFn: fetchVehicleModel,
    ...options,
  });
}
