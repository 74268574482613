import { ModalProps as MuiModalProps, Modal as MuiModal } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledModalContainer } from './Modal.styles';
import { ModalContext } from './hooks/useModalContext';
import { ModalHeader } from './components/ModalHeader';
import { ModalActions } from './components/ModalActions';
import { ModalContent } from './components/ModalContent';

type ModalProps = PropsWithChildren<
  Omit<MuiModalProps, 'open' | 'children'> & {
    open?: boolean;
    width?: number | string;
    onClose: () => void;
  }
>;

export function Modal({
  open = true,
  width = 768,
  children,
  onClose,
  ...props
}: ModalProps) {
  return (
    <MuiModal open={open} onClose={onClose} aria-labelledby="modal-title" {...props}>
      <ModalContext.Provider value={{ close: onClose }}>
        <StyledModalContainer width={width}>{children}</StyledModalContainer>
      </ModalContext.Provider>
    </MuiModal>
  );
}

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;
