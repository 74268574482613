import { styled, Typography } from '@mui/material';

export const StyledAccordionHeaderSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

StyledAccordionHeaderSubtitle.defaultProps = {
  ...StyledAccordionHeaderSubtitle.defaultProps,
  variant: 'body2',
};
