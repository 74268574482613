import {
  QuoteCalculation,
  useQuoteCalculationContext,
  useSelectedPaymentPlan,
} from '@/features/PaymentActions/QuoteCalculation';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';
import { selectLoanRedemption } from '@/helpers/selectors/paymentPlanSelectors';
import {
  sumSelectedAddOnsPremium,
  sumSelectedAddOnsPremiumTax,
} from '@/helpers/data/quoteHelpers';

export function CancelPolicyQuoteCalculationCostSummaryItems() {
  const { fee, netPremium, insurancePremiumTax, feeTotal, total, quote } =
    useQuoteCalculationContext();
  const addOnTotalPremium = sumSelectedAddOnsPremium(quote);
  const addOnTotalPremiumTax = sumSelectedAddOnsPremiumTax(quote);
  const selectedPaymentPlan = useSelectedPaymentPlan();
  const loanRedemption = selectedPaymentPlan
    ? selectLoanRedemption(selectedPaymentPlan)
    : null;

  return (
    <>
      <QuoteCalculation.CostSummary.Item label="Insurance premium" amount={netPremium} />
      <QuoteCalculation.CostSummary.Item
        label="Insurance premium tax"
        amount={insurancePremiumTax}
      />
      {addOnTotalPremium !== 0 && (
        <QuoteCalculation.CostSummary.Item
          label="Add-on Insurance Premium"
          amount={addOnTotalPremium}
        />
      )}
      {addOnTotalPremiumTax !== 0 && (
        <QuoteCalculation.CostSummary.Item
          label="Add-on Insurance Premium Tax"
          amount={addOnTotalPremiumTax}
        />
      )}
      <QuoteCalculation.CostSummary.Item
        label={getFeeTypesName(fee.type!)}
        amount={feeTotal}
      />
      {loanRedemption != null && (
        <QuoteCalculation.CostSummary.Item
          label="Redemption amount"
          amount={loanRedemption}
        />
      )}
      <QuoteCalculation.CostSummary.Item
        label="Total cost for cancellation"
        amount={total}
      />
    </>
  );
}
