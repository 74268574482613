import { PropsWithChildren, createContext } from 'react';
import { SupportAPI } from '@/api/support';
import { NoteAPI } from '@/api/note';
import { VulnerabilitiesAPI } from '@/api/vulnerabilities';
import { RetailAPI as RetailAPIV1 } from '@/api/retail/v1';
import { RetailAPI as RetailAPIV2 } from '@/api/retail/v2';
import { MotorpolicyAPI } from '@/api/motorpolicy';
import { MotorquoteAPI } from '@/api/motorquote';

export type ApiClient = {
  support: SupportAPI;
  note: NoteAPI;
  vulnerabilities: VulnerabilitiesAPI;
  motorpolicy: MotorpolicyAPI;
  motorquote: MotorquoteAPI;
  retailV1: RetailAPIV1;
  retailV2: RetailAPIV2;
};

const apiClient: ApiClient = {
  support: new SupportAPI(),
  note: new NoteAPI(),
  vulnerabilities: new VulnerabilitiesAPI(),
  motorpolicy: new MotorpolicyAPI(),
  motorquote: new MotorquoteAPI(),
  retailV1: new RetailAPIV1(),
  retailV2: new RetailAPIV2(),
};

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

export function ApiClientProvider({ children }: PropsWithChildren) {
  return (
    <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>
  );
}
