import { type PropsWithChildren } from 'react';
import { FormLabel, FormControlProps, FormHelperText } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import {
  FormRadioGroupDisplayStyle,
  StyledRadioFormControl,
  StyledRadioGroup,
} from './FormRadioGroup.styles';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/utils/formHelpers';

type FormRadioGroupProps = PropsWithChildren<{
  label?: string;
  name: string;
  displayStyle?: FormRadioGroupDisplayStyle;
}> &
  FormControlProps;

export function FormRadioGroup({
  name,
  label,
  children,
  displayStyle = 'default',
  ...props
}: FormRadioGroupProps) {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isError = getErrorProps(error).error;
  const helperTextId = `${name}-helperText`;

  return (
    <StyledRadioFormControl error={isError} $displayStyle={displayStyle} {...props}>
      {label ? <FormLabel id={name}>{label}</FormLabel> : null}
      <StyledRadioGroup
        {...field}
        {...overrideReactHookFormRef}
        aria-labelledby={name}
        aria-describedby={helperTextId}
        $error={isError}
        $displayStyle={displayStyle}
      >
        {children}
      </StyledRadioGroup>
      <FormHelperText id={helperTextId}>{getErrorProps(error).helperText}</FormHelperText>
    </StyledRadioFormControl>
  );
}
