import { PaymentCycleModal } from './components/PaymentCycleModal';
import { CancelPolicyConfirmationModal } from './components/CancelPolicyConfirmationModal';
import { CancelPolicyQuoteCalculationCostSummaryItems } from './components/CancelPolicyQuoteCalculationCostSummaryItems';
import { CancelPolicyQuoteCalculationPriceBreakdown } from './components/CancelPolicyQuoteCalculationPriceBreakdown';
import { MotorQuote } from '@/api/motorquote';
import { QuoteCalculation } from '@/features/PaymentActions/QuoteCalculation';
import { QuoteResult } from '@/helpers/data/quoteHelpers';
import { MotorPolicy } from '@/api/motorpolicy';
import { CancelMotorPolicyResponse } from '@/api/motorpolicy';
import { mapPaymentPlanToFullCard } from '@/helpers/data/paymentPlanHelpers';
import { FeeType } from '@/api/motorquote';
import {
  selectPaymentPlans,
  selectTotalPriceIncAddOnsAndLoanRedemption,
} from '@/helpers/selectors/quoteSelectors';

type CancelPolicyQuoteCalculationProps = {
  policy: MotorPolicy;
  quote: MotorQuote & CancelMotorPolicyResponse;
  effectiveDateTime: Date;
};

const alertCopyByQuoteResult: Record<QuoteResult, string> = {
  charge: 'Cancelling the policy means the customer needs to make a payment.',
  refund: 'Cancelling the policy means the customer is due a refund.',
  no_charge_or_refund:
    'Cancelling the policy means the customer doesn’t need to make a payment.',
};

export function CancelPolicyQuoteCalculation({
  policy,
  quote,
  effectiveDateTime,
}: CancelPolicyQuoteCalculationProps) {
  const totalCostForCancellation = selectTotalPriceIncAddOnsAndLoanRedemption(quote);
  const originalPaymentPlans = selectPaymentPlans(quote);
  const newPaymentPlan = mapPaymentPlanToFullCard(originalPaymentPlans[0]);
  const inPaymentCycle = quote.loanRedemption?.inPaymentCycle ?? false;

  if (totalCostForCancellation === null) return <QuoteCalculation.Error />;

  return (
    <QuoteCalculation
      quote={quote}
      feeType={FeeType.CANCELLATION_FEE}
      customizeQuote={{ paymentPlans: [newPaymentPlan] }}
      total={totalCostForCancellation}
    >
      <QuoteCalculation.Alert alertCopyByQuoteResult={alertCopyByQuoteResult} />
      <QuoteCalculation.CostSummary label="Cost for cancellation">
        <CancelPolicyQuoteCalculationCostSummaryItems />
      </QuoteCalculation.CostSummary>
      <QuoteCalculation.PriceBreakdown>
        <CancelPolicyQuoteCalculationPriceBreakdown />
      </QuoteCalculation.PriceBreakdown>
      <QuoteCalculation.PaymentPlans />
      <QuoteCalculation.Actions>
        <QuoteCalculation.CreateOrderAction
          label="Confirm action"
          successMsg="Policy was cancelled successfully"
        >
          {inPaymentCycle && (
            <QuoteCalculation.CreateOrderAction.Prompt>
              <PaymentCycleModal />
            </QuoteCalculation.CreateOrderAction.Prompt>
          )}
          <QuoteCalculation.CreateOrderAction.Prompt>
            <CancelPolicyConfirmationModal
              policy={policy}
              effectiveDateTime={effectiveDateTime}
            />
          </QuoteCalculation.CreateOrderAction.Prompt>
        </QuoteCalculation.CreateOrderAction>
        <QuoteCalculation.UpdateFeesPremiumAction />
      </QuoteCalculation.Actions>
    </QuoteCalculation>
  );
}
