import {
  ClaimCause,
  ClaimStatus,
  ClaimFault,
  ClaimPartyType,
  ClaimAddressType,
} from '@/api/motorpolicy';

export function getClaimCauseName(claimCause: ClaimCause): string {
  switch (claimCause) {
    case ClaimCause.ACCIDENT:
      return 'Accident';
    case ClaimCause.FIRE:
      return 'Fire';
    case ClaimCause.VANDALISM:
      return 'Vandalism';
    case ClaimCause.RIOT:
      return 'Riot';
    case ClaimCause.STORM_FLOOD:
      return 'Storm flood';
    case ClaimCause.THEFT:
      return 'Theft';
    case ClaimCause.WINDSCREEN:
      return 'Windscreen';
  }
}

export function getClaimStatusName(claimStatus: ClaimStatus): string {
  switch (claimStatus) {
    case ClaimStatus.CANCELLED:
      return 'Cancelled';
    case ClaimStatus.DUPLICATE:
      return 'Duplicate';
    case ClaimStatus.REJECTED:
      return 'Rejected';
    case ClaimStatus.OPEN:
      return 'Open';
    case ClaimStatus.PENDING:
      return 'Pending';
    case ClaimStatus.REOPENED:
      return 'Reopened';
    case ClaimStatus.SETTLED:
      return 'Settled';
    case ClaimStatus.SETTLED_WITH_EX_GRATIA:
      return 'Settled with ex gratia';
    case ClaimStatus.DENIED:
      return 'Denied';
  }
}

export function getClaimFaultName(claimFault: ClaimFault): string {
  switch (claimFault) {
    case ClaimFault.DISPUTED:
      return 'Disputed';
    case ClaimFault.FAULT:
      return 'Fault';
    case ClaimFault.NON_FAULT:
      return 'Non fault';
  }
}

export function getClaimPartyTypeName(claimPartyType: ClaimPartyType): string {
  switch (claimPartyType) {
    case ClaimPartyType.CLAIMANT:
      return 'Claimant';
    case ClaimPartyType.CLAIMANT_PASSENGER:
      return 'Claimant passenger';
  }
}

export function getClaimAddressTypeName(claimAddressType: ClaimAddressType): string {
  switch (claimAddressType) {
    case ClaimAddressType.CURRENT_ADDRESS:
      return 'Current address';
    case ClaimAddressType.PREVIOUS_ADDRESS:
      return 'Previous address';
    case ClaimAddressType.CORRESPONDANCE_ADDRESS:
      return 'Correspondance address';
    case ClaimAddressType.RISK_ADDRESS:
      return 'Risk address';
    case ClaimAddressType.INCIDENT_ADDRESS:
      return 'Incident address';
  }
}
