import { Divider, List, Typography } from '@mui/material';
import { Fragment } from 'react';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDateOnly } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { MotorClaim } from '@/api/motorpolicy';

type ClaimContentProps = {
  claims?: MotorClaim[];
};

export function ClaimContent({ claims }: ClaimContentProps) {
  return (
    <>
      <Divider />
      <List>
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
          Claims
        </Typography>
        <ListRow label="Accident or claim in last 5 years">
          {booleanAnswer(Boolean(claims?.length))}
        </ListRow>
        {claims?.map((claim: MotorClaim, index: number) => (
          <Fragment key={index}>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
              Claim #{index + 1}
            </Typography>
            <ListRow label="Type of claim">{claim.type?.description}</ListRow>
            <ListRow label="Date of claim">{formatDateOnly(claim.date)}</ListRow>
            <ListRow label="At fault">{booleanAnswer(claim.atFault)}</ListRow>
            <ListRow label="Claim settled">{booleanAnswer(claim.settled)}</ListRow>
            <ListRow label="Claim amount">
              {claim.damageAmount?.amount && formatCurrency(claim.damageAmount.amount)}
            </ListRow>
            <ListRow label="Claim source">{claim.source.description}</ListRow>
          </Fragment>
        ))}
      </List>
    </>
  );
}
