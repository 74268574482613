export enum VulnerabilityType {
  UNKNOWN = 'Unknown',
  PHYSICAL_DISABILITY = 'PhysicalDisability',
  SEVERE_OR_LONG_TERM_ILLNESS = 'SevereOrLongTermIllness',
  HEARING_OR_VISUAL_IMPAIRMENT = 'HearingOrVisualImpairment',
  MENTAL_HEALTH_CONDITION_OR_DISABILITY = 'MentalHealthConditionOrDisability',
  ADDICTION = 'Addiction',
  LOW_MENTAL_CAPACITY_OR_COGNITIVE_DISABILITY = 'LowMentalCapacityOrCognitiveDisability',
  RETIREMENT = 'Retirement',
  BEREAVEMENT = 'Bereavement',
  INCOME_SHOCK = 'IncomeShock',
  RELATIONSHIP_BREAKDOWN = 'RelationshipBreakdown',
  DOMESTIC_ABUSE = 'DomesticAbuse',
  CARING_RESPONSIBILITIES = 'CaringResponsibilities',
  OTHER_CIRCUMSTANCES_THAT_AFFECT_PEOPLES_EXP_OF_FINANCIAL_SERVICES = 'OtherCircumstancesThatAffectPeoplesExpOfFinancialServices',
  INADEQUATE_OR_ERRATIC_INCOME = 'InadequateOrErraticIncome',
  OVER_INDEBTEDNESS = 'OverIndebtedness',
  LOW_SAVINGS = 'LowSavings',
  LOW_EMOTIONAL_RESILIENCE = 'LowEmotionalResilience',
  LOW_KNOWLEDGE_OR_CONFIDENCE_IN_MANAGING_FINANCES = 'LowKnowledgeOrConfidenceInManagingFinances',
  POOR_LITERACY_OR_NUMERICAL_SKILLS = 'PoorLiteracyOrNumericalSkills',
  POOR_ENGLISH_LANGUAGE_SKILLS = 'PoorEnglishLanguageSkills',
  POOR_OR_NON_EXISTENT_DIGITAL_SKILLS = 'PoorOrNonExistentDigitalSkills',
  LEARNING_DIFFICULTIES = 'LearningDifficulties',
  NO_OR_LOW_ACCESS_TO_HELP_OR_SUPPORT = 'NoOrLowAccessToHelpOrSupport',
}
