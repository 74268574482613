import { useSearchParams } from 'react-router-dom';
import { Drawer } from '@/components/ui/drawers/Drawer';
import { PolicyQueryParams } from '@/features/Policies/policyTypes';
import { Policy } from '@/features/Policy';
import { excludePolicyParams } from '@/helpers/utils/queryParamsHelpers';

export function Presentation() {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearPolicySearchParams = () => {
    if (searchParams.has(PolicyQueryParams.Id)) {
      setSearchParams(new URLSearchParams(excludePolicyParams(searchParams)));
    }
  };

  return (
    <Drawer
      open={searchParams.has(PolicyQueryParams.Id)}
      onClose={clearPolicySearchParams}
    >
      <Policy selectedRowId={searchParams.get(PolicyQueryParams.Id)} />
    </Drawer>
  );
}
