import { FormProvider, SubmitHandler } from 'react-hook-form';
import {
  CancelPolicyFormSchema,
  useCancelPolicyForm,
} from '../../hooks/useCancelPolicyForm';
import { CancelPolicyQuoteCalculation } from './components/CancelPolicyQuoteCalculation';
import { CancelPolicyFormFields } from './components/CancelPolicyFormFields';
import { CancelPolicyFormFooter } from './components/CancelPolicyFormFooter';
import { useCancellationQuote } from './hooks/useCancellationQuote';
import { MotorPolicy } from '@/api/motorpolicy';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { getDateWithOffset } from '@/helpers/utils/dateHelpers';
import { useResetQuoteWhenFormChanges } from '@/features/PaymentActions/QuoteCalculation';
import { useSetAlertError } from '@/hooks/useSetAlertError';

export function CancelPolicyForm({ policy }: { policy: MotorPolicy }) {
  const form = useCancelPolicyForm(policy);
  const {
    data: quote,
    isError: isQuoteError,
    isPending: isQuotePending,
    mutate: requestCancellationQuote,
    reset: resetCancellationQuote,
  } = useCancellationQuote();

  const isQuoteLoaded = Boolean(quote?.id);
  const { watch, handleSubmit } = form;
  const effectiveDateTime = watch('effectiveDateTime');

  const handleValidSubmit: SubmitHandler<CancelPolicyFormSchema> = async ({
    effectiveDateTime,
    cancellationReason,
    description,
  }) => {
    requestCancellationQuote({
      effectiveDateTimeUtc: getDateWithOffset(effectiveDateTime!),
      cancellationReason: cancellationReason ?? undefined,
      cancellationDescription: description ?? '',
    });
  };

  useResetQuoteWhenFormChanges({
    form,
    isQuoteLoaded,
    resetQuote: resetCancellationQuote,
  });
  useSetAlertError(isQuoteError);

  return (
    <FormProvider {...form}>
      <StyledDrawerForm
        sx={{ height: '100%' }}
        onSubmit={handleSubmit(handleValidSubmit)}
      >
        <CancelPolicyFormFields />
        {isQuoteLoaded ? (
          <CancelPolicyQuoteCalculation
            policy={policy}
            quote={quote}
            effectiveDateTime={effectiveDateTime!}
          />
        ) : (
          <CancelPolicyFormFooter isPending={isQuotePending} />
        )}
      </StyledDrawerForm>
    </FormProvider>
  );
}
