import { Button, Divider, List, Typography } from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import { FormattedPrice } from '@/components/ui/presentation/FormattedPrice';
import { StyledGrayBox, StyledGrayBoxItem } from '@/components/ui/styles/StyledBox';
import { Modal } from '@/components/ui/modals/Modal';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { usePaymentsTransactionsQuery } from '@/hooks/queries/usePaymentsTransactionsQuery';

type RefundBalanceModalProps = {
  total: number;
  policyTermCorrelationId: string;
  onConfirm: () => void;
  onCancel: () => void;
  onBypassRefund?: () => void;
};

export function RefundBalanceModal({
  total,
  policyTermCorrelationId,
  onConfirm,
  onCancel,
  onBypassRefund,
}: RefundBalanceModalProps) {
  const { data, isPending, isError } = usePaymentsTransactionsQuery({
    refundAmount: total,
    policyTermCorrelationId,
  });

  const renderTransactions = () => {
    if (isPending) return <DrawerLoading />;
    if (isError) return <DrawerError />;

    return data.transactions?.map((transaction, index) => (
      <Fragment key={index}>
        <Divider />
        <List>
          <ListRow
            disablePadding
            label="Card number"
          >{`**** **** **** ${transaction.lastFourOfCard}`}</ListRow>
          <ListRow disablePadding label="Refund amount">
            {transaction.total != null && formatCurrency(Math.abs(transaction.total))}
          </ListRow>
        </List>
      </Fragment>
    ));
  };

  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Refund</Modal.Header>
      <Modal.Content gap={4}>
        <Typography variant="body2" mb={2}>
          This amount will be refunded to the original card(s) the customer paid with,
          normally within 3 to 5 working days.
        </Typography>
        {renderTransactions()}
        <StyledGrayBox>
          <StyledGrayBoxItem>
            <Typography
              variant="subtitle1"
              id="payments_refund-balance-modal-balance-text"
            >
              One-off refund
            </Typography>
            <FormattedPrice
              price={Math.abs(total)}
              aria-labelledby="payments_refund-balance-modal-balance-text"
              data-testid="payments_refund-balance-price-box"
            />
          </StyledGrayBoxItem>
        </StyledGrayBox>
        {!!onBypassRefund && (
          <Button sx={{ alignSelf: 'flex-start' }} onClick={onBypassRefund}>
            Continue without refund
          </Button>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="contained"
          color="secondary"
          data-testid="payments_refund-balance-process-refund-button"
          onClick={onConfirm}
        >
          Process refund
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          data-testid="payments_refund-balance-cancel-button"
          onClick={onCancel}
        >
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
