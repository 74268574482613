import {
  QuoteCalculation,
  useQuoteCalculationContext,
} from '@/features/PaymentActions/QuoteCalculation';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';

export function HistoryReversalQuoteCalculationCostSummaryItems() {
  const { fee, feeTotal, total } = useQuoteCalculationContext();

  return (
    <>
      <QuoteCalculation.CostSummary.Item
        label={getFeeTypesName(fee.type!)}
        amount={feeTotal}
      />
      <QuoteCalculation.CostSummary.Item
        label="Total cost for reversing"
        amount={total}
      />
    </>
  );
}
