import { useUser } from '@/hooks/useUser';

export function useChangeFeesPremium() {
  const { hasPermission } = useUser();

  const getChangeFeesPremiumTitle = (): string => {
    if (
      hasPermission('patchfees:motorquote') &&
      hasPermission('patchpremium:motorquote')
    ) {
      return 'Change fees / premium';
    }

    if (hasPermission('patchfees:motorquote')) {
      return 'Change fees';
    }

    if (hasPermission('patchpremium:motorquote')) {
      return 'Change premium';
    }

    return '';
  };

  const hasChangeFeesPremiumPermissions =
    hasPermission('patchfees:motorquote') || hasPermission('patchpremium:motorquote');

  const changeFeesPremiumTitle = getChangeFeesPremiumTitle();

  return {
    changeFeesPremiumTitle,
    hasChangeFeesPremiumPermissions,
  };
}
