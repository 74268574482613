import { Stack, Typography } from '@mui/material';
import { FormattedPrice } from '@/components/ui/presentation/FormattedPrice';
import { FormRadio } from '@/components/ui/forms/FormRadio';

type QuoteCalculationPaymentPlanProps = {
  value: number | string;
  label: string;
  amount: number | null;
  priceSuffix?: string;
};

export function QuoteCalculationPaymentPlan({
  value,
  label,
  amount,
  priceSuffix,
}: QuoteCalculationPaymentPlanProps) {
  return (
    <FormRadio
      value={value}
      label={
        <Stack alignItems="center" flex={1} justifyContent="space-between">
          <Typography variant="subtitle1">{label}</Typography>
          <FormattedPrice price={amount && Math.abs(amount)} suffix={priceSuffix} />
        </Stack>
      }
    />
  );
}
