import { Alert } from '@mui/material';
import { useContext } from 'react';
import { NoteHeader } from './NoteHeader';
import { NoteDetails } from './NoteDetails';
import { useNotesQuery } from '@/hooks/queries/useNotesQuery';
import { AccordionList, AccordionListItem } from '@/components/ui/lists/AccordionList';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { Note } from '@/api/note';
import { StyledDrawerContent } from '@/components/ui/styles/StyledDrawerContent';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';

type NotesListProps = {
  onEdit: (note: Note) => void;
};

export function NotesList({ onEdit }: NotesListProps) {
  const parentId = useContext(PolicyIdContext);
  const { data: notes, isPending, isError } = useNotesQuery(parentId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  if (!notes?.length) {
    return (
      <StyledDrawerContent>
        <Alert severity="info">This policy does not have any notes</Alert>
      </StyledDrawerContent>
    );
  }

  const items: AccordionListItem[] = notes.map((note, index) => ({
    key: note.id,
    header: <NoteHeader note={note} index={notes.length - index} />,
    content: <NoteDetails note={note} onEdit={onEdit} />,
  }));

  return <AccordionList items={items} />;
}
