import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { Account } from '@/api/support';

type UseAccountMutationProps = {
  onSuccess?: (data: unknown) => void;
};

export function useAccountMutation({ onSuccess }: UseAccountMutationProps = {}) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ policyId, accountData }: { policyId: string; accountData: Account }) =>
      api.support.customers.postCustomersAccountUpdate({
        policyId,
        requestBody: accountData,
      }),
    onSuccess: async (data: unknown, variables) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.motorPolicy(variables.policyId),
      });
      onSuccess?.(data);
    },
  });
}
