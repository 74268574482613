import { NewMtaFormSchema } from '../../hooks/schemas/newMta.schema';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { MotorPolicy } from '@/api/motorpolicy';

const getVoluntaryExcessAmount = (policy: MotorPolicy) =>
  policy.excesses?.voluntaryAmounts?.reduce(
    (sum, amount) => sum + (amount.total ?? 0),
    0,
  ) ?? 0;

const getYearsOfNCB = (policy: MotorPolicy) =>
  policy.drivers.find(driver => driver.isPolicyholder)?.noClaimsHistory
    ?.yearsNoClaimsBonus ?? 0;

export const mapCoverToNewMtaCoverForm = (
  policy: MotorPolicy,
): NewMtaFormSchema['cover'] => ({
  type: policy.cover.type.code ?? '',
  voluntaryExcessAmount: getVoluntaryExcessAmount(policy),
  hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: booleanAnswer(
    policy.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled,
  ),
  yearsOfNCB: getYearsOfNCB(policy),
});
