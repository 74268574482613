import type { PaymentMethodsResponse } from '../models/PaymentMethodsResponse';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class PaymentMethodService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getPaymentMethod({
    shopperReference,
  }: {
    shopperReference: string;
  }): CancelablePromise<PaymentMethodsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payment-method/{shopperReference}',
      path: {
        shopperReference: shopperReference,
      },
    });
  }
}
