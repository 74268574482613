import { selectMonthlyCost } from '../selectors/paymentPlanSelectors';
import { isFullCardPayment, isMonthlyPayment } from '@/helpers/data/paymentHelpers';
import { getQuoteResult, QuoteResult } from '@/helpers/data/quoteHelpers';
import { PaymentPlan } from '@/api/motorquote';

export const hasPaymentPlanCode = (
  paymentPlan: PaymentPlan,
): paymentPlan is PaymentPlan & {
  type: { code: string; description?: string | null };
} => Boolean(paymentPlan.type?.code);

export const getPaymentPlanLabel = (
  paymentPlan: PaymentPlan,
  totalPriceIncAddOns: number | null,
) => {
  if (paymentPlan.type?.description) {
    return paymentPlan.type.description;
  }

  const paymentPlanCode = paymentPlan.type?.code;

  if (!paymentPlanCode) {
    return '-';
  }

  if (isMonthlyPayment(paymentPlanCode)) {
    return 'New monthly payments';
  }

  if (isFullCardPayment(paymentPlanCode)) {
    const quoteResult = getQuoteResult(totalPriceIncAddOns);
    const labelByQuoteResult: Record<QuoteResult, string> = {
      refund: 'One-off refund',
      charge: 'One-off payment',
      no_charge_or_refund: '-',
    };
    return labelByQuoteResult[quoteResult];
  }

  return '-';
};

export const getPaymentPlanAmount = (
  paymentPlan: PaymentPlan,
  totalPriceIncAddOns: number | null,
): number | null => {
  const paymentPlanCode = paymentPlan.type?.code;

  if (!paymentPlanCode) {
    return null;
  }

  if (isMonthlyPayment(paymentPlanCode)) {
    return selectMonthlyCost(paymentPlan);
  }

  if (isFullCardPayment(paymentPlanCode)) {
    return totalPriceIncAddOns;
  }

  return null;
};

export const getPaymentPlanPriceSuffix = (
  paymentPlanCode: string,
): string | undefined => {
  return isMonthlyPayment(paymentPlanCode) ? ' /month' : undefined;
};

export const mapPaymentPlanToFullCard = (paymentPlan: PaymentPlan): PaymentPlan => ({
  ...paymentPlan,
  type: { code: 'FULL_CARD_V1.4' },
});
