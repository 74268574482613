import {
  OptionCode,
  VehicleFuelType,
  VehicleSummary,
  VehicleTransmissionType,
} from '@/api/retail/v1';
import { uniqueArrayByKey } from '@/helpers/utils/arrayHelpers';
import { useVehicleModelQuery } from '@/hooks/queries/useVehicleModelQuery';

type UseVehicleModelsProps = {
  make: string | null;
  registrationYear: number | null;
  fuelType: VehicleFuelType | null;
  transmission: VehicleTransmissionType | null;
};

const mapVehicleSummaryToOptionCodes = (vehicleSummary: VehicleSummary[]): OptionCode[] =>
  vehicleSummary.map(({ abiCode, model }) => ({
    code: abiCode,
    description: model,
  }));

export function useVehicleModels({
  make,
  registrationYear,
  fuelType,
  transmission,
}: UseVehicleModelsProps) {
  const isEnabled = Boolean(make && registrationYear && fuelType && transmission);

  const query = useVehicleModelQuery(
    {
      make: make ?? undefined,
      registrationYear: registrationYear ?? undefined,
      fuelType: fuelType ?? undefined,
      transmission: transmission ?? undefined,
    },
    {
      enabled: isEnabled,
    },
  );

  const vehicleModels = uniqueArrayByKey(
    mapVehicleSummaryToOptionCodes(query?.data?.detail?.vehicleSummary ?? []),
    'code',
  );

  return {
    ...query,
    isEnabled,
    vehicleModels,
  };
}
