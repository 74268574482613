import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { OptionValue } from '@/api/retail/v2';
import { CancelablePromise } from '@/api/core/CancelablePromise';

type useNoClaimsDiscountYearsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useNoClaimsDiscountYearsQuery(
  {
    productName = 'motor',
    productVersion = 'latest',
  }: useNoClaimsDiscountYearsQueryProps,
  options?: Omit<UseQueryOptions<OptionValue[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchNoClaimsDiscountYears: () => CancelablePromise<OptionValue[]> = () =>
    api.retailV2.product.getProductNoClaimsDiscountYears({
      productName,
      productVersion,
    });

  return useQuery({
    queryKey: queryKeys.noClaimsDiscountYears(productName, productVersion),
    queryFn: fetchNoClaimsDiscountYears,
    ...options,
  });
}
