import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { MotorQuote } from '@/api/motorquote';

export function useMotorQuoteQuery(
  id: string,
  options: Omit<UseQueryOptions<MotorQuote>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  return useQuery({
    queryFn: () => api.motorquote.motorQuote.getMotorQuote({ id }),
    queryKey: queryKeys.motorQuote(id),
    ...options,
  });
}
