import { PropsWithChildren, useState } from 'react';
import { QuoteCalculationCreateOrderActionContext } from '../hooks/useQuoteCalculationCreateOrderActionContext';
import { BypassRefundReason } from '@/models/BypassRefundReason';

export function QuoteCalculationCreateOrderActionProvider({
  children,
}: PropsWithChildren) {
  const [shouldBypassRefund, setShouldBypassRefund] = useState(false);
  const [bypassRefundReason, setBypassRefundReason] = useState<BypassRefundReason | null>(
    null,
  );

  return (
    <QuoteCalculationCreateOrderActionContext.Provider
      value={{
        shouldBypassRefund,
        setShouldBypassRefund,
        bypassRefundReason,
        setBypassRefundReason,
      }}
    >
      {children}
    </QuoteCalculationCreateOrderActionContext.Provider>
  );
}
