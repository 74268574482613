import { BaseAPI } from '../core/BaseAPI';
import { CustomerVulnerabilitiesService } from './services/CustomerVulnerabilitiesService';

export class VulnerabilitiesAPI extends BaseAPI {
  readonly customerVulnerabilities: CustomerVulnerabilitiesService;

  constructor() {
    super({ BASE: import.meta.env.VITE_APP_VULNERABILITIES_API_URL });

    this.customerVulnerabilities = new CustomerVulnerabilitiesService(this.request);
  }
}
