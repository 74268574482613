import { VulnerabilityFormDefaultValues } from '@/features/Drivers/hooks/useVulnerabilitiesForm';
import { CustomerVulnerability } from '@/api/vulnerabilities';
import { booleanAnswer } from '@/helpers/utils/textHelpers';

export function mapRequestToFormData(
  data: Omit<CustomerVulnerability, 'quoteId' | 'policyId' | 'policyCorrelationId'>,
): VulnerabilityFormDefaultValues {
  return {
    _id: data.id!,
    _noteId: data.noteId,
    action: data.actionRequired,
    category: data.category,
    consentObtained: booleanAnswer(data.consentObtained),
    ...(data.followUpDate ? { followUpDate: new Date(data.followUpDate) } : {}),
    resolved: booleanAnswer(data.resolved),
    temporary: booleanAnswer(data.isTemporary),
    type: data.type,
    description: data.description ?? '',
    followUpRequired: booleanAnswer(data.followUpRequired),
  };
}
