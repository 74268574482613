import { FormState } from 'react-hook-form';
import { NewMtaFormSchema } from '../../hooks';
import { mapDriversFormValues } from './mapDriversFormValuesToNewMtaPayload';
import { mapCarFormValues } from './mapCarFormValuesToNewMtaPayload';
import { mapCoverFormValues } from './mapCoverFormValuesToNewMtaPayload';
import {
  CreateSupportMtaRequest,
  Referrer,
  SalesChannel,
  SalesPlatform,
} from '@/api/motorpolicy';
import { getDirtyFields } from '@/helpers/utils/formHelpers';
import { OptionCode } from '@/api/retail/v1';
import { getDateWithOffset } from '@/helpers/utils/dateHelpers';

type NewMtaPayloadType = {
  formValues: NewMtaFormSchema;
  formStateDirtyFields: FormState<NewMtaFormSchema>['dirtyFields'];
  employmentIndustries?: OptionCode[];
  occupations?: OptionCode[];
};

export const mapFormValuesToNewMtaPayload = ({
  formValues,
  formStateDirtyFields,
}: NewMtaPayloadType): CreateSupportMtaRequest => {
  const dirtyFields = getDirtyFields(formValues, formStateDirtyFields);
  const { _policy: policy } = formValues;

  return {
    policyId: policy.id,
    referrer: Referrer.EVERYDAY,
    salesChannel: SalesChannel.DIRECT,
    salesPlatform: SalesPlatform.WEB,
    effectiveDateTime: getDateWithOffset(formValues.startDate!),
    ...mapCoverFormValues(formValues.cover, dirtyFields),
    ...mapDriversFormValues({
      driversFormValues: formValues.drivers,
      dirtyFields,
      policy,
      mainDriverIndex: formValues.mainDriverIndex,
      cover: formValues.cover,
    }),
    ...mapCarFormValues(formValues.car, dirtyFields, policy),
  };
};
