import { createContext, useContext } from 'react';
import { NewMtaTab } from '../newMtaTypes';

type NewMtaTabContextProps = {
  currentTab: NewMtaTab;
  setCurrentTab: (newTab: NewMtaTab) => void;
};

export const NewMtaTabContext = createContext<NewMtaTabContextProps | undefined>(
  undefined,
);

export function useNewMtaTabContext() {
  const context = useContext(NewMtaTabContext);

  if (context === undefined) {
    throw new Error(
      'useNewMtaTabContext must be used within a NewMtaTabContext.Provider',
    );
  }

  return context;
}
