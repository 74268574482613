import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import {
  AttachmentReceivingMethod,
  AttachmentType,
  CommunicationMethod,
  NoteInitiator,
  NoteType,
} from '@/api/note';

type NewAttachment = {
  file: File;
  type: AttachmentType;
  receivingMethod: AttachmentReceivingMethod;
};

export type EditNoteRequestData = {
  noteId: string;
  parentId: string;
  noteType: NoteType;
  initiator: NoteInitiator;
  communicationMethod: CommunicationMethod;
  summary: string;
  description: string;
  dateOfReminder: string | undefined;
  newAttachments: NewAttachment[];
  deletedAttachmentsIds: string[];
  assigneeTeam: string | undefined;
};

type UseEditNoteMutationProps = {
  onSuccess?: () => void;
};

const mapRequestDataToFormData = (data: EditNoteRequestData) => ({
  NoteType: data.noteType,
  Initiator: data.initiator,
  CommunicationMethod: data.communicationMethod,
  Summary: data.summary,
  Description: data.description,
  DateOfReminder: data.dateOfReminder,
  DeletedAttachments: data.deletedAttachmentsIds,
  AssigneeTeam: data.assigneeTeam,
  AttachmentFiles: data.newAttachments.map(attachment => attachment.file),
  AttachmentTypes: data.newAttachments.map(attachment => attachment.type),
  AttachmentReceivingMethods: data.newAttachments.map(
    attachment => attachment.receivingMethod,
  ),
  NewAttachmentsAcceptedAsProof: data.newAttachments.map(() => false),
});

export function useEditNoteMutation({ onSuccess }: UseEditNoteMutationProps) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (data: EditNoteRequestData) =>
      api.note.notes.patchNotes({
        id: data.noteId,
        formData: mapRequestDataToFormData(data),
      }),
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.notes(variables.parentId),
      });
      onSuccess?.();
    },
  });
}
