import { useRef } from 'react';
import { useElementDomRect } from '@/hooks/useElementDomRect';

export function useContentHeight(
  { defaultHeight, heightOffset } = { defaultHeight: 520, heightOffset: 48 },
) {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const { height: contentContainerHeight } = useElementDomRect(contentContainerRef) ?? {
    height: defaultHeight,
  };
  const contentHeight = contentContainerHeight - heightOffset;

  return {
    contentContainerRef,
    contentHeight,
  };
}
