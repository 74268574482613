import { Account } from '@/api/support';
import { YesNo } from '@/models/YesNo';

export const mapAccountDefaultValues = (account: Account | undefined) => ({
  customerId: account?.customerId ?? '',
  customerIdentityProvider: account?.customerIdentityProvider ?? '',
  email: account?.email ?? '',
  landline: account?.telephoneNumber?.landline ?? '',
  mobile: account?.telephoneNumber?.mobile ?? '',
  allowedToMarketByEmail: account?.marketingPreferences?.allowedToMarketByEmail ?? false,
  allowedToMarketBySMS: account?.marketingPreferences?.allowedToMarketBySMS ?? false,
  allowedToMarketByPhone: account?.marketingPreferences?.allowedToMarketByPhone ?? false,
  allowedToMarketByPost: account?.marketingPreferences?.allowedToMarketByPost ?? false,
  autoRenewPolicy: account?.autoRenewPolicy ? YesNo.Yes : YesNo.No,
});
