import { Box } from '@mui/material';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { AccordionTitle } from '@/components/ui/lists/AccordionList';
import { StyledAccordionHeader } from '@/components/ui/styles/StyledAccordionHeader';
import { StyledAccordionHeaderSubtitle } from '@/components/ui/styles/StyledAccordionHeaderSubtitle';
import { Transaction } from '@/api/motorpolicy';
import { getTransactionTypesNames } from '@/helpers/nameMappings/transactionNameMappings';
import { PolicyTransactionType } from '@/api/motorpolicy';

type HistoryHeaderProps = {
  transaction: Transaction;
  transactionNo: number;
};

export function HistoryHeader({ transaction, transactionNo }: HistoryHeaderProps) {
  const quotedDate = transaction.createdDateTimeUtc
    ? formatDateTime(transaction.createdDateTimeUtc)
    : '-';
  const effectivedDate = transaction.effectiveDateTimeUtc
    ? `${formatDateTime(transaction.effectiveDateTimeUtc)}`
    : '-';
  const transactionType = getTransactionTypesNames(
    transaction.type as PolicyTransactionType,
  );

  return (
    <Box width="100%">
      <StyledAccordionHeader>
        <AccordionTitle>{`#${transactionNo} ${transactionType}`}</AccordionTitle>
        <StyledAccordionHeaderSubtitle>
          {`Quoted: ${quotedDate}`}
        </StyledAccordionHeaderSubtitle>
      </StyledAccordionHeader>
      <StyledAccordionHeader>
        <StyledAccordionHeaderSubtitle>{`Created by ${
          transaction.initiatedByUserName ? transaction.initiatedByUserName : 'Customer'
        }`}</StyledAccordionHeaderSubtitle>
        <StyledAccordionHeaderSubtitle>{`Effective: ${effectivedDate}`}</StyledAccordionHeaderSubtitle>
      </StyledAccordionHeader>
    </Box>
  );
}
