import { Policyholder } from '@/api/motorpolicy';
import { Vehicle } from '@/api/motorpolicy';

export const isVehicleKeptAtHomeOvernight = ({
  policyholder,
  vehicle,
}: {
  policyholder: Policyholder;
  vehicle: Vehicle;
}) =>
  policyholder.address?.postOfficeAddress?.postCode ===
  vehicle.location?.overnightLocationPostcode;

export const isCarUsedInBusiness = (carUsageCode: string) =>
  ['003', '004', '005', '006', '007'].includes(carUsageCode);
