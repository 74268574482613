import { PolicyStatus } from '@/api/motorpolicy';
import { PolicyState } from '@/api/support';
import { StateChipType } from '@/components/ui/presentation/StateChip';

export function getStateChipType(policyState: PolicyState | PolicyStatus): StateChipType {
  switch (policyState) {
    case PolicyState.ACTIVE:
    case PolicyStatus.ACTIVE:
    case PolicyState.UPCOMING:
    case PolicyStatus.UPCOMING:
      return StateChipType.Success;
    case PolicyState.EXPIRED:
    case PolicyStatus.EXPIRED:
      return StateChipType.Neutral;
    case PolicyState.CANCELLED:
    case PolicyStatus.CANCELLED:
    case PolicyState.VOIDED:
    case PolicyStatus.VOIDED:
      return StateChipType.Error;
  }
}
