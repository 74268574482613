import { useContext } from 'react';
import { mapNoteFormToAddRequestData } from '../helpers/notesDataHelper';
import { NoteFormSubmitData } from '../hooks/note.schema';
import { NoteForm } from './NoteForm';
import { useCreateNoteMutation } from '@/hooks/mutations/useCreateNoteMutation';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useSetAlertError } from '@/hooks/useSetAlertError';

type AddNoteFormProps = {
  onRequestSuccess: () => void;
};

export function AddNoteForm({ onRequestSuccess }: AddNoteFormProps) {
  const parentId = useContext(PolicyIdContext);

  const {
    isError,
    isPending,
    mutate: createNote,
  } = useCreateNoteMutation({
    onSuccess: onRequestSuccess,
  });

  const handleSubmit = (data: NoteFormSubmitData) => {
    createNote(mapNoteFormToAddRequestData(data, parentId));
  };

  useSetAlertError(isError);

  return (
    <NoteForm
      isSubmitPending={isPending}
      submitButtonText="Add note"
      cancelButtonText="Reset form"
      onSubmit={handleSubmit}
    />
  );
}
