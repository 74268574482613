import type { CoverOptions } from '../models/CoverOptions';
import type { DriverOptions } from '../models/DriverOptions';
import type { Endorsement } from '../models/Endorsement';
import type { LicenceDurations } from '../models/LicenceDurations';
import type { OptionCode } from '../models/OptionCode';
import type { VehicleOptions } from '../models/VehicleOptions';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class ProductService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getProductClaimTypes({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/claim-types',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductConvictionTypes({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/conviction-types',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductSchemeCoverOptions({
    productName,
    productVersion,
    schemeName,
  }: {
    productName: string;
    productVersion: string;
    schemeName: string;
  }): CancelablePromise<CoverOptions> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/cover-options',
      path: {
        productName: productName,
        productVersion: productVersion,
        schemeName: schemeName,
      },
    });
  }

  getProductDriverOptions({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<DriverOptions> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/driver-options',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductDrivingQualifications({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/driving-qualifications',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductEmploymentIndustries({
    productName,
    productVersion,
    match,
    take,
  }: {
    productName: string;
    productVersion: string;
    match?: string;
    take?: number;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/employment-industries',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
      query: {
        match: match,
        take: take,
      },
    });
  }

  getProductEmploymentStatuses({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/employment-statuses',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductSchemeEndorsements({
    productName,
    productVersion,
    schemeName,
  }: {
    productName: string;
    productVersion: string;
    schemeName: string;
  }): CancelablePromise<Endorsement[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/endorsements',
      path: {
        productName: productName,
        productVersion: productVersion,
        schemeName: schemeName,
      },
    });
  }

  getProductLicenceDurations({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<LicenceDurations> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/licence-durations',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductMedicalConditions({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/medical-conditions',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductNoClaimsDiscountYears({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/no-claims-discount-years',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductOccupations({
    productName,
    productVersion,
    match,
    take,
  }: {
    productName: string;
    productVersion: string;
    match?: string;
    take?: number;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/occupations',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
      query: {
        match: match,
        take: take,
      },
    });
  }

  getProductOtherVehicleUsages({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/other-vehicle-usages',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductStudentOccupations({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/student-occupations',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductVehicleModifications({
    productName,
    productVersion,
    match,
    take,
  }: {
    productName: string;
    productVersion: string;
    match?: string;
    take?: number;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-modifications',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
      query: {
        match: match,
        take: take,
      },
    });
  }

  getProductVehicleOptions({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<VehicleOptions> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-options',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }

  getProductVehicleOwner({
    productName,
    productVersion,
  }: {
    productName: string;
    productVersion: string;
  }): CancelablePromise<OptionCode[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-owner',
      path: {
        productName: productName,
        productVersion: productVersion,
      },
    });
  }
}
