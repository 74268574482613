import { ReactNode } from 'react';
import { DrawerMenuListItem } from './DrawerMenuListItem';
import { StyledDrawerMenuList } from './Drawer.styles';

export type DrawerMenuItem<TKey extends string> = {
  key: TKey;
  label: string;
  sectionTitle?: string;
  icon: ReactNode;
  content: ReactNode;
  hasHeaderContent?: boolean;
  disabled?: boolean;
};

type DrawerMenuListProps<TKey extends string> = {
  selectedKey: TKey;
  menuItems: DrawerMenuItem<TKey>[];
  onSelectItem: (key: TKey) => void;
};

export function DrawerMenuList<TKey extends string>({
  selectedKey,
  menuItems,
  onSelectItem,
}: DrawerMenuListProps<TKey>) {
  return (
    <StyledDrawerMenuList>
      {menuItems.map(item => (
        <DrawerMenuListItem
          key={item.key}
          label={item.label}
          icon={item.icon}
          selected={item.key === selectedKey}
          disabled={item.disabled}
          onClick={() => onSelectItem(item.key)}
        />
      ))}
    </StyledDrawerMenuList>
  );
}
