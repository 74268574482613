import { IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

type ZoomControlsProps = {
  scale: number;
  zoom?: number;
  minScale?: number;
  maxScale?: number;
  onScaleChange: (scale: number) => void;
};

export function ZoomControls({
  scale,
  zoom = 0.5,
  minScale = 1,
  maxScale = 3,
  onScaleChange,
}: ZoomControlsProps) {
  const handleScaleUp = () => {
    const newScale = scale + zoom;
    if (newScale <= maxScale) {
      onScaleChange(newScale);
    }
  };

  const handleScaleDown = () => {
    const newScale = scale - zoom;
    if (newScale >= minScale) {
      onScaleChange(newScale);
    }
  };

  return (
    <>
      <IconButton
        aria-label="zoom in"
        color="secondary"
        disabled={scale === maxScale}
        onClick={handleScaleUp}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        aria-label="zoom out"
        color="secondary"
        disabled={scale === minScale}
        onClick={handleScaleDown}
      >
        <ZoomOutIcon />
      </IconButton>
    </>
  );
}
