import { createContext, useContext } from 'react';
import { Fee, PaymentPlan, MotorQuote } from '@/api/motorquote';
import { QuoteResult } from '@/helpers/data/quoteHelpers';

export type QuoteCalculationContextProps = {
  quote: MotorQuote;
  paymentPlans: PaymentPlan[];
  fee: Fee;
  feeTotal: number;
  total: number;
  quoteId: string;
  quoteResult: QuoteResult;
  netPremium: number | null;
  insurancePremiumTax: number | null;
};

export const QuoteCalculationContext = createContext<
  QuoteCalculationContextProps | undefined
>(undefined);

export function useQuoteCalculationContext() {
  const context = useContext(QuoteCalculationContext);

  if (context === undefined) {
    throw new Error(
      'useQuoteCalculationContext must be used within QuoteCalculationContext',
    );
  }

  return context;
}
