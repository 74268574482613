import { useContext } from 'react';
import { Endorsement } from '@/api/motorquote';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { getProductInfo } from '@/helpers/data/retailHelpers';
import { useEndorsementsQuery } from '@/hooks/queries/useEndorsementsQuery';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function useEndorsements(selectedEndorsements: Endorsement[]) {
  const parentId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(parentId);
  const {
    data: endorsementsQueryData,
    isPending,
    isError,
  } = useEndorsementsQuery(
    {
      schemeName: policy?.scheme?.description ?? 'everyday_scheme',
      ...getProductInfo(policy),
    },
    { enabled: !!policy },
  );

  return {
    data: endorsementsQueryData?.filter(endorsement =>
      selectedEndorsements.find(({ code }) => code === endorsement.code),
    ),
    isPending,
    isError,
  };
}
