import { Stack, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

type QuoteCalculationContainerProps = PropsWithChildren<{
  sx?: SxProps;
}>;

export function QuoteCalculationContainer({
  sx,
  children,
}: QuoteCalculationContainerProps) {
  return (
    <StyledButtonsFooter sticky sx={sx}>
      <Stack direction="column" gap={6} flex={1}>
        {children}
      </Stack>
    </StyledButtonsFooter>
  );
}
