import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConvictionFormSchema, convictionFormSchema } from '@/features/NewMta/hooks';

export function useConvictionForm() {
  return useForm<ConvictionFormSchema>({
    defaultValues: {
      _isExisting: false,
      type: null,
      date: null,
      fineAmount: '',
      penaltyPoints: '',
      banReceived: null,
      disqualifiedLengthInMonths: '',
    },
    resolver: zodResolver(convictionFormSchema),
    mode: 'onChange',
  });
}
