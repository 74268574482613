import { RefObject, useLayoutEffect, useState } from 'react';

export function useElementDomRect(elementRef: RefObject<HTMLElement | null>) {
  const [domRect, setDomRect] = useState<DOMRect | null>(null);
  useLayoutEffect(() => {
    function updateDomRect() {
      const newDomRect = elementRef.current?.getBoundingClientRect() ?? null;
      setDomRect(newDomRect);
    }

    window.addEventListener('resize', updateDomRect);
    updateDomRect();
    return () => window.removeEventListener('resize', updateDomRect);
  }, []);
  return domRect;
}
