import { useContext, useMemo } from 'react';
import { Alert } from '@mui/material';
import { ClaimHeader } from './components/ClaimHeader';
import { ClaimContent } from './components/ClaimContent';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { StyledDrawerContent } from '@/components/ui/styles/StyledDrawerContent';
import { AccordionList, AccordionListItem } from '@/components/ui/lists/AccordionList';

export function Claims() {
  const policyId = useContext(PolicyIdContext);
  const { data: policy, isError, isPending } = useMotorPolicyQuery(policyId);
  const insurerClaims = policy?.insurerClaims ?? [];

  const items: AccordionListItem[] = useMemo(
    () =>
      insurerClaims.map((claim, index) => ({
        key: index,
        header: <ClaimHeader claim={claim} claimNo={insurerClaims.length - index} />,
        content: <ClaimContent claim={claim} />,
      })),
    [insurerClaims],
  );

  if (isPending) return <DrawerLoading />;
  if (isError) return <DrawerError />;

  if (!policy.insurerClaims || policy.insurerClaims.length === 0)
    return (
      <StyledDrawerContent>
        <Alert severity="info">This policy does not have any claims</Alert>
      </StyledDrawerContent>
    );

  return <AccordionList items={items} />;
}
