import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export function QuoteCalculationActions({ children }: PropsWithChildren) {
  return (
    <Stack gap={6} sx={{ '& > *': { flexBasis: '50%' } }}>
      {children}
    </Stack>
  );
}
