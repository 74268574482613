import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { DrawerMenuItem } from '@/components/ui/drawers/DrawerMenuList';
import { PolicyMenuItemKey } from '@/features/Policies/policyTypes';
import { OverviewDrawerContent } from '@/features/Overview';
import { DriversDrawerContent } from '@/features/Drivers';
import { CarDrawerContent } from '@/features/Car';
import { CoverDrawerContent } from '@/features/Cover';
import { DocumentsDrawerContent } from '@/features/Documents';
import { NewMtaDrawerContent } from '@/features/NewMta';
import { HistoryDrawerContent } from '@/features/History';
import { NotesDrawerContent } from '@/features/Notes';
import { ActionsDrawerContent } from '@/features/Actions';
import { PaymentsDrawerContent } from '@/features/Payments/PaymentsDrawerContent';
import { AccountDrawerContent } from '@/features/Account';
import { Permission } from '@/models/Permission';
import { ClaimsDrawerContent } from '@/features/Claims';

type DrawerMenuItemWithPermission = DrawerMenuItem<PolicyMenuItemKey> & {
  permissions?: Permission[];
};

export const DRAWER_MENU_ITEMS: DrawerMenuItemWithPermission[] = [
  {
    label: 'Overview',
    key: PolicyMenuItemKey.Overview,
    icon: <InfoOutlinedIcon />,
    content: <OverviewDrawerContent />,
    permissions: ['read:motorpolicy', 'readany:motorpolicy'],
  },
  {
    label: 'Drivers',
    key: PolicyMenuItemKey.Drivers,
    icon: <GroupOutlinedIcon />,
    content: <DriversDrawerContent />,
    permissions: [
      'readany:vulnerablecustomer',
      'read:motorpolicy',
      'readany:motorpolicy',
    ],
  },
  {
    label: 'Car',
    key: PolicyMenuItemKey.Car,
    icon: <DirectionsCarFilledOutlinedIcon />,
    content: <CarDrawerContent />,
    permissions: ['read:motorpolicy', 'readany:motorpolicy'],
  },
  {
    label: 'Cover',
    key: PolicyMenuItemKey.Cover,
    icon: <GppGoodOutlinedIcon />,
    content: <CoverDrawerContent />,
    permissions: ['read:motorpolicy', 'readany:motorpolicy'],
  },
  {
    label: 'Payments',
    key: PolicyMenuItemKey.Payments,
    icon: <PaymentsOutlinedIcon />,
    content: <PaymentsDrawerContent />,
    permissions: ['readanypolicies:support', 'readany:motorpolicy'],
  },
  {
    label: 'Documents',
    key: PolicyMenuItemKey.Documents,
    icon: <FolderOpenIcon />,
    content: <DocumentsDrawerContent />,
    permissions: ['read:motorpolicy'],
  },
  {
    label: "New MTA's",
    key: PolicyMenuItemKey.NewMta,
    icon: <NoteAddOutlinedIcon />,
    content: <NewMtaDrawerContent />,
    permissions: ['adjust:motorpolicy'],
  },
  {
    label: 'History',
    key: PolicyMenuItemKey.History,
    icon: <HistoryOutlinedIcon />,
    content: <HistoryDrawerContent />,
    permissions: ['read:motorpolicy'],
  },
  {
    label: 'Claims',
    key: PolicyMenuItemKey.Claims,
    icon: <ContentPasteIcon />,
    content: <ClaimsDrawerContent />,
    permissions: ['read:motorpolicy'],
  },
  {
    label: 'Account',
    key: PolicyMenuItemKey.Account,
    icon: <AccountCircleOutlinedIcon />,
    content: <AccountDrawerContent />,
    permissions: ['read:motorpolicy'],
  },
  {
    label: 'Notes',
    key: PolicyMenuItemKey.Notes,
    icon: <EditNoteIcon />,
    content: <NotesDrawerContent />,
    permissions: ['readany:note', 'readany:motorpolicy'],
  },
  {
    label: 'Actions',
    key: PolicyMenuItemKey.Actions,
    icon: <DoDisturbAltOutlinedIcon />,
    content: <ActionsDrawerContent />,
    permissions: ['read:motorpolicy', 'cancel:motorpolicy'],
  },
];
