/* eslint-disable max-lines */
import { ThemeOptions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const getActivePalette = (value: string | undefined): 'secondary' | 'primary' =>
  value === 'secondary' ? 'secondary' : 'primary';

export const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      color: 'secondary',
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
      }),
      text: ({ theme, ownerState }) => ({
        background: theme.palette[getActivePalette(ownerState.color)].light,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        '.MuiTab-root:hover': {
          color: theme.palette[getActivePalette(ownerState.textColor)].main,
        },
      }),
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: () => ({
        position: 'relative',
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      body: ({ theme }) => ({
        color: theme.palette.grey[500],
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[300],
        color: theme.palette.grey[600],
      }),
    },
  },
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
      square: true,
      disableGutters: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&::before': {
          display: 'none',
        },
        '&::after': {
          position: 'absolute',
          left: '0',
          bottom: '1px',
          right: '0',
          height: '1px',
          content: '""',
          backgroundColor: theme.palette.grey[300],
        },
      }),
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMoreIcon />,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4),
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.fontSize,
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSelect: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.fontSize,
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        width: 'unset !important',
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiStack: {
    defaultProps: {
      direction: 'row',
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[300],
      }),
    },
  },
  MuiDrawer: {
    defaultProps: {
      anchor: 'right',
      hideBackdrop: true,
      elevation: 2,
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiAlert: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
    variants: [
      {
        props: {
          severity: 'success',
        },
        style: ({ theme }) => ({
          color: theme.palette.success.main,
          background: theme.palette.success.light,
        }),
      },
      {
        props: {
          severity: 'info',
        },
        style: ({ theme }) => ({
          color: theme.palette.info.main,
          background: theme.palette.info.light,
        }),
      },
      {
        props: {
          severity: 'warning',
        },
        style: ({ theme }) => ({
          color: theme.palette.warning.main,
          background: theme.palette.warning.light,
        }),
      },
      {
        props: {
          severity: 'error',
          variant: 'standard',
        },
        style: ({ theme }) => ({
          color: theme.palette.error.main,
          background: theme.palette.error.light,
          fontWeight: theme.typography.fontWeightMedium,
        }),
      },
    ],
  },
  MuiList: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiTypography: {
    variants: [
      {
        props: {
          variant: 'subtitle1',
        },
        style: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold,
        }),
      },
    ],
  },
  MuiCard: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        position: 'relative',
        margin: `${theme.spacing(16)} auto`,
        maxWidth: '600px',
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(6),
      }),
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: `${theme.spacing(6)} 0`,
        margin: `0 ${theme.spacing(6)}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        '& > *': {
          flex: 1,
        },
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        ':hover': {
          color: theme.palette.secondary.dark,
        },
      }),
    },
  },
};
