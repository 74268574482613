import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { NewMtaFormSchema } from '../../hooks';
import { NewMtaCoverDetails } from './components/NewMtaCoverDetails';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useCoverOptionsQuery } from '@/hooks/queries/useCoverOptionsQuery';
import { getProductInfo } from '@/helpers/data/retailHelpers';
import { useNoClaimsDiscountYearsQuery } from '@/hooks/queries/useNoClaimsDiscountYearsQuery';

export function NewMtaCover() {
  const { watch } = useFormContext<NewMtaFormSchema>();
  const policy = watch('_policy');

  const {
    isPending: isCoverOptionsQueryPending,
    isError: isCoverOptionsQueryError,
    data: coverOptions,
  } = useCoverOptionsQuery({
    ...getProductInfo(policy),
    schemeName: policy.scheme?.description ?? 'everyday_scheme',
  });
  const {
    data: noClaimsDiscountYears,
    isPending: isNoClaimsDiscountYearsQueryPending,
    isError: isNoClaimsDiscountYearsQueryError,
  } = useNoClaimsDiscountYearsQuery(getProductInfo(policy));

  const isError = isCoverOptionsQueryError || isNoClaimsDiscountYearsQueryError;
  const isPending = isCoverOptionsQueryPending || isNoClaimsDiscountYearsQueryPending;

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return (
    <Box p={6}>
      <NewMtaCoverDetails
        coverOptions={coverOptions!}
        noClaimsDiscountYears={noClaimsDiscountYears!}
      />
    </Box>
  );
}
