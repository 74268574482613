import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { getMtaMinPurchaseDate } from '@/features/NewMta/helpers/newMtaFormHelper';
import { FormDatePicker } from '@/components/ui/forms/FormDatePicker';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';
import { getDate } from '@/helpers/utils/dateHelpers';

export function NewMtaCarPurchaseDate() {
  const { watch } = useFormContext<NewMtaFormSchema>();
  const purchasedYet = watch('car.purchasedYet');

  return (
    <section aria-label="Car purchase date">
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Purchase date
      </Typography>
      <FormRadioGroup
        name="car.purchasedYet"
        label="Purchased yet"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label="Yes" value={YesNo.Yes} />
        <FormRadio label="No" value={YesNo.No} />
      </FormRadioGroup>
      {purchasedYet === YesNo.Yes && (
        <FormDatePicker
          name="car.datePurchased"
          label="Purchase date"
          minDate={getMtaMinPurchaseDate()}
          maxDate={getDate()}
          sx={{ ...commonSx, ...maxWidthSx }}
        />
      )}
    </section>
  );
}
