import { CommunicationMethod, NoteInitiator, NoteType } from '@/api/note';

export function getNoteTypeName(noteType: NoteType): string {
  switch (noteType) {
    case NoteType.GENERAL_NOTE:
      return 'General note';
    case NoteType.CHARGE_BACKS:
      return 'Charge backs';
    case NoteType.CLAIM_NOTIFICATION:
      return 'Claim notification';
    case NoteType.CLAIM:
      return 'Claim';
    case NoteType.COMPLAINT:
      return 'Complaint';
    case NoteType.PAYMENT_DISPUTES:
      return 'Payment disputes';
    case NoteType.CANCELLATION:
      return 'Cancellation';
    case NoteType.POST_POLICY_DOCUMENTS:
      return 'Post policy documents';
    case NoteType.RESTRICTION:
      return 'Restriction';
    case NoteType.CORRESPONDENCE:
      return 'Correspondence';
    case NoteType.MID_TERM_ADJUSTMENTS:
      return 'Mid term adjustments';
    case NoteType.NO_CLAIMS_BONUS_RELATED:
      return 'No claims bonus related';
    case NoteType.BEREAVEMENT_NOTIFICATION:
      return 'Bereavement notification';
    case NoteType.AMENDMENT_TO_POLICY:
      return 'Amendment to policy';
    case NoteType.COOLING_OFF_PERIOD:
      return 'Cooling off period';
    case NoteType.RENEWAL_ENQUIRY:
      return 'Renewal enquiry';
    case NoteType.QUOTE_ENQUIRY:
      return 'Quote enquiry';
  }
}

export function getNoteInitiatorName(noteInitiator: NoteInitiator): string {
  switch (noteInitiator) {
    case NoteInitiator.SYSTEM:
      return 'System';
    case NoteInitiator.CUSTOMER_SERVICES:
      return 'Customer services';
    case NoteInitiator.CUSTOMER:
      return 'Customer';
  }
}

export function getCommunicationMethodName(
  communicationMethod: CommunicationMethod,
): string {
  switch (communicationMethod) {
    case CommunicationMethod.EMAIL:
      return 'Email';
    case CommunicationMethod.LETTER:
      return 'Letter';
    case CommunicationMethod.PHONE_CALL:
      return 'Phone call';
    case CommunicationMethod.LIVE_CHAT:
      return 'Live chat';
    case CommunicationMethod.SYSTEM:
      return 'System';
  }
}
