import { Box, Button, Divider, Typography } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { AccountFormSchema, useAccountForm } from '../hooks/useAccountForm';
import { mapAccountFormValuesToAccount } from '../helpers/mapAccountFormValuesToAccount';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { Account } from '@/api/support';
import { useAccountMutation } from '@/hooks/mutations/useAccountMutation';
import { FormCheckbox } from '@/components/ui/forms/FormCheckbox';
import { FormCheckboxGroup } from '@/components/ui/forms/FormCheckboxGroup';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { useUser } from '@/hooks/useUser';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { useSetAlertError } from '@/hooks/useSetAlertError';

type AccountFormProps = {
  initialAccount?: Account;
  policyId: string;
};

export function AccountForm({ initialAccount, policyId }: AccountFormProps) {
  const { setAlert } = useSnackbarAlert();
  const { hasPermission } = useUser();
  const isUpdateAvailable = hasPermission('policyholderaccountupdate:motorpolicy');

  const {
    mutate: saveAccount,
    isPending: isSubmitPending,
    isError: isSubmitError,
  } = useAccountMutation({
    onSuccess: () =>
      setAlert({ message: 'Account has been updated', severity: 'success' }),
  });

  const form = useAccountForm(initialAccount);
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = form;

  const onSubmitForm: SubmitHandler<AccountFormSchema> = async (
    data: AccountFormSchema,
  ) => {
    const account = mapAccountFormValuesToAccount(data);

    await saveAccount({ accountData: account, policyId });
    reset(getValues());
  };

  const handleCancel = () => {
    reset();
  };

  useSetAlertError(isSubmitError);

  return (
    <FormProvider {...form}>
      <StyledDrawerForm onSubmit={handleSubmit(onSubmitForm)}>
        <Box height="100%" m={6}>
          <FormTextField
            disabled
            sx={{ ...commonSx, ...maxWidthSx }}
            name="email"
            id="email"
            label="Email address"
          />
          <FormTextField
            disabled={!isUpdateAvailable}
            sx={{ ...commonSx, ...maxWidthSx }}
            name="landline"
            id="landline"
            label="Telephone number"
          />
          <FormTextField
            disabled={!isUpdateAvailable}
            sx={{ ...commonSx, ...maxWidthSx }}
            name="mobile"
            id="mobile"
            label="Mobile number"
          />
          <Divider sx={{ my: 6 }} />
          <Typography variant="subtitle1" sx={{ mb: 6 }}>
            Marketing preferences
          </Typography>
          <FormCheckboxGroup
            name="marketing-preferences"
            data-testid="marketing-preferences"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            <FormCheckbox
              disabled={!isUpdateAvailable}
              name="allowedToMarketByEmail"
              label="Email"
            />
            <FormCheckbox
              disabled={!isUpdateAvailable}
              name="allowedToMarketBySMS"
              label="SMS"
            />
            <FormCheckbox
              disabled={!isUpdateAvailable}
              name="allowedToMarketByPhone"
              label="Telephone"
            />
            <FormCheckbox
              disabled={!isUpdateAvailable}
              name="allowedToMarketByPost"
              label="Post"
            />
          </FormCheckboxGroup>
          <Divider sx={{ my: 6 }} />
          <Typography variant="subtitle1" sx={{ mb: 6 }}>
            Auto-renew policy
          </Typography>
          <FormRadioGroup
            disabled={!isUpdateAvailable}
            name="autoRenewPolicy"
            data-testid="autoRenewPolicy"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
            <FormRadio label={YesNo.No} value={YesNo.No} />
          </FormRadioGroup>
        </Box>
        {isUpdateAvailable && (
          <StyledButtonsFooter sticky>
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={isSubmitPending}
              disabled={!isDirty}
            >
              Save changes
            </LoadingButton>
            <Button variant="outlined" color="inherit" onClick={handleCancel}>
              Cancel changes
            </Button>
          </StyledButtonsFooter>
        )}
      </StyledDrawerForm>
    </FormProvider>
  );
}
