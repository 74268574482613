import { List, Typography } from '@mui/material';
import { HistoryAddonContent } from '../HistoryAddonContent';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { MotorPolicy } from '@/api/motorpolicy';
import { getExcessName } from '@/helpers/nameMappings/excessNameMappings';
import { AddOn } from '@/api/motorpolicy';
import { booleanAnswer, convertPascalCaseToText } from '@/helpers/utils/textHelpers';
import {
  getCompulsoryExcessesIndividualItems,
  getExcessesTotalAmount,
} from '@/helpers/data/excessesHelpers';
import { getPolicyEndDate, getPolicyStartDate } from '@/helpers/data/policyHelpers';

type HistoryCoverContentProps = {
  policy: MotorPolicy;
  policyVersion: number;
};

export function HistoryCoverContent({
  policy,
  policyVersion,
  policy: {
    cover,
    endorsements,
    excesses = {},
    addOns,
    wasProtectedNcbAtQuoteCreation,
    product,
    cancelledDateTimeUtc,
  },
}: HistoryCoverContentProps) {
  const transaction = policy.transactions![policyVersion - 1];
  const compulsoryExcessAmount = getExcessesTotalAmount(excesses.compulsoryAmounts ?? []);
  const voluntaryExcessAmount = getExcessesTotalAmount(excesses.voluntaryAmounts ?? []);
  const compulsoryExcesses = getCompulsoryExcessesIndividualItems(excesses);
  const totalExcessAmount = compulsoryExcessAmount + voluntaryExcessAmount;

  return (
    <List>
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Details
      </Typography>
      <ListRow disablePadding label="Start date">
        {formatDateTime(getPolicyStartDate(policy, transaction))}
      </ListRow>
      <ListRow disablePadding label="End date">
        {(cover.endDateTimeUtc || cancelledDateTimeUtc) &&
          formatDateTime(getPolicyEndDate(policy))}
      </ListRow>
      <ListRow disablePadding label="Product">
        {product.description}
      </ListRow>
      <ListRow disablePadding label="Cover type">
        {cover.type.description}
      </ListRow>
      <ListRow disablePadding label="Underwriter">
        {cover.underwriter}
      </ListRow>
      <ListRow disablePadding label="Total excess">
        {formatCurrency(totalExcessAmount)}
      </ListRow>
      <ListRow disablePadding label="Voluntary excess">
        {formatCurrency(voluntaryExcessAmount)}
      </ListRow>
      <ListRow disablePadding label="Compulsory excess">
        {formatCurrency(compulsoryExcessAmount)}
      </ListRow>
      {compulsoryExcesses?.map((excess, index) => (
        <ListRow
          disablePadding
          key={index}
          label={getExcessName(excess.type?.code, excess.description)}
        >
          {excess.total && formatCurrency(excess.total)}
        </ListRow>
      ))}
      <ListRow disablePadding label="NCB Protection Requested">
        {booleanAnswer(wasProtectedNcbAtQuoteCreation)}
      </ListRow>
      {addOns && addOns.length > 0 && (
        <Typography variant="subtitle1" sx={{ my: 4 }}>
          Add-ons
        </Typography>
      )}
      {addOns?.map((addon: AddOn, addonNumber) => (
        <HistoryAddonContent
          key={`addon-#${addonNumber}`}
          addon={addon}
          addonNumber={addonNumber + 1}
        />
      ))}
      {endorsements && endorsements.length > 0 && (
        <Typography variant="subtitle2" sx={{ mt: 4, mb: 2 }}>
          Conditions (endorsements)
        </Typography>
      )}
      {endorsements?.map(endorsement => (
        <ListRow
          key={endorsement.code}
          label={convertPascalCaseToText(endorsement.name ?? '')}
        >
          {endorsement.wording}
        </ListRow>
      ))}
    </List>
  );
}
