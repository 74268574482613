import { Alert, Button, Divider, List, Typography } from '@mui/material';
import { ExcessAmount } from '@/api/motorquote';
import { ListRow } from '@/components/ui/lists/ListRow';
import { Modal } from '@/components/ui/modals/Modal';
import { useCreateOrderPromptContext } from '@/features/PaymentActions/QuoteCalculation';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';

type NewMtaExcessesModalProps = {
  excesses: ExcessAmount[];
};

export function NewMtaExcessesModal({ excesses }: NewMtaExcessesModalProps) {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();

  return (
    <Modal onClose={cancelPrompt}>
      <Modal.Header>Excesses</Modal.Header>
      <Modal.Content>
        <Alert severity="warning">
          There are new excesses that will be applied to this policy
        </Alert>
        <Typography variant="body2">
          Please confirm with the customer that making these changes mean there are new
          excesses that will be applied to the policy. They will need to agree to the
          following, before continuing.
        </Typography>
        <Divider />
        <List>
          {excesses.map((excess, index) => (
            <ListRow
              disablePadding
              alignContentRight
              key={index}
              label={excess.description ?? ''}
            >
              {excess.total != null && formatCurrency(excess.total)}
            </ListRow>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" onClick={confirmPrompt}>
          Confirm and continue
        </Button>
        <Button variant="outlined" color="inherit" onClick={cancelPrompt}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
