import { DatePicker, DatePickerProps, DatePickerSlotProps } from '@mui/x-date-pickers';
import { useController, useFormContext } from 'react-hook-form';
import { Box, Stack, StackProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { endOfDay, isAfter, isValid, startOfDay } from 'date-fns';
import { getErrorProps } from '@/helpers/utils/formHelpers';

type DateFieldValue = Date | null;

type FormDateRangePickerProps = {
  dateFromFieldName?: string;
  dateToFieldName?: string;
  minDate?: Date;
  maxDate?: Date;
  stackProps?: StackProps;
} & Omit<DatePickerProps<Date, boolean>, 'minDate' | 'slotProps'>;

export function FormDateRangePicker({
  minDate,
  maxDate,
  dateFromFieldName = 'dateFrom',
  dateToFieldName = 'dateTo',
  stackProps,
  ...props
}: FormDateRangePickerProps) {
  const { control, setValue, watch } = useFormContext();
  const {
    field: dateFromField,
    fieldState: { error: dateFromFieldError },
  } = useController({
    name: dateFromFieldName,
    control,
  });
  const {
    field: dateToField,
    fieldState: { error: dateToFieldError },
  } = useController({
    name: dateToFieldName,
    control,
  });
  const dateFromFieldValue = watch(dateFromFieldName) as DateFieldValue;
  const dateToFieldValue = watch(dateToFieldName) as DateFieldValue;
  const [derivedDateTo, setDerivedDateTo] = useState(dateToFieldValue);

  const getSlotProps = (
    fieldName: typeof dateFromFieldName | typeof dateToFieldName,
  ): DatePickerSlotProps<Date, false> => ({
    textField: {
      ...getErrorProps(
        fieldName === dateFromFieldName ? dateFromFieldError : dateToFieldError,
      ),
      size: 'small',
    },
  });

  useEffect(() => {
    if (
      dateFromFieldValue &&
      isValid(dateFromFieldValue) &&
      (!derivedDateTo || (derivedDateTo && isAfter(dateFromFieldValue, derivedDateTo)))
    ) {
      setDerivedDateTo(dateFromFieldValue);
    }
  }, [dateFromFieldValue]);

  useEffect(() => {
    if (derivedDateTo) {
      setValue(dateToFieldName, endOfDay(derivedDateTo));
    }
  }, [derivedDateTo, setValue]);

  return (
    <Stack {...stackProps}>
      <DatePicker
        {...dateFromField}
        name={dateFromFieldName}
        label="Date from"
        slotProps={getSlotProps(dateFromFieldName)}
        onChange={value => dateFromField.onChange(value ? startOfDay(value) : null)}
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
      <Box p={1}>&mdash;</Box>
      <DatePicker
        {...dateToField}
        name={dateToFieldName}
        label="Date to"
        slotProps={getSlotProps(dateToFieldName)}
        onChange={value => dateToField.onChange(value ? endOfDay(value) : null)}
        minDate={dateFromFieldValue ?? minDate}
        maxDate={maxDate}
        {...props}
      />
    </Stack>
  );
}
