import { useEffect } from 'react';
import { useSnackbarAlert } from './useSnackbarAlert';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';

export function useSetAlertError(isError: boolean, errorMessage = GENERAL_ERROR_MSG) {
  const { setAlert } = useSnackbarAlert();

  useEffect(() => {
    if (isError) {
      setAlert({ message: errorMessage, severity: 'error' });
    }
  }, [isError]);
}
