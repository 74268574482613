import { NewMtaFormSchema } from '../../hooks/schemas/newMta.schema';
import {
  DriversSharedFieldsSchema,
  STUDENT_STATUS_CODE,
} from '../../hooks/schemas/newMtaDriversSharedFields.schema';
import { getDefaultValueForReferenceData } from '../getDefaultValueForReferenceData';
import { joinAddressLines } from '../../components/NewMtaDrivers/helpers/joinAddressLines';
import { parseDateOnly } from '@/helpers/utils/dateHelpers';
import { sortArrayByBooleanKey } from '@/helpers/utils/arrayHelpers';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { Driver } from '@/api/motorpolicy';
import { Policyholder } from '@/api/motorpolicy';
import {
  MotorClaim,
  MotorConviction,
  AdvancedDrivingQualification,
} from '@/api/motorpolicy';

const mapPolicyToNewMtaClaimForm = ({
  source,
  atFault,
  settled,
  type,
  date,
  damageAmount,
}: MotorClaim): NewMtaFormSchema['drivers'][number]['claims'][number] => ({
  _isExisting: true,
  _source: source,
  type: type!.code!,
  atFault: booleanAnswer(atFault),
  settled: booleanAnswer(settled),
  date: date ? parseDateOnly(date) : null,
  damageAmount: damageAmount?.amount?.toString() ?? '',
});

const mapPolicyToNewMtaConvictionForm = ({
  type,
  date,
  penaltyPoints,
  fineAmount,
  disqualifiedLengthInMonths,
}: MotorConviction): NewMtaFormSchema['drivers'][number]['convictions'][number] => ({
  _isExisting: true,
  type: getDefaultValueForReferenceData(type),
  date: date ? parseDateOnly(date) : null,
  penaltyPoints: penaltyPoints?.toString() ?? '',
  fineAmount: fineAmount?.amount?.toString() ?? '',
  banReceived: booleanAnswer(!!disqualifiedLengthInMonths),
  disqualifiedLengthInMonths: disqualifiedLengthInMonths?.toString() ?? '',
});

const mapPolicyToNewMtaQualificationForm = ({
  type,
  dateQualified,
}: AdvancedDrivingQualification): NewMtaFormSchema['drivers'][number]['advanceDrivingQualifications'][number] => ({
  type: type?.code ?? null,
  date: dateQualified ? parseDateOnly(dateQualified) : null,
});

const mapPolicyToNewMtaDriverSharedFields = (
  driver: Driver,
): DriversSharedFieldsSchema => {
  const {
    id,
    firstName,
    lastName,
    dateOfBirth,
    title,
    maritalStatus,
    ukResidencyDetails,
    occupations,
    isMainDriver,
    drivingLicence,
    otherVehicleUse,
    advancedDrivingQualifications,
    medicalCondition,
    previousMotorClaims,
    motorConvictions,
    hasNonMotoringConvictions,
  } = driver;
  const primaryOccupation = occupations[0];
  const secondaryOccupation = occupations[1];

  return {
    _isComplete: true,
    _driverId: id,
    _isMainDriver: isMainDriver,
    firstName,
    surname: lastName,
    dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
    title: title?.code ?? '',
    maritalStatus: maritalStatus?.code ?? '',
    isUkResidentSinceBirth: booleanAnswer(ukResidencyDetails?.isUkResidentSinceBirth),
    ukResidentSince: ukResidencyDetails?.ukResidentSince
      ? parseDateOnly(ukResidencyDetails.ukResidentSince)
      : null,
    employmentStatus: primaryOccupation.employmentStatus?.code ?? '',
    anyOtherJob: booleanAnswer(occupations.length > 1),
    studentType:
      primaryOccupation.employmentStatus?.code === STUDENT_STATUS_CODE
        ? primaryOccupation.type?.code ?? null
        : null,
    jobTitle: getDefaultValueForReferenceData(primaryOccupation.type),
    jobIndustry: getDefaultValueForReferenceData(primaryOccupation.industry),
    secondaryJobTitle: getDefaultValueForReferenceData(secondaryOccupation?.type),
    secondaryJobIndustry: getDefaultValueForReferenceData(secondaryOccupation?.industry),
    drivingLicenceProvided: booleanAnswer(!!drivingLicence?.number),
    drivingLicenceNumber: drivingLicence?.number ?? '',
    drivingLicenceType: drivingLicence?.type?.code ?? '',
    yearsHeldLicence: drivingLicence?.yearsHeld ?? 0,
    monthsHeldLicence:
      drivingLicence?.yearsHeld === 0
        ? drivingLicence?.monthsHeld?.toString() ?? ''
        : undefined,
    ownOrDriveAnotherCar: booleanAnswer(otherVehicleUse && otherVehicleUse.code !== 'NO'),
    otherVehicleUsage: otherVehicleUse?.code ?? '',
    hasAdvanceDrivingQualifications: booleanAnswer(
      !!advancedDrivingQualifications.length,
    ),
    advanceDrivingQualifications: advancedDrivingQualifications.map(
      mapPolicyToNewMtaQualificationForm,
    ),
    medicalConditions: booleanAnswer(!!medicalCondition),
    medicalCondition: medicalCondition?.code ?? '',
    hasClaims: booleanAnswer(!!previousMotorClaims?.length),
    claims: (previousMotorClaims ?? []).map(mapPolicyToNewMtaClaimForm),
    hasConvictions: booleanAnswer(!!(motorConvictions ?? []).length),
    convictions: (motorConvictions ?? []).map(mapPolicyToNewMtaConvictionForm),
    hasNonMotoringConvictions: booleanAnswer(hasNonMotoringConvictions),
  };
};

const mapPolicyToNewMtaDriverForm = (
  driver: Driver,
  policyholder: Policyholder,
): NewMtaFormSchema['drivers'][number] => {
  const { isPolicyholder, relationshipToPolicyholder } = driver;
  const sharedFields = mapPolicyToNewMtaDriverSharedFields(driver);
  const { postCode: _postCode, ...addressLines } =
    policyholder.address?.formattedAddress ?? {};

  if (isPolicyholder) {
    return {
      ...sharedFields,
      _isPolicyholder: true,
      postCodeForSearch: policyholder.address?.formattedAddress?.postCode ?? '',
      _queryPostCodeForSearch: policyholder.address?.formattedAddress?.postCode ?? '',
      _fullAddress: joinAddressLines(addressLines),
      postOfficeAddress: policyholder.address?.postOfficeAddress ?? {},
      isHomeOwner: booleanAnswer(policyholder.isHomeOwner),
      numberOfVehiclesInHousehold:
        policyholder.numberOfVehiclesInHousehold?.toString() ?? '',
      childrenUnder16InHousehold:
        policyholder.childrenUnder16InHousehold?.toString() ?? '',
    };
  }

  return {
    ...sharedFields,
    _isPolicyholder: false,
    relationshipToPolicyholder: relationshipToPolicyholder?.code ?? '',
  };
};

export const mapPolicyToNewMtaDriversForm = (
  drivers: Driver[],
  policyholder: Policyholder,
): NewMtaFormSchema['drivers'] =>
  sortArrayByBooleanKey(drivers, 'isPolicyholder').map(driver =>
    mapPolicyToNewMtaDriverForm(driver, policyholder),
  );
