import { useLayoutEffect, useRef } from 'react';

type UseCalculateContentPositionProps = {
  rotate?: number;
  scale?: number;
};

function calculatePosition(containerValue: number, contentValue: number): string {
  const offset = 24;
  return contentValue > containerValue - offset * 2
    ? `${(contentValue - containerValue) / 2 + offset}px`
    : '';
}

export function useCalculateContentPosition({
  rotate,
  scale,
}: UseCalculateContentPositionProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const positionRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const containerElement = containerRef.current;
    const positionElement = positionRef.current;
    const contentElement = contentRef.current;

    if (containerElement && positionElement && contentElement) {
      const { width: containerWidth, height: containerHeight } =
        containerElement.getBoundingClientRect();
      const { width: contentWidth, height: contentHeight } =
        contentElement.getBoundingClientRect();

      positionElement.style.top = calculatePosition(containerHeight, contentHeight);
      positionElement.style.left = calculatePosition(containerWidth, contentWidth);
    }
  }, [rotate, scale]);

  return {
    containerRef,
    positionRef,
    contentRef,
  };
}
