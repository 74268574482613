export const CURRENCY = '£';

export const formatCurrency = (
  amount: number | string,
  currency = CURRENCY,
  suffix = false,
) => {
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  const isNegative = numericAmount < 0;
  const formattedAmount = numericAmount.toFixed(2);
  const absoluteFormattedAmount = Math.abs(numericAmount).toFixed(2);

  if (suffix) {
    return `${formattedAmount} ${currency}`;
  } else {
    return isNegative
      ? `-${currency}${absoluteFormattedAmount}`
      : `${currency}${formattedAmount}`;
  }
};
