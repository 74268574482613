import { useCreateMtaMutation } from '@/hooks/mutations/useCreateMtaMutation';
import { useMotorQuoteQuery } from '@/hooks/queries/useMotorQuoteQuery';
import { CreateMtaResponse } from '@/api/motorpolicy';
import { MotorQuote } from '@/api/motorquote';

export function useNewMtaQuote() {
  const {
    mutate: createMta,
    data: { quoteId, ...createMtaMutationData } = {},
    isPending: isCreateMtaMutationPending,
    isError: isCreateMtaMutationError,
    error,
    reset: resetMtaMutation,
  } = useCreateMtaMutation();
  const {
    data: motorQuoteQueryData,
    isFetching: isMtaQuoteQueryFetching,
    isError: isMtaQuoteQueryError,
  } = useMotorQuoteQuery(quoteId ?? '', {
    enabled: !!quoteId,
    retryOnMount: false,
  });

  return {
    createMta,
    resetMtaMutation,
    quote: (createMtaMutationData && motorQuoteQueryData
      ? { ...createMtaMutationData, ...motorQuoteQueryData }
      : null) as (CreateMtaResponse & MotorQuote) | null,
    isError: isCreateMtaMutationError || isMtaQuoteQueryError,
    error,
    isPending: isMtaQuoteQueryFetching || isCreateMtaMutationPending,
  };
}
