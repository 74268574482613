import { PropsWithChildren } from 'react';
import { SkeletonLoader } from '../SkeletonLoader';

export function LoaderWrapper({
  children,
  isLoading,
  repeat,
}: PropsWithChildren<{ isLoading: boolean; repeat?: number }>) {
  if (isLoading) {
    return <SkeletonLoader repeat={repeat} />;
  }
  return <>{children}</>;
}
