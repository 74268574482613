import { useLayoutEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  ConvictionFormSchema,
  NewMtaFormSchema,
  useNewMtaDriverContext,
} from '@/features/NewMta/hooks';
import { YesNo } from '@/models/YesNo';

export function useDriverConvictionsFormFields() {
  const {
    formState: { errors },
    control,
    setValue,
    trigger,
    watch,
  } = useFormContext<NewMtaFormSchema>();
  const { nestIndex, getFieldName } = useNewMtaDriverContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: getFieldName('convictions'),
  });
  const formError = errors.drivers?.[nestIndex]?._isAddingNewConviction;
  const isAddingNewConviction = watch(getFieldName('_isAddingNewConviction'));
  const hasConvictions = watch(getFieldName('hasConvictions')) === YesNo.Yes;

  const showForm = () => {
    setValue(getFieldName('_isAddingNewConviction'), true);
  };

  const hideForm = () => {
    setValue(getFieldName('_isAddingNewConviction'), false);
    trigger(getFieldName('_isAddingNewConviction'));
  };

  const handleAddConviction = (formValues: ConvictionFormSchema) => {
    append(formValues);
    hideForm();
  };

  useLayoutEffect(() => {
    if (hasConvictions && fields.length === 0) {
      showForm();
    }

    if (!hasConvictions) {
      hideForm();
    }
  }, [hasConvictions]);

  useLayoutEffect(() => {
    if (hasConvictions && fields.length === 0 && !isAddingNewConviction) {
      setValue(getFieldName('hasConvictions'), YesNo.No);
    }
  }, [fields, isAddingNewConviction]);

  return {
    formError,
    hasConvictions,
    isAddingNewConviction,
    fields,
    remove,
    showForm,
    hideForm,
    handleAddConviction,
  };
}
