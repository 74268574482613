import { List, Typography } from '@mui/material';
import { MotorPolicy } from '@/api/motorpolicy';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { getTransactionTypesNames } from '@/helpers/nameMappings/transactionNameMappings';
import { PolicyTransactionType } from '@/api/motorpolicy';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';

type HistoryPaymentsContentProps = {
  policy: MotorPolicy;
  policyVersion: number;
};

export function HistoryPaymentsContent({
  policy,
  policyVersion,
}: HistoryPaymentsContentProps) {
  const payment = policy.transactions![policyVersion - 1];
  const fees = payment.price?.fees ?? [];
  const addons = payment.price?.addOns ?? [];

  return (
    <>
      <List data-testid={'history_payments-transaction'}>
        <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
          Transaction
        </Typography>
        <ListRow disablePadding label="Type">
          {getTransactionTypesNames(payment.type as PolicyTransactionType)}
        </ListRow>
        <ListRow disablePadding label="Net premium">
          {payment.price?.netPremium?.amount &&
            formatCurrency(payment.price.netPremium.amount)}
        </ListRow>
        <ListRow disablePadding label="Commission">
          {payment.price?.commission?.amount &&
            formatCurrency(payment.price.commission.amount)}
        </ListRow>
        <ListRow disablePadding label="IPT">
          {payment.price?.insurancePremiumTax?.amount &&
            formatCurrency(payment.price.insurancePremiumTax.amount)}
        </ListRow>
      </List>
      {fees?.map((fee, index) => (
        <List data-testid={`history_payments-transaction_fee-${index + 1}`} key={index}>
          <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
            Fee #{index + 1}
          </Typography>
          <ListRow disablePadding label={'Type'}>
            {fee.type && getFeeTypesName(fee.type)}
          </ListRow>
          <ListRow disablePadding label={'Amount'}>
            {fee.total?.amount && formatCurrency(fee.total?.amount)}
          </ListRow>
        </List>
      ))}
      {addons?.map((addon, index) => (
        <List data-testid={`history_payments-transaction_addon-${index + 1}`} key={index}>
          <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
            Addon #{index + 1}
          </Typography>
          <ListRow disablePadding label={'Type'}>
            {addon.type?.description}
          </ListRow>
          <ListRow disablePadding label={'Net premium'}>
            {addon.price?.netPremium?.amount &&
              formatCurrency(addon.price.netPremium.amount)}
          </ListRow>
          <ListRow disablePadding label={'Commission'}>
            {addon.price?.commission?.amount &&
              formatCurrency(addon.price.commission.amount)}
          </ListRow>
          <ListRow disablePadding label={'IPT'}>
            {addon.price?.insurancePremiumTax?.amount &&
              formatCurrency(addon.price.insurancePremiumTax.amount)}
          </ListRow>
        </List>
      ))}
    </>
  );
}
