import {
  invalidNameCharactersRegex,
  multipleSpacesRegex,
} from '@/helpers/constants/regex';

export const capitalizeFirstLetter = (value: string) =>
  value
    .split(' ')
    .map(word => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    })
    .join(' ');

export const removeInvalidNameCharacters = (value: string) =>
  value.replace(invalidNameCharactersRegex, '');

export const removeMultipleSpaces = (value: string) =>
  value.replace(multipleSpacesRegex, '');

export const trimWhitespaceStart = (value: string) => value.trimStart();

export const uppercase = (value: string) => value.toUpperCase();
