import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { useController, useFormContext } from 'react-hook-form';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/utils/formHelpers';

type FormDatePickerProps = {
  name: string;
  testId?: string;
} & DatePickerProps<Date, boolean>;

export function FormDatePicker({ name, testId, ...props }: FormDatePickerProps) {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <DatePicker
      {...field}
      {...props}
      {...overrideReactHookFormRef}
      slotProps={{
        textField: { ...getErrorProps(error), inputProps: { 'data-testid': testId } },
      }}
    />
  );
}
