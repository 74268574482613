import { Alert, Button, Typography } from '@mui/material';
import { Modal } from '@/components/ui/modals/Modal';
import { useCreateOrderPromptContext } from '@/features/PaymentActions/QuoteCalculation';

export function PaymentCycleModal() {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();
  return (
    <Modal onClose={cancelPrompt}>
      <Modal.Header>Direct Debit payment in progress</Modal.Header>
      <Modal.Content>
        <Alert severity="warning">
          The Direct Debit payment for this policy is currently in progress
        </Alert>
        <Typography variant="body2">
          Please confirm you have told the customer that any amounts quoted, are based on
          the Direct Debit payment completing successfully and subject to no claims, or
          incidents that will likely to give rise to a claim, on the policy. Please also
          tell the customer not to cancel their existing Direct Debit.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" onClick={confirmPrompt}>
          Confirm and continue
        </Button>
        <Button variant="outlined" color="inherit" onClick={cancelPrompt}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
