import { Alert, Button, Typography } from '@mui/material';
import { Modal } from '@/components/ui/modals/Modal';

type NewMtaRefusalModalProps = { codes: string[]; onClose: () => void };

export function NewMtaRefusalModal({ codes, onClose }: NewMtaRefusalModalProps) {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Quote refusal</Modal.Header>
      <Modal.Content>
        <Alert severity="warning">
          We can’t give the customer a quote (Code {codes.join(', Code ')})
        </Alert>
        <Typography variant="body2">
          Please confirm with the customer that we are unable to generate a new quote,
          based on the new details they have provided.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
