import { VulnerabilitiesFormSchema } from '@/features/Drivers/hooks/useVulnerabilitiesForm';
import { YesNo } from '@/models/YesNo';
import {
  UpdateCustomerVulnerabilitySupportRequest,
  AddCustomerVulnerabilitySupportRequest,
} from '@/api/support';

function transformCommonFormData({
  _noteId,
  temporary,
  consentObtained,
  followUpRequired,
  resolved,
  comment,
  category,
  type,
  action,
  followUpDate,
  description,
}: VulnerabilitiesFormSchema) {
  return {
    noteId: _noteId,
    category: category!,
    type: type!,
    actionRequired: action!,
    isTemporary: temporary === YesNo.Yes,
    consentObtained: consentObtained === YesNo.Yes,
    followUpRequired: followUpRequired === YesNo.Yes,
    followUpDate:
      (followUpRequired === YesNo.Yes || temporary === YesNo.Yes) && followUpDate
        ? followUpDate.toISOString()
        : null,
    resolved: resolved === YesNo.Yes,
    description,
    comment,
  };
}

export function transformAddVulnerabilityFormData({
  policyId,
  driverId,
  policyCorrelationId,
  ...schema
}: VulnerabilitiesFormSchema &
  Required<
    Pick<
      AddCustomerVulnerabilitySupportRequest,
      'driverId' | 'policyId' | 'policyCorrelationId'
    >
  >): AddCustomerVulnerabilitySupportRequest {
  const commonData = transformCommonFormData(schema);
  return {
    ...commonData,
    policyId,
    driverId,
    policyCorrelationId,
  };
}

export function transformUpdateVulnerabilityFormData(
  schema: VulnerabilitiesFormSchema,
): UpdateCustomerVulnerabilitySupportRequest {
  return transformCommonFormData(schema);
}
