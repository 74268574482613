import type { CreateSupportMtaRequest } from '../models/CreateSupportMtaRequest';
import type { CreateMtaResponse } from '../models/CreateMtaResponse';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class MtaService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postMtaSupportMta({
    requestBody,
  }: {
    requestBody?: CreateSupportMtaRequest;
  }): CancelablePromise<CreateMtaResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/Mta/support-mta',
      body: requestBody,
    });
  }
}
