import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { CoverOptions } from '@/api/retail/v2';

export type CoverOptionsParams = {
  productName?: string;
  productVersion?: string;
  schemeName: string;
};

export function useCoverOptionsQuery(
  { productName = 'motor', productVersion = 'latest', schemeName }: CoverOptionsParams,
  options?: Omit<UseQueryOptions<CoverOptions>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchCoverOptions = () =>
    api.retailV2.product.getProductSchemeCoverOptions({
      productName,
      productVersion,
      schemeName,
    });

  return useQuery<CoverOptions>({
    queryKey: queryKeys.coverOptions(productName, productVersion, schemeName),
    queryFn: fetchCoverOptions,
    ...options,
  });
}
