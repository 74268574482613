import { Divider, Stack, Typography } from '@mui/material';
import { useQuoteCalculationContext } from '../../../hooks/useQuoteCalculationContext';
import { useSelectedPaymentPlan } from '../../../hooks/useSelectedPaymentPlan';
import {
  getPaymentPlanAmount,
  getPaymentPlanLabel,
  getPaymentPlanPriceSuffix,
} from '@/helpers/data/paymentPlanHelpers';
import { FormattedPrice } from '@/components/ui/presentation/FormattedPrice';

export function QuoteCalculationPriceBreakdownTotal() {
  const { total } = useQuoteCalculationContext();
  const selectedPaymentPlan = useSelectedPaymentPlan();

  if (!selectedPaymentPlan) return null;

  const label = getPaymentPlanLabel(selectedPaymentPlan, total);
  const amount = getPaymentPlanAmount(selectedPaymentPlan, total);
  const absoluteAmount = amount != null ? Math.abs(amount) : null;
  const suffix = getPaymentPlanPriceSuffix(selectedPaymentPlan.type!.code!);

  return (
    <>
      <Divider />
      <Stack justifyContent="space-between" alignItems="center">
        <Typography variant="body2">{label}</Typography>
        <FormattedPrice price={absoluteAmount} suffix={suffix} />
      </Stack>
    </>
  );
}
