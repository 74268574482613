import { DetailsContent } from './DetailsContent';
import { LicenceAndHistoryContent } from './LicenceAndHistoryContent';
import { VulnerabilitiesContent } from './VulnerabilitiesContent';
import { CustomerVulnerability } from '@/api/vulnerabilities';
import {
  AccordionList,
  AccordionListItem,
  AccordionTitle,
} from '@/components/ui/lists/AccordionList';
import { Policyholder } from '@/api/motorpolicy';

type DriverContentProps = {
  policyholder: Policyholder;
  vulnerabilities: {
    isPending: boolean;
    isError: boolean;
    data: CustomerVulnerability[];
  };
};

export function DriverContent({ policyholder, vulnerabilities }: DriverContentProps) {
  const accordionItems: AccordionListItem[] = [
    {
      key: 'driver-details',
      header: <AccordionTitle>Additional driver’s details</AccordionTitle>,
      content: (
        <DetailsContent policyholder={policyholder} data-testid="driver-details" />
      ),
    },
    {
      key: 'driver-licence',
      header: <AccordionTitle>Additional driver’s licence and history</AccordionTitle>,
      content: <LicenceAndHistoryContent data-testid="driver-licence" />,
    },
    {
      key: 'driver-vulnerabilities',
      header: <AccordionTitle>Vulnerabilities</AccordionTitle>,
      content: (
        <VulnerabilitiesContent
          vulnerabilities={vulnerabilities}
          data-testid="driver-vulnerabilities"
        />
      ),
      sx: {
        details: {
          padding: 0,
        },
      },
    },
  ];

  return <AccordionList items={accordionItems} />;
}
