import { Box, BoxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { CURRENCY } from '@/helpers/utils/currencyHelpers';

type FormattedPriceProps = {
  price: number | null;
  suffix?: ReactNode;
} & BoxProps;

export function FormattedPrice({ price, suffix, ...props }: FormattedPriceProps) {
  if (!price) {
    return <Typography variant="h5">-</Typography>;
  }

  const [integer, decimal] = price.toFixed(2).split('.');

  return (
    <Box display="inline" {...props}>
      <Typography display="inline" variant="h5">
        {CURRENCY}
        {integer}
      </Typography>
      <Typography display="inline" variant="body2">
        .{decimal}
        {suffix}
      </Typography>
    </Box>
  );
}
