import { validationMessages } from '@/helpers/utils/textHelpers';
import { Permission } from '@/models/Permission';

export const patchFeeBandMaxDifferenceMap = {
  U: 1000000,
  E: 500,
  D: 250,
  C: 150,
  B: 100,
  A: 75,
} as const;

const getMaxFeeDifference = (userPermissions: Permission[]) => {
  if (userPermissions.includes('patchfeebandu:motorquote'))
    return patchFeeBandMaxDifferenceMap.U;
  if (userPermissions.includes('patchfeebande:motorquote'))
    return patchFeeBandMaxDifferenceMap.E;
  if (userPermissions.includes('patchfeebandd:motorquote'))
    return patchFeeBandMaxDifferenceMap.D;
  if (userPermissions.includes('patchfeebandc:motorquote'))
    return patchFeeBandMaxDifferenceMap.C;
  if (userPermissions.includes('patchfeebandb:motorquote'))
    return patchFeeBandMaxDifferenceMap.B;
  if (userPermissions.includes('patchfeebanda:motorquote'))
    return patchFeeBandMaxDifferenceMap.A;

  return 0;
};

const getMinMaxPremiumMsg = (amount: number, defaultAmount: number) => {
  if (defaultAmount < 0) {
    if (amount < defaultAmount) return validationMessages.minValue(defaultAmount);
    if (amount > 0) return validationMessages.maxValue(0);
  } else {
    if (amount < 0) return validationMessages.minValue(0);
    if (amount > defaultAmount) return validationMessages.maxValue(defaultAmount);
  }
};

export const validateMaxFeeDifference = ({
  fee,
  _originalFee,
  _userPermissions,
}: {
  fee: string;
  _originalFee: number;
  _userPermissions: Permission[];
}) => {
  const defaultFeeNumber = Number(_originalFee);
  const feeNumber = Number(fee);
  const feeDifference = Math.abs(feeNumber - defaultFeeNumber);
  return feeDifference <= getMaxFeeDifference(_userPermissions);
};

export const validateMaxFeeDifferenceMsg = ({
  _userPermissions,
}: {
  _userPermissions: Permission[];
}) => ({
  message: `Maximum fee difference is ${getMaxFeeDifference(_userPermissions)}`,
  path: ['fee'],
});

export const validateMinMaxPremium = ({
  premium,
  _originalPremium,
}: {
  premium: string;
  _originalPremium: number;
}) => {
  const premiumNumber = parseFloat(premium);
  const defaultPremiumNumber = _originalPremium;

  if (defaultPremiumNumber < 0) {
    return premiumNumber >= defaultPremiumNumber && premiumNumber <= 0;
  }

  return defaultPremiumNumber < 0
    ? premiumNumber >= defaultPremiumNumber && premiumNumber <= 0
    : premiumNumber <= defaultPremiumNumber && premiumNumber >= 0;
};

export const validateMinMaxPremiumMsg = ({
  premium,
  _originalPremium,
}: {
  premium: string;
  _originalPremium: number;
}) => ({
  message: getMinMaxPremiumMsg(parseFloat(premium), _originalPremium),
  path: ['premium'],
});

export const validateRequiredIsFeeChangePermissionGranted = ({
  _userPermissions,
  fee,
  isFeeChangePermissionGranted,
}: {
  _userPermissions: Permission[];
  fee: string;
  isFeeChangePermissionGranted: boolean;
}) => {
  const isCheckboxHidden =
    !_userPermissions.includes('patchfeebandu:motorquote') ||
    Number(fee) <= patchFeeBandMaxDifferenceMap.E;
  return isCheckboxHidden || isFeeChangePermissionGranted;
};

export const validateRequiredIsFeeChangePermissionGrantedMsg = {
  message: validationMessages.required,
  path: ['isFeeChangePermissionGranted'],
};
