import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

export function useVulnerabilitiesQuery(policyId: string) {
  const api = useApiClient();
  const fetchVulnerabilities = () =>
    api.vulnerabilities.customerVulnerabilities
      .getCustomerVulnerabilities({
        policyId,
      })
      .then(response => response.customerVulnerabilities ?? []);

  return useQuery({
    queryKey: queryKeys.vulnerabilities(policyId),
    queryFn: fetchVulnerabilities,
  });
}
