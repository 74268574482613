import { NewMtaFormSchema, PolicyholderDriverSchema } from '../../hooks';
import { employmentStatusCodeJoblessOcupationMap } from '../../components/NewMtaDrivers/helpers/employmentStatusCodeJoblessOcupationHelper';
import { transformToOptionCode } from './mapFormValuesTemporaryHelpers';
import { PROPOSER_OPTION_CODE, NO_OTHER_VEHICLE_USAGE_OPTION_CODE } from './constants';
import { mapPolicyholderFormValues } from './mapPolicyholderFormValues';
import { Driver, Occupation } from '@/api/motorpolicy';
import { getDateOnly } from '@/helpers/utils/dateHelpers';
import { hasAnsweredYes } from '@/helpers/utils/formHelpers';
import { MotorPolicy } from '@/api/motorpolicy';

const mapDriverFormValues =
  (cover: NewMtaFormSchema['cover']) =>
  (driver: NewMtaFormSchema['drivers'][number]): Driver => {
    const jobTitle = driver?.studentType
      ? driver.studentType
      : driver.jobTitle
        ? driver.jobTitle.code
        : employmentStatusCodeJoblessOcupationMap[driver.employmentStatus].type!.code;
    const jobIndustry = driver.jobIndustry
      ? driver.jobIndustry.code
      : employmentStatusCodeJoblessOcupationMap[driver.employmentStatus].industry!.code;

    return {
      id: driver._driverId!,
      isMainDriver: driver._isMainDriver,
      firstName: driver.firstName,
      lastName: driver.surname,
      title: transformToOptionCode(driver.title),
      dateOfBirth: getDateOnly(driver.dateOfBirth!),
      maritalStatus: transformToOptionCode(driver.maritalStatus),
      drivingLicence: {
        number: driver.drivingLicenceNumber || undefined,
        yearsHeld: driver.yearsHeldLicence ?? undefined,
        monthsHeld:
          driver.yearsHeldLicence === 0 ? Number(driver.monthsHeldLicence) : undefined,
        type: transformToOptionCode(driver.drivingLicenceType),
      },
      relationshipToPolicyholder: driver._isPolicyholder
        ? PROPOSER_OPTION_CODE
        : transformToOptionCode(driver.relationshipToPolicyholder),
      ukResidencyDetails: {
        isUkResidentSinceBirth: hasAnsweredYes(driver.isUkResidentSinceBirth),
        ukResidentSince: hasAnsweredYes(driver.isUkResidentSinceBirth)
          ? undefined
          : getDateOnly(driver.ukResidentSince!),
      },
      heldInsuranceLast2Years:
        typeof driver.yearsHeldLicence === 'number' && driver.yearsHeldLicence >= 2,
      medicalCondition: hasAnsweredYes(driver.medicalConditions)
        ? transformToOptionCode(driver.medicalCondition)
        : undefined,
      advancedDrivingQualifications: driver.hasAdvanceDrivingQualifications
        ? driver.advanceDrivingQualifications.map(({ type, date }) => ({
            type: transformToOptionCode(type),
            dateQualified: getDateOnly(date!),
          }))
        : [],
      otherVehicleUse: hasAnsweredYes(driver.ownOrDriveAnotherCar)
        ? transformToOptionCode(driver.otherVehicleUsage)
        : NO_OTHER_VEHICLE_USAGE_OPTION_CODE,
      previousMotorClaims: hasAnsweredYes(driver.hasClaims)
        ? driver.claims.map(claim => ({
            type: transformToOptionCode(claim.type),
            date: getDateOnly(claim.date!),
            settled: hasAnsweredYes(claim.settled),
            damageAmount: { amount: Number(claim.damageAmount) },
            atFault: hasAnsweredYes(claim.atFault),
            source: { code: claim._source.code },
          }))
        : [],
      motorConvictions: hasAnsweredYes(driver.hasConvictions)
        ? driver.convictions.map(conviction => ({
            type: transformToOptionCode(conviction.type?.code),
            date: getDateOnly(conviction.date!),
            penaltyPoints: Number(conviction.penaltyPoints),
            fineAmount: { amount: Number(conviction.fineAmount) },
            disqualifiedLengthInMonths: hasAnsweredYes(conviction.banReceived)
              ? Number(conviction.disqualifiedLengthInMonths)
              : 0,
          }))
        : [],
      hasNonMotoringConvictions: hasAnsweredYes(driver.hasNonMotoringConvictions),
      occupations: [
        {
          isPrimary: true,
          employmentStatus: transformToOptionCode(driver.employmentStatus),
          industry: transformToOptionCode(jobIndustry),
          type: transformToOptionCode(jobTitle),
        },
        ...(hasAnsweredYes(driver.anyOtherJob)
          ? [
              {
                isPrimary: false,
                employmentStatus: transformToOptionCode(driver.employmentStatus),
                industry: transformToOptionCode(driver.secondaryJobIndustry?.code),
                type: transformToOptionCode(driver.secondaryJobTitle?.code),
              } as Occupation,
            ]
          : []),
      ],
      ...(driver._isPolicyholder
        ? {
            noClaimsHistory: {
              yearsNoClaimsBonus: cover.yearsOfNCB,
            },
          }
        : {}),
    };
  };

type MapDriversFormValuesParams = {
  driversFormValues: NewMtaFormSchema['drivers'];
  mainDriverIndex: number;
  dirtyFields: Partial<NewMtaFormSchema>;
  policy: MotorPolicy;
  cover: NewMtaFormSchema['cover'];
};

export const mapDriversFormValues = ({
  driversFormValues,
  dirtyFields,
  policy,
  mainDriverIndex,
  cover,
}: MapDriversFormValuesParams) => {
  const policyDrivers = policy.drivers;
  const hasMainDriverBeenUpdated = !!dirtyFields.mainDriverIndex;
  const hasNcbBeenUpdated = dirtyFields.cover?.yearsOfNCB !== undefined;
  const updatedDrivers = driversFormValues
    .map((driver, index) => ({ ...driver, _isMainDriver: mainDriverIndex === index }))
    .filter((driver, index) => {
      const hasDriverBeenUpdated = !!dirtyFields.drivers?.[index];

      if (driver._isPolicyholder && hasNcbBeenUpdated) {
        return true;
      }

      if (hasDriverBeenUpdated) {
        return true;
      }

      if (hasMainDriverBeenUpdated) {
        const policyDriver = policyDrivers.find(({ id }) => driver._driverId === id);
        const isPreviousMainDriver =
          policyDriver?.isMainDriver && mainDriverIndex !== index;
        const isNewMainDriver = mainDriverIndex === index;

        return isPreviousMainDriver || isNewMainDriver;
      }
    });
  const policyholderUpdatedValues = updatedDrivers.find(
    ({ _isPolicyholder }) => _isPolicyholder,
  );
  const driversToUpdate = updatedDrivers
    .filter(({ _driverId }) => !!_driverId)
    .map(mapDriverFormValues(cover));

  const driversToAdd = updatedDrivers
    .filter(({ _driverId }) => !_driverId)
    .map(mapDriverFormValues(cover));
  const driversToRemove = policyDrivers.reduce((driversIdsToRemove: string[], driver) => {
    if (!driver.id) return driversIdsToRemove;

    if (!driversFormValues.find(({ _driverId }) => _driverId === driver.id)) {
      return [...driversIdsToRemove, driver.id];
    }

    return driversIdsToRemove;
  }, []);

  return {
    driversToRemove,
    policyholder: policyholderUpdatedValues
      ? mapPolicyholderFormValues(
          policyholderUpdatedValues as PolicyholderDriverSchema,
          policy,
        )
      : undefined,
    driversToUpdate,
    driversToAdd,
  };
};
