import { z } from 'zod';
import { validateConvictionOrClaimDate } from '../../helpers/newMtaFormHelper';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { YesNo } from '@/models/YesNo';

export const claimFormSchema = z
  .object({
    _isExisting: z.boolean(),
    _source: z.object({
      code: z.string().nullable().optional(),
      description: z.string().nullable().optional(),
    }),
    type: z
      .string()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    date: z
      .date()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    atFault: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    settled: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    damageAmount: z
      .string()
      .refine(value => Boolean(value), validationMessages.required)
      .refine(value => Number(value) >= 0, validationMessages.minValue(0))
      .refine(
        value => Number(value) <= 100000000,
        validationMessages.maxValue(100000000),
      ),
  })
  .superRefine(validateConvictionOrClaimDate);

export type ClaimFormSchema = z.infer<typeof claimFormSchema>;
