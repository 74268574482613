import { ClaimContentDetails } from './ClaimContentDetails';
import { ClaimContentParty } from './ClaimContentParty';
import { InsurerClaim } from '@/api/motorpolicy';

type ClaimContentProps = {
  claim: InsurerClaim;
};

export function ClaimContent({ claim }: ClaimContentProps) {
  return (
    <>
      <ClaimContentDetails claim={claim} />
      {claim.insurerClaimParties?.map((claimParty, index) => (
        <ClaimContentParty
          key={index}
          claimParty={claimParty}
          claimNumber={claim.claimNumber}
          claimPartyNo={index + 1}
        />
      ))}
    </>
  );
}
