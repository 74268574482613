import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { Document } from '@/models/Documents';

export function useDocumentsQuery(
  policyId: string,
  policyVersion?: number,
  options?: Omit<UseQueryOptions<Document[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchDocuments = () => {
    return api.motorpolicy.motorPolicy.getMotorpoliciesDocuments({
      id: policyId,
      policyVersion,
    });
  };

  return useQuery<Document[]>({
    queryKey: queryKeys.documents(policyId, policyVersion),
    queryFn: fetchDocuments,
    staleTime: 5000,
    ...options,
  });
}
