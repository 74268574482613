import { VulnerabilityCategory, VulnerabilityType } from '@/api/support';
import { ActionRequired } from '@/api/vulnerabilities';

export function getVulnerabilityCategoryName(
  vulnerabilityCategory: VulnerabilityCategory,
): string {
  switch (vulnerabilityCategory) {
    case VulnerabilityCategory.CAPABILITY:
      return 'Capability';
    case VulnerabilityCategory.HEALTH:
      return 'Health';
    case VulnerabilityCategory.LIFE_EVENTS:
      return 'Life Events';
    case VulnerabilityCategory.RESILIENCE:
      return 'Resilience';
    case VulnerabilityCategory.UNKNOWN:
      return 'Unknown';
  }
}

export function getVulnerabilityTypeName(vulnerabilityType: VulnerabilityType): string {
  switch (vulnerabilityType) {
    case VulnerabilityType.PHYSICAL_DISABILITY:
      return 'Physical Disability';
    case VulnerabilityType.SEVERE_OR_LONG_TERM_ILLNESS:
      return 'Severe or long-term illness';
    case VulnerabilityType.HEARING_OR_VISUAL_IMPAIRMENT:
      return 'Hearing or visual impairment';
    case VulnerabilityType.MENTAL_HEALTH_CONDITION_OR_DISABILITY:
      return 'Mental health condition or disability';
    case VulnerabilityType.ADDICTION:
      return 'Addiction';
    case VulnerabilityType.LOW_MENTAL_CAPACITY_OR_COGNITIVE_DISABILITY:
      return 'Low mental capacity or cognitive disability';
    case VulnerabilityType.RETIREMENT:
      return 'Retirement';
    case VulnerabilityType.BEREAVEMENT:
      return 'Bereavement';
    case VulnerabilityType.INCOME_SHOCK:
      return 'Income Shock';
    case VulnerabilityType.RELATIONSHIP_BREAKDOWN:
      return 'Relationship breakdown';
    case VulnerabilityType.DOMESTIC_ABUSE:
      return 'Domestic Abuse';
    case VulnerabilityType.CARING_RESPONSIBILITIES:
      return 'Caring Responsibilities';
    case VulnerabilityType.OTHER_CIRCUMSTANCES_THAT_AFFECT_PEOPLES_EXP_OF_FINANCIAL_SERVICES:
      return 'Other circumstances that affect peoples exp of financial services';
    case VulnerabilityType.INADEQUATE_OR_ERRATIC_INCOME:
      return 'Inadequate or erratic income';
    case VulnerabilityType.OVER_INDEBTEDNESS:
      return 'Over-indebtedness';
    case VulnerabilityType.LOW_SAVINGS:
      return 'Low savings';
    case VulnerabilityType.LOW_EMOTIONAL_RESILIENCE:
      return 'Low emotional resilience';
    case VulnerabilityType.LOW_KNOWLEDGE_OR_CONFIDENCE_IN_MANAGING_FINANCES:
      return 'Low knowledge or confidence in managing finances';
    case VulnerabilityType.POOR_LITERACY_OR_NUMERICAL_SKILLS:
      return 'Poor literacy or numerical skills';
    case VulnerabilityType.POOR_ENGLISH_LANGUAGE_SKILLS:
      return 'Poor English language skills';
    case VulnerabilityType.POOR_OR_NON_EXISTENT_DIGITAL_SKILLS:
      return 'Poor or non-existent digital skills';
    case VulnerabilityType.LEARNING_DIFFICULTIES:
      return 'Learning difficulties';
    case VulnerabilityType.NO_OR_LOW_ACCESS_TO_HELP_OR_SUPPORT:
      return 'No or low access to help or support';
    case VulnerabilityType.UNKNOWN:
      return 'Unknown';
  }
}

export function getVulnerabilityActionName(action: ActionRequired): string {
  switch (action) {
    case ActionRequired.OTHER:
      return 'Other';
    case ActionRequired.ADD_TEMPORARY_DRIVER:
      return 'Add temporary driver';
    case ActionRequired.CANCEL_POLICY_OR_NO_FEE:
      return 'Cancel policy or no fee';
    case ActionRequired.NO_ACTION_NEEDED:
      return 'No action needed';
    case ActionRequired.NOTIFICATION_ONLY:
      return 'Notification only';
    case ActionRequired.PAYMENT_HOLIDAY_ONLY_ONE:
      return 'Payment holiday (only one)';
    case ActionRequired.SUITABLE_FRIEND_OR_THIRD_PERMISSION:
      return 'Suitable friend/third permission';
  }
}
