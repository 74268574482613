import { PropsWithChildren } from 'react';
import { StyledModalHeader, StyledModalTitle } from '../Modal.styles';
import { useModalContext } from '../hooks/useModalContext';
import { CloseIconButton } from '@/components/ui/buttons/CloseIconButton';

export function ModalHeader({ children }: PropsWithChildren) {
  const { close } = useModalContext();

  return (
    <StyledModalHeader>
      <StyledModalTitle id="modal-title">{children}</StyledModalTitle>
      <CloseIconButton testId="ui_modal-close-button" onClose={() => close()} />
    </StyledModalHeader>
  );
}
