import { List, Typography, styled } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { isVehicleKeptAtHomeOvernight } from '@/helpers/data/carHelpers';
import { MotorPolicy } from '@/api/motorpolicy';

type HistoryCarContentProps = {
  policy: MotorPolicy;
};

const StyledUl = styled('ul')(({ theme }) => ({
  listStyleType: 'disc',
  paddingLeft: theme.spacing(4),
}));

export function HistoryCarContent({ policy }: HistoryCarContentProps) {
  return (
    <List>
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Car details
      </Typography>
      <ListRow disablePadding label="Registration">
        {policy.vehicle.registrationNumber}
      </ListRow>
      <ListRow disablePadding label="Car">
        {`${policy.vehicle.make} ${policy.vehicle.model}`}
      </ListRow>
      <ListRow disablePadding label="Year of Manufacture">
        {policy.vehicle.manufactureYear}
      </ListRow>
      <ListRow disablePadding label="Fuel Type">
        {policy.vehicle.primaryFuelType?.description}
      </ListRow>
      <ListRow disablePadding label="Transmission">
        {policy.vehicle.transmission?.description}
      </ListRow>
      <ListRow disablePadding label="Number of Doors">
        {policy.vehicle.numberOfDoors}
      </ListRow>
      <ListRow disablePadding label="Steering Wheel Side" sx={{ mt: 5 }}>
        {policy.vehicle.steeringWheelSide?.description}
      </ListRow>
      <ListRow disablePadding label="Number of Seats">
        {policy.vehicle.numberOfSeats}
      </ListRow>
      <ListRow disablePadding label="Tracking Device">
        {booleanAnswer(Boolean(policy.vehicle.trackingDevice))}
      </ListRow>
      <ListRow disablePadding label="Alarm / Immobiliser">
        {policy.vehicle.alarmImmobiliser?.type?.description}
      </ListRow>
      <ListRow disablePadding label="Imported Vehicle">
        {booleanAnswer(policy.vehicle.isImported)}
      </ListRow>
      <ListRow disablePadding label="Estimated value" sx={{ mt: 5 }}>
        {policy.vehicle.selfDeclaredVehicleValue?.amount &&
          formatCurrency(policy.vehicle.selfDeclaredVehicleValue.amount)}
      </ListRow>
      <ListRow disablePadding label="Modifications" sx={{ mt: 5 }}>
        <StyledUl>
          {policy.vehicle.modifications?.map((mod, key) => (
            <li key={key}>{mod.description}</li>
          ))}
        </StyledUl>
      </ListRow>
      <ListRow disablePadding label="Bought yet" sx={{ mt: 5 }}>
        {booleanAnswer(policy.vehicle.ownership?.isPurchased)}
      </ListRow>
      <ListRow disablePadding label="Purchase date">
        {policy.vehicle.ownership?.selfDeclaredDateOfPurchase &&
          formatDate(policy.vehicle.ownership.selfDeclaredDateOfPurchase)}
      </ListRow>
      <Typography variant="subtitle2" sx={{ mt: 6, mb: 4 }}>
        Car usage
      </Typography>
      <ListRow disablePadding label="Usage">
        {policy.vehicle.usage?.type?.description}
      </ListRow>
      <ListRow disablePadding label="Personal mileage" sx={{ mt: 5 }}>
        {policy.vehicle.usage?.annualMileage}
      </ListRow>
      <ListRow disablePadding label="Business mileage">
        {policy.vehicle.usage?.businessMileage}
      </ListRow>
      <ListRow disablePadding label="Kept at home overnight" sx={{ mt: 5 }}>
        {booleanAnswer(isVehicleKeptAtHomeOvernight(policy))}
      </ListRow>
      <ListRow disablePadding label="Kept during the day" sx={{ mt: 5 }}>
        {policy.vehicle.location?.daytimeLocation?.description}
      </ListRow>
      <ListRow disablePadding label="Kept at night">
        {policy.vehicle.location?.overnightLocation?.description}
      </ListRow>
      <ListRow disablePadding label="Registered keeper">
        {policy.vehicle.ownership?.registeredKeeper?.description}
      </ListRow>
      <ListRow disablePadding label="Legal Owner">
        {policy.vehicle.ownership?.legalOwner?.description}
      </ListRow>
    </List>
  );
}
