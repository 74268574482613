import { AbiVehicleService } from './services/AbiVehicleService';
import { OrderService } from './services/OrderService';
import { VehicleService } from './services/VehicleService';
import { VehicleTypeService } from './services/VehicleTypeService';
import { BaseAPI } from '@/api/core/BaseAPI';

export class RetailAPI extends BaseAPI {
  readonly abiVehicle: AbiVehicleService;
  readonly order: OrderService;
  readonly vehicle: VehicleService;
  readonly vehicleType: VehicleTypeService;

  constructor() {
    super({ BASE: import.meta.env.VITE_APP_RETAIL_API_URL });

    this.abiVehicle = new AbiVehicleService(this.request);
    this.order = new OrderService(this.request);
    this.vehicle = new VehicleService(this.request);
    this.vehicleType = new VehicleTypeService(this.request);
  }
}
