import { styled } from '@mui/material';

export const ContentContainer = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  height: 'calc(90vh - 220px)',
  overflow: 'auto',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ScaleBox = styled('div')<{ scale?: number }>(({ scale }) => ({
  position: 'relative',
  transform: scale ? `scale(${scale})` : undefined,
}));

export const RotateBox = styled('div')<{ rotate?: number }>(({ rotate }) => ({
  display: 'flex',
  position: 'relative',
  transform: rotate ? `rotate(${rotate}deg)` : undefined,
}));
