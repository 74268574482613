import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { SendByPostRequest } from '@/api/support';

type useDocumentsByPostMutationProps = {
  onSuccess?: () => void;
};

export function useDocumentsByPostMutation({
  onSuccess,
}: useDocumentsByPostMutationProps) {
  const api = useApiClient();

  return useMutation({
    mutationFn: (requestBody: SendByPostRequest) =>
      api.support.documents.postDocumentsSendByPost({ requestBody }),
    onSuccess,
  });
}
