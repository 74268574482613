import { BoxProps, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FieldError } from '../FormTextField/components/FieldError';
import { StyledAdyenInputWrapper } from './FormAdyenTextField.styles';
import { useAdyenCheckoutContext } from '@/features/PaymentActions/CardPaymentModal/hooks/useAdyenCheckoutContext';

type FormAdyenTextFieldProps = {
  name: string;
  label?: string;
  sx?: BoxProps['sx'];
  hideError?: boolean;
};

export function FormAdyenTextField({
  name,
  label,
  sx,
  hideError,
}: FormAdyenTextFieldProps) {
  const { focusedField } = useAdyenCheckoutContext();
  const {
    formState: { errors },
  } = useFormContext();
  const isFocused = focusedField === name;
  const errorMessage = errors[name]?.message?.toString();

  return (
    <Stack direction="column" gap={1} className="adyen-text-field">
      <Stack gap={2} direction="column">
        {label ? (
          <Typography component="label" htmlFor={name} variant="body2">
            {label}
          </Typography>
        ) : null}
        <StyledAdyenInputWrapper
          className="styled-adyen-input-wrapper"
          sx={sx}
          $isFocused={isFocused}
          $isError={!!errorMessage}
        >
          <div className="adyen-iframe-container" data-cse={name} />
        </StyledAdyenInputWrapper>
      </Stack>
      {errorMessage && !hideError ? <FieldError>{errorMessage}</FieldError> : null}
    </Stack>
  );
}
