import { SalesPlatform } from '@/api/motorpolicy';

export function getSalesPlatformName(salesPlatform: SalesPlatform): string {
  switch (salesPlatform) {
    case SalesPlatform.WEB:
      return 'Web';
    case SalesPlatform.MOBILE_IOS:
      return 'Mobile IOS';
    case SalesPlatform.MOBILE_ANDROID:
      return 'Mobile Android';
    case SalesPlatform.BACK_OFFICE:
      return 'Back office';
  }
}
