import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useOrderMutation } from '../mutations/useOrderMutation';
import { useOrderQuery } from '../queries/useOrderQuery';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';

export function useOrder() {
  const policyId = useContext(PolicyIdContext);
  const queryClient = useQueryClient();
  const {
    data: orderMutationData,
    variables: orderMutationParams,
    mutate: makeOrder,
    mutateAsync: makeOrderAsync,
    isPending: isOrderMutationPending,
    isError: isOrderMutationError,
    isSuccess: isOrderMutationSuccess,
    reset: resetOrderMutation,
    error: orderMutationError,
  } = useOrderMutation(policyId);

  const {
    data: orderQueryData,
    isSuccess: isOrderQuerySuccess,
    isFetching: isOrderQueryFetching,
    isError: isOrderQueryError,
    error: orderQueryError,
  } = useOrderQuery({
    id: orderMutationData?.orderId,
    isRefund: orderMutationParams?.isRefund,
  });

  const isError =
    isOrderMutationError || isOrderQueryError || !!orderMutationData?.errorCode;

  const isPending =
    !isError &&
    (isOrderMutationPending ||
      isOrderQueryFetching ||
      (Boolean(orderMutationData) && !orderQueryData));

  const isSuccess = isOrderMutationSuccess && isOrderQuerySuccess;

  useEffect(() => {
    if (isSuccess) {
      (async () => {
        if (orderMutationParams?.type === 'quote') {
          await queryClient.invalidateQueries({ queryKey: queryKeys.policies() });
          await queryClient.invalidateQueries({
            queryKey: queryKeys.motorPolicy(policyId),
          });
        }

        if (
          orderMutationParams?.type === 'add_debt' ||
          orderMutationParams?.type === 'settle_debt'
        ) {
          await queryClient.invalidateQueries({ queryKey: queryKeys.debt(policyId) });
          await queryClient.invalidateQueries({
            queryKey: queryKeys.unsettledBalance(policyId),
          });
        }
      })();
    }
  }, [isSuccess, orderMutationParams]);

  return {
    makeOrder,
    makeOrderAsync,
    resetOrder: resetOrderMutation,
    data: { ...orderMutationData, ...orderQueryData },
    isPending,
    isError,
    isSuccess,
    error: orderMutationError ?? orderQueryError,
  };
}
