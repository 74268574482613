import '@adyen/adyen-web/dist/adyen.css';
import { useEffect, useState } from 'react';
import {
  CardPaymentModalContent,
  CardPaymentModalContentProps,
} from './components/CardPaymentModalContent';
import { AdyenCheckoutContextProvider } from './components/AdyenCheckoutContextProvider';
import { BypassPaymentModal } from './components/BypassPaymentModal';
import { Modal } from '@/components/ui/modals/Modal';
import { Order } from '@/api/retail/v1';
import { useOrder } from '@/hooks/useOrder';
import {
  SettleDebtOrderParams,
  QuoteOrderParams,
} from '@/hooks/mutations/useOrderMutation';
import { DebtReason } from '@/api/support';
import { getDebtReasonName } from '@/helpers/nameMappings/paymentNameMappings';

export type CardPaymentModalProps = {
  amount: number;
  onClose: () => void;
  onSuccess: (orderData: Order) => void;
} & (Omit<QuoteOrderParams, 'bypassPayment'> | SettleDebtOrderParams);

export function CardPaymentModal({
  onClose,
  onSuccess,
  ...props
}: CardPaymentModalProps) {
  const [contentComponentKey, setContentComponentKey] = useState(1);
  const [shouldShowBypassPaymentModal, setShowBypassPaymentModal] = useState(false);
  const {
    makeOrder,
    data: orderData,
    isPending: isOrderPending,
    isError: isOrderError,
    isSuccess: isOrderSuccess,
    resetOrder,
  } = useOrder();

  const onProcessPayment: CardPaymentModalContentProps['onSubmit'] = async ({
    encryptedCardNumber,
    encryptedExpiryMonth,
    encryptedExpiryYear,
    encryptedSecurityCode,
    cardholderName,
    previouslyUsedPaymentMethod,
  }) => {
    makeOrder({
      ...props,
      paymentDetails: {
        isThisAMotoPayment: true,
        ...(previouslyUsedPaymentMethod
          ? {
              paymentMethod: 'saved_card',
              token: previouslyUsedPaymentMethod,
            }
          : {
              paymentMethod: 'card',
              cardNumber: encryptedCardNumber,
              securityCode: encryptedSecurityCode,
              holderName: cardholderName,
              expiryMonth: encryptedExpiryMonth,
              expiryYear: encryptedExpiryYear,
            }),
      },
    });
  };

  const handleSubmitBypassPayment = (reason: DebtReason) => {
    if (props.type === 'quote') {
      makeOrder({
        ...props,
        bypassPayment: true,
        bypassPaymentReasonCode: reason,
        bypassPaymentDescription: getDebtReasonName(reason),
      });
    }
  };

  const rerenderModalContent = () => setContentComponentKey(contentComponentKey + 1);
  const onClear = () => {
    resetOrder();
    rerenderModalContent();
  };

  useEffect(() => {
    if (isOrderSuccess) {
      onSuccess(orderData);
    }
  }, [isOrderSuccess, orderData, onClose]);

  return (
    <>
      {shouldShowBypassPaymentModal && (
        <BypassPaymentModal
          isOrderPending={isOrderPending}
          onSubmit={handleSubmitBypassPayment}
          onCancel={() => setShowBypassPaymentModal(false)}
        />
      )}
      <Modal open={!shouldShowBypassPaymentModal} onClose={onClose}>
        <Modal.Header>Debit / credit card</Modal.Header>
        <AdyenCheckoutContextProvider>
          <CardPaymentModalContent
            key={contentComponentKey}
            isPending={isOrderPending}
            isError={isOrderError}
            amount={props.amount}
            canBypassPayment={props.type === 'quote'}
            onSubmit={onProcessPayment}
            onClear={onClear}
            onBypassPayment={() => setShowBypassPaymentModal(true)}
          />
        </AdyenCheckoutContextProvider>
      </Modal>
    </>
  );
}
