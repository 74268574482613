import { TabPanel } from '@mui/lab';
import { useContext } from 'react';
import { PolicyholderContent } from './components/PolicyholderContent';
import { DriverContent } from './components/DriverContent';
import { DriverContext } from './DriverContext';
import { DriverBanners } from './components/DriverBanners';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { sortArrayByBooleanKey } from '@/helpers/utils/arrayHelpers';
import { useVulnerabilitiesQuery } from '@/hooks/queries/useVulnerabilitiesQuery';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function Drivers() {
  const parentId = useContext(PolicyIdContext);
  const {
    isPending: isPolicyPending,
    isError: isPolicyError,
    data: policy,
  } = useMotorPolicyQuery(parentId);
  const {
    isPending: isVulnerabilitiesPending,
    isError: isVulnerabilitiesError,
    data: vulnerabilities = [],
  } = useVulnerabilitiesQuery(parentId);

  if (isPolicyError) return <DrawerError />;
  if (isPolicyPending || isVulnerabilitiesPending || !policy) return <DrawerLoading />;

  const vulnerabilitiesQueryData = {
    isPending: isVulnerabilitiesPending,
    isError: isVulnerabilitiesError,
    data: vulnerabilities,
  };

  const sortedDrivers = sortArrayByBooleanKey(policy.drivers, 'isPolicyholder');

  return (
    <>
      {sortedDrivers.map((driver, key) => (
        <TabPanel value={`driver-${key}-tab`} key={key}>
          <DriverBanners
            driver={driver}
            vulnerabilities={vulnerabilities}
            isVulnerabilitiesError={isVulnerabilitiesError}
          />
          <DriverContext.Provider value={driver}>
            {driver.isPolicyholder ? (
              <PolicyholderContent
                vulnerabilities={vulnerabilitiesQueryData}
                policyholder={policy.policyholder}
              />
            ) : (
              <DriverContent
                vulnerabilities={vulnerabilitiesQueryData}
                policyholder={policy.policyholder}
              />
            )}
          </DriverContext.Provider>
        </TabPanel>
      ))}
    </>
  );
}
