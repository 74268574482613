import { useBlocker } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Modal } from './Modal';

type LeavePagePromptProps = {
  when: boolean;
};

export function LeavePagePrompt({ when }: LeavePagePromptProps) {
  const blocker = useBlocker(when);

  return (
    blocker.state === 'blocked' && (
      <Modal width={600} onClose={() => blocker.reset?.()}>
        <Modal.Header>Do you want to leave without saving?</Modal.Header>
        <Modal.Content>
          <Typography variant="body2">All unsaved changes will be lost</Typography>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="outlined" color="inherit" onClick={() => blocker.reset?.()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => blocker.proceed?.()}
          >
            Leave
          </Button>
        </Modal.Actions>
      </Modal>
    )
  );
}
