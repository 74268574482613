import { Typography, styled } from '@mui/material';

export const FileDetailTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.typography.h5.fontSize,
}));

export const FileDetailDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: theme.typography.fontSize,
  color: theme.palette.grey[500],
}));
