import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseCompleteReminderMutationProps = {
  onSuccess?: () => void;
};

export function useCompleteReminderMutation({
  onSuccess,
}: UseCompleteReminderMutationProps) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (reminderId: string) =>
      api.note.notes.patchNotesCompleteReminder({ id: reminderId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.adminReminders(),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.individualReminders(),
      });
      onSuccess?.();
    },
  });
}
