import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, IconButtonProps } from '@mui/material';
import { MouseEventHandler } from 'react';

type CloseIconButtonProps = {
  testId?: string;
  onClose: MouseEventHandler<HTMLButtonElement>;
} & IconButtonProps;

export function CloseIconButton({ testId, onClose, ...props }: CloseIconButtonProps) {
  return (
    <IconButton
      {...props}
      data-testid={testId}
      aria-label="close"
      tabIndex={1}
      onClick={onClose}
    >
      <CloseOutlinedIcon />
    </IconButton>
  );
}
