import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseDeleteNoteMutationProps = {
  onSuccess?: () => void;
};

type DeleteNoteMutationFnProps = {
  noteId: string;
  policyId: string;
};

export function useDeleteNoteMutation({ onSuccess }: UseDeleteNoteMutationProps) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ noteId }: DeleteNoteMutationFnProps) =>
      api.note.notes.deleteNotes({
        noteId,
      }),
    onSuccess: async (_data, { policyId }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.notes(policyId),
      });
      onSuccess?.();
    },
  });
}
