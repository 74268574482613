import { AccountFormSchema } from '../hooks/useAccountForm';
import { Account } from '@/api/support';
import { YesNo } from '@/models/YesNo';

export const mapAccountFormValuesToAccount = (account: AccountFormSchema): Account => ({
  customerId: account.customerId,
  customerIdentityProvider: account.customerIdentityProvider,
  email: account.email,
  telephoneNumber: {
    landline: account.landline !== '' ? account.landline : null,
    mobile: account.mobile !== '' ? account.mobile : null,
  },
  marketingPreferences: {
    allowedToMarketByEmail: account.allowedToMarketByEmail,
    allowedToMarketBySMS: account.allowedToMarketBySMS,
    allowedToMarketByPhone: account.allowedToMarketByPhone,
    allowedToMarketByPost: account.allowedToMarketByPost,
  },
  autoRenewPolicy: account.autoRenewPolicy === YesNo.Yes,
});
