import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadFile } from '@/helpers/utils/fileHelpers';

type DownloadButtonProps = {
  fileName: string;
  fileContent: string;
};

export function DownloadButton({ fileName, fileContent }: DownloadButtonProps) {
  const handleDownload = () => {
    downloadFile(fileName, fileContent);
  };
  return (
    <IconButton aria-label="download" color="secondary" onClick={handleDownload}>
      <DownloadIcon />
    </IconButton>
  );
}
