import { useEffect } from 'react';
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';

type useSetValueProps<T extends FieldValues> = {
  shouldSetValue?: boolean;
  name: Path<T>;
  value: PathValue<T, Path<T>>;
  setValue: UseFormSetValue<T>;
};

export function useSetValue<T extends FieldValues>({
  shouldSetValue,
  name,
  value,
  setValue,
}: useSetValueProps<T>) {
  return useEffect(() => {
    if (!shouldSetValue) return;

    setValue(name, value);
  }, [setValue, shouldSetValue, name, value]);
}
