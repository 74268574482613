import { styled } from '@mui/material';
import { useEffect, useState } from 'react';
import heic2any from 'heic2any';
import { FileDetailContent } from './FileDetailContent';
import { DownloadButton } from './controls/DownloadButton';
import { RotateButton } from './controls/RotateButton';
import { ZoomControls } from './controls/ZoomControls';
import { FileDetailModalProps } from '@/components/ui/presentation/FileDetail/fileDetailTypes';
import {
  useContentHeight,
  useRotate,
  useScale,
} from '@/components/ui/presentation/FileDetail/hooks';
import { readFileContent } from '@/helpers/utils/fileHelpers';
import { Modal } from '@/components/ui/modals/Modal';

const StyledImage = styled('img')<{ maxHeight: number }>(({ maxHeight }) => ({
  maxWidth: '100%',
  maxHeight,
}));

export function FileDetailImageModal({
  fileName,
  contentType,
  content,
  title,
  onClose,
}: FileDetailModalProps) {
  const [rotate, setRotate] = useRotate();
  const [scale, setScale] = useScale();
  const { contentContainerRef, contentHeight } = useContentHeight();
  const [imgContent, setImgContent] = useState<string | null>(null);

  const processImgContent = async () => {
    if (contentType === 'image/heic') {
      const heicBlob = await fetch(content).then(res => res.blob());
      const pngBlob = (await heic2any({ blob: heicBlob })) as Blob;
      const pngContent = await readFileContent(pngBlob);
      setImgContent(pngContent);
    } else {
      setImgContent(content);
    }
  };

  useEffect(() => {
    void processImgContent();
  }, [content]);

  return (
    <Modal onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <FileDetailContent ref={contentContainerRef} rotate={rotate} scale={scale}>
          {imgContent && (
            <StyledImage src={imgContent} alt={title} maxHeight={contentHeight} />
          )}
        </FileDetailContent>
      </Modal.Content>
      <Modal.Actions stretchItems={false} gap={4}>
        <ZoomControls scale={scale} onScaleChange={setScale} />
        <RotateButton rotate={rotate} onRotateChange={setRotate} />
        <DownloadButton fileName={fileName} fileContent={content} />
      </Modal.Actions>
    </Modal>
  );
}
