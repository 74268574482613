import { styled, Stack } from '@mui/material';

export const StyledButtonsFooter = styled(Stack, {
  shouldForwardProp: (propName: string) =>
    !['sticky', 'stretchItems', 'gap'].includes(propName),
})<{
  sticky?: boolean;
  stretchItems?: boolean;
  gap?: number;
}>(({ theme, sticky = false, stretchItems = true, gap = 6 }) => ({
  gap: theme.spacing(gap),
  padding: `${theme.spacing(6)} 0`,
  margin: `0 ${theme.spacing(6)}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper,

  ...(sticky
    ? {
        position: 'sticky',
        bottom: 0,
        zIndex: theme.zIndex.drawer,
        padding: theme.spacing(6),
        margin: 0,
      }
    : {}),

  ...(stretchItems
    ? {
        '& > *': {
          flexBasis: '50%',
        },
      }
    : {
        justifyContent: 'center',
      }),
}));
