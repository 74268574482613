import { List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { AddOn } from '@/api/motorpolicy';

type HistoryAddonContentProps = {
  addon: AddOn;
  addonNumber: number;
};

export function HistoryAddonContent({ addon, addonNumber }: HistoryAddonContentProps) {
  return (
    <>
      <List data-testid={`addon-${addonNumber}`}>
        <ListRow disablePadding label={`Add-on #${addonNumber} type`}>
          {addon.type.description}
        </ListRow>
        <ListRow disablePadding label={`Add-on #${addonNumber} category`}>
          {addon.category.description}
        </ListRow>
      </List>
    </>
  );
}
