import type { Order } from '../models/Order';
import type { PlaceOrderRequest } from '../models/PlaceOrderRequest';
import type { PlaceOrderResponse } from '../models/PlaceOrderResponse';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class OrderService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postOrder({
    requestBody,
  }: {
    requestBody?: PlaceOrderRequest;
  }): CancelablePromise<PlaceOrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order',
      body: requestBody,
    });
  }

  getOrder({ id }: { id: string }): CancelablePromise<Order> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/order/{id}',
      path: {
        id: id,
      },
    });
  }
}
