import type { SendByPostRequest } from '../models/SendByPostRequest';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class DocumentsService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postDocumentsSendByPost({
    requestBody,
  }: {
    requestBody?: SendByPostRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/documents/send-by-post',
      body: requestBody,
    });
  }
}
