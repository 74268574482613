import { useContext } from 'react';
import { CarDetailsContent } from './components/CarDetailsContent';
import { CarUsageContent } from './components/CarUsageContent';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import {
  AccordionList,
  AccordionListItem,
  AccordionTitle,
} from '@/components/ui/lists/AccordionList';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function Car() {
  const parentId = useContext(PolicyIdContext);
  const { isPending, isError, data: policy } = useMotorPolicyQuery(parentId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  const accordionItems: AccordionListItem[] = [
    {
      key: 'car-details',
      header: <AccordionTitle>Car details</AccordionTitle>,
      content: (
        <CarDetailsContent vehicle={policy.vehicle} data-testid="car-details-content" />
      ),
    },
    {
      key: 'car-usage',
      header: <AccordionTitle>Car usage</AccordionTitle>,
      content: (
        <CarUsageContent
          vehicle={policy.vehicle}
          policyholder={policy.policyholder}
          data-testid="car-usage-content"
        />
      ),
    },
  ];

  return <AccordionList items={accordionItems} />;
}
