import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { OptionCode } from '@/api/retail/v2';

type useClaimTypesQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useClaimTypesQuery(
  { productName = 'motor', productVersion = 'latest' }: useClaimTypesQueryProps,
  options?: Omit<UseQueryOptions<OptionCode[]>, 'queryFn' | 'queryKey'>,
) {
  const api = useApiClient();
  const fetchClaimTypes = () =>
    api.retailV2.product.getProductClaimTypes({ productName, productVersion });

  return useQuery({
    queryFn: fetchClaimTypes,
    queryKey: queryKeys.claimTypes(productName, productVersion),
    ...options,
  });
}
