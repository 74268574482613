import { VulnerabilityCategory, VulnerabilityType } from '@/api/support';

export const VULNERABILITY_TYPES_PER_CATEGORY: Record<
  VulnerabilityCategory,
  VulnerabilityType[]
> = {
  [VulnerabilityCategory.CAPABILITY]: [
    VulnerabilityType.LEARNING_DIFFICULTIES,
    VulnerabilityType.LOW_KNOWLEDGE_OR_CONFIDENCE_IN_MANAGING_FINANCES,
    VulnerabilityType.NO_OR_LOW_ACCESS_TO_HELP_OR_SUPPORT,
    VulnerabilityType.POOR_ENGLISH_LANGUAGE_SKILLS,
    VulnerabilityType.POOR_LITERACY_OR_NUMERICAL_SKILLS,
    VulnerabilityType.POOR_OR_NON_EXISTENT_DIGITAL_SKILLS,
  ],
  [VulnerabilityCategory.HEALTH]: [
    VulnerabilityType.ADDICTION,
    VulnerabilityType.HEARING_OR_VISUAL_IMPAIRMENT,
    VulnerabilityType.LOW_MENTAL_CAPACITY_OR_COGNITIVE_DISABILITY,
    VulnerabilityType.MENTAL_HEALTH_CONDITION_OR_DISABILITY,
    VulnerabilityType.PHYSICAL_DISABILITY,
    VulnerabilityType.SEVERE_OR_LONG_TERM_ILLNESS,
  ],
  [VulnerabilityCategory.LIFE_EVENTS]: [
    VulnerabilityType.BEREAVEMENT,
    VulnerabilityType.CARING_RESPONSIBILITIES,
    VulnerabilityType.OTHER_CIRCUMSTANCES_THAT_AFFECT_PEOPLES_EXP_OF_FINANCIAL_SERVICES,
    VulnerabilityType.DOMESTIC_ABUSE,
    VulnerabilityType.INCOME_SHOCK,
    VulnerabilityType.RELATIONSHIP_BREAKDOWN,
    VulnerabilityType.RETIREMENT,
  ],
  [VulnerabilityCategory.RESILIENCE]: [
    VulnerabilityType.INADEQUATE_OR_ERRATIC_INCOME,
    VulnerabilityType.LOW_SAVINGS,
    VulnerabilityType.LOW_EMOTIONAL_RESILIENCE,
    VulnerabilityType.OVER_INDEBTEDNESS,
  ],
  [VulnerabilityCategory.UNKNOWN]: [],
};
