import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

type useDebtQueryProps = { policyId: string };

export function useDebtQuery({ policyId }: useDebtQueryProps) {
  const api = useApiClient();

  const fetchDebt = () =>
    api.motorpolicy.motorPolicy.getMotorpoliciesDebt({ id: policyId });

  return useQuery({
    queryFn: fetchDebt,
    queryKey: queryKeys.debt(policyId),
  });
}
