import { MotorQuoteService } from './services/MotorQuoteService';
import { BaseAPI } from '../core/BaseAPI';

export class MotorquoteAPI extends BaseAPI {
  readonly motorQuote: MotorQuoteService;

  constructor() {
    super({ BASE: import.meta.env.VITE_APP_MOTORQUOTE_API_URL });

    this.motorQuote = new MotorQuoteService(this.request);
  }
}
