import { useContext } from 'react';
import { AccountForm } from './components/AccountForm';
import { mapAccountInitialValuesFromPolicy } from './helpers/mapAccountInitialValuesFromPolicy';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function Account() {
  const parentId = useContext(PolicyIdContext);

  const { isPending, isError, data: policy } = useMotorPolicyQuery(parentId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return (
    <AccountForm
      policyId={parentId}
      initialAccount={mapAccountInitialValuesFromPolicy(policy)}
    />
  );
}
