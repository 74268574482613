import { PolicyholderHomeContent } from './PolicyholderHomeContent';
import { LicenceAndHistoryContent } from './LicenceAndHistoryContent';
import { VulnerabilitiesContent } from './VulnerabilitiesContent';
import { DetailsContent } from './DetailsContent';
import {
  AccordionList,
  AccordionListItem,
  AccordionTitle,
} from '@/components/ui/lists/AccordionList';
import { CustomerVulnerability } from '@/api/vulnerabilities';
import { Policyholder } from '@/api/motorpolicy';

type PolicyholderContentProps = {
  policyholder: Policyholder;
  vulnerabilities: {
    isPending: boolean;
    isError: boolean;
    data: CustomerVulnerability[];
  };
};

export function PolicyholderContent({
  policyholder,
  vulnerabilities,
}: PolicyholderContentProps) {
  const accordionItems: AccordionListItem[] = [
    {
      key: 'policyholder-details',
      header: <AccordionTitle>Policyholder’s details</AccordionTitle>,
      content: (
        <DetailsContent policyholder={policyholder} data-testid="policyholder-details" />
      ),
    },
    {
      key: 'policyholder-home',
      header: <AccordionTitle>Policyholder’s home</AccordionTitle>,
      content: (
        <PolicyholderHomeContent
          policyholder={policyholder}
          data-testid="policyholder-home"
        />
      ),
    },
    {
      key: 'policyholder-licence',
      header: <AccordionTitle>Policyholder’s licence and history</AccordionTitle>,
      content: <LicenceAndHistoryContent data-testid="policyholder-licence" />,
    },
    {
      key: 'policyholder-vulnerabilities',
      header: <AccordionTitle>Vulnerabilities</AccordionTitle>,
      content: (
        <VulnerabilitiesContent
          vulnerabilities={vulnerabilities}
          data-testid="policyholder-vulnerabilities"
        />
      ),
      sx: {
        details: {
          padding: 0,
        },
      },
    },
  ];

  return <AccordionList items={accordionItems} />;
}
