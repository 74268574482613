import { Button, FormHelperText } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import {
  ALLOWED_FILE_EXTENSIONS,
  NoteAttachmentFormSchema,
} from '../hooks/useNoteAttachmentForm';
import { AttachmentChip } from './AttachmentChip';

const acceptedFileTypes = ALLOWED_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',');

export function AttachmentFileField() {
  const name = 'file';
  const { control } = useFormContext<NoteAttachmentFormSchema>();

  const {
    field: { value: file, onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {file ? (
        <AttachmentChip label={file.name} onDelete={() => onChange(null)} />
      ) : (
        <Button size="small" component="label">
          Browse files
          <input
            type="file"
            data-testid="attachment-file-upload"
            accept={acceptedFileTypes}
            hidden
            {...field}
            onChange={event => {
              onChange(event.target.files?.[0]);
            }}
          />
        </Button>
      )}
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  );
}
