import { PropsWithChildren, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext, useFormState } from 'react-hook-form';
import { Box, Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NewMtaFormSchema } from '../../hooks';
import { mapFormValuesToNewMtaPayload } from '../../helpers/mapFormValuesToNewMtaPayload';
import { getMtaMaxStateDate, getMtaMinStartDate } from '../../helpers/newMtaFormHelper';
import { NewMtaQuoteCalculation } from '../NewMtaQuote';
import { hasUpdatesInNewMtaPayload } from '../../helpers/mapFormValuesToNewMtaPayload/hasUpdatesInNewMtaPayload';
import { useEndorsements } from '../NewMtaQuote/hooks/useEndorsements';
import { NewMtaRefusalModal } from './components/NewMtaRefusalModal';
import { useNewMtaQuote } from './hooks/useNewMtaQuote';
import { useNewMtaScrollToError } from './hooks/useNewMtaScrollToError';
import { useNewMtaDeclinatures } from './hooks/useNewMtaDeclinatures';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { getApiErrorMessage } from '@/helpers/utils/errorHelpers';
import { FormDateTimePicker } from '@/components/ui/forms/FormDateTimePicker';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { selectEndorsements } from '@/helpers/selectors/quoteSelectors';
import { useResetQuoteWhenFormChanges } from '@/features/PaymentActions/QuoteCalculation';
import { useSetAlertError } from '@/hooks/useSetAlertError';

export function NewMtaForm({ children }: PropsWithChildren) {
  const { setAlert } = useSnackbarAlert({ closeOnUnmount: false });
  const { createMta, quote, isPending, isError, error, resetMtaMutation } =
    useNewMtaQuote();
  const { data: endorsements, isPending: isEndorsementsPending } = useEndorsements(
    selectEndorsements(quote ?? {}),
  );

  const { declinaturesCodes, isDeclined } = useNewMtaDeclinatures(quote);
  const [shouldShowRefusalModal, setShouldShowRefusalModal] = useState(false);

  const form = useFormContext<NewMtaFormSchema>();
  const { handleSubmit, reset, watch } = form;
  const { dirtyFields } = useFormState();
  const policy = watch('_policy');

  const shouldShowNewMtaQuote = !!quote && !!endorsements && !isDeclined;

  const handleValidSubmit: SubmitHandler<NewMtaFormSchema> = data => {
    const payload = mapFormValuesToNewMtaPayload({
      formValues: data,
      formStateDirtyFields: dirtyFields,
    });

    if (!hasUpdatesInNewMtaPayload(payload)) {
      return setAlert({
        message: 'You must change something on the form to generate new quote',
        severity: 'error',
      });
    }

    createMta(payload);
  };
  const handleInvalidSubmit = () => {
    setAlert({ message: 'Please check all the details', severity: 'error' });
  };

  useNewMtaScrollToError();
  useResetQuoteWhenFormChanges({
    form,
    isQuoteLoaded: Boolean(quote) || isError,
    resetQuote: resetMtaMutation,
  });
  useSetAlertError(
    isError,
    getApiErrorMessage(error, {
      MaximumExceeded:
        'The permitted number of changes has been reached. Please refer to your Team Manager.',
    }),
  );
  useEffect(() => setShouldShowRefusalModal(isDeclined), [isDeclined]);

  return (
    <StyledDrawerForm onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
      {shouldShowRefusalModal && (
        <NewMtaRefusalModal
          codes={declinaturesCodes}
          onClose={() => setShouldShowRefusalModal(false)}
        />
      )}
      <Box sx={{ padding: 6, display: 'flex' }}>
        <FormDateTimePicker
          sx={{ flex: 1 }}
          label="Date for changes to start"
          name="startDate"
          minDate={getMtaMinStartDate(policy)}
          maxDate={getMtaMaxStateDate(policy)}
        />
      </Box>
      <Divider />
      {children}
      {shouldShowNewMtaQuote ? (
        <NewMtaQuoteCalculation quote={quote} endorsements={endorsements} />
      ) : (
        <StyledButtonsFooter sticky>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={isPending || isEndorsementsPending}
          >
            Generate new quote
          </LoadingButton>
          <Button variant="outlined" color="inherit" onClick={() => reset()}>
            Reset form
          </Button>
        </StyledButtonsFooter>
      )}
    </StyledDrawerForm>
  );
}
