import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { addDays, addMinutes, isBefore } from 'date-fns';
import { CancellationReason } from '@/api/motorpolicy';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { MotorPolicy } from '@/api/motorpolicy';
import { getDate, resetSecondsAndMilliseconds } from '@/helpers/utils/dateHelpers';

export const getMinEffectiveDateTime = (policy: MotorPolicy) => {
  const coverStartDateTime = new Date(policy.cover.startDateTimeUtc);
  const now = addMinutes(resetSecondsAndMilliseconds(getDate()), 1);
  return coverStartDateTime > now ? coverStartDateTime : now;
};

export const getMaxEffectiveDateTime = (policy: MotorPolicy) => {
  const coverEndDateTime = new Date(policy.cover.endDateTimeUtc);
  const maxAllowedDate = addDays(getDate(), 30);
  return coverEndDateTime < maxAllowedDate ? coverEndDateTime : maxAllowedDate;
};

const cancelPolicyFormSchema = z
  .object({
    _policy: z.custom<MotorPolicy>(),
    effectiveDateTime: z
      .date()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    cancellationReason: z
      .nativeEnum(CancellationReason)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    description: z
      .string()
      .min(5, validationMessages.minLength(5))
      .max(500, validationMessages.maxLength(500)),
  })
  .refine(
    ({ effectiveDateTime, _policy }) => {
      return (
        !effectiveDateTime ||
        !isBefore(effectiveDateTime, getMinEffectiveDateTime(_policy))
      );
    },
    ({ _policy }) => ({
      path: ['effectiveDateTime'],
      message: validationMessages.minDateTime(getMinEffectiveDateTime(_policy)),
    }),
  )
  .refine(
    ({ effectiveDateTime, _policy }) => {
      return (
        !effectiveDateTime ||
        isBefore(effectiveDateTime, getMaxEffectiveDateTime(_policy))
      );
    },
    ({ _policy }) => ({
      path: ['effectiveDateTime'],
      message: validationMessages.maxDateTime(getMaxEffectiveDateTime(_policy)),
    }),
  );

export type CancelPolicyFormSchema = z.infer<typeof cancelPolicyFormSchema>;

export function useCancelPolicyForm(policy: MotorPolicy) {
  return useForm<CancelPolicyFormSchema>({
    defaultValues: {
      _policy: policy,
      effectiveDateTime: null,
      cancellationReason: null,
      description: '',
    },
    resolver: zodResolver(cancelPolicyFormSchema),
  });
}
