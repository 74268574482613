import { List, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { QuoteCalculationListRowPrice } from './QuoteCalculationListRowPrice';
import { StyledGrayBox } from '@/components/ui/styles/StyledBox';

type QuoteCalculationCostSummaryProps = PropsWithChildren<{
  label: string;
}>;

export function QuoteCalculationCostSummary({
  label,
  children,
}: QuoteCalculationCostSummaryProps) {
  return (
    <StyledGrayBox>
      <Typography variant="subtitle1">{label}</Typography>
      <List>{children}</List>
    </StyledGrayBox>
  );
}

QuoteCalculationCostSummary.Item = QuoteCalculationListRowPrice;
