import type { AddNoteResponse } from '../models/AddNoteResponse';
import type { AttachmentReceivingMethod } from '../models/AttachmentReceivingMethod';
import type { AttachmentType } from '../models/AttachmentType';
import type { CommunicationMethod } from '../models/CommunicationMethod';
import type { CorrespondenceType } from '../models/CorrespondenceType';
import type { GetNotesResponse } from '../models/GetNotesResponse';
import type { GetReminderGroupsResponse } from '../models/GetReminderGroupsResponse';
import type { GetRemindersResponse } from '../models/GetRemindersResponse';
import type { NoteInitiator } from '../models/NoteInitiator';
import type { NoteType } from '../models/NoteType';
import type { ReassignRemindersRequest } from '../models/ReassignRemindersRequest';
import type { ReminderFilterType } from '../models/ReminderFilterType';
import type { UpdateNoteResponse } from '../models/UpdateNoteResponse';
import type { UpdateReminderDateRequest } from '../models/UpdateReminderDateRequest';
import type { UpdateReminderDateResponse } from '../models/UpdateReminderDateResponse';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class NotesService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getNotes({ parentId }: { parentId?: string }): CancelablePromise<GetNotesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes',
      query: {
        ParentId: parentId,
      },
    });
  }

  postNotes({
    formData,
  }: {
    formData?: {
      ParentId?: string;
      CustomerId?: string;
      IsPolicyNote?: boolean;
      NoteType?: NoteType;
      Initiator?: NoteInitiator;
      CommunicationMethod?: CommunicationMethod;
      CorrespondenceType?: CorrespondenceType;
      Summary?: string;
      Description?: string;
      DateOfReminder?: string;
      Assignee?: string;
      AssigneeTeam?: string;
      AttachmentFiles?: Blob[];
      AttachmentTypes?: AttachmentType[];
      AttachmentReceivingMethods?: AttachmentReceivingMethod[];
      NewAttachmentsAcceptedAsProof?: boolean[];
    };
  }): CancelablePromise<AddNoteResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/notes',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  patchNotes({
    id,
    formData,
  }: {
    id: string;
    formData?: {
      NoteType?: NoteType;
      Initiator?: NoteInitiator;
      CommunicationMethod?: CommunicationMethod;
      CorrespondenceType?: CorrespondenceType;
      Summary?: string;
      DeletedAttachments?: string[];
      ExistingAttachmentsToggleAcceptedAsProof?: string[];
      Description?: string;
      DateOfReminder?: string;
      Assignee?: string;
      AssigneeTeam?: string;
      AttachmentFiles?: Blob[];
      AttachmentTypes?: AttachmentType[];
      AttachmentReceivingMethods?: AttachmentReceivingMethod[];
      NewAttachmentsAcceptedAsProof?: boolean[];
    };
  }): CancelablePromise<UpdateNoteResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/{id}',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  deleteNotes({ noteId }: { noteId: string }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/notes/{noteId}',
      path: {
        noteId: noteId,
      },
    });
  }

  patchNotesCompleteReminder({ id }: { id: string }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/complete-reminder/{Id}',
      path: {
        Id: id,
      },
    });
  }

  patchNotesRemindersUpdateDate({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: UpdateReminderDateRequest;
  }): CancelablePromise<UpdateReminderDateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/reminders/update-date/{id}',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  getNotesReminders({
    filterType,
    upcomingDate,
  }: {
    filterType?: ReminderFilterType;
    upcomingDate?: string;
  }): CancelablePromise<GetRemindersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/reminders',
      query: {
        FilterType: filterType,
        UpcomingDate: upcomingDate,
      },
    });
  }

  getNotesAdminReminders({
    dateFrom,
    dateTo,
    filterType,
    individual,
    assigned,
    team,
  }: {
    dateFrom?: string;
    dateTo?: string;
    filterType?: ReminderFilterType;
    individual?: string;
    assigned?: boolean;
    team?: string;
  }): CancelablePromise<GetRemindersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/admin/reminders',
      query: {
        DateFrom: dateFrom,
        DateTo: dateTo,
        FilterType: filterType,
        Individual: individual,
        Assigned: assigned,
        Team: team,
      },
    });
  }

  patchNotesAdminReminders({
    requestBody,
  }: {
    requestBody?: ReassignRemindersRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/admin/reminders',
      body: requestBody,
    });
  }

  getNotesRemindersGroups(): CancelablePromise<GetReminderGroupsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/reminders/groups',
    });
  }
}
