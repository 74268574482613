import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { readFileContent } from '@/helpers/utils/fileHelpers';

export function useDocumentBlobQuery(
  response?: Response,
  options?: Omit<UseQueryOptions<string | null>, 'queryKey' | 'queryFn'>,
) {
  const readBlob = async (): Promise<string | null> => {
    if (!response) return null;

    const blob = await response.blob();

    return readFileContent(blob);
  };

  return useQuery({
    queryKey: queryKeys.documentBlob(response?.url ?? ''),
    queryFn: readBlob,
    ...options,
  });
}
