import type { AbiVehicleIAPIResult } from '../models/AbiVehicleIAPIResult';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class AbiVehicleService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getAbiVehicle({
    abiCode,
  }: {
    abiCode: string;
  }): CancelablePromise<AbiVehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/abi-vehicle/{abiCode}',
      path: {
        abiCode: abiCode,
      },
    });
  }
}
