import { CircularProgress, Alert } from '@mui/material';
import { useContext } from 'react';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useVulnerabilitiesQuery } from '@/hooks/queries/useVulnerabilitiesQuery';

export function OverviewVulnerabilityAlert() {
  const policyId = useContext(PolicyIdContext);
  const {
    isPending: isVulnerabilitiesPending,
    isError: isVulnerabilitiesError,
    data: vulnerabilities = [],
  } = useVulnerabilitiesQuery(policyId);
  if (isVulnerabilitiesPending) {
    return <CircularProgress sx={{ m: 'auto' }} />;
  }

  if (isVulnerabilitiesError) {
    return (
      <Alert key="vulnerability-error" severity="error">
        Failed to load data for vulnerabilities
      </Alert>
    );
  }

  if (vulnerabilities.length) {
    return <Alert severity="error">This policy has vulnerable drivers</Alert>;
  }

  return null;
}
