import { Divider, List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { ReferenceData } from '@/api/motorpolicy';

type MedicalConditionsContentProps = {
  medicalCondition: ReferenceData | undefined;
};

export function MedicalConditionsContent({
  medicalCondition,
}: MedicalConditionsContentProps) {
  return (
    <>
      <Divider />
      <List>
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
          Medical conditions
        </Typography>
        <ListRow label="Medical conditions">
          {booleanAnswer(Boolean(medicalCondition))}
        </ListRow>
        {medicalCondition && (
          <ListRow label="Condition">{medicalCondition?.description}</ListRow>
        )}
      </List>
    </>
  );
}
