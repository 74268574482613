import { ReactNode, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Typography,
  styled,
} from '@mui/material';
import { ExpandedContext } from '@/contexts/ExpandedContext';

export const AccordionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle1.fontSize,
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
}));

export type AccordionListItem = {
  key: string | number;
  header: ReactNode;
  content: ReactNode;
  defaultExpanded?: boolean;
  sx?: {
    item?: SxProps;
    summary?: SxProps;
    details?: SxProps;
  };
};

type AccordionListProps = {
  items: AccordionListItem[];
};

type AccordionItemProps = {
  accordionKey: string | number;
  item: AccordionListItem;
};

function AccordionItem({ item, accordionKey }: AccordionItemProps) {
  const [expanded, setExpanded] = useState(Boolean(item.defaultExpanded));

  return (
    <ExpandedContext.Provider value={expanded}>
      <Accordion
        sx={item.sx?.item}
        onChange={(_, expanded) => setExpanded(expanded)}
        expanded={expanded}
      >
        <AccordionSummaryStyled
          aria-controls={`${accordionKey}-content`}
          data-testid={`${accordionKey}-header`}
          id={`${accordionKey}-header`}
          sx={item.sx?.summary}
        >
          {item.header}
        </AccordionSummaryStyled>
        <AccordionDetailsStyled
          sx={{ ...item.sx?.details }}
          data-testid={`${accordionKey}-content`}
        >
          {item.content}
        </AccordionDetailsStyled>
      </Accordion>
    </ExpandedContext.Provider>
  );
}

export function AccordionList({ items }: AccordionListProps) {
  return items.map(item => (
    <AccordionItem key={item.key} item={item} accordionKey={item.key} />
  ));
}
