import { List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { Endorsement } from '@/api/motorpolicy';
import { convertPascalCaseToText } from '@/helpers/utils/textHelpers';

type EndorsementsContentProps = {
  endorsements: Endorsement[];
};

export function EndorsementsContent({ endorsements }: EndorsementsContentProps) {
  return (
    <>
      {endorsements.map((endorsement, endorsmentIndex) => (
        <div key={endorsmentIndex}>
          <List data-testid={`endorsment-${endorsmentIndex}`}>
            <ListRow label={convertPascalCaseToText(endorsement.name ?? '')}>
              {endorsement.wording}
            </ListRow>
          </List>
        </div>
      ))}
    </>
  );
}
