import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { GetRemindersResponse, ReminderFilterType } from '@/api/note';
import { ReminderDetail } from '@/api/note';
import { sortByDateAsc } from '@/helpers/utils/arrayHelpers';

type RemindersParams = { filterType: ReminderFilterType };

export function useIndividualRemindersQuery(filters: RemindersParams) {
  const api = useApiClient();

  const fetchIndividualReminders = () => api.note.notes.getNotesReminders(filters);
  const selectIndividualReminders = (response: GetRemindersResponse) =>
    sortByDateAsc(response.remindersDetails ?? [], 'dateOfReminder') as ReminderDetail[];

  const query = useQuery({
    queryKey: queryKeys.individualReminders(filters),
    queryFn: fetchIndividualReminders,
    select: selectIndividualReminders,
  });

  return {
    ...query,
    reminders: query.data ?? [],
  };
}
