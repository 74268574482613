import { OptionCode } from '@/api/retail/v1';

export type NonNullableFields<T, K extends keyof T = never> = {
  [P in Exclude<keyof T, K>]-?: Exclude<T[P], undefined | null>;
} & {
  [P in K]: T[P];
};

type ValidOptionCode = {
  code: string;
} & OptionCode;

export const isValidOptionCode = (
  optionCode: OptionCode,
): optionCode is ValidOptionCode => typeof optionCode.code === 'string';

export function getValidOptionCodes(items: OptionCode[]) {
  return items.filter(isValidOptionCode);
}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
type Pipe = {
  <A>(value: A): A;
  <A, B>(value: A, fn1: (input: A) => B): B;
  <A, B, C>(value: A, fn1: (input: A) => B, fn2: (input: B) => C): C;
  <A, B, C, D>(
    value: A,
    fn1: (input: A) => B,
    fn2: (input: B) => C,
    fn3: (input: C) => D,
  ): D;
  <A, B, C, D, E>(
    value: A,
    fn1: (input: A) => B,
    fn2: (input: B) => C,
    fn3: (input: C) => D,
    fn4: (input: D) => E,
  ): E;
  // ... and so on
};

export const pipe: Pipe = (value: any, ...fns: Function[]): unknown => {
  return fns.reduce((acc, fn) => fn(acc), value);
};
