export enum PolicyQueryParams {
  Id = 'policy.id',
  Section = 'policy.section',
  Tab = 'policy.tab',
}

export enum PolicyMenuItemKey {
  Overview = 'overview',
  Car = 'car',
  Cover = 'cover',
  Payments = 'payments',
  Drivers = 'drivers',
  Documents = 'documents',
  Notes = 'notes',
  NewMta = 'new-mta',
  History = 'history',
  Claims = 'claims',
  Actions = 'actions',
  Account = 'account',
}
