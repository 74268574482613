import { MotorQuote } from '@/api/motorquote';
import { getValidOptionCodes } from '@/helpers/utils/typesHelpers';

export function useNewMtaDeclinatures(quote: MotorQuote | null) {
  const declinaturesCodes = getValidOptionCodes(quote?.declinatures ?? []).map(
    ({ code }) => code,
  );
  const isDeclined = declinaturesCodes.length > 0;

  return {
    declinaturesCodes,
    isDeclined,
  };
}
