import { PropsWithChildren, createContext } from 'react';
import { useDriversSelectOptions } from './hooks/useDriversSelectOptions';
import { getDriversFormFieldName } from './helpers/getDriversFormFieldName';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';

type NewMtaDriverContextProps = {
  nestIndex: number;
  getFieldName: ReturnType<typeof getDriversFormFieldName>;
} & ReturnType<typeof useDriversSelectOptions>;

export const NewMtaDriverContext = createContext<NewMtaDriverContextProps | null>(null);

type NewMtaDriverContextProviderProps = PropsWithChildren<{
  index: number;
}>;

export function NewMtaDriverContextProvider({
  index,
  children,
}: NewMtaDriverContextProviderProps) {
  const driversSelectOptions = useDriversSelectOptions();
  const getFieldName = getDriversFormFieldName(index);

  if (driversSelectOptions.isPending) {
    return <DrawerLoading />;
  }

  return (
    <NewMtaDriverContext.Provider
      value={{
        nestIndex: index,
        getFieldName,
        ...driversSelectOptions,
      }}
    >
      {children}
    </NewMtaDriverContext.Provider>
  );
}
