import { FormProvider } from 'react-hook-form';
import { DriverNestedFormFields } from '../DriverNestedFormFields';
import { useQualificationForm } from './hooks/useQualificationForm';
import { QualificationFormSchema } from '@/features/NewMta/hooks/schemas/newMtaDriversQualificationSchema';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { FormDatePicker } from '@/components/ui/forms/FormDatePicker';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';

type QualificationFormProps = {
  onAddQualification: (fieldValues: QualificationFormSchema) => void;
  onCancel: () => void;
};

export function QualificationForm({
  onAddQualification,
  onCancel,
}: QualificationFormProps) {
  const form = useQualificationForm();
  const { drivingQualifications } = useNewMtaDriverContext();

  return (
    <FormProvider {...form}>
      <DriverNestedFormFields<QualificationFormSchema>
        form={form}
        onAdd={onAddQualification}
        onCancel={onCancel}
        buttonLabel="Add this qualification"
      >
        <FormTextField
          select
          label="Qualification"
          name="type"
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          {renderSelectOptionsByOptionsCodes(drivingQualifications ?? [])}
        </FormTextField>
        <FormDatePicker
          label="Date of qualification"
          name="date"
          sx={{ ...commonSx, ...maxWidthSx }}
          maxDate={new Date()}
        />
      </DriverNestedFormFields>
    </FormProvider>
  );
}
