import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { MotorPolicy } from '@/api/motorpolicy';

type MotorPolicyParams = {
  policyVersion?: number;
  skipFutureVersion?: boolean;
};

export const fetchAuditMinutes = 15;

export function useMotorPolicyQuery(
  id: string,
  { policyVersion, skipFutureVersion }: MotorPolicyParams = {},
  options?: Omit<UseQueryOptions<MotorPolicy>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchMotorPolicy = () =>
    api.motorpolicy.motorPolicy.getMotorpolicies({
      id,
      fetchAuditMinutes,
      policyVersion,
      skipFutureVersion,
    }) as unknown as MotorPolicy;

  return useQuery<MotorPolicy>({
    queryKey: queryKeys.motorPolicy(id, policyVersion),
    queryFn: fetchMotorPolicy,
    staleTime: 5000,
    ...options,
  });
}
