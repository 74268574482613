import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import {
  NewMtaFormSchema,
  PolicyholderDriverSchema,
  useNewMtaDriverContext,
} from '@/features/NewMta/hooks';
import { useAddressQuery } from '@/hooks/queries/useAddressQuery';

export function usePolicyholderAddressSearch() {
  const {
    formState: { errors },
    trigger,
    getValues,
    setValue,
    watch,
  } = useFormContext<NewMtaFormSchema>();
  const { nestIndex, getFieldName } = useNewMtaDriverContext();

  const queryPostCodeForSearch = watch(getFieldName('_queryPostCodeForSearch'));
  const postOfficeAddress = watch(getFieldName('postOfficeAddress'));

  const isQueryEnabled = postOfficeAddress.postCode !== queryPostCodeForSearch;

  const {
    data: addressOptions = [],
    isError: isAddressSearchError,
    isFetching: isAddressSearchFetching,
    isPending: isAddressSearchPending,
  } = useAddressQuery(
    { postcode: queryPostCodeForSearch },
    {
      enabled: isQueryEnabled,
      retry: false,
    },
  );

  const validateAndFindAddress = async () => {
    const isValid = await trigger(getFieldName('postCodeForSearch'));
    if (isValid) {
      setValue(
        getFieldName('_queryPostCodeForSearch'),
        getValues(getFieldName('postCodeForSearch')),
      );

      const policyholderErrors = errors.drivers?.[nestIndex] as
        | FieldErrorsImpl<PolicyholderDriverSchema>
        | undefined;

      if (policyholderErrors?._queryPostCodeForSearch) {
        trigger(getFieldName('_queryPostCodeForSearch'));
      }
    }
  };

  return {
    addressOptions,
    isAddressSearchError,
    isAddressSearchFetching,
    isAddressSearchPending,
    validateAndFindAddress,
  };
}
