import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { CreateSupportMtaRequest, CreateMtaResponse } from '@/api/motorpolicy';
import { ApiError } from '@/api/core/ApiError';

export function useCreateMtaMutation() {
  const api = useApiClient();

  return useMutation<CreateMtaResponse, ApiError, CreateSupportMtaRequest>({
    mutationFn: async requestBody =>
      api.motorpolicy.mta.postMtaSupportMta({ requestBody }),
  });
}
