import { List } from '@mui/material';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { ListRow } from '@/components/ui/lists/ListRow';
import { StateChip } from '@/components/ui/presentation/StateChip';
import { getStateChipType } from '@/features/Policies/helpers/policyHelper';
import { MotorPolicy } from '@/api/motorpolicy';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { getReferrerName } from '@/helpers/nameMappings/referrerNameMappings';
import { getSalesPlatformName } from '@/helpers/nameMappings/salesPlatformNameMappings';
import { getMainTransaction, getPolicyEndDate } from '@/helpers/data/policyHelpers';

type OverviewListProps = {
  policy: MotorPolicy;
};

export function OverviewList({ policy }: OverviewListProps) {
  const mainTransaction = getMainTransaction(policy.transactions ?? []);

  return (
    <List>
      <ListRow label="Status">
        {policy.status && (
          <StateChip
            label={policy.status}
            type={getStateChipType(policy.status)}
            testId="overview_policy-status-chip"
          />
        )}
      </ListRow>
      <ListRow label="Policy ID">{policy.id}</ListRow>
      <ListRow label="Policy correlation ID">{policy.policyCorrelationId}</ListRow>
      <ListRow label="Registration">{policy.vehicle.registrationNumber}</ListRow>
      <ListRow label="Details">{`${policy.vehicle.make} ${policy.vehicle.model}`}</ListRow>
      <ListRow label="Policyholder">{`${policy.policyholder.firstName} ${policy.policyholder.lastName}`}</ListRow>
      <ListRow label="Start">{formatDateTime(policy.cover.startDateTimeUtc)}</ListRow>
      <ListRow label="End">{formatDateTime(getPolicyEndDate(policy))}</ListRow>
      <ListRow label="Product">{policy.product.description}</ListRow>
      <ListRow label="Underwriter">{policy.cover.underwriter}</ListRow>
      <ListRow label="Payment type">
        {mainTransaction?.paymentPlan?.type?.description}
      </ListRow>
      <ListRow label="Cover type">{policy.cover.type.description}</ListRow>
      <ListRow label="Referrer">
        {mainTransaction?.referrer && getReferrerName(mainTransaction.referrer)}
      </ListRow>
      <ListRow label="Sales platform">
        {mainTransaction?.salesPlatform &&
          getSalesPlatformName(mainTransaction.salesPlatform)}
      </ListRow>
      <ListRow label="Any driver ever had insurance declined or cancelled">
        {booleanAnswer(policy.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled)}
      </ListRow>
      <ListRow label="Auto Renew">{booleanAnswer(policy.isAutoRenewalRequested)}</ListRow>
    </List>
  );
}
