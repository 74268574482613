import { Overview } from './Overview';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function OverviewDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Overview" />
      <Overview />
    </DrawerContent>
  );
}
