import { Box, Button } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { QuoteCalculationListRowPrice } from '../QuoteCalculationListRowPrice';
import { useQuoteCalculationContext } from '../../hooks/useQuoteCalculationContext';
import { QuoteCalculationPriceBreakdownModal } from './components/QuoteCalculationPriceBreakdownModal';
import { QuoteCalculationPriceBreakdownInstallmentDetails } from './components/QuoteCalculationPriceBreakdownInstallmentDetails';
import { QuoteCalculationPriceBreakdownTotal } from './components/QuoteCalculationPriceBreakdownTotal';

export function QuoteCalculationPriceBreakdown({ children }: PropsWithChildren) {
  const { quoteResult } = useQuoteCalculationContext();
  const [isModalOpen, setModalOpen] = useState(false);

  if (quoteResult === 'no_charge_or_refund') return null;

  return (
    <Box>
      {isModalOpen && (
        <QuoteCalculationPriceBreakdownModal onClose={() => setModalOpen(false)}>
          {children}
        </QuoteCalculationPriceBreakdownModal>
      )}
      <Button size="small" onClick={() => setModalOpen(true)}>
        View price breakdown
      </Button>
    </Box>
  );
}

QuoteCalculationPriceBreakdown.Price = QuoteCalculationListRowPrice;
QuoteCalculationPriceBreakdown.InstallmentDetails =
  QuoteCalculationPriceBreakdownInstallmentDetails;
QuoteCalculationPriceBreakdown.Total = QuoteCalculationPriceBreakdownTotal;
