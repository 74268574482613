import type { GetCustomerVulnerabilitiesResponse } from '../models/GetCustomerVulnerabilitiesResponse';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class CustomerVulnerabilitiesService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getCustomerVulnerabilities({
    policyId,
  }: {
    policyId?: string;
  }): CancelablePromise<GetCustomerVulnerabilitiesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/customer-vulnerabilities',
      query: {
        policyId: policyId,
      },
    });
  }
}
