import { Divider, List } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useSelectedPaymentPlan } from '../../../hooks/useSelectedPaymentPlan';
import { isMonthlyPaymentPlan } from '../../../helpers/quoteCalculationHelper';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import {
  selectAprFromProvider,
  selectFinanceAmount,
  selectInterestRate,
  selectMonthlyCost,
  selectNumberOfInstallments,
  selectTotal,
} from '@/helpers/selectors/paymentPlanSelectors';

export function QuoteCalculationPriceBreakdownInstallmentDetails({
  children,
}: PropsWithChildren) {
  const selectedPaymentPlan = useSelectedPaymentPlan();
  const shouldShowInstallmentDetails =
    selectedPaymentPlan?.installmentDetails && isMonthlyPaymentPlan(selectedPaymentPlan);

  if (!shouldShowInstallmentDetails) return null;

  const installmentDetails = selectedPaymentPlan.installmentDetails!;

  const financeAmount = selectFinanceAmount({ installmentDetails });
  const interestRate = selectInterestRate({ installmentDetails });
  const aprFromProvider = selectAprFromProvider({ installmentDetails });
  const monthlyCost = selectMonthlyCost({ installmentDetails });
  const numberOfInstallments = selectNumberOfInstallments({ installmentDetails });
  const total = selectTotal({ installmentDetails });

  return (
    <>
      <Divider />
      <List>
        <ListRow disablePadding alignContentRight label="Amount to be financed">
          {financeAmount != null ? formatCurrency(financeAmount) : null}
        </ListRow>
        <ListRow disablePadding alignContentRight label="Interest rate (variable)">
          {interestRate != null && `${interestRate}%`}
        </ListRow>
        <ListRow disablePadding alignContentRight label="Representative APR (variable)">
          {aprFromProvider != null && `${aprFromProvider}%`}
        </ListRow>
        {numberOfInstallments != null && (
          <ListRow
            disablePadding
            alignContentRight
            label={`${numberOfInstallments} monthly payments remaining`}
          >
            {monthlyCost != null && formatCurrency(monthlyCost)}
          </ListRow>
        )}
        <ListRow disablePadding alignContentRight label="Total amount payable for change">
          {total != null && formatCurrency(total)}
        </ListRow>
        {children}
      </List>
    </>
  );
}
