import { LoadingButton } from '@mui/lab';
import { useContext, useEffect } from 'react';
import { Stack } from '@mui/material';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { useGenerateLetterMutation } from '@/hooks/mutations/useGenerateLetterMutation';
import { useSetAlertError } from '@/hooks/useSetAlertError';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';

export function CustomerLetter() {
  const { setAlert } = useSnackbarAlert();
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const {
    mutate: generateLetter,
    isPending: isGenerateLetterMutationPending,
    isError: isGenerateLetterMutationError,
    isSuccess: isGenerateLetterMutationSuccess,
  } = useGenerateLetterMutation();
  const handleGenerateLetter = () => {
    if (!policy) {
      setAlert({ severity: 'error', message: GENERAL_ERROR_MSG });
      return;
    }

    const {
      policyholder: { firstName, lastName, address: { formattedAddress } = {} },
    } = policy;

    generateLetter({
      policyId,
      policyholderFirstName: firstName ?? '',
      policyholderLastName: lastName ?? '',
      address: {
        line1: formattedAddress?.line1 ?? '',
        line2: formattedAddress?.line2 ?? undefined,
        line3: formattedAddress?.line3 ?? undefined,
        line4: formattedAddress?.line4 ?? undefined,
        line5: formattedAddress?.line5 ?? undefined,
        postCode: formattedAddress?.postCode ?? '',
      },
    });
  };

  useSetAlertError(isGenerateLetterMutationError);

  useEffect(() => {
    if (isGenerateLetterMutationSuccess) {
      setAlert({
        message: 'Customer letter has been generated and downloaded',
        severity: 'success',
      });
    }
  }, [isGenerateLetterMutationSuccess]);

  return (
    <Stack direction="column" flex={1} justifyContent="flex-end">
      <StyledButtonsFooter sticky>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={isGenerateLetterMutationPending}
          onClick={handleGenerateLetter}
        >
          Generate customer letter
        </LoadingButton>
      </StyledButtonsFooter>
    </Stack>
  );
}
