import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks/useNewMtaDriverContext';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';

export function NonMotoringConvictionsField() {
  const { getFieldName } = useNewMtaDriverContext();

  return (
    <FormRadioGroup
      label="Non-motoring convictions"
      name={getFieldName('hasNonMotoringConvictions')}
      sx={{ ...commonSx, ...maxWidthSx }}
    >
      <FormRadio
        testId="new-mta_has-non-motoring-convictions-yes-radio"
        label={YesNo.Yes}
        value={YesNo.Yes}
      />
      <FormRadio
        testId="new-mta_has-non-motoring-convictions-no-radio"
        label={YesNo.No}
        value={YesNo.No}
      />
    </FormRadioGroup>
  );
}
