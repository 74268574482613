import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { PaymentPlan } from '@/api/motorquote';

const paymentPlansFormSchema = z.object({
  paymentPlanCode: z.string(),
});

export type PaymentPlansFormSchema = z.infer<typeof paymentPlansFormSchema>;

const getDefaultPaymentPlanCode = (paymentPlans: PaymentPlan[]) => {
  const defaultPaymentPlan = paymentPlans.find(paymentPlan => paymentPlan.default);
  return defaultPaymentPlan?.type?.code ?? paymentPlans[0]?.type?.code ?? '';
};

export function usePaymentPlansForm(paymentPlans: PaymentPlan[]) {
  return useForm<PaymentPlansFormSchema>({
    defaultValues: {
      paymentPlanCode: getDefaultPaymentPlanCode(paymentPlans),
    },
    resolver: zodResolver(paymentPlansFormSchema),
  });
}
