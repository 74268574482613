import type { PatchMotorQuotePriceRequest } from '../models/PatchMotorQuotePriceRequest';
import type { InsurancePrice } from '../models/InsurancePrice';
import type { MotorQuote } from '../models/MotorQuote';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class MotorQuoteService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getMotorQuote({ id }: { id: string }): CancelablePromise<MotorQuote> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-quote/{id}',
      path: {
        id: id,
      },
    });
  }

  patchMotorQuotePrice({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PatchMotorQuotePriceRequest;
  }): CancelablePromise<InsurancePrice> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/motor-quote/{id}/price',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }
}
