import { ChangeFeesPremiumFormSchema } from '../hooks/useChangeFeesPremiumForm';
import { FeeType } from '@/api/motorquote';
import { PatchMotorQuotePriceRequest } from '@/api/motorquote';

const getFeesUpdate = (fee: string, feeType: FeeType) => ({
  updateFees: [
    {
      total: { amount: Number(fee) },
      type: feeType,
    },
  ],
});

const getPremiumUpdate = (premium: string) => ({
  updatePremium: { amount: Number(premium) },
});

export const mapChangeFeesPremiumFormValuesToPayload = (
  defaultValues: Pick<ChangeFeesPremiumFormSchema, 'fee' | 'premium'>,
  formData: ChangeFeesPremiumFormSchema,
  feeType: FeeType,
): PatchMotorQuotePriceRequest => ({
  ...(defaultValues.fee !== formData.fee
    ? getFeesUpdate(formData.fee, feeType)
    : undefined),
  ...(defaultValues.premium !== formData.premium
    ? getPremiumUpdate(formData.premium)
    : undefined),
  reason: formData.reason,
});
