import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { noteFormSchema, NoteFormSchema } from './note.schema';
import { Note } from '@/api/note';

const getExistingAttachments = (
  note: Note | undefined,
): NoteFormSchema['existingAttachments'] =>
  note?.attachments.map(attachment => ({
    id: attachment.id!,
    fileName: attachment.fileName!,
  })) ?? [];

const mapNoteToDefaultValues = (note: Note | undefined): NoteFormSchema => ({
  noteType: note?.noteType ?? null,
  noteInitiator: note?.initiator ?? null,
  communicationMethod: note?.communicationMethod ?? null,
  summary: note?.summary ?? '',
  description: note?.description ?? '',
  dateOfReminder: note?.reminder?.dateOfReminder
    ? new Date(note.reminder.dateOfReminder)
    : null,
  assigneeTeam: note?.reminder?.assigneeTeam ?? '',
  existingAttachments: getExistingAttachments(note),
  newAttachments: [],
});

export function useNoteForm(note: Note | undefined) {
  return useForm<NoteFormSchema>({
    defaultValues: mapNoteToDefaultValues(note),
    resolver: zodResolver(noteFormSchema),
  });
}
