import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { AddCustomerVulnerabilitySupportRequest } from '@/api/support';

type UseCreateVulnerabilityMutationProps = {
  onSuccess?: () => void;
};

export function useCreateVulnerabilityMutation({
  onSuccess,
}: UseCreateVulnerabilityMutationProps = {}) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (data: AddCustomerVulnerabilitySupportRequest) =>
      api.support.customerVulnerability.postCustomerVulnerabilities({
        requestBody: data,
      }),
    onSuccess: async data => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.vulnerabilities(data.customerVulnerability!.policyId!),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.adminReminders(),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.individualReminders(),
      });
      onSuccess?.();
    },
  });
}
