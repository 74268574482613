import { Box, Button } from '@mui/material';
import { MouseEventHandler } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { AccordionTitle } from '@/components/ui/lists/AccordionList';
import { StateChip, StateChipType } from '@/components/ui/presentation/StateChip';
import { StyledAccordionHeader } from '@/components/ui/styles/StyledAccordionHeader';
import { StyledAccordionHeaderSubtitle } from '@/components/ui/styles/StyledAccordionHeaderSubtitle';
import { formatDate } from '@/helpers/utils/dateHelpers';

type NewMtaDriverAccordionHeaderProps = {
  id: string;
  name: string;
  isMainDriver: boolean;
  isPolicyholder: boolean;
  dateOfBirth: Date | string;
  isInvalid: boolean;
  onRemove: MouseEventHandler<HTMLButtonElement>;
};

export function NewMtaDriverAccordionHeader({
  id,
  name,
  isMainDriver,
  isPolicyholder,
  dateOfBirth,
  isInvalid,
  onRemove,
}: NewMtaDriverAccordionHeaderProps) {
  const labelId = `${id}-label`;
  const shouldShowRemoveButton = !isPolicyholder && !isMainDriver;

  return (
    <Box width="100%" role="region" aria-labelledby={labelId}>
      <StyledAccordionHeader>
        <Box>
          <AccordionTitle>
            {isInvalid && (
              <Box component="span" mr={1}>
                <CircleIcon color="error" sx={{ fontSize: '8px' }} />
              </Box>
            )}

            <span id={labelId}>{name}</span>
          </AccordionTitle>
        </Box>
        <Box sx={{ display: 'flex', gap: 3 }}>
          {isMainDriver ? (
            <StateChip
              label="Main driver"
              type={StateChipType.Success}
              testId={`new-mta_driver[${id}]_main-driver-chip`}
            />
          ) : null}
          {isPolicyholder ? (
            <StateChip
              label="Policyholder"
              type={StateChipType.Info}
              testId={`new-mta_driver[${id}]_policyholder-chip`}
            />
          ) : null}
        </Box>
      </StyledAccordionHeader>
      <StyledAccordionHeader
        sx={isPolicyholder || isMainDriver ? { justifyContent: 'flex-end' } : {}}
      >
        {shouldShowRemoveButton ? (
          <Button size="small" onClick={onRemove} sx={{ mt: 2 }}>
            Remove driver
          </Button>
        ) : null}
        <StyledAccordionHeaderSubtitle>
          Date of birth {formatDate(dateOfBirth)}
        </StyledAccordionHeaderSubtitle>
      </StyledAccordionHeader>
    </Box>
  );
}
