import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { FormTextField, uppercase } from '@/components/ui/forms/FormTextField';
import { commonSx, getErrorProps, maxWidthSx } from '@/helpers/utils/formHelpers';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';

type NewMtaCarDetailsRegQueryFieldProps = {
  isPending: boolean;
  isError: boolean;
  inputLabel: string;
  buttonText: string;
  onConfirm: () => void;
};

export function NewMtaCarDetailsRegQueryField({
  isPending,
  isError,
  inputLabel,
  buttonText,
  onConfirm,
}: NewMtaCarDetailsRegQueryFieldProps) {
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext<NewMtaFormSchema>();

  const { registration, _queryRegistration: queryRegistration } = watch('car');

  const getRegistrationErrorProps = () => {
    if (errors.car?.registration) {
      return getErrorProps(errors.car.registration);
    }

    if (errors.car?._queryRegistration) {
      return getErrorProps(errors.car?._queryRegistration);
    }

    if (isError) {
      return getErrorProps({
        message: 'Please check this',
        type: '',
      });
    }

    return {};
  };

  useEffect(() => {
    if (errors.car?._queryRegistration) {
      trigger('car._queryRegistration');
    }
  }, [registration]);

  return (
    <Stack gap={3} mt={4}>
      <FormTextField
        name="car.registration"
        label={inputLabel}
        sx={{ ...commonSx, ...maxWidthSx, m: 0 }}
        transformers={[uppercase]}
        {...getRegistrationErrorProps()}
      />
      <LoadingButton
        disabled={registration === queryRegistration}
        loading={isPending}
        onClick={onConfirm}
        sx={{ alignSelf: 'baseline' }}
      >
        {buttonText}
      </LoadingButton>
    </Stack>
  );
}
