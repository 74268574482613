export function sortArrayByBooleanKey<T>(array: T[], key: keyof T): T[] {
  return array.sort((a, b) => {
    if (a[key] === b[key]) {
      return 0;
    }

    return a[key] ? -1 : 1;
  });
}

export function uniqueArrayByKey<T extends object, TKey extends keyof T>(
  array: T[],
  key: TKey,
): T[] {
  return [...new Map(array.map(item => [item[key], item])).values()];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByDateAsc = <T extends Record<string, any>>(
  collection: T[],
  key: keyof T,
) => [...collection].sort((obj1, obj2) => Date.parse(obj1[key]) - Date.parse(obj2[key]));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByDateDesc = <T extends Record<string, any>>(
  collection: T[],
  key: keyof T,
) => [...collection].sort((obj1, obj2) => Date.parse(obj2[key]) - Date.parse(obj1[key]));

export const sumArray = (numbers: number[]) =>
  numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
