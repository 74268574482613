import { Divider, Typography } from '@mui/material';
import { DriverLicenceAndHistoryFormFields } from '../DriverLicenceAndHistoryFormFields';
import { DriverClaimsFormFields } from '../DriverClaimsFormFields';
import { NonMotoringConvictionsField } from '../NonMotoringConvictionsField';
import { DriverConvictionsFormFields } from '../DriverConvictionsFormFields';
import { DriverDetailsFormFields } from '../DriverDetailsFormFields';
import { PolicyholderHome } from './components/PolicyholderHome';

export function NewMtaPolicyholderDriverForm() {
  return (
    <>
      <Divider sx={{ mb: 4 }} />
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Policyholder&apos;s details
      </Typography>
      <DriverDetailsFormFields />
      <Divider sx={{ my: 4 }} />
      <PolicyholderHome />
      <Divider sx={{ my: 4 }} />
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Policyholder&apos;s licence and history
      </Typography>
      <DriverLicenceAndHistoryFormFields />
      <Divider sx={{ my: 4 }} />
      <DriverClaimsFormFields />
      <Divider sx={{ my: 4 }} />
      <DriverConvictionsFormFields />
      <Divider sx={{ my: 4 }} />
      <NonMotoringConvictionsField />
    </>
  );
}
