import { ReferenceData } from '@/api/motorpolicy';

export const getDefaultValueForReferenceData = (
  referenceData: ReferenceData | undefined,
) =>
  referenceData?.code && referenceData.description
    ? {
        code: referenceData.code,
        description: referenceData.description,
      }
    : null;
