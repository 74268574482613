import { Alert, Button, Typography } from '@mui/material';
import { Modal } from '@/components/ui/modals/Modal';

type NewMtaCarRegChangeModalProps = {
  onConfirm: () => void;
  onClose: () => void;
};

export function NewMtaCarRegChangeModal({
  onConfirm,
  onClose,
}: NewMtaCarRegChangeModalProps) {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Changing just the reg</Modal.Header>
      <Modal.Content>
        <Alert severity="warning">The new reg doesn’t match our records</Alert>
        <Typography variant="body2">
          Please confirm with the customer that the new reg for the current car doesn’t
          match our records and we will need to perform checks until it matches.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" onClick={onConfirm}>
          Confirm and continue
        </Button>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
