import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FilterType } from '@/api/support';
import { validatePolicyDateRange } from '@/helpers/validations/policyValidations';

const searchSchema = z
  .object({
    keyword: z.string(),
    type: z
      .object({
        code: z.nativeEnum(FilterType),
        description: z.string(),
      })
      .nullable(),
    dateFrom: z.date().nullable(),
    dateTo: z.date().nullable(),
  })
  .superRefine(({ dateFrom, dateTo }, ctx) => {
    validatePolicyDateRange({ dateFrom, dateTo }, ctx);
  });

export type SearchSchema = z.infer<typeof searchSchema>;

export function useSearchForm(defaultValues?: Partial<SearchSchema>) {
  return useForm<SearchSchema>({
    defaultValues: {
      keyword: '',
      type: null,
      dateFrom: null,
      dateTo: null,
      ...defaultValues,
    },
    resolver: zodResolver(searchSchema),
    mode: 'onChange',
  });
}
