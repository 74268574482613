import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BypassRefundReason } from '@/models/BypassRefundReason';

type QuoteCalculationCreateOrderActionContextProps = {
  shouldBypassRefund: boolean;
  setShouldBypassRefund: Dispatch<SetStateAction<boolean>>;
  bypassRefundReason: BypassRefundReason | null;
  setBypassRefundReason: Dispatch<SetStateAction<BypassRefundReason | null>>;
};

export const QuoteCalculationCreateOrderActionContext = createContext<
  QuoteCalculationCreateOrderActionContextProps | undefined
>(undefined);

export function useQuoteCalculationCreateOrderActionContext() {
  const context = useContext(QuoteCalculationCreateOrderActionContext);

  if (context === undefined) {
    throw new Error(
      'useQuoteCalculationCreateOrderActionContext must be used within QuoteCalculationCreateOrderActionContext',
    );
  }

  return context;
}
