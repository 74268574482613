import { IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

type RotateButtonProps = {
  rotate: number;
  onRotateChange: (rotate: number) => void;
};

export function RotateButton({ rotate, onRotateChange }: RotateButtonProps) {
  const handleRotate = () => {
    const rotateDegrees = [0, 90, 180, 270];
    const index = rotateDegrees.findIndex(degree => rotate === degree);
    const newIndex = index + 1 < rotateDegrees.length ? index + 1 : 0;
    onRotateChange(rotateDegrees[newIndex]);
  };

  return (
    <IconButton aria-label="rotate" color="secondary" onClick={handleRotate}>
      <CachedIcon />
    </IconButton>
  );
}
