import { FormProvider } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import { usePaymentPlansForm } from '../hooks/usePaymentPlansForm';
import { useQuoteCalculationContext } from '../hooks/useQuoteCalculationContext';

export function QuoteCalculationPaymentPlansFormProvider({
  children,
}: PropsWithChildren) {
  const { paymentPlans } = useQuoteCalculationContext();
  const form = usePaymentPlansForm(paymentPlans);

  return <FormProvider {...form}>{children}</FormProvider>;
}
