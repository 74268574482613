import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseDeleteVulnerabilityMutationProps = {
  onSuccess?: () => void;
};

type DeleteVulnerabilityMutationFnProps = {
  policyId: string;
  vulnerabilityId: string;
  noteId: string | null;
};

export function useDeleteVulnerabilityMutation({
  onSuccess,
}: UseDeleteVulnerabilityMutationProps) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ vulnerabilityId, noteId }: DeleteVulnerabilityMutationFnProps) =>
      api.support.customerVulnerability.deleteCustomerVulnerabilities({
        id: vulnerabilityId,
        noteId: noteId ?? undefined,
      }),
    onSuccess: async (_data, { policyId }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.vulnerabilities(policyId),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.adminReminders(),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.individualReminders(),
      });
      onSuccess?.();
    },
  });
}
