import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';
import { CommunicationMethod, NoteInitiator, NoteType } from '@/api/note';
import {
  getCommunicationMethodName,
  getNoteInitiatorName,
  getNoteTypeName,
} from '@/helpers/nameMappings/noteNameMappings';

export function NoteFormBasicFields() {
  return (
    <>
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        select
        label="Note type"
        name="noteType"
        id="noteType"
      >
        {renderSelectOptionsByEnum(NoteType, getNoteTypeName)}
      </FormTextField>
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        select
        label="Initiator"
        name="noteInitiator"
        id="noteInitiator"
      >
        {renderSelectOptionsByEnum(NoteInitiator, getNoteInitiatorName)}
      </FormTextField>
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        select
        label="Communication method"
        name="communicationMethod"
        id="communicationMethod"
      >
        {renderSelectOptionsByEnum(CommunicationMethod, getCommunicationMethodName)}
      </FormTextField>
      <FormTextField sx={commonSx} name="summary" id="summary" label="Summary" />
      <FormTextField
        sx={commonSx}
        name="description"
        id="description"
        label="Description"
        multiline
        rows={6}
      />
    </>
  );
}
