import { QualificationFormSchema } from '@/features/NewMta/hooks/schemas/newMtaDriversQualificationSchema';
import { ListRow } from '@/components/ui/lists/ListRow';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { formatDate } from '@/helpers/utils/dateHelpers';

type QualificationProps = QualificationFormSchema;

export function Qualification({ type, date }: QualificationProps) {
  const { drivingQualifications } = useNewMtaDriverContext();

  const typeDescription = drivingQualifications?.find(
    ({ code }) => code === type,
  )?.description;

  return (
    <>
      <ListRow label="Qualification">{typeDescription}</ListRow>
      <ListRow label="Date of qualification">{date ? formatDate(date) : null}</ListRow>
    </>
  );
}
