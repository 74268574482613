export enum CancellationReason {
  AUTO_CANCEL_NON_PAYMENT = 'AutoCancelNonPayment',
  CAN_NO_LONGER_AFFORD = 'CanNoLongerAfford',
  CANCEL_TO_REISSUE_ANEW_POLICY = 'CancelToReissueANewPolicy',
  CANNOT_MEET_THE_TRACKER_DEMANDS = 'CannotMeetTheTrackerDemands',
  COOLING_OFF_PERIOD = 'CoolingOffPeriod',
  COVER_NO_LONGER_SUITABLE = 'CoverNoLongerSuitable',
  DRIVER_DISQUALIFIED = 'DriverDisqualified',
  ENFORCED_INSURER_CANCELLATION_NO_PHINVOLVEMENT = 'EnforcedInsurerCancellationNoPHInvolvement',
  ENFORCED_INSURER_CANCELLATION_SUSPECTED_FRAUD = 'EnforcedInsurerCancellationSuspectedFraud',
  ENFORCED_INSURER_CANCELLATION_BEHAVIOUR = 'EnforcedInsurerCancellationBehaviour',
  ENFORCED_INSURER_CANCELLATION_FAILED_VALIDATION = 'EnforcedInsurerCancellationFailedValidation',
  INSURER_VOID = 'InsurerVoid',
  REASON_NOT_DISCLOSED = 'ReasonNotDisclosed',
  SYSTEM_CORRECTION = 'SystemCorrection',
  VEHICLE_HAS_BEEN_SOLD = 'VehicleHasBeenSold',
  VEHICLE_NOT_PURCHASED = 'VehicleNotPurchased',
  VEHICLE_TAKEN_OFF_THE_ROAD = 'VehicleTakenOffTheRoad',
}
