import { OptionCode } from '@/api/retail/v1';

// TODO remove after endpoint gets updated to accept only code string
export const transformToOptionCode = (
  code: string | null | undefined,
): OptionCode | undefined => {
  if (!code) return undefined;

  return {
    code,
    description: '',
  };
};

export const DUMMY_OPTION_CODE: OptionCode = {
  code: 'foo',
  description: 'bar',
};
