import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { AddressDetailsIAPIResult } from '@/api/retail/v2';

type useAddressDetailsQueryProps = {
  postKey: string;
};

export function useAddressDetailsQuery(
  { postKey }: useAddressDetailsQueryProps,
  options?: Omit<UseQueryOptions<AddressDetailsIAPIResult>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();
  const fetchAddressDetails = () =>
    api.retailV2.address.getAddressDetail({ postkey: postKey });

  return useQuery({
    queryKey: queryKeys.addressDetails(postKey),
    queryFn: fetchAddressDetails,
    ...options,
  });
}
