import { Button, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useBypassRefundForm } from '../hooks/useBypassRefundForm';
import { Modal } from '@/components/ui/modals/Modal';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { renderSelectOptionsByEnum } from '@/helpers/utils/formHelpers';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { BypassRefundReason } from '@/models/BypassRefundReason';
import { useQuoteCalculationCreateOrderActionContext } from '@/features/PaymentActions/QuoteCalculation/components/QuoteCalculationCreateOrderAction/hooks/useQuoteCalculationCreateOrderActionContext';

type BypassRefundModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  onSuccess: () => void;
};

export function BypassRefundModal({ onConfirm, onCancel }: BypassRefundModalProps) {
  const form = useBypassRefundForm();
  const { setBypassRefundReason } = useQuoteCalculationCreateOrderActionContext();
  const { watch, handleSubmit } = form;
  const reasonFieldValue = watch('reason');
  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.stopPropagation();

    handleSubmit(values => {
      setBypassRefundReason(values.reason);
      onConfirm();
    })(event);
  };

  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Continue without refund</Modal.Header>
      <FormProvider {...form}>
        <StyledDrawerForm onSubmit={onSubmit}>
          <Modal.Content gap={4}>
            <Typography variant="body2" mb={2}>
              Please select a reason below, for skipping refund.
            </Typography>
            <FormTextField
              name="reason"
              label="Reason for skipping refund"
              id="reason"
              data-testid="payments_refund-bypass-reason-select"
              select
            >
              {renderSelectOptionsByEnum(BypassRefundReason)}
            </FormTextField>
          </Modal.Content>
          <Modal.Actions>
            <Button
              variant="contained"
              color="secondary"
              data-testid="payments_refund-bypass-continue-button"
              disabled={!reasonFieldValue}
              type="submit"
            >
              Continue without refund
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              data-testid="payments_refund-bypass-cancel-button"
              onClick={onCancel}
            >
              Go back
            </Button>
          </Modal.Actions>
        </StyledDrawerForm>
      </FormProvider>
    </Modal>
  );
}
