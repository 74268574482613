import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Divider, Stack } from '@mui/material';
import { NewMtaFormSchema } from '../../hooks';
import { getDriverFullName } from '../../helpers/newMtaFormHelper';
import { NewMtaDriverAccordionHeader } from './components/NewMtaDriverAccordionHeader';
import { NewMtaDriverContextProvider } from './NewMtaDriverContext';
import { useDriversSelectOptions } from './hooks/useDriversSelectOptions';
import { NewMtaPolicyholderDriverForm } from './components/NewMtaPolicyholderDriverForm';
import { NewMtaAdditionalDriverForm } from './components/NewMtaAdditionalDriverForm';
import { NewAddedAdditionalDriverForm } from './components/NewAddedAdditionalDriverForm';
import { defaultValues } from './components/NewAddedAdditionalDriverForm/defaultValues';
import { AccordionList, AccordionListItem } from '@/components/ui/lists/AccordionList';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { renderSelectOptionsByOptionsCodes } from '@/helpers/utils/formHelpers';
import { OptionValue } from '@/api/retail/v2';
import { StyledFieldset } from '@/components/ui/styles/StyledFieldset';

export function NewMtaDrivers() {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext<NewMtaFormSchema>();
  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: 'drivers',
  });
  const { isError } = useDriversSelectOptions();
  const mainDriverIndex = watch('mainDriverIndex');

  const newAdditionalDriver = fields.find(({ _isComplete }) => !_isComplete);
  const shouldShowAddNewDriverButton = !newAdditionalDriver && fields.length < 5;
  const mainDriverSelectOptions: OptionValue[] = fields.map(
    ({ firstName, surname }, index) => ({
      code: index,
      description: getDriverFullName(firstName, surname),
    }),
  );

  if (isError) {
    return <DrawerError />;
  }

  const onRemoveDriverClick: (
    index: number,
  ) => React.MouseEventHandler<HTMLButtonElement> = index => event => {
    event.stopPropagation();
    remove(index);
  };

  const onAddDriver = () => {
    append(defaultValues);
  };

  const handleAddDriver = (index: number) => {
    const drivers = getValues('drivers');
    const existingDrivers = drivers.filter((_, fieldIndex) => fieldIndex !== index);
    const newDriver = drivers.find((_, fieldIndex) => fieldIndex === index);

    if (!newDriver) return;

    const newFields = [...existingDrivers, { ...newDriver, _isComplete: true }];

    replace(newFields);
  };

  const mtaDriversAccordionItems: AccordionListItem[] = fields
    ?.filter(({ _isComplete }) => _isComplete)
    .map(({ id, firstName, surname, _isPolicyholder, dateOfBirth }, index) => {
      const name = `${firstName} ${surname}`;
      const isMainDriver = mainDriverIndex === index;
      const testId = `drivers[${index}].fieldset`;

      return {
        key: id,
        defaultExpanded: !!errors.drivers?.[index],
        header: (
          <NewMtaDriverAccordionHeader
            id={id}
            name={name}
            isMainDriver={isMainDriver}
            isPolicyholder={_isPolicyholder}
            dateOfBirth={dateOfBirth!}
            isInvalid={Boolean(errors.drivers?.[index])}
            onRemove={onRemoveDriverClick(index)}
          />
        ),
        content: (
          <NewMtaDriverContextProvider index={index}>
            <StyledFieldset data-testid={testId}>
              {_isPolicyholder ? (
                <NewMtaPolicyholderDriverForm />
              ) : (
                <NewMtaAdditionalDriverForm />
              )}
            </StyledFieldset>
          </NewMtaDriverContextProvider>
        ),
      };
    });

  return (
    <>
      <AccordionList items={mtaDriversAccordionItems} />
      {newAdditionalDriver ? (
        <Stack sx={{ m: 6 }} direction="column">
          {fields.map(({ id, _isComplete }, index) => {
            if (_isComplete) return null;

            return (
              <NewMtaDriverContextProvider key={id} index={index}>
                <NewAddedAdditionalDriverForm
                  onAdd={() => handleAddDriver(index)}
                  onCancel={onRemoveDriverClick(index)}
                />
              </NewMtaDriverContextProvider>
            );
          })}
        </Stack>
      ) : null}
      {shouldShowAddNewDriverButton ? (
        <>
          <Button
            size="small"
            variant="contained"
            onClick={onAddDriver}
            sx={{ alignSelf: 'flex-start', m: 6 }}
          >
            Add new driver
          </Button>
          <Divider />
        </>
      ) : null}
      <Stack sx={{ m: 6 }} direction="column" gap={4}>
        <FormTextField
          select
          label="Who is the main driver?"
          name="mainDriverIndex"
          sx={{ maxWidth: '65%' }}
        >
          {renderSelectOptionsByOptionsCodes(mainDriverSelectOptions ?? [])}
        </FormTextField>
      </Stack>
    </>
  );
}
