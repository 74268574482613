import { RefObject, useCallback, useEffect, useState } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import {
  FieldValues,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useTheme } from '@mui/material';
import { useAdyenCheckoutContext } from './useAdyenCheckoutContext';

type AdyenCore = Awaited<ReturnType<typeof AdyenCheckout>>;

type AdyenSecuredFieldsElement = ReturnType<AdyenCore['create']>;

type AdyenFieldError = {
  errorI18n: string;
  errorMessage: string;
  isValid: boolean;
  error: string;
} | null;

type UseAdyenCheckoutProps<T extends FieldValues> = {
  containerRef: RefObject<HTMLElement>;
  setValue: UseFormSetValue<T>;
  setError: UseFormSetError<T>;
  clearErrors: UseFormClearErrors<T>;
};

function useAdyenStyles() {
  const theme = useTheme();

  return {
    base: {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.fontSize}px`,
      fontWeight: 400,
      letterSpacing: theme.typography.body2.letterSpacing,
    },
    error: {
      color: theme.palette.error.main,
    },
    placeholder: {
      color: theme.palette.text.primary,
    },
    validated: {
      color: theme.palette.text.primary,
    },
  };
}

export function useAdyenCheckout<T extends FieldValues>({
  containerRef,
  setValue,
  setError,
  clearErrors,
}: UseAdyenCheckoutProps<T>) {
  type OnChangeProps = {
    data: {
      paymentMethod: Record<Path<T>, PathValue<T, Path<T>>> & {
        type: string;
        brand: string;
        checkoutAttemptId: string;
      };
    };
    errors: Record<Path<T>, AdyenFieldError>;
  };

  const { setFocusedField } = useAdyenCheckoutContext();
  const styles = useAdyenStyles();
  const [hasConfigured, setHasConfigured] = useState(false);
  const [isError, setIsError] = useState(false);
  const [checkout, setCheckout] = useState<AdyenCore>();
  const getAdyenCheckout = useCallback(async () => {
    const data = await AdyenCheckout({
      locale: 'en_GB',
      environment: import.meta.env.VITE_APP_ADYEN_ENVIRONMENT,
      clientKey: import.meta.env.VITE_APP_ADYEN_CLIENT_KEY,
      translations: {
        'en-GB': {
          'creditCard.numberField.placeholder': 'Enter 16 digits',
        },
      },
    });

    setCheckout(data);
  }, [setCheckout]);
  const [customCard, setCustomCard] = useState<AdyenSecuredFieldsElement | null>(null);

  const onChange = useCallback(
    ({
      data: {
        paymentMethod: { checkoutAttemptId, type, brand, ...fields },
      },
      errors,
    }: OnChangeProps) => {
      Object.entries(errors).forEach(error => {
        const [key, value] = error as [Path<T>, AdyenFieldError];

        if (!value) {
          clearErrors(key);
          return;
        }

        setError(key, { message: value.errorI18n });
      });

      Object.entries(fields).forEach(([key, value]) =>
        setValue(key as Path<T>, value as PathValue<T, Path<T>>, {
          shouldDirty: true,
          shouldTouch: true,
        }),
      );
    },
    [],
  );

  const onFocus = useCallback(
    (e: { fieldType: string; focus: boolean }) => {
      if (!e.focus) {
        setFocusedField('');
        return;
      }

      setFocusedField(e.fieldType);
    },
    [setFocusedField],
  );

  const onConfigSuccess = () => {
    setHasConfigured(true);
  };

  useEffect(() => {
    if (checkout) return;

    getAdyenCheckout();
  }, [checkout, getAdyenCheckout]);

  useEffect(() => {
    if (!checkout) return;
    setCustomCard(
      checkout.create('securedfields', {
        type: 'card',
        brands: ['mc', 'visa'],
        onFocus,
        onChange,
        onConfigSuccess,
        styles,
      }),
    );
  }, [checkout]);

  useEffect(() => {
    if (!customCard || !containerRef.current) return;

    try {
      customCard.mount(containerRef.current);
    } catch {
      setIsError(true);
    }
  }, [customCard, setHasConfigured, containerRef.current]);

  return {
    data: customCard,
    isPending: !hasConfigured,
    isError,
  };
}
