import type { StringListIAPIResult } from '../models/StringListIAPIResult';
import type { VehicleFuelType } from '../models/VehicleFuelType';
import type { VehicleTransmissionType } from '../models/VehicleTransmissionType';
import type { VehicleType } from '../models/VehicleType';
import type { VehicleTypeModelsIAPIResult } from '../models/VehicleTypeModelsIAPIResult';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class VehicleTypeService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getVehicleTypeMake({
    vehicleType,
    registrationYear,
  }: {
    vehicleType: VehicleType;
    registrationYear?: number;
  }): CancelablePromise<StringListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle-type/{vehicleType}/make',
      path: {
        vehicleType: vehicleType,
      },
      query: {
        registrationYear: registrationYear,
      },
    });
  }

  getVehicleTypeMakeModel({
    vehicleType,
    make,
    fuelType,
    transmission,
    registrationYear,
  }: {
    vehicleType: VehicleType;
    make: string;
    fuelType?: VehicleFuelType;
    transmission?: VehicleTransmissionType;
    registrationYear?: number;
  }): CancelablePromise<VehicleTypeModelsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle-type/{vehicleType}/make/{make}/model',
      path: {
        vehicleType: vehicleType,
        make: make,
      },
      query: {
        fuelType: fuelType,
        transmission: transmission,
        registrationYear: registrationYear,
      },
    });
  }
}
