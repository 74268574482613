import { Divider, List, Typography } from '@mui/material';
import { Fragment } from 'react';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDateOnly } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { MotorConviction } from '@/api/motorpolicy';

type ConvictionContentProps = {
  convictions?: MotorConviction[];
};

export function ConvictionContent({ convictions }: ConvictionContentProps) {
  return (
    <>
      <Divider />
      <List>
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
          Convictions
        </Typography>
        <ListRow label="Driving convictions in last 5 years">
          {booleanAnswer(Boolean(convictions?.length))}
        </ListRow>
        {convictions?.map((conviction, index: number) => (
          <Fragment key={index}>
            <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
              Conviction #{index + 1}
            </Typography>
            <ListRow label="Conviction code">{conviction.type?.description}</ListRow>
            <ListRow label="Date of conviction">
              {conviction.date && formatDateOnly(conviction.date)}
            </ListRow>
            <ListRow label="Penalty points">{conviction.penaltyPoints}</ListRow>
            <ListRow label="Fine amount">
              {conviction.fineAmount?.amount &&
                formatCurrency(conviction.fineAmount.amount)}
            </ListRow>
            <ListRow label="Ban received">
              {booleanAnswer(Boolean(conviction.disqualifiedLengthInMonths))}
            </ListRow>
            <ListRow label="Ban length in months">
              {conviction.disqualifiedLengthInMonths}
            </ListRow>
          </Fragment>
        ))}
      </List>
    </>
  );
}
