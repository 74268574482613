import { useEffect, useRef } from 'react';

// This make sure useEffect is called once in StrictMode
export function useEffectOnce(callback: () => void) {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      const timeout = setTimeout(() => {
        initialized.current = true;
        callback();
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, []);
}
