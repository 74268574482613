import { Button, Typography } from '@mui/material';
import { DriverNestedFieldDisplay } from '../DriverNestedFieldDisplay';
import { Claim } from './components/Claim';
import { ClaimForm } from './components/ClaimForm';
import { SELF_DECLARED_CLAIM_CODE } from './hooks/useClaimForm';
import { useDriverClaimsFormFields } from './hooks/useDriverClaimsFormFields';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks';

export function DriverClaimsFormFields() {
  const { getFieldName } = useNewMtaDriverContext();
  const {
    formError,
    hasClaims,
    isAddingNewClaim,
    fields,
    remove,
    showForm,
    hideForm,
    handleAddClaim,
  } = useDriverClaimsFormFields();

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Claims
      </Typography>
      <FormRadioGroup
        label="Accident or claim in last 5 years"
        name={getFieldName('hasClaims')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          testId="new-mta_has-claims-yes-radio"
          label={YesNo.Yes}
          value={YesNo.Yes}
        />
        <FormRadio
          testId="new-mta_has-claims-no-radio"
          label={YesNo.No}
          value={YesNo.No}
        />
      </FormRadioGroup>
      {hasClaims &&
        (isAddingNewClaim ? (
          <>
            <ClaimForm onAddClaim={handleAddClaim} onCancel={hideForm} />
            {formError && (
              <Typography mt={2} color="error" variant="body2">
                {formError.message}
              </Typography>
            )}
          </>
        ) : (
          <>
            {fields.map(({ id, ...claim }, index) => (
              <DriverNestedFieldDisplay
                key={id}
                name={`Claim #${index + 1}`}
                isRemoveDisplayed={claim._source.code === SELF_DECLARED_CLAIM_CODE}
                onRemove={() => remove(index)}
              >
                <Claim {...claim} />
              </DriverNestedFieldDisplay>
            ))}
            <Button
              data-testid="new-mta_add-new-claim-button"
              sx={{ alignSelf: 'flex-start' }}
              variant="contained"
              size="small"
              onClick={() => showForm()}
            >
              Add another claim
            </Button>
          </>
        ))}
    </>
  );
}
