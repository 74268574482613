import { InsurancePriceFee, FeeType } from '@/api/motorpolicy';
import { Fee, FeeType as MotorQuoteFeeType } from '@/api/motorquote';
import { MotorQuote } from '@/api/motorquote';
import { MotorPolicy } from '@/api/motorpolicy';

export const selectFeeByType = <T extends InsurancePriceFee | Fee>(
  fees: T[],
  type: FeeType | MotorQuoteFeeType,
) => fees.find(fee => fee.type === type) ?? null;

export const selectFeeTotalByType = (
  fees: (InsurancePriceFee | Fee)[],
  type: FeeType | MotorQuoteFeeType,
) => fees.find(fee => fee.type === type)?.total?.amount ?? null;

export const selectFees = (data: MotorQuote) => data.insurancePrice?.fees ?? [];
export const selectPaymentPlans = (data: MotorQuote) => data.paymentPlans ?? [];
export const selectTotalPriceIncAddOns = (data: MotorQuote) =>
  data.totalPriceIncAddOns?.amount ?? null;
export const selectTotalPriceIncAddOnsAndLoanRedemption = (data: MotorQuote) =>
  data.totalPriceIncAddOnsAndLoanRedemption?.amount ?? null;
export const selectEndorsements = (data: MotorQuote) => data.endorsements ?? [];
export const selectTotalPrice = (data: MotorQuote) =>
  data.insurancePrice?.total?.amount ?? null;
export const selectNetPremium = (data: MotorQuote) =>
  data.insurancePrice?.netPremium?.amount ?? null;
export const selectInsurancePremiumTax = (data: MotorQuote) =>
  data.insurancePrice?.insurancePremiumTax?.amount ?? null;
export const selectSetupFee = (policy: MotorPolicy) =>
  policy.applicableFees?.administrationFee?.amount ?? null;
