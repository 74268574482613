import { AttachmentReceivingMethod, AttachmentType } from '@/api/note';

export function getAttachmentTypeName(attachmentType: AttachmentType): string {
  switch (attachmentType) {
    case AttachmentType.DEATH_CERTIFICATE:
      return 'Death certificate';
    case AttachmentType.MARRIAGE_CERTIFICATE:
      return 'Marriage certificate';
    case AttachmentType.NAME_CHANGE_BY_DEED_POLL:
      return 'Name change by deed poll';
    case AttachmentType.OTHER:
      return 'Other';
    case AttachmentType.PROOF_OF_ADDRESS:
      return 'Proof of address';
    case AttachmentType.PROOF_OF_LICENSE:
      return 'Proof of license';
    case AttachmentType.PROOF_OF_NCD:
      return 'Proof of ncd';
    case AttachmentType.PROOF_OF_POWER_OF_ATTORNEY:
      return 'Proof of power of attorney';
  }
}

export function getAttachmentReceivingMethodName(
  receivingMethod: AttachmentReceivingMethod,
): string {
  switch (receivingMethod) {
    case AttachmentReceivingMethod.EMAIL:
      return 'Email';
    case AttachmentReceivingMethod.POST:
      return 'Post';
  }
}
