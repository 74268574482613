import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { VehicleOptions } from '@/api/retail/v2';

type UseVehicleOptionsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useVehicleOptionsQuery(
  { productName = 'motor', productVersion = 'latest' }: UseVehicleOptionsQueryProps,
  options?: Omit<UseQueryOptions<VehicleOptions>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchVehicleOptions = () =>
    api.retailV2.product.getProductVehicleOptions({
      productName,
      productVersion,
    });

  return useQuery({
    queryKey: queryKeys.vehicleOptions(productName, productVersion),
    queryFn: fetchVehicleOptions,
    ...options,
  });
}
