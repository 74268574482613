import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { OptionCode } from '@/api/retail/v2';

type useMedicalConditionsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useMedicalConditionsQuery(
  { productName = 'motor', productVersion = 'latest' }: useMedicalConditionsQueryProps,
  options?: Omit<UseQueryOptions<OptionCode[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchMedicalConditions = () =>
    api.retailV2.product.getProductMedicalConditions({
      productName,
      productVersion,
    });

  return useQuery({
    queryKey: queryKeys.medicalConditions(productName, productVersion),
    queryFn: fetchMedicalConditions,
    ...options,
  });
}
