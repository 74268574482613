import { styled } from '@mui/material';
import { EverydayinsureLogo } from './EverydayinsureLogo';

export const topNavbarHeight = 64;

const StyledNavbar = styled('nav')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: `${topNavbarHeight}px`,
  background: '#000E33',
}));

export function EverydayinsureNavbar() {
  return (
    <StyledNavbar>
      <EverydayinsureLogo />
    </StyledNavbar>
  );
}
