import { useFormContext } from 'react-hook-form';
import { ExcessAmount, MotorQuote } from '@/api/motorquote';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';

const getUpdatedExcesses = (
  policyExcesses: ExcessAmount[],
  quoteExcesses: ExcessAmount[],
): ExcessAmount[] => {
  return quoteExcesses.filter(quoteExcess => {
    const policyExcess = policyExcesses.find(
      policyExcess => quoteExcess.type?.code === policyExcess.type?.code,
    );
    return !policyExcess || policyExcess.total !== quoteExcess.total;
  });
};

export function useExcesses(quote: MotorQuote) {
  const { watch } = useFormContext<NewMtaFormSchema>();
  const policy = watch('_policy');

  return {
    updatedExcesses: [
      ...getUpdatedExcesses(
        policy.excesses?.compulsoryAmounts ?? [],
        quote.excesses?.compulsoryAmounts ?? [],
      ),
      ...getUpdatedExcesses(
        policy.excesses?.voluntaryAmounts ?? [],
        quote.excesses?.voluntaryAmounts ?? [],
      ),
    ],
  };
}
