import { Box, Divider } from '@mui/material';
import { NewMtaCarDetails } from './components/NewMtaCarDetails';
import { NewMtaCarSpecs } from './components/NewMtaCarSpecs';
import { NewMtaCarMods } from './components/NewMtaCarMods';
import { NewMtaCarPurchaseDate } from './components/NewMtaCarPurchaseDate';
import { NewMtaCarUsage } from './components/NewMtaCarUsage';

export function NewMtaCar() {
  const divider = <Divider sx={{ my: 4 }} />;

  return (
    <Box m={6}>
      <NewMtaCarDetails />
      {divider}
      <NewMtaCarSpecs />
      {divider}
      <NewMtaCarMods />
      {divider}
      <NewMtaCarPurchaseDate />
      {divider}
      <NewMtaCarUsage />
    </Box>
  );
}
