import { Skeleton, Tab } from '@mui/material';
import { TabList } from '@mui/lab';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { sortArrayByBooleanKey } from '@/helpers/utils/arrayHelpers';
import { PolicyQueryParams } from '@/features/Policies/policyTypes';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function DriversTabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const parentId = useContext(PolicyIdContext);
  const { isPending, data: policy } = useMotorPolicyQuery(parentId);

  const handleChange = (_event: React.SyntheticEvent, newTab: string) => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams),
        [PolicyQueryParams.Tab]: newTab,
      }),
    );
  };

  if (isPending) {
    return (
      <TabList>
        <Tab label={<Skeleton />} />
      </TabList>
    );
  }

  const sortedDrivers = sortArrayByBooleanKey(policy?.drivers ?? [], 'isPolicyholder');

  return (
    <TabList onChange={handleChange}>
      {sortedDrivers?.map((driver, index) => (
        <Tab
          label={`${driver.firstName} ${driver.lastName}`}
          value={`driver-${index}-tab`}
          key={driver.id}
        />
      ))}
    </TabList>
  );
}
