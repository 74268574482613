import { useContext } from 'react';
import { DriverContext } from '../DriverContext';

export function useDriverContext() {
  const context = useContext(DriverContext);

  if (context === undefined) {
    throw new Error('useDriverContext must be used within a DriverContextProvider');
  }

  return context;
}
