import { Button, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

type DriverNestedFormFieldsProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  onAdd: (fieldValues: T) => void;
  onCancel: () => void;
  buttonLabel: string;
  confirmButtonTestId?: string;
  cancelButtonTestId?: string;
} & PropsWithChildren;

export function DriverNestedFormFields<T extends FieldValues>({
  form,
  onAdd,
  onCancel,
  buttonLabel,
  confirmButtonTestId,
  cancelButtonTestId,
  children,
}: DriverNestedFormFieldsProps<T>) {
  const { getValues, trigger } = form;
  const handleAdd = async () => {
    const isValid = await trigger();

    if (isValid) {
      onAdd(getValues());
    }
  };

  return (
    <FormProvider {...form}>
      {children}
      <Stack sx={{ gap: 4, alignItems: 'flex-start' }}>
        <Button
          data-testid={confirmButtonTestId}
          variant="contained"
          size="small"
          onClick={handleAdd}
        >
          {buttonLabel}
        </Button>
        <Button
          data-testid={cancelButtonTestId}
          variant="text"
          size="small"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}
