import { NotesService } from './services/NotesService';
import { BaseAPI } from '../core/BaseAPI';

export class NoteAPI extends BaseAPI {
  readonly notes: NotesService;

  constructor() {
    super({ BASE: import.meta.env.VITE_APP_NOTE_API_URL });

    this.notes = new NotesService(this.request);
  }
}
