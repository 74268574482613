import { Alert, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { Modal } from '@/components/ui/modals/Modal';

type MarkAsCompleteModalProps = {
  isError: boolean;
  isPending: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function MarkAsCompleteModal({
  isError,
  isPending,
  onConfirm,
  onCancel,
}: MarkAsCompleteModalProps) {
  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Mark as complete</Modal.Header>
      <Modal.Content>
        {isError && <DrawerError />}
        <Alert data-testid="reminders_mark_as_complete_alert" severity="warning">
          You are about to mark this reminder as complete
        </Alert>
        <Typography variant="body2">
          Please confirm all tasks for this reminder have been completed.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          data-testid="reminders_confirm-mark-as-complete-button"
          variant="contained"
          color="secondary"
          loading={isPending}
          onClick={onConfirm}
        >
          Confirm and mark as complete
        </LoadingButton>
        <Button
          data-testid="reminders_cancel-mark-as-complete-button"
          variant="outlined"
          color="inherit"
          onClick={onCancel}
        >
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
