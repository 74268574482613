import { useContext } from 'react';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useVehicleOwnerQuery } from '@/hooks/queries/useVehicleOwnerQuery';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { getProductInfo } from '@/helpers/data/retailHelpers';

export function useVehicleOwner() {
  const parentId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(parentId);
  const query = useVehicleOwnerQuery(getProductInfo(policy), {
    enabled: Boolean(policy),
  });
  return query;
}
