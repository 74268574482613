import type { ConfirmEmailRequest } from '../models/ConfirmEmailRequest';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { CreateUserResponse } from '../models/CreateUserResponse';
import type { GetIndividualsResponse } from '../models/GetIndividualsResponse';
import type { GetRolesResponse } from '../models/GetRolesResponse';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class UsersService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getUsers(): CancelablePromise<GetIndividualsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users',
    });
  }

  getAdminUsers(): CancelablePromise<GetIndividualsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/users',
    });
  }

  postAdminUsers({
    requestBody,
  }: {
    requestBody?: CreateUserRequest;
  }): CancelablePromise<CreateUserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/admin/users',
      body: requestBody,
    });
  }

  putAdminUsers({
    requestBody,
  }: {
    requestBody?: UpdateUserRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/admin/users',
      body: requestBody,
    });
  }

  getAdminUsersRoles(): CancelablePromise<GetRolesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/users/roles',
    });
  }

  postUsersConfirmEmail({
    requestBody,
  }: {
    requestBody?: ConfirmEmailRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/confirm-email',
      body: requestBody,
    });
  }

  postUsersSendPasswordReset({
    email,
    requestBody,
  }: {
    email: string;
    requestBody?: SendPasswordResetRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/{email}/send-password-reset',
      path: {
        email: email,
      },
      body: requestBody,
    });
  }

  postUsersConfirmPasswordReset({
    personId,
    requestBody,
  }: {
    personId: string;
    requestBody?: ConfirmPasswordResetRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/{personId}/confirm-password-reset',
      path: {
        personId: personId,
      },
      body: requestBody,
    });
  }

  postUsersSendEmailVerification({
    email,
    requestBody,
  }: {
    email: string;
    requestBody?: SendVerificationEmailRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/{email}/send-email-verification',
      path: {
        email: email,
      },
      body: requestBody,
    });
  }
}
