import { List, Typography } from '@mui/material';
import { HistoryClaimContent } from '../HistoryClaimContent';
import { HistoryConvictionContent } from '../HistoryConvictionContent';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { booleanAnswer, genderByTitle } from '@/helpers/utils/textHelpers';
import { sortArrayByBooleanKey } from '@/helpers/utils/arrayHelpers';
import { Driver } from '@/api/motorpolicy';
import { Policyholder } from '@/api/motorpolicy';
import { MotorClaim } from '@/api/motorpolicy';
import { MotorConviction } from '@/api/motorpolicy';
import { getIsStudent } from '@/helpers/data/driverHelpers';

type HistoryDriverContentProps = {
  driver: Driver;
  policyholder: Policyholder;
};

export function HistoryDriverContent({
  driver,
  policyholder,
}: HistoryDriverContentProps) {
  const sortedOccupations = sortArrayByBooleanKey(driver.occupations, 'isPrimary');
  const formattedAddress = policyholder.address?.formattedAddress;
  const isStudent = getIsStudent(driver);

  return (
    <>
      <List>
        {!driver.isPolicyholder && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
              Additional driver’s details
            </Typography>
            <ListRow disablePadding label="Relation to policyholder">
              {driver.relationshipToPolicyholder?.description}
            </ListRow>
          </>
        )}
        {driver.isPolicyholder && (
          <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
            Policyholder’s details
          </Typography>
        )}
        <ListRow disablePadding label="Title">
          {driver.title?.description}
        </ListRow>
        <ListRow disablePadding label="First name">
          {driver.firstName}
        </ListRow>
        <ListRow disablePadding label="Last name">
          {driver.lastName}
        </ListRow>
        <ListRow disablePadding label="Gender">
          {genderByTitle(driver.title?.description)}
        </ListRow>
        <ListRow disablePadding label="Date of birth">
          {formatDate(driver.dateOfBirth)}
        </ListRow>
        <ListRow disablePadding label="Marital status">
          {driver.maritalStatus?.description}
        </ListRow>
        <ListRow disablePadding label="UK resident since birth">
          {booleanAnswer(driver.ukResidencyDetails?.isUkResidentSinceBirth)}
        </ListRow>
        {driver.ukResidencyDetails?.ukResidentSince && (
          <ListRow disablePadding label="UK resident since">
            {driver.ukResidencyDetails?.ukResidentSince}
          </ListRow>
        )}
        <ListRow disablePadding label="Employment status" sx={{ mt: 5 }}>
          {sortedOccupations[0].employmentStatus?.description}
        </ListRow>
        {isStudent ? (
          <ListRow disablePadding label="Student type">
            {sortedOccupations[0].type?.description}
          </ListRow>
        ) : (
          <>
            <ListRow disablePadding label="Job title">
              {sortedOccupations[0].type?.description}
            </ListRow>
            <ListRow disablePadding label="Job industry">
              {sortedOccupations[0].industry?.description}
            </ListRow>
          </>
        )}
        <ListRow disablePadding label="Any other jobs">
          {booleanAnswer(sortedOccupations.length > 1)}
        </ListRow>
        <ListRow disablePadding label="Secondary job title">
          {sortedOccupations[1]?.type?.description}
        </ListRow>
        <ListRow disablePadding label="Secondary job industry">
          {sortedOccupations[1]?.industry?.description}
        </ListRow>
        {driver.isPolicyholder && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
              Policyholder’s home
            </Typography>
            <ListRow disablePadding label="Address">
              {formattedAddress?.line1 && <div>{formattedAddress.line1}</div>}
              {formattedAddress?.line2 && <div>{formattedAddress.line2}</div>}
              {formattedAddress?.line3 && <div>{formattedAddress.line3}</div>}
              {formattedAddress?.line4 && <div>{formattedAddress.line4}</div>}
            </ListRow>
            <ListRow disablePadding label="Postcode">
              {policyholder.address?.formattedAddress?.postCode}
            </ListRow>
            <ListRow disablePadding label="Homeowner">
              {booleanAnswer(policyholder.isHomeOwner)}
            </ListRow>
            <ListRow disablePadding label="Vehicles at home">
              {policyholder.numberOfVehiclesInHousehold}
            </ListRow>
          </>
        )}
        <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
          {driver.isPolicyholder
            ? 'Policyholder’s licence and history'
            : 'Additional driver’s licence and history'}
        </Typography>
        <ListRow disablePadding label="Driving licence number">
          {driver.drivingLicence?.number}
        </ListRow>
        <ListRow disablePadding label="Driving licence type">
          {driver.drivingLicence?.type?.description}
        </ListRow>
        <ListRow disablePadding label="Years held licence">
          {driver.drivingLicence?.yearsHeld}
        </ListRow>
        <ListRow disablePadding label="Months held licence">
          {driver.drivingLicence?.monthsHeld}
        </ListRow>
        <ListRow disablePadding label="Held insurance last 2 years">
          {booleanAnswer(driver.heldInsuranceLast2Years)}
        </ListRow>
        <ListRow disablePadding label="Years of NCB">
          {driver.noClaimsHistory?.yearsNoClaimsBonus}
        </ListRow>
        <ListRow disablePadding label="Years of named driver NCB">
          {driver.noClaimsHistory?.yearsNamedDriverNoClaimsExperience}
        </ListRow>
        <ListRow disablePadding label="NCB method">
          {driver.noClaimsHistory?.discountMethod?.description}
        </ListRow>
        <ListRow disablePadding label="Other vehicle use">
          {driver.otherVehicleUse?.description}
        </ListRow>
        <ListRow disablePadding label="Advanced driving qualifications">
          {driver.advancedDrivingQualifications?.map((qual, key) => (
            <div key={key}>{qual.type?.description}</div>
          ))}
        </ListRow>
        <ListRow disablePadding label="Medical conditions">
          {driver.medicalCondition?.description}
        </ListRow>
        {driver?.previousMotorClaims?.map((claim: MotorClaim, index) => (
          <HistoryClaimContent
            key={`claim-#${index}`}
            claim={claim}
            claimNumber={index + 1}
          />
        ))}
        {driver?.motorConvictions?.map((conviction: MotorConviction, index) => (
          <HistoryConvictionContent
            key={`conviction-#${index}`}
            conviction={conviction}
            convictionNumber={index + 1}
          />
        ))}
        <ListRow label="Non-motoring convictions" sx={{ mt: 5 }}>
          {booleanAnswer(driver.hasNonMotoringConvictions)}
        </ListRow>
        <ListRow disablePadding label="Main driver">
          {booleanAnswer(driver.isMainDriver)}
        </ListRow>
      </List>
    </>
  );
}
