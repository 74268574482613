import type { Account } from '../models/Account';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
import { ContactUsRequest } from '../models/ContactUsRequest';
export class CustomersService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postCustomersAccountUpdate({
    policyId,
    requestBody,
  }: {
    policyId: string;
    requestBody?: Account;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/customers/{policyId}/account-update',
      path: {
        policyId: policyId,
      },
      body: requestBody,
    });
  }

  postContactUs(requestBody: ContactUsRequest): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/customers/contact-us',
      body: requestBody,
    });
  }
}
