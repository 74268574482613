import { Divider, List, styled } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { Vehicle } from '@/api/motorpolicy';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDate } from '@/helpers/utils/dateHelpers';

type CarDetailsProps = {
  vehicle: Vehicle;
};

const StyledUl = styled('ul')(({ theme }) => ({
  listStyleType: 'disc',
  paddingLeft: theme.spacing(4),
}));

export function CarDetailsContent({ vehicle }: CarDetailsProps) {
  return (
    <>
      <Divider sx={{ mt: -1 }} />
      <List>
        <ListRow label="Registration">{vehicle.registrationNumber}</ListRow>
        <ListRow label="Vehicle">{`${vehicle.make} ${vehicle.model}`}</ListRow>
        <ListRow label="Vehicle type">{vehicle.type.description}</ListRow>
        <ListRow label="Year of manufacture">{vehicle.manufactureYear}</ListRow>
        <ListRow label="Manufacture year start">{vehicle.manufactureStartYear}</ListRow>
        <ListRow label="Manufacture year end">{vehicle.manufactureEndYear}</ListRow>
        <ListRow label="Primary fuel type">
          {vehicle.primaryFuelType?.description}
        </ListRow>
        <ListRow label="Secondary fuel type">
          {vehicle.secondaryFuelType?.description}
        </ListRow>
        <ListRow label="Power output">{vehicle.cubicCapicityInMillilitres}</ListRow>
        <ListRow label="Weight in kg">{vehicle.weightInKg}</ListRow>
        <ListRow label="ABI code">{vehicle.abiCode}</ListRow>
        <ListRow label="VIN">{vehicle.vin}</ListRow>
        <ListRow label="Transmission">{vehicle.transmission?.description}</ListRow>
        <ListRow label="Number of doors">{vehicle.numberOfDoors}</ListRow>
        <ListRow label="Steering wheel side">
          {vehicle.steeringWheelSide?.description}
        </ListRow>
        <ListRow label="Number of seats">{vehicle.numberOfSeats}</ListRow>
        <ListRow label="Tracking device installed">
          {booleanAnswer(Boolean(vehicle.trackingDevice))}
        </ListRow>
        <ListRow label="Tracking device type">
          {vehicle.trackingDevice?.description ?? '-'}
        </ListRow>
        <ListRow label="Alarm / immobiliser">
          {vehicle.alarmImmobiliser?.type?.description}
        </ListRow>
        <ListRow label="Imported vehicle">{booleanAnswer(vehicle.isImported)}</ListRow>
        <ListRow label="Import type">{vehicle.importType}</ListRow>
      </List>
      <Divider />
      <List>
        <ListRow label="Estimated value">
          {vehicle.selfDeclaredVehicleValue?.amount}
        </ListRow>
      </List>
      {!!vehicle.modifications?.length && (
        <>
          <Divider />
          <List>
            <ListRow label="Modifications">
              <StyledUl>
                {vehicle.modifications.map((mod, key) => (
                  <li key={key}>{mod.description}</li>
                ))}
              </StyledUl>
            </ListRow>
          </List>
        </>
      )}
      <Divider />
      <List>
        <ListRow label="Bought yet">
          {booleanAnswer(vehicle.ownership?.isPurchased)}
        </ListRow>
        <ListRow label="Purchase date">
          {vehicle.ownership?.selfDeclaredDateOfPurchase &&
            formatDate(vehicle.ownership.selfDeclaredDateOfPurchase)}
        </ListRow>
      </List>
    </>
  );
}
