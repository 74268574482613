import { TransactionStatus } from '@/api/support';
import { PolicyTransactionType } from '@/api/motorpolicy';

export function getTransactionStatusName(transactionStatus: TransactionStatus): string {
  switch (transactionStatus) {
    case TransactionStatus.FAILED:
      return 'Failed';
    case TransactionStatus.PARTIALLY_SUCCEEDED:
      return 'Partially succeeded';
    case TransactionStatus.PENDING:
      return 'Pending';
    case TransactionStatus.SCHEDULED:
      return 'Scheduled';
    case TransactionStatus.SUCCEEDED:
      return 'Succeeded';
    case TransactionStatus.AUTHORISED:
      return 'Authorised';
  }
}

export function getTransactionTypesNames(
  policyTransaction: PolicyTransactionType,
): string {
  switch (policyTransaction) {
    case PolicyTransactionType.CANCELLATION:
      return 'Cancellation';
    case PolicyTransactionType.LOCK_CHANGE:
      return 'Lock change';
    case PolicyTransactionType.MID_TERM_ADJUSTMENT:
      return 'Mid term adjustment';
    case PolicyTransactionType.NEW_BUSINESS:
      return 'New business';
    case PolicyTransactionType.POLICYHOLDER_ACCOUNT_UPDATE:
      return 'Policyholder account update';
    case PolicyTransactionType.RENEWAL:
      return 'Renewal';
    case PolicyTransactionType.VOIDANCE:
      return 'Voidance';
    case PolicyTransactionType.DEBT:
      return 'Debt';
    case PolicyTransactionType.REVERSAL:
      return 'Reversal';
  }
}
