import { Actions } from './Actions';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function ActionsDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Actions" />
      <Actions />
    </DrawerContent>
  );
}
