export enum ClaimStatus {
  CANCELLED = 'Cancelled',
  DUPLICATE = 'Duplicate',
  REJECTED = 'Rejected',
  OPEN = 'Open',
  PENDING = 'Pending',
  REOPENED = 'Reopened',
  SETTLED = 'Settled',
  SETTLED_WITH_EX_GRATIA = 'SettledWithExGratia',
  DENIED = 'Denied',
}
