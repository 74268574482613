import { Alert, Button, Divider, List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { MotorPolicy } from '@/api/motorpolicy';
import { Modal } from '@/components/ui/modals/Modal';
import { useCreateOrderPromptContext } from '@/features/PaymentActions/QuoteCalculation';

type CancelPolicyConfirmationModalProps = {
  policy: MotorPolicy;
  effectiveDateTime: Date;
};

export function CancelPolicyConfirmationModal({
  policy: {
    id,
    vehicle: { registrationNumber, model, make },
    policyholder,
    cover: { startDateTimeUtc, endDateTimeUtc },
  },
  effectiveDateTime,
}: CancelPolicyConfirmationModalProps) {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();

  return (
    <Modal onClose={cancelPrompt}>
      <Modal.Header>Confirm cancellation</Modal.Header>
      <Modal.Content>
        <Alert severity="error">Check you are cancelling the correct policy</Alert>
        <Typography variant="body2">
          Please check the details below and confirm with the customer that this policy is
          about the be cancelled. By continuing, all cover will cease{' '}
          <b>{formatDateTime(effectiveDateTime!)}</b> and that the Motor Insurance
          Database will be amended accordingly.
        </Typography>
        <Divider />
        <List>
          <ListRow disablePadding label="Policy ID">
            {id}
          </ListRow>
          <ListRow disablePadding label="Registration">
            {registrationNumber}
          </ListRow>
          <ListRow disablePadding label="Details">
            {`${make} ${model}`}
          </ListRow>
          <ListRow
            disablePadding
            label="Policyholder"
          >{`${policyholder.firstName} ${policyholder.lastName}`}</ListRow>
          <ListRow disablePadding label="Start">
            {formatDateTime(startDateTimeUtc)}
          </ListRow>
          <ListRow disablePadding label="End">
            {formatDateTime(endDateTimeUtc)}
          </ListRow>
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" color="secondary" onClick={confirmPrompt}>
          Confirm and continue
        </Button>
        <Button variant="outlined" color="inherit" onClick={cancelPrompt}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
