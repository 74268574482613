import { useLayoutEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  ClaimFormSchema,
  NewMtaFormSchema,
  useNewMtaDriverContext,
} from '@/features/NewMta/hooks';
import { YesNo } from '@/models/YesNo';

export function useDriverClaimsFormFields() {
  const {
    formState: { errors },
    control,
    setValue,
    trigger,
    watch,
  } = useFormContext<NewMtaFormSchema>();
  const { nestIndex, getFieldName } = useNewMtaDriverContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: getFieldName('claims'),
  });
  const formError = errors.drivers?.[nestIndex]?._isAddingNewClaim;
  const isAddingNewClaim = watch(getFieldName('_isAddingNewClaim'));
  const hasClaims = watch(getFieldName('hasClaims')) === YesNo.Yes;

  const showForm = () => {
    setValue(getFieldName('_isAddingNewClaim'), true);
  };

  const hideForm = () => {
    setValue(getFieldName('_isAddingNewClaim'), false);
    trigger(getFieldName('_isAddingNewClaim'));
  };

  const handleAddClaim = (formValues: ClaimFormSchema) => {
    append(formValues);
    hideForm();
  };

  useLayoutEffect(() => {
    if (hasClaims && fields.length === 0) {
      showForm();
    }

    if (!hasClaims) {
      hideForm();
    }
  }, [hasClaims]);

  useLayoutEffect(() => {
    if (hasClaims && fields.length === 0 && !isAddingNewClaim) {
      setValue(getFieldName('hasClaims'), YesNo.No);
    }
  }, [fields, isAddingNewClaim]);

  return {
    formError,
    hasClaims,
    isAddingNewClaim,
    fields,
    remove,
    showForm,
    hideForm,
    handleAddClaim,
  };
}
