import { useFormContext } from 'react-hook-form';
import { DriverQualificationsFields } from '../DriverQualificationsFields';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks/useNewMtaDriverContext';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  hasAnsweredYes,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';

export function DriverLicenceAndHistoryFormFields() {
  const { watch } = useFormContext<NewMtaFormSchema>();
  const {
    getFieldName,
    driverOptions,
    licenceDurations,
    otherVehicleUsages,
    medicalConditions,
  } = useNewMtaDriverContext();
  const isOtherVehicleUsage = hasAnsweredYes(watch(getFieldName('ownOrDriveAnotherCar')));
  const isDrivingLicenceProvided = hasAnsweredYes(
    watch(getFieldName('drivingLicenceProvided')),
  );
  const hasMedicalConditions = hasAnsweredYes(watch(getFieldName('medicalConditions')));
  const isLicenceDurationLessThanYear = watch(getFieldName('yearsHeldLicence')) === 0;

  return (
    <>
      <FormRadioGroup
        label="Driving licence number provided"
        name={getFieldName('drivingLicenceProvided')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      {isDrivingLicenceProvided ? (
        <FormTextField
          label="Driving licence number"
          name={getFieldName('drivingLicenceNumber')}
          sx={{ ...commonSx, ...maxWidthSx }}
        />
      ) : null}
      <FormTextField
        label="Driving licence type"
        name={getFieldName('drivingLicenceType')}
        select
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(driverOptions?.licenceTypes ?? [])}
      </FormTextField>
      <FormTextField
        label="Years held licence"
        name={getFieldName('yearsHeldLicence')}
        select
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(licenceDurations?.yearsHeldLicence ?? [])}
      </FormTextField>
      {isLicenceDurationLessThanYear ? (
        <FormNumberField
          label="Number of months"
          name={getFieldName('monthsHeldLicence')}
          integerOnly
          sx={{ ...commonSx, ...maxWidthSx }}
        />
      ) : null}
      <FormRadioGroup
        label="Own or drive another car"
        name={getFieldName('ownOrDriveAnotherCar')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      {isOtherVehicleUsage ? (
        <FormTextField
          label="Other car use"
          name={getFieldName('otherVehicleUsage')}
          sx={{ ...commonSx, ...maxWidthSx }}
          select
        >
          {renderSelectOptionsByOptionsCodes(otherVehicleUsages ?? [])}
        </FormTextField>
      ) : null}
      <DriverQualificationsFields />
      <FormRadioGroup
        label="Medical conditions"
        name={getFieldName('medicalConditions')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      {hasMedicalConditions ? (
        <FormTextField
          label="Condition"
          name={getFieldName('medicalCondition')}
          sx={{ ...commonSx, ...maxWidthSx }}
          select
        >
          {renderSelectOptionsByOptionsCodes(medicalConditions ?? [])}
        </FormTextField>
      ) : null}
    </>
  );
}
