import { Box, Stack, Typography, styled } from '@mui/material';

export const StyledModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  overflowY: 'auto',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
}));

export const StyledModalHeader = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.modal,
  background: theme.palette.background.paper,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
}));
