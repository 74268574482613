import { Divider, List } from '@mui/material';
import { ClaimContent } from '../ClaimContent';
import { ConvictionContent } from '../ConvictionContent';
import { useDriverContext } from '../../hooks/useDriverContext';
import { AdvanceDrivingContent } from '../AdvanceDrivingContent';
import { MedicalConditionsContent } from '../MedicalConditionsContent';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';

export function LicenceAndHistoryContent() {
  const driver = useDriverContext();
  const yearsHeldLicence =
    (driver.drivingLicence?.yearsHeld ?? 0) >= 20
      ? '20+'
      : driver.drivingLicence?.yearsHeld;

  return (
    <>
      <Divider sx={{ mt: -1 }} />
      <List>
        <ListRow label="Driving licence type">
          {driver.drivingLicence?.type?.description}
        </ListRow>
        <ListRow label="Driving licence number">{driver.drivingLicence?.number}</ListRow>
        <ListRow label="Years held licence">{yearsHeldLicence}</ListRow>
        <ListRow label="Months held licence">{driver.drivingLicence?.monthsHeld}</ListRow>
        <ListRow label="Years of NCB">
          {driver.noClaimsHistory?.yearsNoClaimsBonus}
        </ListRow>
        <ListRow label="Other vehicle use">{driver.otherVehicleUse?.description}</ListRow>
      </List>
      <AdvanceDrivingContent
        advancedDrivingQualifications={driver.advancedDrivingQualifications}
      />
      <MedicalConditionsContent medicalCondition={driver.medicalCondition} />
      <ClaimContent claims={driver.previousMotorClaims} />
      <ConvictionContent convictions={driver.motorConvictions} />
      <Divider />
      <List>
        <ListRow label="Non-motoring convictions">
          {booleanAnswer(driver.hasNonMotoringConvictions)}
        </ListRow>
      </List>
    </>
  );
}
