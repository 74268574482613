import { Typography } from '@mui/material';
import { useState } from 'react';
import { PolicyholderHomeAddressView } from './PolicyholderHomeAddressView';
import { PolicyholderHomeAddressSearch } from './PolicyholderHomeAddressSearch';
import { PolicyholderHomeAddressForm } from './PolicyholderHomeAddressForm';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks/useNewMtaDriverContext';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';

type AddressComponent = 'view' | 'search' | 'form';

export function PolicyholderHome() {
  const { getFieldName } = useNewMtaDriverContext();
  const [addressComponentType, setAddressComponentType] =
    useState<AddressComponent>('view');

  const addressComponent = (
    {
      view: (
        <PolicyholderHomeAddressView onEdit={() => setAddressComponentType('search')} />
      ),
      search: (
        <PolicyholderHomeAddressSearch
          onEnterAddressManually={() => setAddressComponentType('form')}
          onSelect={() => setAddressComponentType('view')}
        />
      ),
      form: (
        <PolicyholderHomeAddressForm
          onConfirm={() => setAddressComponentType('view')}
          onCancel={() => setAddressComponentType('search')}
        />
      ),
    } as Record<AddressComponent, JSX.Element>
  )[addressComponentType];

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Policyholder&apos;s home
      </Typography>
      {addressComponent}
      <FormRadioGroup
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Owns own home"
        name={getFieldName('isHomeOwner')}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      <FormNumberField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Number of cars kept at home"
        name={getFieldName('numberOfVehiclesInHousehold')}
        integerOnly
      />
      <FormNumberField
        sx={{ ...commonSx, ...maxWidthSx }}
        label="Number of children under 16 living at home"
        name={getFieldName('childrenUnder16InHousehold')}
        integerOnly
      />
    </>
  );
}
