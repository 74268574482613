import { Autocomplete, SxProps, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/utils/formHelpers';
import { OptionCode } from '@/api/retail/v2';

type FormComboBoxOption = OptionCode;

type FormComboBoxProps = {
  name: string;
  label: string;
  options: FormComboBoxOption[];
  sx: SxProps;
  testId?: string;
};

export function FormComboBox({ name, label, options, sx, testId }: FormComboBoxProps) {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const { onChange } = field;

  return (
    <Autocomplete<FormComboBoxOption>
      {...field}
      {...overrideReactHookFormRef}
      sx={{ display: 'inline-flex', ...sx }}
      options={options}
      onChange={(_e, data) => {
        onChange(data);
      }}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }

        return option.description!;
      }}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, 'data-testid': testId }}
          label={label}
          {...getErrorProps(error)}
        />
      )}
    />
  );
}
