import { styled, Box, Typography, List, Divider, Button } from '@mui/material';
import { PropsWithChildren } from 'react';

export type DriverNestedFieldDisplayProps = {
  name: string;
  isRemoveDisplayed?: boolean;
  onRemove: () => void;
} & PropsWithChildren;

const StyledDriverNestedFieldDisplay = styled(Box)`
  width: 65%;

  li {
    padding: 0;
  }
`;

export function DriverNestedFieldDisplay({
  name,
  isRemoveDisplayed = true,
  onRemove,
  children,
}: DriverNestedFieldDisplayProps) {
  return (
    <Box sx={{ mb: 4 }}>
      <Divider sx={{ mb: 2 }} />
      <StyledDriverNestedFieldDisplay>
        <Typography variant="subtitle2">{name}</Typography>
        <List aria-label={name}>{children}</List>
      </StyledDriverNestedFieldDisplay>
      {isRemoveDisplayed && (
        <Button
          size="small"
          onClick={onRemove}
          aria-label={`Remove ${name.toLowerCase()}`}
          sx={{ mt: 2 }}
        >
          Remove
        </Button>
      )}
    </Box>
  );
}
