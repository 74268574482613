import { PropsWithChildren } from 'react';
import { SxProps } from '@mui/material';
import { QuoteCalculationAlert } from './components/QuoteCalculationAlert';
import { QuoteCalculationCostSummary } from './components/QuoteCalculationCostSummary';
import { QuoteCalculationPriceBreakdown } from './components/QuoteCalculationPriceBreakdown';
import { QuoteCalculationPaymentPlans } from './components/QuoteCalculationPaymentPlans';
import { QuoteCalculationActions } from './components/QuoteCalculationActions';
import { QuoteCalculationUpdateFeesPremiumAction } from './components/QuoteCalculationUpdateFeesPremiumAction';
import { QuoteCalculationLoading } from './components/QuoteCalculationLoading';
import { QuoteCalculationError } from './components/QuoteCalculationError';
import { QuoteCalculationPaymentPlansFormProvider } from './components/QuoteCalculationPaymentPlansFormProvider';
import { QuoteCalculationContainer } from './components/QuoteCalculationContainer';
import { QuoteCalculationCreateOrderAction } from './components/QuoteCalculationCreateOrderAction';
import { QuoteCalculationProvider } from './components/QuoteCalculationProvider';
import { PaymentPlan, MotorQuote } from '@/api/motorquote';
import { FeeType } from '@/api/motorquote';

type QuoteCalculationProps = PropsWithChildren<{
  quote: MotorQuote;
  feeType: FeeType;
  customizeQuote?: {
    paymentPlans?: PaymentPlan[];
  };
  total: number;
  sx?: SxProps;
}>;

export function QuoteCalculation({
  sx,
  children,
  ...providerProps
}: QuoteCalculationProps) {
  return (
    <QuoteCalculationProvider {...providerProps}>
      <QuoteCalculationPaymentPlansFormProvider>
        <QuoteCalculationContainer sx={sx}>{children}</QuoteCalculationContainer>
      </QuoteCalculationPaymentPlansFormProvider>
    </QuoteCalculationProvider>
  );
}

QuoteCalculation.Loading = QuoteCalculationLoading;
QuoteCalculation.Error = QuoteCalculationError;
QuoteCalculation.Alert = QuoteCalculationAlert;
QuoteCalculation.CostSummary = QuoteCalculationCostSummary;
QuoteCalculation.PriceBreakdown = QuoteCalculationPriceBreakdown;
QuoteCalculation.PaymentPlans = QuoteCalculationPaymentPlans;
QuoteCalculation.Actions = QuoteCalculationActions;
QuoteCalculation.CreateOrderAction = QuoteCalculationCreateOrderAction;
QuoteCalculation.UpdateFeesPremiumAction = QuoteCalculationUpdateFeesPremiumAction;
