import { useState } from 'react';
import { StyledReminderDetailContent } from '../IndividualRemindersContent/IndividualRemindersContent.styles';
import { ReminderDetail } from '@/api/note';
import { useChangeReminderDateMutation } from '@/hooks/mutations/useChangeReminderDateMutation';
import { ReminderDetailHeader } from '@/features/Reminders/ReminderDetailHeader';
import { ChangeDateModal } from '@/features/Reminders/ChangeDateModal';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';

type IndividualRemindersAccordionHeaderProps = {
  reminder: ReminderDetail;
};

export function IndividualRemindersAccordionHeader({
  reminder,
}: IndividualRemindersAccordionHeaderProps) {
  const { setAlert } = useSnackbarAlert({ closeOnUnmount: false });
  const [isChangeDateModalOpen, setChangeDateModalOpen] = useState(false);
  const closeChangeDateModal = () => setChangeDateModalOpen(false);
  const openChangeDateModal = () => setChangeDateModalOpen(true);

  const handleChangeDateSuccess = () => {
    setAlert({ message: 'Reminder date has been changed', severity: 'success' });
    closeChangeDateModal();
  };

  const {
    mutate: changeDateMutate,
    isPending: isChangeDatePending,
    isError: isChangeDateError,
  } = useChangeReminderDateMutation({ onSuccess: handleChangeDateSuccess });

  const handleChangeDate = (data: { dueDate: Date }) => {
    changeDateMutate({ dueDate: data.dueDate, reminderId: reminder.reminderId });
  };

  return (
    <>
      <StyledReminderDetailContent paddingRight={3}>
        <ReminderDetailHeader reminder={reminder} onChangeDate={openChangeDateModal} />
      </StyledReminderDetailContent>
      {isChangeDateModalOpen && (
        <ChangeDateModal
          isError={isChangeDateError}
          isPending={isChangeDatePending}
          onConfirm={handleChangeDate}
          onCancel={closeChangeDateModal}
        />
      )}
    </>
  );
}
