import { Divider, List, Typography } from '@mui/material';
import { Fragment } from 'react';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDateOnly } from '@/helpers/utils/dateHelpers';
import { AdvancedDrivingQualification } from '@/api/motorpolicy';

type AdvanceDrivingContentProps = {
  advancedDrivingQualifications: AdvancedDrivingQualification[];
};

export function AdvanceDrivingContent({
  advancedDrivingQualifications,
}: AdvanceDrivingContentProps) {
  return (
    <>
      <Divider />
      <List>
        <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
          Advance driving qualifications
        </Typography>
        <ListRow label="Advanced driving qualifications">
          {booleanAnswer(Boolean(advancedDrivingQualifications?.length))}
        </ListRow>
        {advancedDrivingQualifications?.map((adq, index) => {
          const adqNumber = index + 1;
          return (
            <Fragment key={adqNumber}>
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }}>
                Qualification #{adqNumber}
              </Typography>
              <ListRow label="Qualification">{adq.type?.description}</ListRow>
              <ListRow label="Date qualified">
                {adq.dateQualified && formatDateOnly(adq.dateQualified)}
              </ListRow>
            </Fragment>
          );
        })}
      </List>
    </>
  );
}
