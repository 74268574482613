import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import {
  AttachmentReceivingMethod,
  AttachmentType,
  CommunicationMethod,
  NoteInitiator,
  NoteType,
} from '@/api/note';
import { queryKeys } from '@/helpers/constants/queryKeys';

type Attachment = {
  file: File;
  type: AttachmentType;
  receivingMethod: AttachmentReceivingMethod;
};

export type CreateNoteRequestData = {
  parentId: string;
  isPolicyNote: boolean;
  noteType: NoteType;
  initiator: NoteInitiator;
  communicationMethod: CommunicationMethod;
  summary: string;
  description: string;
  dateOfReminder: string | undefined;
  assigneeTeam: string;
  attachments: Attachment[];
};

type UseCreateNoteMutationProps = {
  onSuccess?: () => void;
};

const mapRequestDataToFormData = (data: CreateNoteRequestData) => ({
  ParentId: data.parentId,
  IsPolicyNote: data.isPolicyNote,
  NoteType: data.noteType,
  Initiator: data.initiator,
  CommunicationMethod: data.communicationMethod,
  Summary: data.summary,
  Description: data.description,
  DateOfReminder: data.dateOfReminder,
  AssigneeTeam: data.assigneeTeam,
  AttachmentFiles: data.attachments.map(attachment => attachment.file),
  AttachmentTypes: data.attachments.map(attachment => attachment.type),
  AttachmentReceivingMethods: data.attachments.map(
    attachment => attachment.receivingMethod,
  ),
  NewAttachmentsAcceptedAsProof: data.attachments.map(() => false),
});

export function useCreateNoteMutation({ onSuccess }: UseCreateNoteMutationProps = {}) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (data: CreateNoteRequestData) =>
      api.note.notes.postNotes({
        formData: mapRequestDataToFormData(data),
      }),
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.notes(variables.parentId),
      });
      onSuccess?.();
    },
  });
}
