import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { validationMessages } from '@/helpers/utils/textHelpers';

const cardPaymentFormSchema = z
  .object({
    previouslyUsedPaymentMethod: z.string(),
    cardholderName: z.string().max(26, validationMessages.maxLength(26)),
    encryptedCardNumber: z.string(),
    encryptedExpiryMonth: z.string(),
    encryptedExpiryYear: z.string(),
    encryptedSecurityCode: z.string(),
  })
  .refine(
    ({ previouslyUsedPaymentMethod, cardholderName }) =>
      !!(previouslyUsedPaymentMethod || cardholderName),
    { path: ['cardholderName'], message: validationMessages.required },
  )
  .refine(
    ({ previouslyUsedPaymentMethod, encryptedCardNumber }) =>
      !!(previouslyUsedPaymentMethod || encryptedCardNumber),
    { path: ['encryptedCardNumber'], message: validationMessages.required },
  )
  .refine(
    ({ previouslyUsedPaymentMethod, encryptedExpiryMonth }) =>
      !!(previouslyUsedPaymentMethod || encryptedExpiryMonth),
    { path: ['encryptedExpiryMonth'], message: validationMessages.required },
  )
  .refine(
    ({ previouslyUsedPaymentMethod, encryptedExpiryYear }) =>
      !!(previouslyUsedPaymentMethod || encryptedExpiryYear),
    { path: ['encryptedExpiryYear'], message: validationMessages.required },
  )
  .refine(
    ({ previouslyUsedPaymentMethod, encryptedSecurityCode }) =>
      !!(previouslyUsedPaymentMethod || encryptedSecurityCode),
    { path: ['encryptedSecurityCode'], message: validationMessages.required },
  );

export type CardPaymentFormSchema = z.infer<typeof cardPaymentFormSchema>;

export function useCardPaymentForm() {
  return useForm<CardPaymentFormSchema>({
    defaultValues: {
      cardholderName: '',
      encryptedCardNumber: '',
      encryptedExpiryMonth: '',
      encryptedExpiryYear: '',
      encryptedSecurityCode: '',
      previouslyUsedPaymentMethod: '',
    },
    mode: 'onChange',
    resolver: zodResolver(cardPaymentFormSchema),
  });
}
