import { useState } from 'react';
import { TabContext } from '@mui/lab';
import { usePolicyDrawerMenuItems } from './hooks/usePolicyDrawerMenuItems';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { DrawerMenuList } from '@/components/ui/drawers/DrawerMenuList';

type PolicyProps = {
  selectedRowId: string | null;
};

export function Policy({ selectedRowId }: PolicyProps) {
  const [policyId] = useState(selectedRowId!);
  const { data: policy } = useMotorPolicyQuery(policyId);

  const { menuItems, selectedTab, selectedMenuItem, handleMenuItemChange } =
    usePolicyDrawerMenuItems(policy);

  return (
    <PolicyIdContext.Provider value={policyId}>
      <TabContext value={selectedTab}>
        <DrawerMenuList
          menuItems={menuItems}
          selectedKey={selectedMenuItem.key}
          onSelectItem={handleMenuItemChange}
        />
        {selectedMenuItem.content}
      </TabContext>
    </PolicyIdContext.Provider>
  );
}
