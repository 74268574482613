import { Divider } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import {
  NoteAttachmentFormSchema,
  NoteAttachmentFormSubmitData,
  useNoteAttachmentForm,
} from '../hooks/useNoteAttachmentForm';
import { AttachmentFileField } from './AttachmentFileField';
import { AttachmentReceivingMethod, AttachmentType } from '@/api/note';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';
import {
  getAttachmentReceivingMethodName,
  getAttachmentTypeName,
} from '@/helpers/nameMappings/attachmentNameMappings';

type AddAttachmentFormProps = {
  formName: string;
  onSubmit: (attachment: NoteAttachmentFormSubmitData) => void;
};

export function AddAttachmentForm({ formName, onSubmit }: AddAttachmentFormProps) {
  const form = useNoteAttachmentForm();
  const { handleSubmit } = form;

  const handleValidSubmit = (data: NoteAttachmentFormSchema) => {
    onSubmit(data as NoteAttachmentFormSubmitData);
  };

  return (
    <FormProvider {...form}>
      <form id={formName} onSubmit={handleSubmit(handleValidSubmit)}>
        <AttachmentFileField />
        <Divider sx={{ my: 6 }} />
        <FormTextField
          name="type"
          select
          label="Attachment type"
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          {renderSelectOptionsByEnum(AttachmentType, getAttachmentTypeName)}
        </FormTextField>
        <FormTextField
          name="receivingMethod"
          select
          label="Attachment receiving method"
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          {renderSelectOptionsByEnum(
            AttachmentReceivingMethod,
            getAttachmentReceivingMethodName,
          )}
        </FormTextField>
      </form>
    </FormProvider>
  );
}
