import { FeeType } from '@/api/motorpolicy';
import { FeeType as MotorQuoteFeeType } from '@/api/motorquote';

export function getFeeTypesName(feeType: FeeType | MotorQuoteFeeType): string {
  switch (feeType) {
    case MotorQuoteFeeType.ADMINISTRATION_FEE:
    case FeeType.ADMINISTRATION_FEE:
      return 'Administration fee';
    case MotorQuoteFeeType.CANCELLATION_FEE:
    case FeeType.CANCELLATION_FEE:
      return 'Cancellation fee';
    case MotorQuoteFeeType.MTAFEE:
    case FeeType.MTAFEE:
      return 'MTA fee';
  }
}
