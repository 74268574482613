import { useAuth } from 'react-oidc-context';

export function useFetchFile({ withAuth = true } = {}) {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  const fetchFile = async (url: string) => {
    const authHeaders = { Authorization: `Bearer ${accessToken}` };

    const response = await fetch(url, {
      headers: {
        ...(withAuth ? authHeaders : undefined),
      },
    });

    return response.blob();
  };

  return {
    fetchFile,
  };
}
