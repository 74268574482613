export enum NewMtaTab {
  Car = 'car',
  Drivers = 'drivers',
  Cover = 'cover',
}

export enum NewMtaCarChange {
  ExistingCarOrNothing = 'existing-car-or-nothing',
  NewCar = 'car',
  Registration = 'registration',
}
