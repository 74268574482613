import { MotorPolicy } from '@/api/motorpolicy';
import { Account } from '@/api/support';

export const mapAccountInitialValuesFromPolicy = (policy: MotorPolicy): Account => ({
  customerId: policy.policyholder.customerId!,
  customerIdentityProvider: policy.policyholder.customerIdentityProvider!,
  email: policy.policyholder.email!,
  telephoneNumber: policy.policyholder.telephoneNumber!,
  marketingPreferences: policy.policyholder.contactPreferences!,
  autoRenewPolicy: policy.isAutoRenewalRequested ?? false,
});
