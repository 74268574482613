import { DriversTabs } from './components/DriversTabs';
import { Drivers } from './Drivers';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function DriversDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Drivers">
        <DriversTabs />
      </DrawerHeader>
      <Drivers />
    </DrawerContent>
  );
}
