import { List } from '@mui/material';
import {
  QuoteCalculation,
  useQuoteCalculationContext,
} from '@/features/PaymentActions/QuoteCalculation';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';
import { getSelectedAddOnByCode } from '@/helpers/data/quoteHelpers';
import {
  selectAddOnPremium,
  selectAddOnPremiumTax,
} from '@/helpers/selectors/addOnSelectors';

export function HistoryReversalQuoteCalculationPriceBreakdown() {
  const { netPremium, insurancePremiumTax, fee, feeTotal, quote } =
    useQuoteCalculationContext();

  const protectedNcbAddOn = getSelectedAddOnByCode(quote, 'PROTECTED_NCB');
  const protectedNcbPremium = selectAddOnPremium(protectedNcbAddOn);
  const protectedNcbPremiumTax = selectAddOnPremiumTax(protectedNcbAddOn);

  return (
    <>
      <List>
        <QuoteCalculation.PriceBreakdown.Price
          label="Insurance premium"
          amount={netPremium}
        />
        <QuoteCalculation.PriceBreakdown.Price
          label="Insurance premium tax"
          amount={insurancePremiumTax}
        />
        {protectedNcbPremium !== 0 && (
          <QuoteCalculation.PriceBreakdown.Price
            label="Protected NCB Insurance premium"
            amount={protectedNcbPremium}
          />
        )}
        {protectedNcbPremiumTax !== 0 && (
          <QuoteCalculation.PriceBreakdown.Price
            label="Protected NCB Insurance premium tax"
            amount={protectedNcbPremiumTax}
          />
        )}
        <QuoteCalculation.PriceBreakdown.Price
          label={getFeeTypesName(fee.type!)}
          amount={feeTotal}
        />
      </List>
      <QuoteCalculation.PriceBreakdown.InstallmentDetails />
      <QuoteCalculation.PriceBreakdown.Total />
    </>
  );
}
