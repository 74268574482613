import { List, Typography } from '@mui/material';
import { StyledCompactListRow } from '@/features/NewMta/NewMta.styles';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';

type NewMtaCarDetailsListProps = {
  title: string;
  carDetails: NewMtaFormSchema['car']['details'];
};

export function NewMtaCarDetailsList({ title, carDetails }: NewMtaCarDetailsListProps) {
  return (
    <>
      <Typography variant="subtitle2" sx={{ mt: 6 }}>
        {title}
      </Typography>
      <List aria-label="Car details">
        <StyledCompactListRow label="Registration">
          {carDetails.registration}
        </StyledCompactListRow>
        <StyledCompactListRow label="Car">
          {`${carDetails.make} ${carDetails.model}`}
        </StyledCompactListRow>
        <StyledCompactListRow label="Year of manufacture">
          {carDetails.registrationYear}
        </StyledCompactListRow>
        <StyledCompactListRow label="Fuel type">
          {carDetails.fuelType?.description}
        </StyledCompactListRow>
        <StyledCompactListRow label="Transmission">
          {carDetails.transmission?.description}
        </StyledCompactListRow>
        <StyledCompactListRow label="Number of doors">
          {carDetails.numberOfDoors}
        </StyledCompactListRow>
      </List>
    </>
  );
}
