import { CustomersService } from './services/CustomersService';
import { CustomerVulnerabilityService } from './services/CustomerVulnerabilityService';
import { DocumentsService } from './services/DocumentsService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { PaymentsService } from './services/PaymentsService';
import { PoliciesService } from './services/PoliciesService';
import { QuotesService } from './services/QuotesService';
import { UsersService } from './services/UsersService';
import { BaseAPI } from '../core/BaseAPI';

export class SupportAPI extends BaseAPI {
  readonly customers: CustomersService;
  readonly customerVulnerability: CustomerVulnerabilityService;
  readonly documents: DocumentsService;
  readonly paymentMethod: PaymentMethodService;
  readonly payments: PaymentsService;
  readonly policies: PoliciesService;
  readonly quotes: QuotesService;
  readonly users: UsersService;

  constructor() {
    super({ BASE: import.meta.env.VITE_APP_SUPPORT_API_URL });

    this.customers = new CustomersService(this.request);
    this.customerVulnerability = new CustomerVulnerabilityService(this.request);
    this.documents = new DocumentsService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.payments = new PaymentsService(this.request);
    this.policies = new PoliciesService(this.request);
    this.quotes = new QuotesService(this.request);
    this.users = new UsersService(this.request);
  }
}
