import { CreateStyled } from '@emotion/styled';
import { Children, isValidElement, ReactNode } from 'react';

export const withTransientProps: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

export const getChildrenByName = (children: ReactNode, name: string) =>
  Children.toArray(children).filter(
    child =>
      isValidElement(child) && child.type && (child.type as React.FC).name === name,
  );
