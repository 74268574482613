import { subYears } from 'date-fns';
import { DriverNestedFormFields } from '../../DriverNestedFormFields';
import { useClaimForm } from '../hooks/useClaimForm';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks/useNewMtaDriverContext';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { FormDatePicker } from '@/components/ui/forms/FormDatePicker';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { ClaimFormSchema } from '@/features/NewMta/hooks';
import { FormRadio } from '@/components/ui/forms/FormRadio';

type ClaimFormProps = {
  onAddClaim: (fieldValues: ClaimFormSchema) => void;
  onCancel: () => void;
};

export function ClaimForm({ onAddClaim, onCancel }: ClaimFormProps) {
  const form = useClaimForm();
  const { claimTypes } = useNewMtaDriverContext();

  return (
    <DriverNestedFormFields<ClaimFormSchema>
      confirmButtonTestId="new-mta-confirm-claim-form-button"
      cancelButtonTestId="new-mta-cancel-claim-form-button"
      form={form}
      onAdd={onAddClaim}
      onCancel={onCancel}
      buttonLabel="Add this claim"
    >
      <FormTextField
        testId="new-mta_add-claim-type-selectbox"
        select
        label="Type of claim"
        name="type"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        {renderSelectOptionsByOptionsCodes(claimTypes ?? [])}
      </FormTextField>
      <FormDatePicker
        testId="new-mta_add-claim-date-datepicker"
        label="Date of claim"
        name="date"
        sx={{ ...commonSx, ...maxWidthSx }}
        maxDate={new Date()}
        minDate={subYears(new Date(), 5)}
      />
      <FormRadioGroup label="At fault" name="atFault" sx={{ ...commonSx, ...maxWidthSx }}>
        <FormRadio
          testId="new-mta_claim-at-fault-yes-radio"
          label={YesNo.Yes}
          value={YesNo.Yes}
        />
        <FormRadio
          testId="new-mta_claim-at-fault-no-radio"
          label={YesNo.No}
          value={YesNo.No}
        />
      </FormRadioGroup>
      <FormRadioGroup
        label="Claim settled"
        name="settled"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          testId="new-mta_claim-settled-yes-radio"
          label={YesNo.Yes}
          value={YesNo.Yes}
        />
        <FormRadio
          testId="new-mta_claim-settled-no-radio"
          label={YesNo.No}
          value={YesNo.No}
        />
      </FormRadioGroup>
      <FormNumberField
        testId="new-mta_claim-amount-numberbox"
        label="Claim amount"
        name="damageAmount"
        sx={{ ...commonSx, ...maxWidthSx }}
        symbol="money"
      />
    </DriverNestedFormFields>
  );
}
