import type { FilterType } from '../models/FilterType';
import type { GetPoliciesResponse } from '../models/GetPoliciesResponse';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class PoliciesService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getPolicies({
    dateFrom,
    dateTo,
    filterType,
    filterValue,
    skip,
    take,
  }: {
    dateFrom?: string;
    dateTo?: string;
    filterType?: FilterType;
    filterValue?: string;
    skip?: number;
    take?: number;
  }): CancelablePromise<GetPoliciesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policies',
      query: {
        DateFrom: dateFrom,
        DateTo: dateTo,
        FilterType: filterType,
        FilterValue: filterValue,
        Skip: skip,
        Take: take,
      },
    });
  }
}
