import { useController, useFormContext } from 'react-hook-form';
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { overrideReactHookFormRef } from '@/helpers/utils/formHelpers';

type FormCheckboxProps = {
  name: string;
  label: string;
  testId?: string;
} & CheckboxProps;

export function FormCheckbox({ label, name, testId, ...props }: FormCheckboxProps) {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  const helperTextId = `${name}-helperText`;
  const inputProps = {
    'aria-describedby': helperTextId,
    ...(testId ? { 'data-testid': testId } : {}),
    ...props.inputProps,
  };

  return (
    <Box width="100%">
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            {...props}
            {...overrideReactHookFormRef}
            inputProps={inputProps}
            checked={field.value}
            name={name}
          />
        }
        label={label}
      />
      <FormHelperText error id={helperTextId}>
        {error?.message}
      </FormHelperText>
    </Box>
  );
}
