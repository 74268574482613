import { PropsWithChildren, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { CreateOrderActionContext } from '../../hooks/useCreateOrderContext';
import { CreateOrderPromptContext } from '../../hooks/useCreateOrderPromptContext';
import { isMonthlyPaymentPlan } from '../../helpers/quoteCalculationHelper';
import { useQuoteCalculationContext } from '../../hooks/useQuoteCalculationContext';
import { useSelectedPaymentPlan } from '../../hooks/useSelectedPaymentPlan';
import { QuoteCalculationMakeOrder } from './components/QuoteCalculationMakeOrder';
import { QuoteCalculationCreateOrderPrompt } from './components/QuoteCalculationCreateOrderPrompt';
import { useCreateOrderPrompts } from './hooks/useCreateOrderPrompts';
import { QuoteCalculationRefundConfirmationModal } from './components/QuoteCalculationRefundConfirmationModal';
import { QuoteCalculationCreateOrderActionProvider } from './components/QuoteCalculationCreateOrderActionProvider';

type QuoteCalculationCreateOrderActionProps = PropsWithChildren<{
  label: string;
  successMsg: string;
  includeSelectedPaymentPlanInNonPaymentRequest?: boolean;
}>;

export function QuoteCalculationCreateOrderAction({
  label,
  successMsg,
  includeSelectedPaymentPlanInNonPaymentRequest = false,
  children,
}: QuoteCalculationCreateOrderActionProps) {
  const [isLoading, setLoading] = useState(false);
  const { quoteResult } = useQuoteCalculationContext();
  const selectedPaymentPlan = useSelectedPaymentPlan();
  const { currentPrompt, areAllPromptsConfirmed, displayNextPrompt, resetPrompt } =
    useCreateOrderPrompts(
      <>
        {children}
        {quoteResult === 'refund' && !isMonthlyPaymentPlan(selectedPaymentPlan!) && (
          <QuoteCalculationCreateOrderPrompt>
            <QuoteCalculationRefundConfirmationModal />
          </QuoteCalculationCreateOrderPrompt>
        )}
      </>,
    );

  return (
    <CreateOrderActionContext.Provider value={{ setLoading }}>
      <CreateOrderPromptContext.Provider
        value={{ confirmPrompt: displayNextPrompt, cancelPrompt: resetPrompt }}
      >
        <QuoteCalculationCreateOrderActionProvider>
          {areAllPromptsConfirmed ? (
            <QuoteCalculationMakeOrder
              successMsg={successMsg}
              includeSelectedPaymentPlanInNonPaymentRequest={
                includeSelectedPaymentPlanInNonPaymentRequest
              }
              resetPrompt={resetPrompt}
            />
          ) : (
            currentPrompt
          )}
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={isLoading}
            onClick={displayNextPrompt}
          >
            {label}
          </LoadingButton>
        </QuoteCalculationCreateOrderActionProvider>
      </CreateOrderPromptContext.Provider>
    </CreateOrderActionContext.Provider>
  );
}

QuoteCalculationCreateOrderAction.Prompt = QuoteCalculationCreateOrderPrompt;
