import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UsePaymentsTransactionsQueryProps = {
  policyTermCorrelationId: string;
  refundAmount: number;
};

export function usePaymentsTransactionsQuery({
  refundAmount,
  policyTermCorrelationId,
}: UsePaymentsTransactionsQueryProps) {
  const api = useApiClient();

  const fetchPaymentsTransactions = () =>
    api.support.payments.getPaymentsTransactions({
      refundAmount,
      policyTermCorrelationId,
    });

  return useQuery({
    queryKey: queryKeys.paymentsTransactions(refundAmount, policyTermCorrelationId),
    queryFn: fetchPaymentsTransactions,
  });
}
