import { useQuoteCalculationContext } from '../hooks/useQuoteCalculationContext';
import { QuoteCalculationPaymentPlan } from './QuoteCalculationPaymentPlan';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import {
  getPaymentPlanAmount,
  getPaymentPlanLabel,
  getPaymentPlanPriceSuffix,
  hasPaymentPlanCode,
} from '@/helpers/data/paymentPlanHelpers';

export function QuoteCalculationPaymentPlans() {
  const { paymentPlans, total, quoteResult } = useQuoteCalculationContext();

  if (quoteResult === 'no_charge_or_refund') return null;

  return (
    <FormRadioGroup name="paymentPlanCode" displayStyle="tile">
      {paymentPlans.filter(hasPaymentPlanCode).map(paymentPlan => (
        <QuoteCalculationPaymentPlan
          key={paymentPlan.type.code}
          value={paymentPlan.type.code}
          label={getPaymentPlanLabel(paymentPlan, total)}
          amount={getPaymentPlanAmount(paymentPlan, total)}
          priceSuffix={getPaymentPlanPriceSuffix(paymentPlan.type.code)}
        />
      ))}
    </FormRadioGroup>
  );
}
