import { Divider, List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import {
  isCarUsedInBusiness,
  isVehicleKeptAtHomeOvernight,
} from '@/helpers/data/carHelpers';
import { Vehicle } from '@/api/motorpolicy';
import { Policyholder } from '@/api/motorpolicy';

type CarUsageContentProps = {
  vehicle: Vehicle;
  policyholder: Policyholder;
};

export function CarUsageContent({ vehicle, policyholder }: CarUsageContentProps) {
  return (
    <>
      <Divider sx={{ mt: -1 }} />
      <List>
        <ListRow label="Usage">{vehicle.usage?.type?.description}</ListRow>
      </List>
      <Divider />
      <List>
        <ListRow label="Total personal mileage">{vehicle.usage?.annualMileage}</ListRow>
        {vehicle.usage?.type?.code && isCarUsedInBusiness(vehicle.usage.type.code) && (
          <ListRow label="Total business mileage">
            {vehicle.usage.businessMileage}
          </ListRow>
        )}
      </List>
      <Divider />
      <List>
        <ListRow label="Kept at home overnight">
          {booleanAnswer(isVehicleKeptAtHomeOvernight({ vehicle, policyholder }))}
        </ListRow>
        {!isVehicleKeptAtHomeOvernight({ vehicle, policyholder }) && (
          <ListRow label="Postcode">
            {vehicle.location?.overnightLocationPostcode}
          </ListRow>
        )}
      </List>
      <Divider />
      <List>
        <ListRow label="Kept during the day">
          {vehicle.location?.daytimeLocation?.description}
        </ListRow>
        <ListRow label="Kept at night">
          {vehicle.location?.overnightLocation?.description}
        </ListRow>
      </List>
      <Divider />
      <List>
        <ListRow label="Registered owner">
          {vehicle.ownership?.legalOwner?.description}
        </ListRow>
        <ListRow label="Registered keeper">
          {vehicle.ownership?.registeredKeeper?.description}
        </ListRow>
      </List>
    </>
  );
}
