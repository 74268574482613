import { useQuoteCalculationContext } from '../../../hooks/useQuoteCalculationContext';
import { usePaymentPlanCodeForPaymentOrder } from '../hooks/usePaymentPlanCodeForPaymentOrder';
import { CardPaymentModal } from '@/features/PaymentActions/CardPaymentModal';

type QuoteCalculationMakePaymentOrderProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export function QuoteCalculationMakePaymentOrder({
  onCancel,
  onSuccess,
}: QuoteCalculationMakePaymentOrderProps) {
  const { total, quoteId } = useQuoteCalculationContext();
  const paymentPlanCode = usePaymentPlanCodeForPaymentOrder();

  return (
    <CardPaymentModal
      type="quote"
      amount={total}
      quoteId={quoteId}
      selectedPaymentPlan={paymentPlanCode}
      onClose={onCancel}
      onSuccess={onSuccess}
    />
  );
}
