import { Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DriverDetailsFormFields } from '../DriverDetailsFormFields';
import { DriverLicenceAndHistoryFormFields } from '../DriverLicenceAndHistoryFormFields';
import { DriverClaimsFormFields } from '../DriverClaimsFormFields';
import { NonMotoringConvictionsField } from '../NonMotoringConvictionsField';
import { DriverConvictionsFormFields } from '../DriverConvictionsFormFields';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks/useNewMtaDriverContext';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';

export function NewMtaAdditionalDriverForm() {
  const { watch } = useFormContext<NewMtaFormSchema>();
  const { getFieldName, driverOptions } = useNewMtaDriverContext();
  const isNewDriver = !watch(getFieldName('_isComplete'));

  return (
    <>
      {isNewDriver ? (
        <Typography variant="subtitle1" sx={{ mb: 6 }}>
          New additional driver&apos;s details
        </Typography>
      ) : (
        <>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="subtitle1" sx={{ mb: 6 }}>
            Additional driver&apos;s details
          </Typography>
        </>
      )}
      <FormTextField
        label="Relation to policyholder"
        name={getFieldName('relationshipToPolicyholder')}
        sx={{ ...commonSx, ...maxWidthSx }}
        select
      >
        {renderSelectOptionsByOptionsCodes(driverOptions?.relationships ?? [])}
      </FormTextField>
      <DriverDetailsFormFields />
      <Divider sx={{ my: 4 }} />
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Additional driver&apos;s licence and history
      </Typography>
      <DriverLicenceAndHistoryFormFields />
      <Divider sx={{ my: 4 }} />
      <DriverClaimsFormFields />
      <Divider sx={{ my: 4 }} />
      <DriverConvictionsFormFields />
      <Divider sx={{ my: 4 }} />
      <NonMotoringConvictionsField />
    </>
  );
}
