import type { VehicleIAPIResult } from '../models/VehicleIAPIResult';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class VehicleService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getVehicle({
    vehicleRegistration,
  }: {
    vehicleRegistration: string;
  }): CancelablePromise<VehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleRegistration}',
      path: {
        vehicleRegistration: vehicleRegistration,
      },
    });
  }
}
