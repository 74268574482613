import { Alert, Box, Button, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NoteFormSchema } from '../hooks/note.schema';
import { AttachmentChip } from './AttachmentChip';

type NoteFormAttachments = {
  onAddAttachment: () => void;
};

export function NoteFormAttachments({ onAddAttachment }: NoteFormAttachments) {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext<NoteFormSchema>();
  const { remove: removeExistingAttachment } = useFieldArray({
    control,
    name: 'existingAttachments',
  });
  const { remove: removeNewAttachment } = useFieldArray({
    control,
    name: 'newAttachments',
  });
  const existingAttachments = watch('existingAttachments');
  const newAttachments = watch('newAttachments');
  const allAttachmentsCount = existingAttachments.length + newAttachments.length;

  return (
    <>
      <Typography my={4} variant="subtitle2">
        Attachments
      </Typography>
      {errors.newAttachments?.message && (
        <Alert severity="error">{errors.newAttachments?.message}</Alert>
      )}
      {existingAttachments.map((attachment, index) => (
        <Box my={4} key={index}>
          <AttachmentChip
            label={attachment.fileName}
            onDelete={() => removeExistingAttachment(index)}
          />
        </Box>
      ))}
      {newAttachments.map((attachment, index) => (
        <Box my={4} key={index}>
          <AttachmentChip
            label={attachment.file.name}
            onDelete={() => removeNewAttachment(index)}
          />
        </Box>
      ))}
      <Button disabled={allAttachmentsCount >= 10} size="small" onClick={onAddAttachment}>
        Add attachment
      </Button>
    </>
  );
}
