import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { UpdateCustomerVulnerabilitySupportRequest } from '@/api/support';

type UseEditVulnerabilityMutationProps = {
  onSuccess?: () => void;
};

export function useEditVulnerabilityMutation({
  onSuccess,
}: UseEditVulnerabilityMutationProps = {}) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: UpdateCustomerVulnerabilitySupportRequest;
    }) =>
      api.support.customerVulnerability.patchCustomerVulnerabilities({
        id,
        requestBody: data,
      }),
    onSuccess: async data => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.vulnerabilities(data.customerVulnerability!.policyId!),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.adminReminders(),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.individualReminders(),
      });
      onSuccess?.();
    },
  });
}
