import { useContext } from 'react';
import { NewMtaDriverContext } from '../components/NewMtaDrivers/NewMtaDriverContext';

export function useNewMtaDriverContext() {
  const context = useContext(NewMtaDriverContext);

  if (!context) {
    throw new Error(
      'useNewMtaDriverContext must be used within NewMtaDriverContextProvider',
    );
  }

  return context;
}
