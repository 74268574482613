import { z } from 'zod';
import { isAfter, isBefore } from 'date-fns';
import { NewMtaCarChange } from '../../newMtaTypes';
import { getMtaMinPurchaseDate } from '../../helpers/newMtaFormHelper';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { carRegistrationRegex, postcodeRegex } from '@/helpers/constants/regex';
import { YesNo } from '@/models/YesNo';
import { isCarUsedInBusiness } from '@/helpers/data/carHelpers';
import { getDate } from '@/helpers/utils/dateHelpers';

export const newMtaCarFormSchema = z
  .object({
    whatNeedsChanging: z.nativeEnum(NewMtaCarChange),
    registration: z
      .string()
      .regex(carRegistrationRegex, 'Enter a valid registration')
      .refine(value => Boolean(value), validationMessages.required),
    _queryRegistration: z.string(),
    hasVerifiedCarNotImpounded: z.boolean(),
    details: z.object({
      abiCode: z.string(),
      registration: z.string(),
      make: z.string(),
      model: z.string(),
      engineSize: z.number().nullable(),
      registrationYear: z.number().nullable(),
      fuelType: z
        .object({
          code: z.string().nullable().optional(),
          description: z.string().nullable().optional(),
        })
        .nullable(),
      transmission: z
        .object({
          code: z.string().nullable().optional(),
          description: z.string().nullable().optional(),
        })
        .nullable(),
      numberOfDoors: z.number().nullable(),
      vehicleType: z.string().nullable(),
    }),
    steeringWheelSide: z
      .string()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    numberOfSeats: z
      .number()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    trackingDevice: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    trackingDeviceType: z.string().nullable(),
    alarmImmobiliser: z
      .string()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    importedVehicle: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    estimatedValue: z
      .string()
      .refine(value => value !== '', validationMessages.required)
      .refine(value => Number(value) >= 1, validationMessages.minValue(1))
      .refine(value => Number(value) <= 1000000, validationMessages.maxValue(1000000)),
    modifiedVehicle: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    modifications: z.array(
      z
        .object({
          code: z.string().nullable().optional(),
          description: z.string().nullable().optional(),
        })
        .nullable()
        .refine(value => Boolean(value), validationMessages.required),
    ),
    purchasedYet: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    datePurchased: z.date().nullable(),
    usage: z.string().refine(value => Boolean(value), validationMessages.required),
    personalMiles: z
      .string()
      .refine(value => value !== '', validationMessages.required)
      .refine(value => Number(value) >= 200, validationMessages.minValue(200))
      .refine(value => Number(value) <= 99999, validationMessages.maxValue(99999)),
    businessMiles: z.string(),
    keptAtHomeOvernight: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    postCode: z.string(),
    keptDuringDay: z
      .string()
      .refine(value => Boolean(value), validationMessages.required),
    keptAtNight: z.string().refine(value => Boolean(value), validationMessages.required),
    legalOwner: z
      .string()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    registeredKeeper: z
      .string()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
  })
  .refine(
    ({ trackingDevice, trackingDeviceType }) =>
      trackingDevice === YesNo.No || Boolean(trackingDeviceType),
    {
      message: validationMessages.required,
      path: ['trackingDeviceType'],
    },
  )
  .refine(
    ({ purchasedYet, datePurchased }) =>
      purchasedYet === YesNo.No || Boolean(datePurchased),
    {
      message: validationMessages.required,
      path: ['datePurchased'],
    },
  )
  .refine(
    ({ purchasedYet, datePurchased }) =>
      purchasedYet === YesNo.No ||
      !datePurchased ||
      !isBefore(datePurchased, getMtaMinPurchaseDate()),
    {
      message: 'Min year is 1935',
      path: ['datePurchased'],
    },
  )
  .refine(
    ({ purchasedYet, datePurchased }) =>
      purchasedYet === YesNo.No || !datePurchased || !isAfter(datePurchased, getDate()),
    {
      message: 'Max date is today',
      path: ['datePurchased'],
    },
  )
  .refine(
    ({ usage, businessMiles }) => !isCarUsedInBusiness(usage) || Boolean(businessMiles),
    {
      message: validationMessages.required,
      path: ['businessMiles'],
    },
  )
  .refine(
    ({ usage, businessMiles }) => !isCarUsedInBusiness(usage) || businessMiles !== '',
    {
      message: validationMessages.required,
      path: ['businessMiles'],
    },
  )
  .refine(
    ({ usage, businessMiles }) =>
      !isCarUsedInBusiness(usage) || Number(businessMiles) >= 200,
    {
      message: validationMessages.minValue(200),
      path: ['businessMiles'],
    },
  )
  .refine(
    ({ usage, businessMiles }) =>
      !isCarUsedInBusiness(usage) || Number(businessMiles) <= 99999,
    {
      message: validationMessages.maxValue(99999),
      path: ['businessMiles'],
    },
  )
  .refine(
    ({ keptAtHomeOvernight, postCode }) =>
      keptAtHomeOvernight === YesNo.Yes || postCode.match(postcodeRegex),
    { message: 'Enter valid UK postcode', path: ['postCode'] },
  )
  .refine(({ registration, _queryRegistration }) => registration === _queryRegistration, {
    message: 'Please confirm your changes by clicking the button',
    path: ['_queryRegistration'],
  });
