import {
  QuoteCalculation,
  useQuoteCalculationContext,
} from '@/features/PaymentActions/QuoteCalculation';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';

const formatCostDifference = (costDifference: number): string => {
  const costDifferenceOperator = costDifference > 0 ? '+' : costDifference < 0 ? '-' : '';
  return `${costDifferenceOperator}${formatCurrency(Math.abs(costDifference))}`;
};

export function NewMtaQuoteCalculationCostSummaryItems() {
  const { fee, feeTotal, total } = useQuoteCalculationContext();
  const costDifference = total - feeTotal;

  return (
    <>
      <QuoteCalculation.CostSummary.Item
        label={getFeeTypesName(fee.type!)}
        amount={feeTotal}
      />
      <QuoteCalculation.CostSummary.Item
        label="Policy cost difference"
        amount={formatCostDifference(costDifference)}
      />
      <QuoteCalculation.CostSummary.Item label="Total cost for changes" amount={total} />
    </>
  );
}
