import { CreateSupportMtaRequest } from '@/api/motorpolicy';

export const hasUpdatesInNewMtaPayload = (payload: CreateSupportMtaRequest): boolean =>
  Boolean(payload.driversToAdd?.length) ||
  Boolean(payload.driversToRemove?.length) ||
  Boolean(payload.driversToUpdate?.length) ||
  Boolean(payload.vehicleUpdate) ||
  Boolean(payload.vehicleSwap) ||
  Boolean(payload.policyholder) ||
  Boolean(payload.cover) ||
  Boolean(payload.excesses) ||
  Boolean(payload.policyholder) ||
  Boolean(payload.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled);
