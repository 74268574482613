import { subYears } from 'date-fns';
import { useMemo } from 'react';
import { DriverNestedFormFields } from '../../DriverNestedFormFields';
import { useConvictionForm } from '../hooks/useConvictionForm';
import { ConvictionFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { FormDatePicker } from '@/components/ui/forms/FormDatePicker';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { FormComboBox } from '@/components/ui/forms/FormComboBox';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';

type ConvictionFormProps = {
  onAddConviction: (fieldValues: ConvictionFormSchema) => void;
  onCancel: () => void;
};

export function ConvictionForm({ onAddConviction, onCancel }: ConvictionFormProps) {
  const form = useConvictionForm();
  const { watch } = form;
  const shouldShowBanLengthField = watch('banReceived') === YesNo.Yes;
  const { convictionTypes } = useNewMtaDriverContext();

  const convictionTypesOptions = useMemo(
    () =>
      (convictionTypes ?? []).map(({ code, description }) => ({
        code,
        description: `${code} - ${description}`,
      })),
    [convictionTypes],
  );

  return (
    <DriverNestedFormFields<ConvictionFormSchema>
      confirmButtonTestId="new-mta-confirm-conviction-form-button"
      cancelButtonTestId="new-mta-cancel-conviction-form-button"
      form={form}
      onAdd={onAddConviction}
      onCancel={onCancel}
      buttonLabel="Add this conviction"
    >
      <FormComboBox
        testId="new-mta_conviction-code-combobox"
        label="Conviction code"
        name="type"
        sx={{ ...commonSx, ...maxWidthSx }}
        options={convictionTypesOptions}
      />
      <FormDatePicker
        testId="new-mta_conviction-date-datepicker"
        label="Date of conviction"
        name="date"
        sx={{ ...commonSx, ...maxWidthSx }}
        maxDate={new Date()}
        minDate={subYears(new Date(), 5)}
      />
      <FormNumberField
        testId="new-mta_conviction-penalty-points-numberbox"
        label="Penalty points"
        name="penaltyPoints"
        integerOnly
        sx={{ ...commonSx, ...maxWidthSx }}
      />
      <FormNumberField
        testId="new-mta_conviction-fine-amount-numberbox"
        label="Fine amount"
        name="fineAmount"
        sx={{ ...commonSx, ...maxWidthSx }}
        symbol="money"
      />
      <FormRadioGroup
        label="Ban received"
        name="banReceived"
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          testId="new-mta_conviction-ban-received-yes-radio"
          label={YesNo.Yes}
          value={YesNo.Yes}
        />
        <FormRadio
          testId="new-mta_conviction-ban-received-no-radio"
          label={YesNo.No}
          value={YesNo.No}
        />
      </FormRadioGroup>
      {shouldShowBanLengthField ? (
        <FormNumberField
          testId="new-mta_conviction-ban-length-numberbox"
          label="Ban length (months)"
          name="disqualifiedLengthInMonths"
          sx={{ ...commonSx, ...maxWidthSx }}
        />
      ) : null}
    </DriverNestedFormFields>
  );
}
