import { Box } from '@mui/material';
import { InsurerClaim } from '@/api/motorpolicy';
import { AccordionTitle } from '@/components/ui/lists/AccordionList';
import { StyledAccordionHeader } from '@/components/ui/styles/StyledAccordionHeader';
import {
  getClaimCauseName,
  getClaimStatusName,
} from '@/helpers/nameMappings/claimNameMappings';
import { StyledAccordionHeaderSubtitle } from '@/components/ui/styles/StyledAccordionHeaderSubtitle';
import { formatDate, parseDateOnly } from '@/helpers/utils/dateHelpers';

type ClaimHeaderProps = {
  claim: InsurerClaim;
  claimNo: number;
};

export function ClaimHeader({ claim, claimNo }: ClaimHeaderProps) {
  const primaryCause = claim.primaryCause ? getClaimCauseName(claim.primaryCause) : '';
  const incidentDate = claim.incidentDate
    ? formatDate(parseDateOnly(claim.incidentDate))
    : '-';
  const claimStatus = claim.status ? getClaimStatusName(claim.status) : '';

  return (
    <Box width="100%">
      <StyledAccordionHeader>
        <AccordionTitle>{`#${claimNo} ${primaryCause}`}</AccordionTitle>
        <StyledAccordionHeaderSubtitle>
          {`Claim number ${claim.claimNumber}`}
        </StyledAccordionHeaderSubtitle>
      </StyledAccordionHeader>
      <StyledAccordionHeader>
        <StyledAccordionHeaderSubtitle>{claimStatus}</StyledAccordionHeaderSubtitle>
        <StyledAccordionHeaderSubtitle>
          {`Incident ${incidentDate}`}
        </StyledAccordionHeaderSubtitle>
      </StyledAccordionHeader>
    </Box>
  );
}
