import { LoadingButton } from '@mui/lab';
import { Alert, Button, Typography } from '@mui/material';
import { Modal } from '@/components/ui/modals/Modal';
import { useCreateOrderPromptContext } from '@/features/PaymentActions/QuoteCalculation';

export function HistoryReversalConfirmationModal() {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();

  return (
    <Modal onClose={cancelPrompt}>
      <Modal.Header>Confirm reversal</Modal.Header>
      <Modal.Content>
        <Alert severity="error">You are about to reverse this policy</Alert>
        <Typography variant="body2">
          Please confirm with the customer that this policy is about the be reversed. By
          continuing, the Motor Insurance Database will be amended accordingly.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton variant="contained" color="secondary" onClick={confirmPrompt}>
          Confirm and continue
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={cancelPrompt}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
