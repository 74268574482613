import { InputBase, css, styled } from '@mui/material';
import { withTransientProps } from '@/helpers/utils/componentHelpers';

export const StyledFormTextFieldBasic = styled(InputBase, withTransientProps)<{
  $isError?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
  border-width: 1px;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border-style: solid;
  outline-style: solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  outline: ${({ theme, $isError }) =>
    $isError ? `1px solid ${theme.palette.error.main}` : 'none'};

  ${({ $isError }) =>
    $isError &&
    css`
      border: none;
    `}

  &:hover {
    ${({ theme, $isError }) =>
      !$isError &&
      css`
        border-color: ${theme.palette.grey[700]};
      `};
  }

  &:focus-within {
    outline: ${({ theme, $isError }) =>
      $isError
        ? `2px solid ${theme.palette.error.main}`
        : `1px solid ${theme.palette.primary.main}`};
  }

  input {
    padding: 0;
  }
`;
