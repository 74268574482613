import { IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type DocumentPaginationProps = {
  pageNumber: number;
  numPages: number;
  onPageChange: (pageNumber: number) => void;
};

export function DocumentPagination({
  pageNumber,
  numPages,
  onPageChange,
}: DocumentPaginationProps) {
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      onPageChange(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      onPageChange(pageNumber + 1);
    }
  };

  return (
    <Stack alignItems="center">
      <Typography>
        Page {pageNumber} of {numPages}
      </Typography>
      <IconButton
        aria-label="previous page"
        onClick={handlePreviousPage}
        disabled={pageNumber === 1}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        aria-label="next page"
        onClick={handleNextPage}
        disabled={pageNumber === numPages}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Stack>
  );
}
