import { createContext, Dispatch, SetStateAction, useContext } from 'react';

type CreateOrderActionContextProps = {
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const CreateOrderActionContext = createContext<
  CreateOrderActionContextProps | undefined
>(undefined);

export function useCreateOrderActionContext() {
  const context = useContext(CreateOrderActionContext);

  if (context === undefined) {
    throw new Error(
      'useCreateOrderActionContext must be used within CreateOrderActionContext',
    );
  }

  return context;
}
