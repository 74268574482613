import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ChangeDateForm } from './components/ChangeDateForm';
import { Modal } from '@/components/ui/modals/Modal';

type ChangeDateModalProps = {
  isError: boolean;
  isPending: boolean;
  onConfirm: (data: { dueDate: Date }) => void;
  onCancel: () => void;
};

export function ChangeDateModal({
  isError,
  isPending,
  onConfirm,
  onCancel,
}: ChangeDateModalProps) {
  const formName = 'change-date-form';

  return (
    <Modal onClose={onCancel} onClick={e => e.stopPropagation()}>
      <Modal.Header>Change date</Modal.Header>
      <Modal.Content>
        <ChangeDateForm formName={formName} isError={isError} onSubmit={onConfirm} />
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          data-testid="reminders_change-date-submitbutton"
          type="submit"
          form={formName}
          variant="contained"
          color="secondary"
          loading={isPending}
        >
          Change date
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
