import { Button, Stack } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  VulnerabilitiesFormSchema,
  VulnerabilityFormDefaultValues,
  getMinVulnerabilityFollowUpDate,
  useVulnerabilitiesForm,
} from '../../hooks/useVulnerabilitiesForm';
import { StyledVulnerabilityHeader } from '../VulnerabilitiesContent/VulnerabilitiesContent.styles';
import { useVulnerabilityFormOperations } from '../../hooks/useVulnerabilityFormOperations';
import {
  getVulnerabilityActionName,
  getVulnerabilityCategoryName,
  getVulnerabilityTypeName,
} from './helpers/vulnerabilityNameHelper';
import { VULNERABILITY_TYPES_PER_CATEGORY } from './helpers/vulnerabilityFormHelper';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';
import { ActionRequired } from '@/api/vulnerabilities';
import { LeavePagePrompt } from '@/components/ui/modals/LeavePagePrompt';
import { YesNo } from '@/models/YesNo';
import { VulnerabilityCategory, VulnerabilityType } from '@/api/support';
import { FormDateTimePicker } from '@/components/ui/forms/FormDateTimePicker';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

export type VulnerabilityFormProps = {
  vulnerabilityNumber: number;
  defaultValues?: VulnerabilityFormDefaultValues;
  onCancel: () => void;
  onSubmit: SubmitHandler<VulnerabilitiesFormSchema>;
  isPending: boolean;
};

export function VulnerabilityForm({
  vulnerabilityNumber,
  defaultValues,
  onCancel,
  onSubmit,
  isPending,
}: VulnerabilityFormProps) {
  const formName = `Vulnerability #${vulnerabilityNumber}`;
  const form = useVulnerabilitiesForm(defaultValues);
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const {
    isEditMode,
    isFollowUpRequired,
    isTemporary,
    categoryField,
    handleCategoryChange,
  } = useVulnerabilityFormOperations(form);

  return (
    <FormProvider {...form}>
      <LeavePagePrompt when={isDirty} />
      <form aria-label={formName} name={formName} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" p={6}>
          <StyledVulnerabilityHeader>{formName}</StyledVulnerabilityHeader>
          <FormRadioGroup
            name="temporary"
            label="Temporary"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
            <FormRadio label={YesNo.No} value={YesNo.No} />
          </FormRadioGroup>
          <FormRadioGroup
            name="consentObtained"
            label="Consent obtained"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
            <FormRadio label={YesNo.No} value={YesNo.No} />
          </FormRadioGroup>
          <FormTextField
            select
            label="Category"
            name="category"
            sx={{ ...commonSx, ...maxWidthSx }}
            onChange={event =>
              handleCategoryChange(event.target.value as VulnerabilityCategory)
            }
          >
            {renderSelectOptionsByEnum(
              VulnerabilityCategory,
              getVulnerabilityCategoryName,
              {
                exclude: [VulnerabilityCategory.UNKNOWN],
              },
            )}
          </FormTextField>
          <FormTextField
            select
            name="type"
            label="Type"
            sx={{ ...commonSx, ...maxWidthSx }}
            disabled={!categoryField}
          >
            {renderSelectOptionsByEnum(VulnerabilityType, getVulnerabilityTypeName, {
              include: categoryField
                ? VULNERABILITY_TYPES_PER_CATEGORY[categoryField]
                : undefined,
            })}
          </FormTextField>
          <FormTextField
            select
            name="action"
            label="Action"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            {renderSelectOptionsByEnum(ActionRequired, getVulnerabilityActionName)}
          </FormTextField>
          <FormTextField
            sx={commonSx}
            name="description"
            label="Description"
            multiline
            rows={6}
          />
          <FormRadioGroup
            name="followUpRequired"
            label="Follow-up required"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
            <FormRadio label={YesNo.No} value={YesNo.No} />
          </FormRadioGroup>
          <FormDateTimePicker
            disabled={!(isFollowUpRequired || isTemporary)}
            name="followUpDate"
            label="Reminder due date/time"
            minDate={getMinVulnerabilityFollowUpDate()}
            slotProps={{ field: { clearable: true } }}
            sx={{ ...commonSx, ...maxWidthSx }}
          />
          <FormRadioGroup
            name="resolved"
            label="Resolved"
            sx={{ ...commonSx, ...maxWidthSx }}
          >
            <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
            <FormRadio label={YesNo.No} value={YesNo.No} />
          </FormRadioGroup>
          <FormTextField
            sx={commonSx}
            name="comment"
            label="Comments (Optional)"
            multiline
            rows={6}
          />
        </Stack>
        <StyledButtonsFooter sticky>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={isPending}
          >
            {isEditMode ? 'Save changes' : 'Add vulnerability'}
          </LoadingButton>
          <Button
            variant="outlined"
            color="inherit"
            onClick={isDirty ? () => reset() : onCancel}
          >
            {isDirty ? 'Reset form' : 'Cancel changes'}
          </Button>
        </StyledButtonsFooter>
      </form>
    </FormProvider>
  );
}
