import { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import { HistoryReversalQuoteCalculation } from './components/HistoryReversalQuoteCalculation';
import { useReversalQuoteMutation } from '@/hooks/mutations/useReversalQuoteMutation';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { getApiErrorMessage } from '@/helpers/utils/errorHelpers';
import { useMotorQuoteQuery } from '@/hooks/queries/useMotorQuoteQuery';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { useSetAlertError } from '@/hooks/useSetAlertError';

type HistoryReversalProps = { transactionId: string };

export function HistoryReversal({ transactionId }: HistoryReversalProps) {
  const policyId = useContext(PolicyIdContext);
  const {
    mutate: getReversalQuote,
    data: { quoteId } = {},
    isError: isReversalQuoteError,
    isPending: isReversalQuotePending,
    error: reversalQuoteError,
  } = useReversalQuoteMutation();
  const {
    data: reversalQuote,
    isFetching: isMotorQuoteQueryFetching,
    isError: isMotorQuoteQueryError,
  } = useMotorQuoteQuery(quoteId ?? '', {
    enabled: !!quoteId,
  });
  const isQuoteFetching = isReversalQuotePending || isMotorQuoteQueryFetching;
  const isQuoteError = isReversalQuoteError || isMotorQuoteQueryError;

  const shouldShowQuote = !isQuoteError && !!reversalQuote;
  const handleCalculateReversal = () => getReversalQuote({ policyId, transactionId });

  useSetAlertError(isQuoteError, getApiErrorMessage(reversalQuoteError));

  return shouldShowQuote ? (
    <HistoryReversalQuoteCalculation quote={reversalQuote} />
  ) : (
    <StyledButtonsFooter sticky sx={{ py: 6, px: 0 }}>
      <LoadingButton
        loading={isQuoteFetching}
        onClick={handleCalculateReversal}
        sx={{ alignSelf: 'flex-start' }}
      >
        Calculate reversal cost
      </LoadingButton>
    </StyledButtonsFooter>
  );
}
