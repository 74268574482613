import { useContext } from 'react';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useVehicleOptionsQuery } from '@/hooks/queries/useVehicleOptionsQuery';
import { getProductInfo } from '@/helpers/data/retailHelpers';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function useVehicleOptions() {
  const parentId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(parentId);
  const query = useVehicleOptionsQuery(getProductInfo(policy), {
    enabled: Boolean(policy),
  });
  return query;
}
