import { PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import { PolicyQueryParams } from '../Policies/policyTypes';
import { NewMtaTabContext } from './hooks/useNewMtaTabContext';
import { NewMtaTab } from './newMtaTypes';

const getCurrentTab = (searchParams: URLSearchParams) => {
  const queryParam = searchParams.get(PolicyQueryParams.Tab) as NewMtaTab | null;

  return queryParam && Object.values(NewMtaTab).includes(queryParam)
    ? (queryParam as NewMtaTab)
    : NewMtaTab.Drivers;
};

export function NewMtaTabContextProvider({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = getCurrentTab(searchParams);

  const handleChange = (newTab: NewMtaTab) => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams),
        [PolicyQueryParams.Tab]: newTab,
      }),
    );
  };

  return (
    <TabContext value={currentTab}>
      <NewMtaTabContext.Provider
        value={{
          currentTab,
          setCurrentTab: handleChange,
        }}
      >
        {children}
      </NewMtaTabContext.Provider>
    </TabContext>
  );
}
