import { Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { MotorClaim } from '@/api/motorpolicy';

type HistoryClaimContentProps = {
  claimNumber: number;
  claim: MotorClaim;
};

export function HistoryClaimContent({ claim, claimNumber }: HistoryClaimContentProps) {
  return (
    <>
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Claim #{claimNumber}
      </Typography>
      <ListRow disablePadding label="Source">
        {claim.source.description}
      </ListRow>
      <ListRow disablePadding label="Type">
        {claim.type?.description}
      </ListRow>
      <ListRow disablePadding label="Date">
        {formatDate(claim.date)}
      </ListRow>
      <ListRow disablePadding label="Settled">
        {booleanAnswer(claim.settled)}
      </ListRow>
      <ListRow disablePadding label="Damage type">
        {claim.damageType?.description}
      </ListRow>
      <ListRow disablePadding label="Damage amount">
        {claim.damageAmount?.amount && formatCurrency(claim.damageAmount.amount)}
      </ListRow>
      <ListRow disablePadding label="Claim made against insurance">
        {booleanAnswer(claim.claimMadeAgainstYourInsurance)}
      </ListRow>
      <ListRow disablePadding label="NCB affected">
        {booleanAnswer(claim.ncbAffected)}
      </ListRow>
      <ListRow disablePadding label="At fault">
        {booleanAnswer(claim.atFault)}
      </ListRow>
    </>
  );
}
