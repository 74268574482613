import { styled } from '@mui/material';
import { FileDetailContent } from './FileDetailContent';
import { RotateButton } from './controls/RotateButton';
import { DownloadButton } from './controls/DownloadButton';
import { useRotate } from '@/components/ui/presentation/FileDetail/hooks';
import { FileDetailModalProps } from '@/components/ui/presentation/FileDetail/fileDetailTypes';
import { Modal } from '@/components/ui/modals/Modal';

const StyledVideo = styled('video')(() => ({
  maxWidth: '100%',
}));

export function FileDetailVideoModal({
  fileName,
  content,
  title,
  onClose,
}: FileDetailModalProps) {
  const [rotate, setRotate] = useRotate();

  return (
    <Modal onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <FileDetailContent rotate={rotate}>
          <StyledVideo controls>
            <source src={content} type="video/mp4" />
          </StyledVideo>
        </FileDetailContent>
      </Modal.Content>
      <Modal.Actions stretchItems={false} gap={4}>
        <RotateButton rotate={rotate} onRotateChange={setRotate} />
        <DownloadButton fileName={fileName} fileContent={content} />
      </Modal.Actions>
    </Modal>
  );
}
