import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  useRadioGroup,
} from '@mui/material';
import { InputHTMLAttributes } from 'react';

type FormRadioProps = Omit<FormControlLabelProps, 'control'> & { testId?: string };

export function FormRadio({ testId, ...props }: FormRadioProps) {
  const radioGroup = useRadioGroup();
  const isSelected = radioGroup?.value === props.value;

  const inputProps = testId
    ? ({ 'data-testid': testId } as InputHTMLAttributes<HTMLInputElement>)
    : undefined;

  return (
    <FormControlLabel
      aria-selected={isSelected}
      control={<Radio inputProps={inputProps} />}
      {...props}
    />
  );
}
