import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { Order } from '@/api/retail/v1';

type UseOrderQueryProps = {
  id: string | undefined | null;
  isRefund?: boolean;
};

export function useOrderQuery(
  { id, isRefund }: UseOrderQueryProps,
  options?: Omit<UseQueryOptions<Order>, 'queryKey' | 'queryFn'>,
) {
  const [isComplete, setIsComplete] = useState(false);
  const api = useApiClient();
  const getOrder = () => {
    if (!id) {
      throw new Error('No order ID');
    }

    return api.retailV1.order.getOrder({
      id,
    });
  };

  const query = useQuery({
    queryFn: getOrder,
    queryKey: queryKeys.order(id ?? ''),
    gcTime: 0,
    refetchInterval: isComplete ? false : 2000,
    enabled: !!id,
    ...options,
  });

  useEffect(() => {
    if (
      query.isSuccess &&
      (query.data.status === 'complete' ||
        query.data.status === 'authorised' ||
        (isRefund && query.data.status === 'processing'))
    ) {
      setIsComplete(true);
    }
  }, [query.isSuccess, query.data]);

  return {
    ...query,
    data: isComplete ? query.data : null,
    isSuccess: query.isSuccess && isComplete,
    isError: query.isError || query.data?.status === 'refused',
  };
}
