import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { getCancellationReasonLabel } from '../helpers/cancellationNameHelper';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';
import { FormDateTimePicker } from '@/components/ui/forms/FormDateTimePicker';
import {
  CancelPolicyFormSchema,
  getMaxEffectiveDateTime,
  getMinEffectiveDateTime,
} from '@/features/Actions/hooks/useCancelPolicyForm';
import { CancellationReason } from '@/api/motorpolicy';

export function CancelPolicyFormFields() {
  const { watch } = useFormContext<CancelPolicyFormSchema>();
  const policy = watch('_policy');
  const minEffectiveDateTime = getMinEffectiveDateTime(policy);
  const maxEffectiveDateTime = getMaxEffectiveDateTime(policy);

  return (
    <Box height="100%" mx={6}>
      <FormDateTimePicker
        name="effectiveDateTime"
        label="Cancellation date"
        minDate={minEffectiveDateTime}
        maxDate={maxEffectiveDateTime}
        sx={{ ...commonSx, ...maxWidthSx }}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        select
        label="Reason"
        name="cancellationReason"
        id="cancellationReason"
      >
        {renderSelectOptionsByEnum(CancellationReason, getCancellationReasonLabel)}
      </FormTextField>
      <FormTextField
        sx={commonSx}
        name="description"
        id="description"
        label="Description"
        multiline
        rows={6}
      />
    </Box>
  );
}
