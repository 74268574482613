import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { Endorsement } from '@/api/retail/v2';

type UseEndorsementsQueryProps = {
  productName?: string;
  productVersion?: string;
  schemeName: string;
};

export function useEndorsementsQuery(
  {
    productName = 'motor',
    productVersion = 'latest',
    schemeName,
  }: UseEndorsementsQueryProps,
  options: Omit<UseQueryOptions<Endorsement[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchEndorsements = () =>
    api.retailV2.product.getProductSchemeEndorsements({
      productName,
      productVersion,
      schemeName,
    });

  return useQuery({
    queryKey: queryKeys.endorsements(productName, productVersion),
    queryFn: fetchEndorsements,
    ...options,
  });
}
