import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { LicenceDurations } from '@/api/retail/v2';

type useLicenceDurationsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useLicenceDurationsQuery(
  { productName = 'motor', productVersion = 'latest' }: useLicenceDurationsQueryProps,
  options?: Omit<UseQueryOptions<LicenceDurations>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchLicenceDurations = () =>
    api.retailV2.product.getProductLicenceDurations({
      productName,
      productVersion,
    });

  return useQuery({
    queryKey: queryKeys.licenseDurations(productName, productVersion),
    queryFn: fetchLicenceDurations,
    ...options,
  });
}
