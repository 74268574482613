import { useContext } from 'react';
import { useQuoteCalculationContext } from '../../../hooks/useQuoteCalculationContext';
import { useSelectedPaymentPlan } from '../../../hooks/useSelectedPaymentPlan';
import { QuoteType } from '@/api/motorquote';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import {
  getIsMonthlyPaymentPolicy,
  getMainTransaction,
} from '@/helpers/data/policyHelpers';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function usePaymentPlanCodeForPaymentOrder() {
  const selectedPaymentPlan = useSelectedPaymentPlan();
  const { quote } = useQuoteCalculationContext();
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const isMonthlyPaymentPolicy = !!(policy && getIsMonthlyPaymentPolicy(policy));
  const initialPolicyPaymentPlanCode =
    (policy?.transactions &&
      getMainTransaction(policy.transactions)?.paymentPlan?.type?.code) ??
    '';
  const shouldResetPaymentPlanCode =
    !!initialPolicyPaymentPlanCode &&
    isMonthlyPaymentPolicy &&
    quote.type === QuoteType.CANCELLATION;

  return shouldResetPaymentPlanCode
    ? initialPolicyPaymentPlanCode
    : (selectedPaymentPlan!.type!.code! as string);
}
