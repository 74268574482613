import { Payments } from './Payments';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function PaymentsDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Payments" />
      <Payments />
    </DrawerContent>
  );
}
