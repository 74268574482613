import { Claims } from './Claims';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function ClaimsDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Claims" />
      <Claims />
    </DrawerContent>
  );
}
