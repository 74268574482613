import { Alert, Divider } from '@mui/material';
import { CustomerVulnerability } from '@/api/vulnerabilities';
import { StyledDrawerContent } from '@/components/ui/styles/StyledDrawerContent';
import { Driver } from '@/api/motorpolicy';

type DriverBannersProps = {
  driver: Driver;
  vulnerabilities: CustomerVulnerability[];
  isVulnerabilitiesError: boolean;
};

export function DriverBanners({
  driver,
  vulnerabilities,
  isVulnerabilitiesError,
}: DriverBannersProps) {
  const isVulnerableDriver = vulnerabilities.some(
    ({ driverId }) => driverId === driver.id,
  );

  const banners = [
    {
      condition: isVulnerableDriver,
      element: (
        <Alert key="driver-vulnerable" severity="error">
          This driver is vulnerable
        </Alert>
      ),
    },
    {
      condition: isVulnerabilitiesError,
      element: (
        <Alert key="vulnerability-error" severity="error">
          Failed to load data for vulnerabilities
        </Alert>
      ),
    },
    {
      condition: driver.isMainDriver,
      element: (
        <Alert key="main-driver" severity="success">
          This driver is the main driver
        </Alert>
      ),
    },
  ];

  const visibleBanners = banners.filter(({ condition }) => condition);

  if (visibleBanners.length === 0) {
    return null;
  }

  return (
    <>
      <StyledDrawerContent>
        {visibleBanners.map(({ element }) => element)}
      </StyledDrawerContent>
      <Divider />
    </>
  );
}
