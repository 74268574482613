import { useCreateOrderPromptContext } from '../../../hooks/useCreateOrderPromptContext';
import { useQuoteCalculationContext } from '../../../hooks/useQuoteCalculationContext';
import { useQuoteCalculationCreateOrderActionContext } from '../hooks/useQuoteCalculationCreateOrderActionContext';
import { RefundBalanceModal } from '@/features/Payments/components/RefundBalanceModal';
import { BypassRefundModal } from '@/features/Payments/components/BypassRefundModal';

export function QuoteCalculationRefundConfirmationModal() {
  const { confirmPrompt, cancelPrompt } = useCreateOrderPromptContext();
  const { total, quote } = useQuoteCalculationContext();
  const { setShouldBypassRefund, shouldBypassRefund } =
    useQuoteCalculationCreateOrderActionContext();

  const onCancelBypassRefund = () => {
    setShouldBypassRefund(false);
    cancelPrompt();
  };

  return shouldBypassRefund ? (
    <BypassRefundModal
      onConfirm={confirmPrompt}
      onSuccess={confirmPrompt}
      onCancel={onCancelBypassRefund}
    />
  ) : (
    <RefundBalanceModal
      total={total}
      policyTermCorrelationId={quote.policyTermCorrelationId!}
      onConfirm={confirmPrompt}
      onCancel={cancelPrompt}
      onBypassRefund={() => setShouldBypassRefund(true)}
    />
  );
}
