import { styled, Stack } from '@mui/material';

export const NoteHeaderContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
  marginRight: theme.spacing(4),
}));

export const NoteHeaderRow = styled(Stack)({
  justifyContent: 'space-between',
  width: '100%',
});

export const StyledButtonsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: 0,
  zIndex: theme.zIndex.fab,
}));
