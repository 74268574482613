import { Alert } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';

export function QuoteCalculationError({ children }: PropsWithChildren) {
  return (
    <StyledButtonsFooter sticky stretchItems={false}>
      <Alert severity="error">{children ?? GENERAL_ERROR_MSG}</Alert>
    </StyledButtonsFooter>
  );
}
