import { Box, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const StyledTableMessage = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  textAlign: 'center',
  backgroundColor: theme.palette.grey[100],
  position: 'absolute',
  width: 'calc(100% - 2px)',
}));

export function TableEmptyPlaceholder({
  children = 'The search returned no results',
}: PropsWithChildren) {
  return (
    <tbody>
      <tr>
        <td data-testid="ui_table-empty-placeholder">
          <Box sx={{ height: '65px' }}>
            <StyledTableMessage>{children}</StyledTableMessage>
          </Box>
        </td>
      </tr>
    </tbody>
  );
}
