import type { AddDebtRequest } from '../models/AddDebtRequest';
import type { GetDebtResponse } from '../models/GetDebtResponse';
import type { GetPaymentInformationResponse } from '../models/GetPaymentInformationResponse';
import type { GetRefundTransactionsResponse } from '../models/GetRefundTransactionsResponse';
import type { PlaceOrderResponse } from '../models/PlaceOrderResponse';
import type { SettleDebtRequest } from '../models/SettleDebtRequest';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class PaymentsService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getPayments({
    policyId,
  }: {
    policyId: string;
  }): CancelablePromise<GetPaymentInformationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payments/{policyId}',
      path: {
        policyId: policyId,
      },
    });
  }

  getPaymentsDebt({
    policyId,
  }: {
    policyId: string;
  }): CancelablePromise<GetDebtResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payments/debt/{policyId}',
      path: {
        policyId: policyId,
      },
    });
  }

  postPaymentsDebt({
    policyId,
    requestBody,
  }: {
    policyId: string;
    requestBody?: AddDebtRequest;
  }): CancelablePromise<PlaceOrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payments/debt/{policyId}',
      path: {
        policyId: policyId,
      },
      body: requestBody,
    });
  }

  getPaymentsTransactions({
    policyTermCorrelationId,
    refundAmount,
  }: {
    policyTermCorrelationId?: string;
    refundAmount?: number;
  }): CancelablePromise<GetRefundTransactionsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payments/transactions',
      query: {
        policyTermCorrelationId: policyTermCorrelationId,
        refundAmount: refundAmount,
      },
    });
  }

  postPaymentsSettleDebt({
    policyId,
    requestBody,
  }: {
    policyId: string;
    requestBody?: SettleDebtRequest;
  }): CancelablePromise<PlaceOrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payments/{policyId}/settle-debt',
      path: {
        policyId: policyId,
      },
      body: requestBody,
    });
  }
}
