import { Button, Divider } from '@mui/material';
import { useContext, useState } from 'react';
import { AddNewFeeModal } from './components/AddNewFeeModal';
import { PaymentsOverview } from './components/PaymentsOverview';
import { PaymentsBilling } from './components/PaymentsBilling';
import { PaymentsAdhocFees } from './components/PaymentsAdhocFees';
import {
  AccordionList,
  AccordionListItem,
  AccordionTitle,
} from '@/components/ui/lists/AccordionList';
import { usePaymentsQuery } from '@/hooks/queries/usePaymentsQuery';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useUser } from '@/hooks/useUser';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { useUnsettledBalanceQuery } from '@/hooks/queries/useUnsettledBalanceQuery';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { PolicyTransactionType } from '@/api/motorpolicy';

export function Payments() {
  const [isAddNewFeeModalOpen, setAddNewFeeModalOpen] = useState(false);
  const { setAlert } = useSnackbarAlert();
  const policyId = useContext(PolicyIdContext);
  const {
    isPending: isPolicyPending,
    isError: isPolicyError,
    data: policy,
  } = useMotorPolicyQuery(policyId);
  const {
    data: payment,
    isError: isPaymentsError,
    isPending: isPaymentsPending,
  } = usePaymentsQuery(policyId);
  const {
    data: { balance } = {},
    isError: isBalanceError,
    isPending: isBalancePending,
  } = useUnsettledBalanceQuery(policyId);
  const { hasPermission } = useUser();
  const newBusinessTransaction = policy?.transactions?.find(
    transaction => transaction.type === PolicyTransactionType.NEW_BUSINESS,
  );

  if (isPaymentsError || isBalanceError || isPolicyError) return <DrawerError />;
  if (isPaymentsPending || isBalancePending || isPolicyPending) return <DrawerLoading />;

  const accordionItems: AccordionListItem[] = [
    {
      key: 'payments-overview',
      header: <AccordionTitle>Overview</AccordionTitle>,
      content: (
        <PaymentsOverview
          balance={balance!}
          payment={payment}
          installmentDetails={
            newBusinessTransaction?.paymentPlan?.installmentDetails ?? {}
          }
        />
      ),
    },
    {
      key: 'payments-billing',
      header: <AccordionTitle>Billing</AccordionTitle>,
      content: <PaymentsBilling billings={payment.billings ?? []} />,
    },
    {
      key: 'payments-adhoc-fees',
      header: <AccordionTitle>Ad hoc fees (unaccounted for balance)</AccordionTitle>,
      content: (
        <PaymentsAdhocFees
          balance={balance!}
          policyTermCorrelationId={policy.policyTermCorrelationId!}
        />
      ),
    },
  ];

  const handleAddNewFeeSuccess = () => {
    setAlert({ message: 'New fee has been added', severity: 'success' });
    setAddNewFeeModalOpen(false);
  };

  return (
    <>
      {hasPermission('adddebt:support') && (
        <>
          <Button
            sx={{ m: 6, ml: 'auto' }}
            size="small"
            color="secondary"
            onClick={() => setAddNewFeeModalOpen(true)}
          >
            Add new fee
          </Button>
          <Divider />
        </>
      )}
      <AccordionList items={accordionItems} />
      {isAddNewFeeModalOpen && (
        <AddNewFeeModal
          onSuccess={handleAddNewFeeSuccess}
          onClose={() => setAddNewFeeModalOpen(false)}
        />
      )}
    </>
  );
}
