import { useContext } from 'react';
import { AdyenCheckoutContext } from '../components/AdyenCheckoutContextProvider';

export function useAdyenCheckoutContext() {
  const context = useContext(AdyenCheckoutContext);

  if (!context)
    throw new Error(
      'useAdyenCheckoutContext should be used within AdyenCheckoutContextProvider',
    );

  return context;
}
