import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  NewMtaCarDetailsFormSchema,
  newMtaCarDetailsFormSchema,
} from './schemas/newMtaCarDetails.schema';

type UseNewMtaCarDetailsFormProps = {
  registration: string;
};

export function useNewMtaCarDetailsForm({ registration }: UseNewMtaCarDetailsFormProps) {
  return useForm<NewMtaCarDetailsFormSchema>({
    mode: 'onChange',
    defaultValues: {
      registration,
      registrationYear: null,
      make: null,
      fuelType: null,
      transmission: null,
      model: null,
    },
    resolver: zodResolver(newMtaCarDetailsFormSchema),
  });
}
