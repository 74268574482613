import { useContext } from 'react';
import { CoverDetails } from './components/CoverDetails';
import { AddonsContent } from './components/AddonsContent';
import { EndorsementsContent } from './components/EndorsementsContent';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import {
  AccordionList,
  AccordionListItem,
  AccordionTitle,
} from '@/components/ui/lists/AccordionList';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function Cover() {
  const parentId = useContext(PolicyIdContext);
  const { isPending, isError, data: policy } = useMotorPolicyQuery(parentId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  const accordionItems: AccordionListItem[] = [
    {
      key: 'cover-details',
      header: <AccordionTitle>Details</AccordionTitle>,
      content: <CoverDetails policy={policy} data-testid="cover-details-content" />,
    },
    ...(policy.addOns
      ? [
          {
            key: 'cover-addons',
            header: <AccordionTitle>Add-ons</AccordionTitle>,
            content: (
              <AddonsContent addOns={policy.addOns} data-testid="cover-addons-content" />
            ),
          },
        ]
      : []),
    ...(policy.endorsements
      ? [
          {
            key: 'cover-endorsements',
            header: <AccordionTitle>Conditions (endorsements)</AccordionTitle>,
            content: (
              <EndorsementsContent
                endorsements={policy.endorsements}
                data-testid="cover-endorsements-content"
              />
            ),
          },
        ]
      : []),
  ];

  return <AccordionList items={accordionItems} />;
}
