import { Box } from '@mui/material';
import { HistoryDocumentItem } from './components/HistoryDocumentItem';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useDocumentsQuery } from '@/hooks/queries/useDocumentsQuery';
import { MotorPolicy } from '@/api/motorpolicy';

type HistoryDocumentsContentProps = {
  policy: MotorPolicy;
  policyVersion: number;
};

export function HistoryDocumentsContent({
  policy,
  policyVersion,
}: HistoryDocumentsContentProps) {
  const {
    isPending,
    isError,
    data: documents,
  } = useDocumentsQuery(policy.id, policyVersion);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return documents.map((document, index) => (
    <Box key={index} my={6}>
      <HistoryDocumentItem document={document} />
    </Box>
  ));
}
