import { Typography, useTheme } from '@mui/material';
import { NoteHeaderContainer, NoteHeaderRow } from '../Notes.styles';
import { getNoteTypeName } from '@/helpers/nameMappings/noteNameMappings';
import { AccordionTitle } from '@/components/ui/lists/AccordionList';
import { Note } from '@/api/note';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { StateChip, StateChipType } from '@/components/ui/presentation/StateChip';
import { ReminderStatus } from '@/api/note';

type NoteHeaderProps = {
  note: Note;
  index: number;
};

const getStateChipType = (reminderStatus: ReminderStatus | undefined): StateChipType => {
  if (!reminderStatus) {
    return StateChipType.Neutral;
  }

  switch (reminderStatus) {
    case ReminderStatus.DUE:
      return StateChipType.Warning;
    case ReminderStatus.OVER_DUE:
      return StateChipType.Error;
    case ReminderStatus.UPCOMING:
      return StateChipType.Info;
    case ReminderStatus.COMPLETE:
      return StateChipType.Success;
  }
};

const getReminderStatusLabel = (reminderStatus: ReminderStatus | undefined): string => {
  if (!reminderStatus) {
    return 'No reminder';
  }

  switch (reminderStatus) {
    case ReminderStatus.DUE:
      return 'Due';
    case ReminderStatus.OVER_DUE:
      return 'Overdue';
    case ReminderStatus.UPCOMING:
      return 'Upcoming';
    case ReminderStatus.COMPLETE:
      return 'Complete';
  }
};

export function NoteHeader({ note, index }: NoteHeaderProps) {
  const theme = useTheme();

  return (
    <NoteHeaderContainer>
      <NoteHeaderRow>
        <AccordionTitle>{`#${index}`}</AccordionTitle>
        <StateChip
          type={getStateChipType(note.reminder?.status)}
          label={getReminderStatusLabel(note.reminder?.status)}
          testId={`notes_note[${note.reminder?.id}]_status-chip`}
        />
      </NoteHeaderRow>
      <NoteHeaderRow>
        <Typography variant="body2">{getNoteTypeName(note.noteType)}</Typography>
        {note.reminder?.dateOfReminder && (
          <Typography variant="body2" color={theme.palette.grey[500]}>
            {`Reminder due ${formatDate(note.reminder.dateOfReminder)}`}
          </Typography>
        )}
      </NoteHeaderRow>
      <NoteHeaderRow>
        <Typography variant="body2" color={theme.palette.grey[500]}>
          {note.summary}
        </Typography>
      </NoteHeaderRow>
    </NoteHeaderContainer>
  );
}
