import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { isFuture } from 'date-fns';
import { zodResolver } from '@hookform/resolvers/zod';
import { validationMessages } from '@/helpers/utils/textHelpers';

const changeDateFormSchema = z.object({
  dueDate: z
    .nullable(z.date())
    .refine(value => Boolean(value), validationMessages.required)
    .refine(value => !value || isFuture(value), 'Date has to be in the future'),
});

export type ChangeDateFormSchema = z.infer<typeof changeDateFormSchema>;

export function useChangeDateForm() {
  return useForm({
    defaultValues: {
      dueDate: null,
    },
    resolver: zodResolver(changeDateFormSchema),
  });
}
