import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormContext } from 'react-hook-form';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { CancelPolicyFormSchema } from '@/features/Actions/hooks/useCancelPolicyForm';

type CancelPolicyFormFooterProps = {
  isPending: boolean;
};

export function CancelPolicyFormFooter({ isPending }: CancelPolicyFormFooterProps) {
  const { reset } = useFormContext<CancelPolicyFormSchema>();
  return (
    <StyledButtonsFooter sticky>
      <LoadingButton
        type="submit"
        variant="contained"
        color="secondary"
        loading={isPending}
      >
        Calculate payment
      </LoadingButton>
      <Button variant="outlined" color="inherit" onClick={() => reset()}>
        Reset form
      </Button>
    </StyledButtonsFooter>
  );
}
