import { Divider } from '@mui/material';
import { useContext, useState } from 'react';
import { useDriverContext } from '../../hooks/useDriverContext';
import {
  VulnerabilityForm,
  VulnerabilityFormProps,
  transformAddVulnerabilityFormData,
  transformUpdateVulnerabilityFormData,
} from '../VulnerabilityForm';
import { VulnerabilitiesList } from '../VulnerabilitiesList';
import { mapRequestToFormData } from '../VulnerabilityForm/helpers/mapRequestToFormData';
import { VulnerabilityFormDefaultValues } from '../../hooks/useVulnerabilitiesForm';
import { VulnerabilityDeleteModal } from '../VulnerabilityDeleteModal';
import { useCreateVulnerabilityMutation } from '@/hooks/mutations/useCreateVulnerabilityMutation';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { CustomerVulnerability } from '@/api/vulnerabilities';
import { useEditVulnerabilityMutation } from '@/hooks/mutations/useEditVulnerabilityMutation';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { useDeleteVulnerabilityMutation } from '@/hooks/mutations/useDeleteVulnerabilityMutation';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { useSetAlertError } from '@/hooks/useSetAlertError';

type VulnerabilitiesContentProps = {
  vulnerabilities: {
    isPending: boolean;
    isError: boolean;
    data: CustomerVulnerability[];
  };
};

export function VulnerabilitiesContent({ vulnerabilities }: VulnerabilitiesContentProps) {
  const driver = useDriverContext();
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const [shouldShowVulnerabilityForm, setShouldShowVulnerabilityForm] = useState(false);
  const [editedVulnerabilityIndex, setEditedVulnerabilityIndex] = useState<number | null>(
    null,
  );
  const [editedVulnerability, setEditedVulnerability] =
    useState<VulnerabilityFormDefaultValues | null>(null);
  const [vulnerabilityToBeDeleted, setVulnerabilityToBeDeleted] =
    useState<CustomerVulnerability | null>();
  const { setAlert } = useSnackbarAlert();

  const toggleShouldShowVulnerabilityForm = () =>
    setShouldShowVulnerabilityForm(!shouldShowVulnerabilityForm);

  const {
    mutate: createVulnerability,
    isPending: isCreateVulnerabilityPending,
    isError: isCreateVulnerabilityError,
  } = useCreateVulnerabilityMutation({
    onSuccess: () => {
      setAlert({ message: 'New vulnerability added', severity: 'success' });
      toggleShouldShowVulnerabilityForm();
    },
  });

  const {
    mutate: editVulnerability,
    isPending: isEditVulnerabilityPending,
    isError: isEditVulnerabilityError,
  } = useEditVulnerabilityMutation({
    onSuccess: () => {
      setAlert({ message: 'Vulnerability edited', severity: 'success' });
      toggleShouldShowVulnerabilityForm();
      setEditedVulnerability(null);
      setEditedVulnerabilityIndex(null);
    },
  });

  const {
    mutate: deleteVulnerability,
    isPending: isDeleteVulnerabilityPending,
    isError: isDeleteVulnerabilityError,
  } = useDeleteVulnerabilityMutation({
    onSuccess: () => {
      setVulnerabilityToBeDeleted(null);
      setAlert({ message: 'Vulnerability deleted', severity: 'success' });
    },
  });

  const isFormError = isCreateVulnerabilityError || isEditVulnerabilityError;
  const isFormPending = isCreateVulnerabilityPending || isEditVulnerabilityPending;

  const handleEditVulnerability = (
    vulnerability: CustomerVulnerability,
    index: number,
  ) => {
    setEditedVulnerabilityIndex(index);
    setEditedVulnerability({
      ...mapRequestToFormData(vulnerability),
    });
    toggleShouldShowVulnerabilityForm();
  };

  const handleDeleteVulnerability = () => {
    if (vulnerabilityToBeDeleted) {
      deleteVulnerability({
        policyId,
        vulnerabilityId: vulnerabilityToBeDeleted.id!,
        noteId: vulnerabilityToBeDeleted.noteId ?? null,
      });
    }
  };

  const handleCancelChanges = () => {
    toggleShouldShowVulnerabilityForm();
    setEditedVulnerability(null);
    setEditedVulnerabilityIndex(null);
  };

  const driverVulnerabilities = vulnerabilities.data.filter(
    ({ driverId }) => driverId === driver.id,
  );

  const handleFormSubmit: VulnerabilityFormProps['onSubmit'] = data => {
    if (editedVulnerability) {
      const requestData = transformUpdateVulnerabilityFormData(data);
      editVulnerability({ id: editedVulnerability._id, data: requestData });
    } else {
      const requestData = transformAddVulnerabilityFormData({
        driverId: driver.id,
        policyId,
        policyCorrelationId: policy?.policyCorrelationId ?? '',
        ...data,
      });
      createVulnerability(requestData);
    }
  };

  useSetAlertError(isFormError);

  if (vulnerabilities.isError) return <DrawerError />;
  if (vulnerabilities.isPending) return <DrawerLoading />;

  return (
    <>
      <Divider sx={{ mx: 6 }} />
      {shouldShowVulnerabilityForm ? (
        <VulnerabilityForm
          defaultValues={editedVulnerability ?? undefined}
          vulnerabilityNumber={
            ((editedVulnerabilityIndex ?? driverVulnerabilities?.length) || 0) + 1
          }
          onCancel={handleCancelChanges}
          onSubmit={handleFormSubmit}
          isPending={isFormPending}
        />
      ) : (
        <VulnerabilitiesList
          items={driverVulnerabilities}
          onEditVulnerability={handleEditVulnerability}
          onDeleteVulnerability={setVulnerabilityToBeDeleted}
          onAddNewVulnerability={toggleShouldShowVulnerabilityForm}
        />
      )}
      {Boolean(vulnerabilityToBeDeleted) && (
        <VulnerabilityDeleteModal
          isError={isDeleteVulnerabilityError}
          isPending={isDeleteVulnerabilityPending}
          onConfirm={handleDeleteVulnerability}
          onCancel={() => setVulnerabilityToBeDeleted(null)}
        />
      )}
    </>
  );
}
