import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { OptionCode } from '@/api/retail/v2';

type UseOccupationsQueryProps = {
  productName?: string;
  productVersion?: string;
};

export function useOccupationsQuery(
  { productName = 'motor', productVersion = 'latest' }: UseOccupationsQueryProps,
  options?: Omit<UseQueryOptions<OptionCode[]>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();
  const fetchOccupations = () =>
    api.retailV2.product.getProductOccupations({ productName, productVersion });

  return useQuery({
    queryKey: queryKeys.occupations(productName, productVersion),
    queryFn: fetchOccupations,
    ...options,
  });
}
