import { ApiError } from '@/api/core/ApiError';

export const GENERAL_ERROR_MSG = 'Something went wrong. Please try again later on.';

const isApiError = (error: unknown): error is ApiError => !!(error as ApiError)?.body;

export const getApiErrorMessage = (
  error: ApiError | unknown,
  errorCodeMessages?: Record<string, string>,
): string => {
  if (!isApiError(error) || error.status === 500) {
    return GENERAL_ERROR_MSG;
  }

  if (typeof error.body === 'string' && error.body.trim().length > 0) {
    return error.body;
  }

  if (typeof error.body === 'object' && errorCodeMessages) {
    const matchedErrorCode = Object.keys(error.body).find(
      code => errorCodeMessages[code],
    );
    if (matchedErrorCode) {
      return errorCodeMessages[matchedErrorCode];
    }
  }

  return GENERAL_ERROR_MSG;
};
