import {
  UseStoredPaymentsQueryProps,
  useStoredPaymentsQuery,
} from '@/hooks/queries/useStoredPaymentsQuery';

type UseSelectedPreviouslyUsedPaymentMethodProps = {
  storedPaymentMethodId: string;
} & UseStoredPaymentsQueryProps;

export function useSelectedPreviouslyUsedPaymentMethod({
  storedPaymentMethodId,
  shopperReference,
}: UseSelectedPreviouslyUsedPaymentMethodProps) {
  const { data: storedPaymentQueryData } = useStoredPaymentsQuery({ shopperReference });

  return storedPaymentQueryData?.storedPaymentMethods?.find(
    storedPaymentMethod => storedPaymentMethod.id === storedPaymentMethodId,
  );
}
