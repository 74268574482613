import { Box, Button, Divider } from '@mui/material';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { NoteFormSubmitData } from '../hooks/note.schema';
import { useNoteForm } from '../hooks/useNoteForm';
import { NoteAttachmentFormSubmitData } from '../hooks/useNoteAttachmentForm';
import { AddAttachmentModal } from './AddAttachmentModal';
import { NoteFormBasicFields } from './NoteFormBasicFields';
import { NoteFormAttachments } from './NoteFormAttachments';
import { NoteFormReminderFields } from './NoteFormReminderFields';
import { Note } from '@/api/note';
import { LeavePagePrompt } from '@/components/ui/modals/LeavePagePrompt';
import { StyledDrawerForm } from '@/components/ui/styles/StyledDrawerForm';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

type NoteFormProps = {
  isSubmitPending: boolean;
  submitButtonText: string;
  cancelButtonText: string;
  defaultNote?: Note;
  shouldResetOnCancel?: boolean;
  onCancel?: () => void;
  onSubmit: (data: NoteFormSubmitData) => void;
};

export function NoteForm({
  isSubmitPending,
  submitButtonText,
  cancelButtonText,
  defaultNote,
  shouldResetOnCancel = true,
  onCancel,
  onSubmit,
}: NoteFormProps) {
  const [isAddAttachmentModalOpen, setAddAttachmentModalOpen] = useState(false);

  const form = useNoteForm(defaultNote);
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = form;
  const { append: appendNewAttachment } = useFieldArray({
    control,
    name: 'newAttachments',
  });

  const handleAddAttachment = (attachment: NoteAttachmentFormSubmitData) => {
    appendNewAttachment(attachment);
    setAddAttachmentModalOpen(false);
  };

  const handleCancel = () => {
    if (shouldResetOnCancel) {
      reset();
    }
    onCancel?.();
  };

  return (
    <FormProvider {...form}>
      <LeavePagePrompt when={isDirty && !isSubmitPending} />
      {isAddAttachmentModalOpen && (
        <AddAttachmentModal
          onSubmit={handleAddAttachment}
          onClose={() => setAddAttachmentModalOpen(false)}
        />
      )}
      <StyledDrawerForm
        onSubmit={handleSubmit(data => onSubmit(data as NoteFormSubmitData))}
      >
        <Box height="100%" m={6}>
          <NoteFormBasicFields />
          <Divider sx={{ my: 6 }} />
          <NoteFormAttachments onAddAttachment={() => setAddAttachmentModalOpen(true)} />
          <Divider sx={{ my: 6 }} />
          <NoteFormReminderFields />
        </Box>
        <StyledButtonsFooter sticky>
          <LoadingButton
            data-testid="notes_form-submitbutton"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isSubmitPending}
          >
            {submitButtonText}
          </LoadingButton>
          <Button
            data-testid="notes_cancel-button"
            variant="outlined"
            color="inherit"
            onClick={handleCancel}
          >
            {cancelButtonText}
          </Button>
        </StyledButtonsFooter>
      </StyledDrawerForm>
    </FormProvider>
  );
}
