import { z } from 'zod';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { YesNo } from '@/models/YesNo';

export function validateOtherAmountToPay(
  formFields: { _balance: number; payInFull: YesNo | null; otherAmountToPay: string },
  ctx: z.RefinementCtx,
) {
  if (formFields.payInFull === YesNo.No) {
    if (!formFields.otherAmountToPay) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['otherAmountToPay'],
        message: validationMessages.required,
      });
    }

    const minValue = 0.01;
    if (Number(formFields.otherAmountToPay) < minValue) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['otherAmountToPay'],
        message: validationMessages.minValue(minValue),
      });
    }

    const maxValue = formFields._balance;
    if (Number(formFields.otherAmountToPay) > maxValue) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['otherAmountToPay'],
        message: validationMessages.maxValue(maxValue),
      });
    }
  }
}
