import { Button, List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { Billing } from '@/api/support';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { getTransactionStatusName } from '@/helpers/nameMappings/transactionNameMappings';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { Modal } from '@/components/ui/modals/Modal';

type BillingDetailsModalProps = {
  billing: Billing | null;
  onClose: () => void;
};

export function BillingDetailsModal({ billing, onClose }: BillingDetailsModalProps) {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>{billing?.collectionMethod}</Modal.Header>
      <Modal.Content>
        <List>
          {billing?.settlementProvider && (
            <ListRow disablePadding label="Settlement provider">
              {billing.settlementProvider}
            </ListRow>
          )}
          {billing?.cardType && (
            <ListRow
              disablePadding
              label="Card type"
              sx={{ textTransform: 'capitalize' }}
            >
              {billing.cardType}
            </ListRow>
          )}
          {billing?.cardNumber && (
            <ListRow disablePadding label="Card number">
              {`**** **** **** **** ${billing.cardNumber}`}
            </ListRow>
          )}
          {billing?.cardExpires && (
            <ListRow disablePadding label="Card expires">
              {billing.cardExpires}
            </ListRow>
          )}
          {billing?.cardTransactionId && (
            <ListRow disablePadding label="Card transaction ID">
              {billing.cardTransactionId}
            </ListRow>
          )}
          {billing?.merchantServiceAccount && (
            <ListRow disablePadding label="Merchant service account">
              {billing.merchantServiceAccount}
            </ListRow>
          )}
          {billing?.dueDate && (
            <ListRow disablePadding label="Due date">
              {formatDate(billing.dueDate)}
            </ListRow>
          )}
          {billing?.originalCollectionDate && (
            <ListRow disablePadding label="Original collection date">
              {formatDate(billing.originalCollectionDate)}
            </ListRow>
          )}
          {billing?.collectionDate && (
            <ListRow disablePadding label="Collection date">
              {formatDate(billing.collectionDate)}
            </ListRow>
          )}
          {billing?.paymentReference && (
            <ListRow disablePadding label="Payment reference">
              {billing.paymentReference}
            </ListRow>
          )}
          {billing?.collectionMethod && (
            <ListRow disablePadding label="Collection method">
              {billing.collectionMethod}
            </ListRow>
          )}
          {billing?.originalCollectionAmount != null && (
            <ListRow disablePadding label="Original collection amount">
              {formatCurrency(billing.originalCollectionAmount)}
            </ListRow>
          )}
          {billing?.collectionAmount != null && (
            <ListRow disablePadding label="Collection amount">
              {formatCurrency(billing.collectionAmount)}
            </ListRow>
          )}
          {billing?.paymentStatus && (
            <ListRow disablePadding label="Payment status">
              {getTransactionStatusName(billing.paymentStatus)}
            </ListRow>
          )}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
