import { Button, List, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ListRow } from '@/components/ui/lists/ListRow';
import { NewMtaFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';

type PolicyholderHomeAddressViewProps = {
  onEdit: () => void;
};

export function PolicyholderHomeAddressView({
  onEdit,
}: PolicyholderHomeAddressViewProps) {
  const { getFieldName, nestIndex } = useNewMtaDriverContext();
  const {
    formState: { dirtyFields },
    watch,
  } = useFormContext<NewMtaFormSchema>();
  const addressOneLine = watch(getFieldName('_fullAddress'));
  const formattedAddress = watch(getFieldName('formattedAddress'));
  const postOfficeAddress = watch(getFieldName('postOfficeAddress'));
  const driverDirtyFields = dirtyFields.drivers?.[nestIndex] ?? {};
  const isNewAddress =
    Object.hasOwn(driverDirtyFields, '_fullAddress') ||
    Object.hasOwn(driverDirtyFields, 'formattedAddress');

  const heading = isNewAddress ? 'New address' : 'Current address';

  return (
    <Stack direction="column">
      <Typography variant="subtitle2">{heading}</Typography>
      <List>
        <ListRow disablePadding label="Postcode">
          {formattedAddress?.postCode ?? postOfficeAddress.postCode}
        </ListRow>
        <ListRow disablePadding label="Address">
          {addressOneLine}
        </ListRow>
      </List>
      <Button size="small" onClick={onEdit} sx={{ mt: 2, alignSelf: 'flex-start' }}>
        Edit
      </Button>
    </Stack>
  );
}
