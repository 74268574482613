import type { AddressDetailsIAPIResult } from '../models/AddressDetailsIAPIResult';
import type { AddressV1ListIAPIResult } from '../models/AddressV1ListIAPIResult';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class AddressService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getAddress({
    postcode,
    houseNumber,
  }: {
    postcode: string;
    houseNumber?: string;
  }): CancelablePromise<AddressV1ListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/address/{postcode}',
      path: {
        postcode: postcode,
      },
      query: {
        houseNumber: houseNumber,
      },
    });
  }

  getAddressDetail({
    postkey,
  }: {
    postkey: string;
  }): CancelablePromise<AddressDetailsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/address/{postkey}/detail',
      path: {
        postkey: postkey,
      },
    });
  }
}
