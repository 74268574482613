import { AxiosHttpRequest } from './AxiosHttpRequest';
import { BaseHttpRequest } from './BaseHttpRequest';
import { OpenAPIConfig } from './OpenAPI';

export abstract class BaseAPI {
  readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>) {
    this.request = new AxiosHttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
  }
}
