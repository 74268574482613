import { DRAWER_MENU_ITEMS } from '../components/PolicyDrawerMenuItems';
import { usePolicyDrawerParams } from './usePolicyDrawerParams';
import { MotorPolicy } from '@/api/motorpolicy';
import { PolicyMenuItemKey } from '@/features/Policies/policyTypes';
import { useUser } from '@/hooks/useUser';
import {
  getIsPolicyInactive,
  getIsPolicyWithUpcomingMta,
} from '@/helpers/data/policyHelpers';

export function usePolicyDrawerMenuItems(policy: MotorPolicy | undefined) {
  const { selectedTab, selectedSection, updateSectionParam } = usePolicyDrawerParams();
  const { hasPermissions } = useUser();

  const getAvailableMenuItems = () => {
    return DRAWER_MENU_ITEMS.filter(
      menuItem => !menuItem.permissions || hasPermissions(menuItem.permissions),
    );
  };

  const getDisabledItems = () => {
    if (!policy) {
      return [];
    }

    if (getIsPolicyInactive(policy) || getIsPolicyWithUpcomingMta(policy)) {
      return [PolicyMenuItemKey.NewMta];
    }

    return [];
  };

  const getMenuItems = () => {
    const availableMenuItems = getAvailableMenuItems();
    const disabledItems = getDisabledItems();
    return availableMenuItems.map(item => ({
      ...item,
      disabled: disabledItems.includes(item.key),
    }));
  };

  const menuItems = getMenuItems();
  const selectedMenuItem = menuItems.find(menuItem => menuItem.key === selectedSection)!;

  return {
    menuItems,
    selectedTab,
    selectedMenuItem,
    handleMenuItemChange: updateSectionParam,
  };
}
