import { Children, isValidElement, ReactNode, useState } from 'react';
import { QuoteCalculationCreateOrderPrompt } from '../components/QuoteCalculationCreateOrderPrompt';
import { getChildrenByName } from '@/helpers/utils/componentHelpers';

function getPromptsFromChildren(children: ReactNode): ReactNode[] {
  const fragment = Children.toArray(children)[0];
  const childrenPrompts = isValidElement(fragment) && fragment.props.children;
  return childrenPrompts
    ? getChildrenByName(childrenPrompts, QuoteCalculationCreateOrderPrompt.name)
    : [];
}

export function useCreateOrderPrompts(children: ReactNode) {
  const [currentPromptIndex, setCurrentPromptIndex] = useState(-1);
  const [areAllPromptsConfirmed, setAreAllPromptsConfirmed] = useState(false);
  const prompts = getPromptsFromChildren(children);
  const currentPrompt = prompts[currentPromptIndex];

  const displayNextPrompt = () => {
    if (prompts[currentPromptIndex + 1]) {
      setCurrentPromptIndex(currentPromptIndex + 1);
    } else {
      setCurrentPromptIndex(-1);
      setAreAllPromptsConfirmed(true);
    }
  };

  const resetPrompt = () => {
    setCurrentPromptIndex(-1);
    setAreAllPromptsConfirmed(false);
  };

  return {
    currentPrompt,
    areAllPromptsConfirmed,
    displayNextPrompt,
    resetPrompt,
  };
}
