import phone from 'phone';
import { isValidPhoneNumber as validatePhoneNumber } from 'libphonenumber-js';

const isValidPhoneNumber = (contactNumber: string): boolean =>
  phone(contactNumber, { country: 'GB' }).isValid;

const isValidLandlineNumber = (contactNumber: string): boolean =>
  validatePhoneNumber(contactNumber, 'GB');

export { isValidPhoneNumber, isValidLandlineNumber };
