import { useState } from 'react';
import { FileDetail } from '@/components/ui/presentation/FileDetail';
import {
  getAttachmentReceivingMethodName,
  getAttachmentTypeName,
} from '@/helpers/nameMappings/attachmentNameMappings';
import { useNoteAttachmentQuery } from '@/hooks/queries/useNoteAttachmentQuery';
import { Attachment } from '@/api/note';

type NoteDetailsAttachmentProps = {
  attachment: Attachment;
};

export function NoteDetailsAttachment({ attachment }: NoteDetailsAttachmentProps) {
  const [isContentRequest, setContentRequested] = useState(false);
  const { data: attachmentContent } = useNoteAttachmentQuery({
    filePath: attachment.filePath!,
    enabled: isContentRequest,
  });

  const receivingMethodText = `Received via ${getAttachmentReceivingMethodName(
    attachment.receivingMethod,
  ).toLowerCase()}`;

  return (
    <FileDetail
      key={attachment.id}
      title={attachment.fileName ?? 'Unknown file name'}
      description1={getAttachmentTypeName(attachment.type)}
      description2={receivingMethodText}
      fileName={attachment.fileName ?? ''}
      content={attachmentContent ?? null}
      onContentRequest={() => setContentRequested(true)}
    />
  );
}
