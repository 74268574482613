import { sumArray } from '../utils/arrayHelpers';
import { MotorQuote } from '@/api/motorquote';

export type QuoteResult = 'charge' | 'refund' | 'no_charge_or_refund';

export const getQuoteResult = (price: number | null): QuoteResult => {
  if (price != null && price > 0) return 'charge';
  if (price != null && price < 0) return 'refund';

  return 'no_charge_or_refund';
};

export const getSelectedAddOnByCode = (quote: MotorQuote, addOnCode: string) =>
  quote.addOns?.find(addOn => addOn.selected && addOn.type?.code === addOnCode);

export const sumSelectedAddOnsPremium = (quote: MotorQuote): number =>
  sumArray(
    (quote.addOns ?? [])
      .filter(addOn => addOn.selected)
      .map(addOn => addOn.price?.netPremium?.amount ?? 0),
  );

export const sumSelectedAddOnsPremiumTax = (quote: MotorQuote): number =>
  sumArray(
    (quote.addOns ?? [])
      .filter(addOn => addOn.selected)
      .map(addOn => addOn.price?.insurancePremiumTax?.amount ?? 0),
  );
