import { Tab } from '@mui/material';
import { TabList } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { NotesTab } from '../notesTypes';
import { PolicyQueryParams } from '@/features/Policies/policyTypes';
import { useUser } from '@/hooks/useUser';

export function NotesTabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasPermissions } = useUser();

  const handleChange = (_event: React.SyntheticEvent, newTab: NotesTab) => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams),
        [PolicyQueryParams.Tab]: newTab,
      }),
    );
  };

  return (
    <TabList onChange={handleChange}>
      <Tab value={NotesTab.List} label="Previous" />
      {hasPermissions(['readany:note', 'create:note']) && (
        <Tab value={NotesTab.Add} label="Add" />
      )}
    </TabList>
  );
}
