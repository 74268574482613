import { Alert, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';
import { Modal } from '@/components/ui/modals/Modal';

type VulnerabilityDeleteModalProps = {
  isError: boolean;
  isPending: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function VulnerabilityDeleteModal({
  isError,
  isPending,
  onConfirm,
  onCancel,
}: VulnerabilityDeleteModalProps) {
  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Delete vulnerability</Modal.Header>
      <Modal.Content>
        {isError && <Alert severity="error">{GENERAL_ERROR_MSG}</Alert>}
        <Alert severity="warning">You are about to delete this vulnerability</Alert>
        <Typography variant="body2">
          Please confirm to delete this vulnerability.
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={isPending}
          onClick={onConfirm}
        >
          Confirm
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
