import { COMPREHENSIVE_COVER_TYPE_CODE, NewMtaFormSchema } from '../../hooks';
import { transformToOptionCode } from './mapFormValuesTemporaryHelpers';
import { ExcessNames } from '@/helpers/nameMappings/excessNameMappings';
import { hasAnsweredYes } from '@/helpers/utils/formHelpers';

export const mapCoverFormValues = (
  cover: NewMtaFormSchema['cover'],
  dirtyFields: Partial<NewMtaFormSchema>,
) => {
  return {
    ...(dirtyFields.cover?.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled
      ? {
          hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: hasAnsweredYes(
            cover.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled,
          ),
        }
      : {}),
    ...(dirtyFields.cover?.type
      ? { cover: { type: transformToOptionCode(cover.type) } }
      : {}),
    ...(dirtyFields.cover?.voluntaryExcessAmount &&
    cover.type === COMPREHENSIVE_COVER_TYPE_CODE
      ? {
          excesses: {
            voluntaryAmounts: [
              {
                total: cover.voluntaryExcessAmount,
                type: { code: ExcessNames.PolicyXs },
              },
            ],
          },
        }
      : {}),
  };
};
