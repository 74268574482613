import { ForwardedRef, PropsWithChildren, forwardRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { ContentContainer, RotateBox, ScaleBox } from './FileDetailContent.styles';
import { useCalculateContentPosition } from '@/components/ui/presentation/FileDetail/hooks/useCalculateContentPosition';

type FileDetailContentProps = PropsWithChildren<{
  rotate?: number;
  scale?: number;
}>;

export const FileDetailContent = forwardRef(function FileDetailContent(
  { rotate, scale, children }: FileDetailContentProps,
  ref: ForwardedRef<HTMLDivElement | null>,
) {
  const { containerRef, contentRef, positionRef } = useCalculateContentPosition({
    rotate,
    scale,
  });

  return (
    <ContentContainer ref={mergeRefs([containerRef, ref])}>
      <ScaleBox
        data-testid="file-detail-content-scale"
        data-scale={scale}
        ref={positionRef}
        scale={scale}
      >
        <RotateBox
          data-testid="file-detail-content-rotate"
          data-rotate={rotate}
          ref={contentRef}
          rotate={rotate}
        >
          {children}
        </RotateBox>
      </ScaleBox>
    </ContentContainer>
  );
});
