import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { GetDebtResponse } from '@/api/support';

export function useUnsettledBalanceQuery(
  policyId: string,
  options?: Omit<UseQueryOptions<GetDebtResponse>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();
  const fetchUnsettledBalance = () => api.support.payments.getPaymentsDebt({ policyId });

  return useQuery({
    queryKey: queryKeys.unsettledBalance(policyId),
    queryFn: fetchUnsettledBalance,
    ...options,
  });
}
