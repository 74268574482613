import { PolicyholderDriverSchema } from '../../hooks';
import { transformToOptionCode } from './mapFormValuesTemporaryHelpers';
import { CreateSupportMtaRequest } from '@/api/motorpolicy';
import { getDateOnly } from '@/helpers/utils/dateHelpers';
import { hasAnsweredYes } from '@/helpers/utils/formHelpers';
import { MotorPolicy } from '@/api/motorpolicy';

export const mapPolicyholderFormValues = (
  policyholder: PolicyholderDriverSchema,
  policy: MotorPolicy,
): CreateSupportMtaRequest['policyholder'] => {
  const getAddress = () => {
    if (policyholder.formattedAddress) {
      return {
        formattedAddress: policyholder.formattedAddress,
      };
    }
    return {
      postOfficeAddress: policyholder.postOfficeAddress,
      formattedAddress: {},
    };
  };

  return {
    firstName: policyholder.firstName,
    lastName: policyholder.surname,
    email: policy.policyholder.email,
    address: getAddress(),
    telephoneNumber: policy.policyholder.telephoneNumber,
    title: transformToOptionCode(policyholder.title),
    maritalStatus: transformToOptionCode(policyholder.maritalStatus),
    dateOfBirth: getDateOnly(policyholder.dateOfBirth!),
    isHomeOwner: hasAnsweredYes(policyholder.isHomeOwner),
    childrenUnder16InHousehold: Number(policyholder.childrenUnder16InHousehold),
    numberOfVehiclesInHousehold: Number(policyholder.numberOfVehiclesInHousehold),
  };
};
