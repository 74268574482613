import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import { OptionCode } from '@/api/retail/v2';

type NewMtaCarModItem = {
  mod: OptionCode;
  modNo: number;
  sx: SxProps;
  onRemove: () => void;
};

export function NewMtaCarModItem({ mod, modNo, sx, onRemove }: NewMtaCarModItem) {
  return (
    <Box component="section" aria-label={`Modification #${modNo}`} sx={sx}>
      <Stack my={1.5} gap={3} justifyContent="space-between">
        <Typography variant="body2" sx={{ flex: '0 0 auto' }}>
          Modification #{modNo}
        </Typography>
        <Typography
          variant="body2"
          textAlign="right"
          color={theme => theme.palette.grey[500]}
        >
          {mod.description}
        </Typography>
      </Stack>
      <Button
        size="small"
        aria-label={`Remove #${modNo} modification`}
        onClick={onRemove}
        sx={{ ml: 2 }}
      >
        Remove
      </Button>
    </Box>
  );
}
