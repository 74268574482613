import { Divider, List } from '@mui/material';
import { useDriverContext } from '../../hooks/useDriverContext';
import { ListRow } from '@/components/ui/lists/ListRow';
import { formatDate, formatDateOnly } from '@/helpers/utils/dateHelpers';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { sortArrayByBooleanKey } from '@/helpers/utils/arrayHelpers';
import { Policyholder } from '@/api/motorpolicy';
import { getIsStudent } from '@/helpers/data/driverHelpers';

type DetailsContentProps = {
  policyholder: Policyholder;
};

export function DetailsContent({ policyholder }: DetailsContentProps) {
  const driver = useDriverContext();
  const sortedOccupations = sortArrayByBooleanKey(driver.occupations, 'isPrimary');
  const isStudent = getIsStudent(driver);

  return (
    <>
      <Divider sx={{ mt: -1 }} />
      <List>
        {!driver.isPolicyholder && (
          <ListRow label="Relation to policyholder">
            {driver.relationshipToPolicyholder?.description}
          </ListRow>
        )}
        <ListRow label="Title">{driver.title?.description}</ListRow>
        <ListRow label="First name">{driver.firstName}</ListRow>
        <ListRow label="Last name">{driver.lastName}</ListRow>
        <ListRow label="Date of birth">{formatDate(driver.dateOfBirth)}</ListRow>
        <ListRow label="Marital status">{driver.maritalStatus?.description}</ListRow>
        <ListRow label="UK resident since birth">
          {booleanAnswer(driver.ukResidencyDetails?.isUkResidentSinceBirth)}
        </ListRow>
        {driver.ukResidencyDetails?.ukResidentSince && (
          <ListRow label="UK resident since">
            {formatDateOnly(driver.ukResidencyDetails.ukResidentSince)}
          </ListRow>
        )}
      </List>
      <Divider />
      <List>
        <ListRow label="Employment status">
          {sortedOccupations[0].employmentStatus?.description}
        </ListRow>
        {isStudent ? (
          <ListRow label="Student type">{sortedOccupations[0].type?.description}</ListRow>
        ) : (
          <>
            <ListRow label="Job title">{sortedOccupations[0].type?.description}</ListRow>
            <ListRow label="Job industry">
              {sortedOccupations[0].industry?.description}
            </ListRow>
          </>
        )}
        <ListRow label="Any other jobs">
          {booleanAnswer(sortedOccupations.length > 1)}
        </ListRow>
        <ListRow label="Secondary job title">
          {sortedOccupations[1]?.type?.description}
        </ListRow>
        <ListRow label="Secondary job industry">
          {sortedOccupations[1]?.industry?.description}
        </ListRow>
      </List>
      {driver.isPolicyholder && (
        <>
          <Divider />
          <List>
            <ListRow label="Email">{policyholder.email}</ListRow>
            <ListRow label="Telephone number">
              {policyholder.telephoneNumber?.landline}
            </ListRow>
            <ListRow label="Mobile number">
              {policyholder.telephoneNumber?.mobile}
            </ListRow>
          </List>
        </>
      )}
    </>
  );
}
