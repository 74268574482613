import { StackProps, Stack, styled, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormAdyenTextField, StyledAdyenInputWrapper } from './FormAdyenTextField';
import { FieldError } from './FormTextField/components/FieldError';
import { useAdyenCheckoutContext } from '@/features/PaymentActions/CardPaymentModal/hooks/useAdyenCheckoutContext';

type FormAdyenDateFieldProps = {
  monthFieldName: string;
  yearFieldName: string;
  sx?: StackProps['sx'];
};

const StyledFormAdyenDateField = styled(StyledAdyenInputWrapper)`
  display: flex;

  .adyen-text-field {
    .styled-adyen-input-wrapper {
      border: none;

      border-radius: ${({ theme }) =>
        `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`};
    }

    &:nth-of-type(2) {
      border-left: ${({ theme }) => `1px solid ${theme.palette.grey[400]}`};

      .styled-adyen-input-wrapper {
        border-radius: ${({ theme }) =>
          `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`};
      }
    }
  }
`;

export function FormAdyenDateField({
  monthFieldName,
  yearFieldName,
  sx,
}: FormAdyenDateFieldProps) {
  const { focusedField } = useAdyenCheckoutContext();
  const {
    formState: { errors },
  } = useFormContext();
  const isFocused = focusedField === monthFieldName || focusedField === yearFieldName;
  const monthFieldErrorMessage = errors[monthFieldName]?.message?.toString();
  const yearFieldErrorMessage = errors[yearFieldName]?.message?.toString();

  return (
    <Stack sx={sx} direction="column" gap={2}>
      <Typography variant="body2">Expiry date</Typography>
      <Stack direction="column" gap={1}>
        <StyledFormAdyenDateField
          $isFocused={isFocused}
          $isError={!!monthFieldErrorMessage || !!yearFieldErrorMessage}
        >
          <FormAdyenTextField name={monthFieldName} hideError />
          <FormAdyenTextField name={yearFieldName} hideError />
        </StyledFormAdyenDateField>
        <Stack justifyContent="space-between">
          {monthFieldErrorMessage ? (
            <FieldError>{monthFieldErrorMessage}</FieldError>
          ) : null}
          {yearFieldErrorMessage ? (
            <FieldError>{yearFieldErrorMessage}</FieldError>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}
