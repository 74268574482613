import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { AttachmentReceivingMethod, AttachmentType } from '@/api/note';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { NonNullableFields } from '@/helpers/utils/typesHelpers';

export const ALLOWED_FILE_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'heic',
  'mp4',
  'txt',
  'pdf',
  'doc',
  'docx',
  'odt',
  'dot',
];
const MAX_FILE_SIZE_MB = 3;
const MAX_VIDEO_FILE_SIZE_MB = 50;

const convertMbToBytes = (mb: number) => mb * 1024 * 1024;
const getMaxFileSizeMb = (fileType: string) =>
  fileType.includes('video') ? MAX_VIDEO_FILE_SIZE_MB : MAX_FILE_SIZE_MB;
const getFileExtension = (fileName: string) =>
  fileName.split('.').pop()?.toLowerCase() ?? '';

const validateFileSize = (file: File | null | undefined) =>
  !file || file.size <= convertMbToBytes(getMaxFileSizeMb(file.type));
const getValidateFileSizeMsg = (file: File | null | undefined): { message: string } => ({
  message: file ? `Maximum file size is ${getMaxFileSizeMb(file.type)}MB` : '',
});

const validateFileExtension = (file: File | null | undefined) =>
  !file || ALLOWED_FILE_EXTENSIONS.includes(getFileExtension(file.name));
const validateFileExtensionMsg = `Invalid file type. Allowed file extensions are ${ALLOWED_FILE_EXTENSIONS.join(', ')}`;

const noteAttachmentFormSchema = z.object({
  file: z
    .instanceof(File)
    .nullish()
    .refine(value => Boolean(value), validationMessages.required)
    .refine(validateFileSize, getValidateFileSizeMsg)
    .refine(validateFileExtension, validateFileExtensionMsg),
  type: z
    .nativeEnum(AttachmentType)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  receivingMethod: z
    .nativeEnum(AttachmentReceivingMethod)
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
});

export type NoteAttachmentFormSchema = z.infer<typeof noteAttachmentFormSchema>;
export type NoteAttachmentFormSubmitData = NonNullableFields<NoteAttachmentFormSchema>;

export function useNoteAttachmentForm() {
  return useForm<NoteAttachmentFormSchema>({
    defaultValues: {
      file: undefined,
      type: null,
      receivingMethod: null,
    },
    resolver: zodResolver(noteAttachmentFormSchema),
  });
}
