import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { StringListIAPIResult, VehicleType } from '@/api/retail/v1';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseVehicleMakeQueryProps = {
  vehicleType?: VehicleType;
  registrationYear?: number;
};

export function useVehicleMakeQuery(
  { vehicleType = VehicleType.MOTOR_CAR, registrationYear }: UseVehicleMakeQueryProps,
  options?: Omit<UseQueryOptions<StringListIAPIResult>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchVehicleMake = () => {
    return api.retailV1.vehicleType.getVehicleTypeMake({
      vehicleType,
      registrationYear: registrationYear,
    });
  };

  return useQuery({
    queryKey: queryKeys.vehicleMake(vehicleType, registrationYear),
    queryFn: fetchVehicleMake,
    ...options,
  });
}
