import { Divider, List, Typography } from '@mui/material';
import { useContext } from 'react';
import { isFuture } from 'date-fns';
import { HistoryCarContent } from '../HistoryCarContent';
import { HistoryDriverContent } from '../HistoryDriverContent';
import { HistoryCoverContent } from '../HistoryCoverContent';
import { HistoryDocumentsContent } from '../HistoryDocumentsContent';
import { HistoryReversal } from '../HistoryReversal';
import { HistoryAccountContent } from '../HistoryAccountContent';
import { HistoryPaymentsContent } from '../HistoryPaymentsContent';
import { sortArrayByBooleanKey } from '@/helpers/utils/arrayHelpers';
import { ExpandedContext } from '@/contexts/ExpandedContext';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { PolicyTransactionType } from '@/api/motorpolicy';
import { useUser } from '@/hooks/useUser';
import { Transaction } from '@/api/motorpolicy';
import { getIsPolicyInactive } from '@/helpers/data/policyHelpers';

type HistoryDetailsProps = {
  transaction: Transaction;
};

export function HistoryDetails({ transaction }: HistoryDetailsProps) {
  const { type, effectiveDateTimeUtc, policyVersion } = transaction;

  const { hasPermission } = useUser();
  const expanded = useContext(ExpandedContext);
  const policyId = useContext(PolicyIdContext);
  const {
    isError,
    isPending,
    data: policy,
  } = useMotorPolicyQuery(
    policyId,
    {
      policyVersion,
      skipFutureVersion: false,
    },
    { enabled: expanded },
  );

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  const hasPendingMta =
    policy.pendingChanges?.transactionType === PolicyTransactionType.MID_TERM_ADJUSTMENT;
  const shouldShowCalculateReversalButton =
    hasPermission('reverse:motorpolicy') &&
    hasPendingMta &&
    type === PolicyTransactionType.MID_TERM_ADJUSTMENT &&
    isFuture(new Date(effectiveDateTimeUtc)) &&
    !getIsPolicyInactive(policy);

  const sortedDrivers = sortArrayByBooleanKey(policy.drivers, 'isPolicyholder');

  return (
    <>
      <Divider sx={{ mt: -1 }} />
      <List>
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          Car
        </Typography>
        <HistoryCarContent policy={policy} />
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          Drivers
        </Typography>
        {sortedDrivers?.map((driver, key) => (
          <HistoryDriverContent
            key={key}
            policyholder={policy.policyholder}
            driver={driver}
          />
        ))}
        <Divider sx={{ mt: 6, mb: 6 }} />
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          Cover
        </Typography>
        <HistoryCoverContent policy={policy} policyVersion={policyVersion!} />
        <Divider sx={{ mt: 6, mb: 6 }} />
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          Payments
        </Typography>
        <HistoryPaymentsContent policy={policy} policyVersion={policyVersion!} />
        <Divider sx={{ mt: 6, mb: 6 }} />
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          Documents
        </Typography>
        <HistoryDocumentsContent
          policy={policy}
          policyVersion={transaction.policyVersion!}
        />
        <Divider sx={{ mt: 6, mb: 6 }} />
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          Account
        </Typography>
        <HistoryAccountContent policy={policy} />
      </List>
      {shouldShowCalculateReversalButton ? (
        <HistoryReversal transactionId={transaction.id} />
      ) : null}
    </>
  );
}
