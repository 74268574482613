import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Modal } from '@/components/ui/modals/Modal';

type QuoteCalculationPriceBreakdownModalProps = PropsWithChildren<{
  onClose: () => void;
}>;

export function QuoteCalculationPriceBreakdownModal({
  children,
  onClose,
}: QuoteCalculationPriceBreakdownModalProps) {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Price breakdown</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
