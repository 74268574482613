import { Chip } from '@mui/material';

type AttachmentChipProps = {
  label: string;
  onDelete: () => void;
};

export function AttachmentChip({ label, onDelete }: AttachmentChipProps) {
  return (
    <Chip
      label={label}
      variant="outlined"
      color="secondary"
      size="small"
      onDelete={onDelete}
    />
  );
}
