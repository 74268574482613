import { addDays, addMonths, isFuture } from 'date-fns';
import { getDate } from '../utils/dateHelpers';
import { isMonthlyPayment } from './paymentHelpers';
import { MotorPolicy } from '@/api/motorpolicy';
import { PolicyTransactionType, PolicyStatus } from '@/api/motorpolicy';
import { Transaction } from '@/api/motorpolicy';

export const getIsPolicyCancelled = ({ pendingChanges }: MotorPolicy) =>
  pendingChanges?.transactionType === PolicyTransactionType.CANCELLATION &&
  !!pendingChanges.effectiveDateTimeUtc &&
  isFuture(new Date(pendingChanges.effectiveDateTimeUtc));

export const getIsPolicyInactive = (policy: MotorPolicy) =>
  [PolicyStatus.CANCELLED, PolicyStatus.EXPIRED, PolicyStatus.VOIDED].includes(
    policy.status!,
  ) || getIsPolicyCancelled(policy);

export const getIsPolicyWithUpcomingMta = (policy: MotorPolicy) =>
  policy.transactions?.some(
    transaction =>
      transaction.type === PolicyTransactionType.MID_TERM_ADJUSTMENT &&
      transaction.effectiveDateTimeUtc &&
      isFuture(new Date(transaction.effectiveDateTimeUtc)),
  ) &&
  policy.pendingChanges?.transactionType === PolicyTransactionType.MID_TERM_ADJUSTMENT;

export const getPolicyMinDate = () => new Date(2023, 0, 1);
export const getPolicyMaxDate = () => addDays(addMonths(getDate(), 15), -1);

export const getPolicyEndDate = (policy: MotorPolicy) =>
  policy?.cancelledDateTimeUtc ?? policy.cover.endDateTimeUtc;

export const getPolicyStartDate = (policy: MotorPolicy, transaction: Transaction) =>
  transaction?.type !== PolicyTransactionType.CANCELLATION &&
  transaction?.effectiveDateTimeUtc
    ? transaction?.effectiveDateTimeUtc
    : policy.cover.startDateTimeUtc;

export const getMainTransaction = (transactions: Transaction[]): Transaction | null => {
  return (
    transactions.find(
      transaction => transaction.type === PolicyTransactionType.NEW_BUSINESS,
    ) ?? null
  );
};

export const getIsMonthlyPaymentPolicy = (policy: MotorPolicy) =>
  isMonthlyPayment(getMainTransaction(policy.transactions!)!.paymentPlan!.type!.code!);
