import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

export function useReminderGroupsQuery() {
  const api = useApiClient();

  const fetchReminderGroups = () => api.note.notes.getNotesRemindersGroups();

  const { data: response, ...restQuery } = useQuery({
    queryKey: queryKeys.reminderGroups(),
    queryFn: fetchReminderGroups,
  });

  const data = response?.reminderGroups;

  return {
    ...restQuery,
    data,
  };
}
