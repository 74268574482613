import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { mapPolicyToNewMtaForm } from '../helpers/mapPolicyToNewMtaForm';
import { NewMtaFormSchema, newMtaFormSchema } from './schemas/newMta.schema';
import { MotorPolicy } from '@/api/motorpolicy';

export function useNewMtaForm(policy: MotorPolicy) {
  return useForm<NewMtaFormSchema>({
    defaultValues: mapPolicyToNewMtaForm(policy),
    resolver: zodResolver(newMtaFormSchema),
    mode: 'onChange',
  });
}
