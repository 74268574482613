import { useController, useFormContext } from 'react-hook-form';
import { InputAdornment, TextField, type TextFieldProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/utils/formHelpers';

type FormNumberFieldProps = {
  name: string;
  testId?: string;
  symbol?: 'money';
  integerOnly?: boolean;
} & TextFieldProps &
  PropsWithChildren;

const formatValue = (value: string) => {
  if (value === '' || value == null) {
    return '';
  }

  if (value === '-') {
    return '-';
  }

  if (value.includes('.')) {
    const [integer, decimal] = value.split('.');
    return `${Number(integer).toLocaleString()}.${decimal}`;
  }

  return Number(value).toLocaleString();
};

const isValidInput = (value: string) => {
  const regex = /^-?(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
  return regex.test(value);
};

export function FormNumberField({
  name,
  testId,
  symbol,
  children,
  integerOnly = false,
  ...props
}: FormNumberFieldProps) {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  const inputProps = testId
    ? { 'data-testid': testId, ...props.inputProps }
    : props.inputProps;

  const InputProps =
    symbol === 'money'
      ? {
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
          ...props.InputProps,
        }
      : props.InputProps;

  const normalizeValue = (value: string) => {
    let normalizedValue = value;

    if (integerOnly) {
      normalizedValue = value.replace(/\./g, '');
    }

    return normalizedValue.replace(/,/g, '');
  };

  return (
    <TextField
      {...getErrorProps(error)}
      {...field}
      {...overrideReactHookFormRef}
      value={formatValue(field.value)}
      onChange={e => {
        const value = normalizeValue(e.target.value);

        if (isValidInput(value)) {
          field.onChange(value);
        }
      }}
      {...props}
      inputProps={inputProps}
      InputProps={InputProps}
    >
      {children}
    </TextField>
  );
}
