import { useFormContext } from 'react-hook-form';
import { getDateOfReminderMinDate, NoteFormSchema } from '../hooks/note.schema';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByName,
} from '@/helpers/utils/formHelpers';
import { useReminderGroupsQuery } from '@/hooks/queries/useReminderGroupsQuery';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { FormDateTimePicker } from '@/components/ui/forms/FormDateTimePicker';
import { useSetValue } from '@/hooks/useSetValue';

export function NoteFormReminderFields() {
  const { watch, setValue } = useFormContext<NoteFormSchema>();
  const { isPending, isError, data: reminderGroups } = useReminderGroupsQuery();

  const dateOfReminderField = watch('dateOfReminder');
  const assigneeTeamField = watch('assigneeTeam');

  useSetValue<NoteFormSchema>({
    name: 'assigneeTeam',
    value: '',
    shouldSetValue: !!(!dateOfReminderField && assigneeTeamField),
    setValue,
  });

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return (
    <>
      <FormDateTimePicker
        name="dateOfReminder"
        minDate={getDateOfReminderMinDate()}
        label="Reminder due date/time"
        sx={{ ...commonSx, ...maxWidthSx, mr: 4 }}
        slotProps={{ field: { clearable: true } }}
      />
      <FormTextField
        sx={{ ...commonSx, ...maxWidthSx }}
        select
        label="Assign to team"
        name="assigneeTeam"
        id="assigneeTeam"
        disabled={!dateOfReminderField}
      >
        {renderSelectOptionsByName(reminderGroups ?? [])}
      </FormTextField>
    </>
  );
}
