export enum DebtReason {
  FORCED_CANCELLATION = 'ForcedCancellation',
  PAYMENT_ARREARS = 'PaymentArrears',
  UNCHARGED_PREMIUM = 'UnchargedPremium',
  DEBT_SETTLEMENT = 'DebtSettlement',
  COMPENSATION = 'Compensation',
  FOSCOMPENSATION = 'FOSCompensation',
  CANCELLATION_FEE_AMENDMENT = 'CancellationFeeAmendment',
  MTAFEE_AMENDMENT = 'MTAFeeAmendment',
  SET_UP_FEE_AMENDMENT = 'SetUpFeeAmendment',
  WRITE_OFF = 'WriteOff',
}
