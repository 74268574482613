import { AddressService } from './services/AddressService';
import { ProductService } from './services/ProductService';
import { BaseAPI } from '@/api/core/BaseAPI';

export class RetailAPI extends BaseAPI {
  readonly address: AddressService;
  readonly product: ProductService;

  constructor() {
    super({ BASE: import.meta.env.VITE_APP_RETAIL_API_URL });

    this.address = new AddressService(this.request);
    this.product = new ProductService(this.request);
  }
}
