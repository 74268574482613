export enum AttachmentType {
  DEATH_CERTIFICATE = 'DeathCertificate',
  MARRIAGE_CERTIFICATE = 'MarriageCertificate',
  NAME_CHANGE_BY_DEED_POLL = 'NameChangeByDeedPoll',
  OTHER = 'Other',
  PROOF_OF_ADDRESS = 'ProofOfAddress',
  PROOF_OF_LICENSE = 'ProofOfLicense',
  PROOF_OF_NCD = 'ProofOfNcd',
  PROOF_OF_POWER_OF_ATTORNEY = 'ProofOfPowerOfAttorney',
}
