import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { PaymentDetails, PlaceOrderRequest } from '@/api/retail/v1';
import { PlaceOrderResponse } from '@/api/retail/v1';
import { DebtReason } from '@/api/support';
import { CancelablePromise } from '@/api/core/CancelablePromise';

const collectBrowserInfo = () => {
  const { userAgent, language } = navigator;
  const { pixelDepth, height, width } = window.screen;
  const timeZoneOffset = new Date().getTimezoneOffset();

  return {
    acceptHeader: 'application/json',
    colorDepth: pixelDepth,
    javaEnabled: false,
    language: language || 'en',
    screenHeight: height,
    screenWidth: width,
    timeZoneOffset,
    userAgent,
  };
};

export type QuoteOrderParams = {
  type: 'quote';
  quoteId: string;
  selectedPaymentPlan?: string;
  bypassPayment?: boolean;
  bypassPaymentReasonCode?: string;
  bypassPaymentDescription?: string;
};

export type SettleDebtOrderParams = {
  type: 'settle_debt';
  amount: number;
};

type AddDebtOrderParams = {
  type: 'add_debt';
  reason: DebtReason;
  amount: number;
  description: string;
};

type OrderParams = { paymentDetails?: PaymentDetails; isRefund?: boolean } & (
  | QuoteOrderParams
  | SettleDebtOrderParams
  | AddDebtOrderParams
);

type SharedOrderParams = Pick<
  PlaceOrderRequest,
  'browserInfo' | 'origin' | 'returnUrl' | 'channel' | 'paymentDetails'
>;

export function useOrderMutation(policyId: string) {
  const api = useApiClient();

  const makeOrder = ({ paymentDetails, ...params }: OrderParams) => {
    const sharedProps: SharedOrderParams = {
      browserInfo: collectBrowserInfo(),
      origin: window.location.origin,
      returnUrl: window.location.origin,
      channel: 'web',
    };

    if (params.type === 'quote') {
      return api.retailV1.order.postOrder({
        requestBody: {
          ...sharedProps,
          paymentDetails,
          motorQuoteId: params.quoteId,
          selectedPaymentPlan: params.selectedPaymentPlan,
          bypassPayment: params.bypassPayment,
          bypassPaymentReasonCode: params.bypassPaymentReasonCode,
          bypassPaymentDescription: params.bypassPaymentDescription,
        },
      }) as CancelablePromise<PlaceOrderResponse>;
    }

    if (params.type === 'settle_debt') {
      return api.support.payments.postPaymentsSettleDebt({
        policyId,
        requestBody: {
          ...sharedProps,
          paymentDetails,
          amount: params.amount,
        },
      }) as unknown as CancelablePromise<PlaceOrderResponse>;
    }

    if (params.type === 'add_debt') {
      return api.support.payments.postPaymentsDebt({
        policyId,
        requestBody: {
          ...sharedProps,
          reason: params.reason,
          description: params.description,
          amount: params.amount,
        },
      }) as unknown as CancelablePromise<PlaceOrderResponse>;
    }

    throw new Error('Not implemented');
  };

  const mutation = useMutation({
    mutationFn: makeOrder,
  });

  return { ...mutation, isSuccess: mutation.isSuccess && !mutation.data.errorCode };
}
