import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

type ModalContentProps = PropsWithChildren<{
  gap?: number;
}>;

export function ModalContent({ gap = 6, children }: ModalContentProps) {
  return (
    <Stack direction="column" p={6} gap={gap}>
      {children}
    </Stack>
  );
}
