import { List } from '@mui/material';
import { CancelPolicyQuoteCalculationPriceBreakdownSetupFee } from './CancelPolicyQuoteCalculationPriceBreakdownSetupFee';
import {
  QuoteCalculation,
  useQuoteCalculationContext,
} from '@/features/PaymentActions/QuoteCalculation';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';
import {
  sumSelectedAddOnsPremium,
  sumSelectedAddOnsPremiumTax,
} from '@/helpers/data/quoteHelpers';

export function CancelPolicyQuoteCalculationPriceBreakdown() {
  const { netPremium, insurancePremiumTax, fee, feeTotal, quoteResult, quote } =
    useQuoteCalculationContext();
  const addOnTotalPremium = sumSelectedAddOnsPremium(quote);
  const addOnTotalPremiumTax = sumSelectedAddOnsPremiumTax(quote);

  return (
    <>
      <List>
        <QuoteCalculation.PriceBreakdown.Price
          label="Insurance premium"
          amount={netPremium}
        />
        <QuoteCalculation.PriceBreakdown.Price
          label="Insurance premium tax"
          amount={insurancePremiumTax}
        />
        {addOnTotalPremium !== 0 && (
          <QuoteCalculation.CostSummary.Item
            label="Add-on Insurance Premium"
            amount={addOnTotalPremium}
          />
        )}
        {addOnTotalPremiumTax !== 0 && (
          <QuoteCalculation.CostSummary.Item
            label="Add-on Insurance Premium Tax"
            amount={addOnTotalPremiumTax}
          />
        )}
        <QuoteCalculation.PriceBreakdown.Price
          label={getFeeTypesName(fee.type!)}
          amount={feeTotal}
        />
      </List>
      <QuoteCalculation.PriceBreakdown.Total />
      {quoteResult === 'refund' && <CancelPolicyQuoteCalculationPriceBreakdownSetupFee />}
    </>
  );
}
