import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  validateMaxFeeDifference,
  validateMaxFeeDifferenceMsg,
  validateMinMaxPremium,
  validateMinMaxPremiumMsg,
  validateRequiredIsFeeChangePermissionGranted,
  validateRequiredIsFeeChangePermissionGrantedMsg,
} from '../helpers/changeFeesPremiumFormHelper';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { Permission, PermissionSchema } from '@/models/Permission';

const changeFeesPremiumFormSchema = z
  .object({
    _userPermissions: z.array(PermissionSchema),
    _originalFee: z.number(),
    _originalPremium: z.number(),
    fee: z.string().refine(value => Boolean(value), validationMessages.required),
    premium: z.string().refine(value => Boolean(value), validationMessages.required),
    reason: z
      .string()
      .min(5, { message: validationMessages.minLength(5) })
      .max(500, { message: validationMessages.maxLength(500) }),
    isFeeChangePermissionGranted: z.boolean(),
  })
  .refine(validateMaxFeeDifference, validateMaxFeeDifferenceMsg)
  .refine(validateMinMaxPremium, validateMinMaxPremiumMsg)
  .refine(
    validateRequiredIsFeeChangePermissionGranted,
    validateRequiredIsFeeChangePermissionGrantedMsg,
  );

export type ChangeFeesPremiumFormSchema = z.infer<typeof changeFeesPremiumFormSchema>;

export function useChangeFeesPremiumForm(
  data: Pick<ChangeFeesPremiumFormSchema, 'fee' | 'premium'>,
  originalFee: number,
  originalPremium: number,
  userPermissions: Permission[],
) {
  return useForm<ChangeFeesPremiumFormSchema>({
    defaultValues: {
      _userPermissions: userPermissions,
      _originalFee: originalFee,
      _originalPremium: originalPremium,
      fee: data.fee || '',
      premium: data.premium || '',
      reason: '',
      isFeeChangePermissionGranted: false,
    },
    resolver: zodResolver(changeFeesPremiumFormSchema),
  });
}
