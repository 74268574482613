import { PropsWithChildren } from 'react';
import { QuoteCalculationContext } from '../hooks/useQuoteCalculationContext';
import { QuoteCalculationError } from './QuoteCalculationError';
import { PaymentPlan, MotorQuote, Fee } from '@/api/motorquote';
import { FeeType } from '@/api/motorquote';
import { getQuoteResult } from '@/helpers/data/quoteHelpers';
import {
  selectFeeByType,
  selectFees,
  selectInsurancePremiumTax,
  selectNetPremium,
  selectPaymentPlans,
} from '@/helpers/selectors/quoteSelectors';

const buildFee = (amount: number, type: FeeType): Fee => ({ total: { amount }, type });

type QuoteCalculationProviderProps = PropsWithChildren<{
  quote: MotorQuote;
  feeType: FeeType;
  customizeQuote?: {
    paymentPlans?: PaymentPlan[];
  };
  total: number;
}>;

export function QuoteCalculationProvider({
  quote,
  feeType,
  customizeQuote,
  total,
  children,
}: QuoteCalculationProviderProps) {
  const fees = selectFees(quote);
  const fee = selectFeeByType(fees, feeType) ?? buildFee(0, feeType);
  const feeTotal = fee.total?.amount ?? 0;
  const paymentPlans = customizeQuote?.paymentPlans ?? selectPaymentPlans(quote);
  const netPremium = selectNetPremium(quote);
  const insurancePremiumTax = selectInsurancePremiumTax(quote);
  const quoteId = quote.id;
  const quoteResult = getQuoteResult(total);

  if (!fee || !quoteId) return <QuoteCalculationError />;

  return (
    <QuoteCalculationContext.Provider
      value={{
        quote,
        paymentPlans,
        fee,
        feeTotal,
        total,
        quoteId,
        quoteResult,
        netPremium,
        insurancePremiumTax,
      }}
    >
      {children}
    </QuoteCalculationContext.Provider>
  );
}
