import { useContext } from 'react';
import { mapNoteFormToEditRequestData } from '../helpers/notesDataHelper';
import { NoteFormSubmitData } from '../hooks/note.schema';
import { NoteForm } from './NoteForm';
import { useEditNoteMutation } from '@/hooks/mutations/useEditNoteMutation';
import { Note } from '@/api/note';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useSetAlertError } from '@/hooks/useSetAlertError';

type EditNoteFormProps = {
  note: Note;
  onRequestSuccess: () => void;
  onCancel: () => void;
};

export function EditNoteForm({ note, onRequestSuccess, onCancel }: EditNoteFormProps) {
  const parentId = useContext(PolicyIdContext);
  const {
    isError,
    isPending,
    mutate: editNote,
  } = useEditNoteMutation({
    onSuccess: onRequestSuccess,
  });

  const handleSubmit = (data: NoteFormSubmitData) => {
    editNote(mapNoteFormToEditRequestData(data, note, parentId));
  };

  const handleCancel = () => {
    onCancel();
  };

  useSetAlertError(isError);

  return (
    <NoteForm
      isSubmitPending={isPending}
      submitButtonText="Save changes"
      cancelButtonText="Cancel changes"
      defaultNote={note}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
}
