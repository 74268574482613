import { ExcessNames } from '../nameMappings/excessNameMappings';
import { ExcessAmount } from '@/api/motorpolicy';
import { Excesses } from '@/api/motorpolicy';

export const getExcessesTotalAmount = (excesses: ExcessAmount[]) =>
  excesses?.find(excess => excess.type?.code === ExcessNames.PolicyXs)?.total ?? 0;

export const getCompulsoryExcessesIndividualItems = (excesses: Excesses) =>
  excesses?.compulsoryAmounts?.filter(
    excess => excess.type?.code !== ExcessNames.PolicyXs,
  );
