import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import {
  ReversalQuoteMotorPolicyResponse,
  ReversalQuoteMotorPolicyRequest,
} from '@/api/motorpolicy';
import { ApiError } from '@/api/core/ApiError';

export function useReversalQuoteMutation() {
  const api = useApiClient();

  return useMutation<
    ReversalQuoteMotorPolicyResponse,
    ApiError,
    {
      policyId: string;
      transactionId: string;
      requestBody?: ReversalQuoteMotorPolicyRequest;
    }
  >({
    mutationFn: params =>
      api.motorpolicy.motorPolicy.postMotorpoliciesTransactionReversalQuote({
        ...params,
        requestBody: params.requestBody ?? {},
      }),
  });
}
