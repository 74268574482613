import { List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { MotorPolicy } from '@/api/motorpolicy';
import { booleanAnswer } from '@/helpers/utils/textHelpers';

type HistoryAccountContentProps = {
  policy: MotorPolicy;
};

export function HistoryAccountContent({ policy }: HistoryAccountContentProps) {
  return (
    <List>
      <ListRow disablePadding label="Email address">
        {policy.policyholder.email}
      </ListRow>
      <ListRow disablePadding label="Telephone number">
        {policy.policyholder.telephoneNumber?.landline}
      </ListRow>
      <ListRow disablePadding label="Mobile number">
        {policy.policyholder.telephoneNumber?.mobile}
      </ListRow>
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Marketing preferences
      </Typography>
      <ListRow disablePadding label="Email">
        {booleanAnswer(policy.policyholder.contactPreferences?.allowedToMarketByEmail)}
      </ListRow>
      <ListRow disablePadding label="SMS">
        {booleanAnswer(policy.policyholder.contactPreferences?.allowedToMarketBySMS)}
      </ListRow>
      <ListRow disablePadding label="Telephone">
        {booleanAnswer(policy.policyholder.contactPreferences?.allowedToMarketByPhone)}
      </ListRow>
      <ListRow disablePadding label="Post">
        {booleanAnswer(policy.policyholder.contactPreferences?.allowedToMarketByPost)}
      </ListRow>
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Auto-renew policy
      </Typography>
      <ListRow disablePadding label="Auto-renew policy">
        {booleanAnswer(policy.isAutoRenewalRequested)}
      </ListRow>
    </List>
  );
}
