import { ListRow } from '@/components/ui/lists/ListRow';
import { ClaimFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';

type ClaimProps = ClaimFormSchema;

export function Claim({
  _source: source,
  type,
  date,
  atFault,
  settled,
  damageAmount,
}: ClaimProps) {
  const { claimTypes } = useNewMtaDriverContext();

  const typeDescription = claimTypes?.find(({ code }) => code === type)?.description;

  return (
    <>
      <ListRow label="Type of claim">{typeDescription}</ListRow>
      <ListRow label="Date of claim">{date ? formatDate(date) : null}</ListRow>
      <ListRow label="At fault">{atFault}</ListRow>
      <ListRow label="Claim settled">{settled}</ListRow>
      <ListRow label="Claim amount">
        {damageAmount && formatCurrency(damageAmount.toLocaleString())}
      </ListRow>
      <ListRow label="Claim source">{source.description}</ListRow>
    </>
  );
}
