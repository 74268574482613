import { ListRow } from '@/components/ui/lists/ListRow';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';

type QuoteCalculationListRowPriceProps = {
  label: string;
  amount: number | string | null;
};

export function QuoteCalculationListRowPrice({
  label,
  amount,
}: QuoteCalculationListRowPriceProps) {
  return (
    <ListRow disablePadding alignContentRight label={label}>
      {typeof amount === 'number' ? formatCurrency(amount) : amount}
    </ListRow>
  );
}
