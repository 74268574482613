import { useFormContext } from 'react-hook-form';
import { Button, Divider, Stack } from '@mui/material';
import { NewMtaAdditionalDriverForm } from '../NewMtaAdditionalDriverForm';
import { NewMtaFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { StyledFieldset } from '@/components/ui/styles/StyledFieldset';

type NewAddedAdditionalDriverFormProps = {
  onAdd: () => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function NewAddedAdditionalDriverForm({
  onAdd,
  onCancel,
}: NewAddedAdditionalDriverFormProps) {
  const { nestIndex } = useNewMtaDriverContext();
  const { trigger } = useFormContext<NewMtaFormSchema>();
  const handleAdd = async () => {
    const isValid = await trigger(`drivers.${nestIndex}`);

    if (isValid) {
      onAdd();
    }
  };

  return (
    <>
      <StyledFieldset data-testid="new-driver.fieldset">
        <NewMtaAdditionalDriverForm />
      </StyledFieldset>
      <Divider sx={{ mt: 4 }} />
      <Stack gap={4} mt={6}>
        <Button variant="contained" size="small" onClick={handleAdd}>
          Add this new driver
        </Button>
        <Button variant="text" size="small" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </>
  );
}
