import { useState } from 'react';
import {
  ActionType,
  FileCategory,
} from '@/components/ui/presentation/FileDetail/fileDetailTypes';
import { downloadFile } from '@/helpers/utils/fileHelpers';

export type FileDetailProps = {
  title: string;
  description1?: string;
  description2?: string;
  fileName: string;
  content: string | null;
  onContentRequest?: () => void;
  isError?: boolean;
  url?: string;
};

const imageExtensions = ['.jpg', '.jpeg', '.png', '.bmp', '.heic'];
const videoExtensions = ['.mov', '.mp4'];

function getFileCategory(fileName: string | null): FileCategory | null {
  if (fileName?.endsWith('.pdf')) return FileCategory.Document;
  if (imageExtensions.some(extension => fileName?.endsWith(extension)))
    return FileCategory.Image;
  if (videoExtensions.some(extension => fileName?.endsWith(extension)))
    return FileCategory.Video;

  return null;
}

export function useFileDetail({ fileName, content, onContentRequest }: FileDetailProps) {
  const [pendingAction, setPendingAction] = useState<ActionType | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const category = getFileCategory(fileName);

  const viewFile = () => {
    setModalOpen(true);
  };

  const runActionByType = (type: ActionType, content: string) => {
    switch (type) {
      case ActionType.View:
        return viewFile();
      case ActionType.Download:
        return downloadFile(fileName, content);
    }
  };

  const handleAction = (actionType: ActionType) => {
    if (!pendingAction) {
      if (content) {
        runActionByType(actionType, content);
      } else {
        setPendingAction(actionType);
        onContentRequest?.();
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const runPendingAction = (action: ActionType, content: string) => {
    runActionByType(action, content);
    setPendingAction(null);
  };

  return {
    pendingAction,
    isModalOpen,
    category,
    handleAction,
    handleCloseModal,
    runPendingAction,
  };
}
