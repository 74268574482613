import { styled } from '@mui/material';

export const VulnerabilitiesListItem = styled('li')(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(6),
  },
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));
