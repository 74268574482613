import { styled, Box, Stack } from '@mui/material';

export const StyledGrayBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(6),
  width: '100%',
}));

export const StyledGrayBoxItem = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
}));
