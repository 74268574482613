import { NewMtaFormProvider } from './components/NewMtaFormProvider';
import { NewMtaTabs } from './components/NewMtaTabs';
import { NewMta } from './NewMta';
import { NewMtaTabContextProvider } from './NewMtaTabContextProvider';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';

export function NewMtaDrawerContent() {
  return (
    <DrawerContent>
      <NewMtaFormProvider>
        <NewMtaTabContextProvider>
          <DrawerHeader title="New MTA's">
            <NewMtaTabs />
          </DrawerHeader>
          <NewMta />
        </NewMtaTabContextProvider>
      </NewMtaFormProvider>
    </DrawerContent>
  );
}
