import { createContext, useContext } from 'react';

type CreateOrderPromptContextProps = {
  confirmPrompt: () => void;
  cancelPrompt: () => void;
};

export const CreateOrderPromptContext = createContext<
  CreateOrderPromptContextProps | undefined
>(undefined);

export function useCreateOrderPromptContext() {
  const context = useContext(CreateOrderPromptContext);

  if (context === undefined) {
    throw new Error(
      'useCreateOrderPromptContext must be used within CreateOrderPromptContext',
    );
  }

  return context;
}
