import { NotesTabs } from './components/NotesTabs';
import { Notes } from './Notes';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function NotesDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="Notes">
        <NotesTabs />
      </DrawerHeader>
      <Notes />
    </DrawerContent>
  );
}
