import type { AddCustomerVulnerabilitySupportRequest } from '../models/AddCustomerVulnerabilitySupportRequest';
import type { AddCustomerVulnerabilitySupportResponse } from '../models/AddCustomerVulnerabilitySupportResponse';
import type { UpdateCustomerVulnerabilitySupportRequest } from '../models/UpdateCustomerVulnerabilitySupportRequest';
import type { CancelablePromise } from '@/api/core/CancelablePromise';
import type { BaseHttpRequest } from '@/api/core/BaseHttpRequest';
export class CustomerVulnerabilityService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postCustomerVulnerabilities({
    requestBody,
  }: {
    requestBody?: AddCustomerVulnerabilitySupportRequest;
  }): CancelablePromise<AddCustomerVulnerabilitySupportResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/customer-vulnerabilities',
      body: requestBody,
    });
  }

  patchCustomerVulnerabilities({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: UpdateCustomerVulnerabilitySupportRequest;
  }): CancelablePromise<AddCustomerVulnerabilitySupportResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/customer-vulnerabilities/{id}',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  deleteCustomerVulnerabilities({
    id,
    noteId,
  }: {
    id: string;
    noteId?: string;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/customer-vulnerabilities/{id}',
      path: {
        id: id,
      },
      query: {
        noteId: noteId,
      },
    });
  }
}
