export enum FileCategory {
  Document = 'document',
  Image = 'image',
  Video = 'video',
}

export enum ActionType {
  View = 'view',
  Download = 'download',
}

export type FileDetailModalProps = {
  fileName: string;
  contentType?: string;
  content: string;
  title: string;
  onClose: () => void;
};
