import { OccupationModel } from '@/api/retail/v1';

export const employmentStatusCodeJoblessOcupationMap: Record<string, OccupationModel> = {
  H: {
    type: { code: 'H09' },
    employmentStatus: { code: 'H' },
    industry: { code: '948' },
  },
  I: {
    type: { code: 'I02' },
    employmentStatus: { code: 'I' },
    industry: { code: '186' },
  },
  N: {
    type: { code: '43D' },
    employmentStatus: { code: 'N' },
    industry: { code: '949' },
  },
  R: {
    type: { code: 'R09' },
    employmentStatus: { code: 'R' },
    industry: { code: '947' },
  },
  V: {
    type: { code: 'V06' },
    employmentStatus: { code: 'V' },
    industry: { code: '186' },
  },
  U: {
    type: { code: 'U03' },
    employmentStatus: { code: 'U' },
    industry: { code: '747' },
  },
  F: {
    type: { code: 'S34' },
    employmentStatus: { code: 'F' },
    industry: { code: '950' },
  },
} as const;
