import { useContext } from 'react';
import { DocumentList } from './components/DocumentList';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useDocumentsQuery } from '@/hooks/queries/useDocumentsQuery';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';

export function Documents() {
  const policyId = useContext(PolicyIdContext);
  const { isPending, isError, data: documents } = useDocumentsQuery(policyId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return <DocumentList documents={documents} data-testid="document-content" />;
}
