import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';

export function usePaymentsQuery(policyId: string) {
  const api = useApiClient();

  const fetchPayments = () =>
    api.support.payments
      .getPayments({ policyId })
      .then(response => response.paymentInformation!);

  return useQuery({
    queryKey: queryKeys.payments(policyId),
    queryFn: fetchPayments,
  });
}
