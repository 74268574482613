import { Divider, List, Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { InsurerClaim } from '@/api/motorpolicy';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import {
  getClaimCauseName,
  getClaimFaultName,
  getClaimStatusName,
} from '@/helpers/nameMappings/claimNameMappings';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { formatDate, parseDateOnly } from '@/helpers/utils/dateHelpers';

type ClaimContentDetailsProps = {
  claim: InsurerClaim;
};

export function ClaimContentDetails({ claim }: ClaimContentDetailsProps) {
  return (
    <>
      <Divider />
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Claim details
      </Typography>
      <List>
        <ListRow label="Claim number">{claim.claimNumber}</ListRow>
        <ListRow label="Claim version">{claim.claimVersion}</ListRow>
        <ListRow label="Policy ID">{claim.policyId}</ListRow>
        <ListRow label="Large loss">{booleanAnswer(claim.isLargeLoss)}</ListRow>
        <ListRow label="NCB decision">{booleanAnswer(claim.ncbDecision)}</ListRow>
        <ListRow label="At fault">
          {claim.atFault && getClaimFaultName(claim.atFault)}
        </ListRow>
        <ListRow label="Damage amount">
          {claim.damageAmount != null && formatCurrency(claim.damageAmount)}
        </ListRow>
        <ListRow label="Settled date">
          {claim.settledDate && formatDate(parseDateOnly(claim.settledDate))}
        </ListRow>
        <ListRow label="Settled status">
          {claim.status && getClaimStatusName(claim.status)}
        </ListRow>
        <ListRow label="Incident date">
          {claim.incidentDate && formatDate(parseDateOnly(claim.incidentDate))}
        </ListRow>
        <ListRow label="Underwriter">{claim.underwriter}</ListRow>
        <ListRow label="Primary cause">
          {claim.primaryCause && getClaimCauseName(claim.primaryCause)}
        </ListRow>
      </List>
    </>
  );
}
