import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { useUser } from '../useUser';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { OptionCode } from '@/api/retail/v1';
import { PaymentMethodsResponse } from '@/api/support';

export type UseStoredPaymentsQueryProps = {
  shopperReference?: string;
};

export const mapStoredPaymentsQueryToSelectOptions = ({
  storedPaymentMethods,
}: PaymentMethodsResponse): OptionCode[] =>
  (storedPaymentMethods ?? []).map(storePaymentMethod => ({
    code: storePaymentMethod.id,
    description: `${storePaymentMethod.holderName} **** **** **** ${storePaymentMethod.lastFour}`,
  }));

export function useStoredPaymentsQuery<TData = PaymentMethodsResponse>(
  { shopperReference = '' }: UseStoredPaymentsQueryProps,
  options?: Omit<
    UseQueryOptions<PaymentMethodsResponse, unknown, TData>,
    'queryKey' | 'queryFn'
  >,
) {
  const { hasPermission } = useUser();
  const api = useApiClient();
  const fetchStoredPayments = () =>
    api.support.paymentMethod.getPaymentMethod({ shopperReference });

  return useQuery({
    queryKey: queryKeys.storedPayments(shopperReference),
    queryFn: fetchStoredPayments,
    enabled: !!shopperReference && hasPermission('readanypaymentmethod:support'),
    ...options,
  });
}
