import { Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  BypassPaymentFormSchema,
  useBypassPaymentForm,
} from '../hooks/useBypassPaymentForm';
import { Modal } from '@/components/ui/modals/Modal';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
  stopPropagate,
} from '@/helpers/utils/formHelpers';
import { getDebtReasonName } from '@/helpers/nameMappings/paymentNameMappings';
import { DebtReason } from '@/api/support';

type BypassPaymentModalProps = {
  isOrderPending: boolean;
  onSubmit: (reason: DebtReason) => void;
  onCancel: () => void;
};

export function BypassPaymentModal({
  isOrderPending,
  onSubmit,
  onCancel,
}: BypassPaymentModalProps) {
  const formName = 'bypass-payment-form';
  const form = useBypassPaymentForm();
  const { handleSubmit } = form;

  const handleValidSubmit: SubmitHandler<BypassPaymentFormSchema> = ({ reason }) => {
    onSubmit(reason!);
  };

  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Continue without payment</Modal.Header>
      <Modal.Content>
        <FormProvider {...form}>
          <form id={formName} onSubmit={stopPropagate(handleSubmit(handleValidSubmit))}>
            <Typography variant="body2" mb={6}>
              Please select a reason below, for skipping payment. This amount will still
              be owing and will need to be paid within 7 working days.
            </Typography>
            <FormTextField
              name="reason"
              select
              label="Reason for skipping payment"
              sx={{ ...commonSx, ...maxWidthSx }}
            >
              {renderSelectOptionsByEnum(DebtReason, getDebtReasonName)}
            </FormTextField>
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          type="submit"
          form={formName}
          variant="contained"
          color="secondary"
          loading={isOrderPending}
        >
          Continue without payment
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
