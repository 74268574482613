import { Button, List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { ReminderDetail } from '@/api/note';
import { ReminderStatus } from '@/api/support';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { useUser } from '@/hooks/useUser';

type ReminderDetailListProps = {
  reminder: ReminderDetail;
  openMarkAsCompleteModal?: () => void;
};

export function ReminderDetailList({
  reminder,
  openMarkAsCompleteModal,
}: ReminderDetailListProps) {
  const { hasPermission } = useUser();

  return (
    <>
      <List>
        <ListRow label="Type">{reminder.noteType}</ListRow>
        <ListRow label="Summary">{reminder.summary}</ListRow>
        <ListRow label="Description">{reminder.description}</ListRow>
        <ListRow label="Assigned team">{reminder.assigneeTeam}</ListRow>
        <ListRow label="Assigned individual">{reminder.assigneeName}</ListRow>
        {reminder.reminderStatus === ReminderStatus.COMPLETE ? (
          <ListRow label="Original due date">
            {formatDateTime(reminder.dateOfReminder)}
          </ListRow>
        ) : null}
      </List>
      {Boolean(openMarkAsCompleteModal) &&
        reminder.reminderStatus !== ReminderStatus.COMPLETE &&
        hasPermission('updateany:reminders') && (
          <Button
            data-testid="reminders_mark-as-complete-button"
            color="secondary"
            size="small"
            sx={{ mb: 4, mt: 2 }}
            onClick={openMarkAsCompleteModal}
          >
            Mark as complete
          </Button>
        )}
    </>
  );
}
