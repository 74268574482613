import { useVehicleMakeQuery } from '@/hooks/queries/useVehicleMakeQuery';

type UseVehicleMakeProps = {
  registrationYear: number | null;
};

export function useVehicleMakes({ registrationYear }: UseVehicleMakeProps) {
  const isEnabled = Boolean(registrationYear);

  const query = useVehicleMakeQuery(
    { registrationYear: registrationYear ?? undefined },
    { enabled: isEnabled },
  );

  const vehicleMakes = query.data?.detail ?? [];

  return {
    ...query,
    isEnabled,
    vehicleMakes,
  };
}
