export enum ExcessNames {
  WindscreenRepairXs = 'WINDSCREEN_REPAIR_XS',
  WindscreenReplaceXs = 'WINDSCREEN_REPLACE_XS',
  TheftOfKeysXs = 'THEFT_OF_KEYS_XS',
  NonApprovedRepairXs = 'NON_APPROVED_REPAIR_XS',
  CompulsoryXs = 'COMPULSORY_XS',
  VolXs = 'VOL_XS',
  PolicyXs = 'POLICY_XS',
}

export const getExcessName = (
  code?: string | null,
  description?: string | null,
): string => {
  switch (code) {
    case ExcessNames.WindscreenRepairXs:
      return 'Windscreen repair excess';
    case ExcessNames.WindscreenReplaceXs:
      return 'Windscreen replacement excess';
    case ExcessNames.TheftOfKeysXs:
      return 'Theft of keys excess';
    case ExcessNames.NonApprovedRepairXs:
      return 'Non-approved repairer excess';
    case ExcessNames.CompulsoryXs:
      return 'Compulsory excess';
    case ExcessNames.VolXs:
      return 'Voluntary excess';
    case ExcessNames.PolicyXs:
      return 'Policy excess';
    default:
      return description ? description : code ?? '-';
  }
};
