import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { AddressV1ListIAPIResult } from '@/api/retail/v2';
import { OptionCode } from '@/api/retail/v1';

type useAddressQueryProps = {
  postcode: string;
  houseNumber?: string;
};

const mapAddressQueryToSelectOptions = (address: AddressV1ListIAPIResult): OptionCode[] =>
  (address.detail ?? []).map(({ displayText, postKey }) => ({
    code: postKey,
    description: displayText,
  }));

export function useAddressQuery<TData>(
  { postcode, houseNumber }: useAddressQueryProps,
  options?: Omit<
    UseQueryOptions<AddressV1ListIAPIResult, Error, TData>,
    'queryKey' | 'queryFn'
  >,
) {
  const api = useApiClient();
  const fetchAddresses = () => api.retailV2.address.getAddress({ postcode, houseNumber });

  return useQuery({
    queryKey: queryKeys.addresses(postcode),
    queryFn: fetchAddresses,
    ...options,
    select: mapAddressQueryToSelectOptions,
  });
}
