import { SkeletonLoader } from '@/components/ui/loaders/SkeletonLoader';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';

export function QuoteCalculationLoading() {
  return (
    <StyledButtonsFooter sticky stretchItems={false}>
      <SkeletonLoader />
    </StyledButtonsFooter>
  );
}
