import { PaymentPlan } from '@/api/motorquote';

export const selectPaymentPlanByType = (paymentsPlans: PaymentPlan[], type: string) =>
  paymentsPlans.find(paymentPlan => paymentPlan.type?.code === type);
export const selectFinanceAmount = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.financeAmount?.amount ?? null;
export const selectInterestRate = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.interestRate ?? null;
export const selectAprFromProvider = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.aprFromProvider ?? null;
export const selectMonthlyCost = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.monthlyCost?.amount ?? null;
export const selectNumberOfInstallments = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.numberOfInstallments ?? null;
export const selectTotal = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.total?.amount ?? null;
export const selectLoanRedemption = (paymentPlan: PaymentPlan) =>
  paymentPlan?.installmentDetails?.loanRedemption?.amount?.amount ?? null;
