import { Typography } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { MotorConviction } from '@/api/motorpolicy';

type HistoryConvictionContentProps = {
  convictionNumber: number;
  conviction: MotorConviction;
};

export function HistoryConvictionContent({
  conviction,
  convictionNumber,
}: HistoryConvictionContentProps) {
  return (
    <>
      <Typography variant="subtitle2" sx={{ mt: 4, mb: 3 }}>
        Conviction #{convictionNumber}
      </Typography>
      <ListRow disablePadding label="Type">
        {conviction.type?.description}
      </ListRow>
      <ListRow label="Date">{conviction.date && formatDate(conviction.date)}</ListRow>
      <ListRow disablePadding label="Penalty points">
        {conviction.penaltyPoints}
      </ListRow>
      <ListRow disablePadding label="Fine amount">
        {conviction.fineAmount?.amount && formatCurrency(conviction.fineAmount.amount)}
      </ListRow>
      <ListRow disablePadding label="Ban received">
        {booleanAnswer(Boolean(conviction.disqualifiedLengthInMonths))}
      </ListRow>
      <ListRow disablePadding label="Ban length in months">
        {conviction.disqualifiedLengthInMonths}
      </ListRow>
      <ListRow disablePadding label="Related to accident">
        {booleanAnswer(conviction.relatedToAccident)}
      </ListRow>
    </>
  );
}
