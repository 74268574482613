import { addDays, isAfter, isBefore, min, startOfDay, subYears } from 'date-fns';
import { z } from 'zod';
import { MotorPolicy } from '@/api/motorpolicy';
import { getDate } from '@/helpers/utils/dateHelpers';
import { validationMessages } from '@/helpers/utils/textHelpers';

export const getMtaMinStartDate = (policy: MotorPolicy) => {
  const policyStartDate = startOfDay(new Date(policy?.cover?.startDateTimeUtc));
  const todayDate = getDate();

  return policyStartDate > todayDate ? policyStartDate : todayDate;
};

export const getMtaMaxStateDate = (policy: MotorPolicy) =>
  min(
    [
      policy.cancelledDateTimeUtc,
      policy.voidedDateTimeUtc,
      policy.cover.endDateTimeUtc,
      addDays(getDate(), 30).toISOString(),
    ]
      .filter((date): date is string => Boolean(date))
      .map(date => new Date(date)),
  );

export const getMtaMinPurchaseDate = () => new Date(1935, 0, 1);

export const getDriverFullName = (firstName: string, surname: string) =>
  `${firstName} ${surname}`;

export const validateConvictionOrClaimDate = (
  { date, _isExisting }: { date: Date | null; _isExisting: boolean },
  ctx: z.RefinementCtx,
) => {
  if (_isExisting || !date) return;

  const today = startOfDay(getDate());
  const fiveYearsAgo = startOfDay(subYears(getDate(), 5));

  if (isAfter(date, today)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['date'],
      message: validationMessages.maxDate(today),
    });

    return;
  }

  if (isBefore(date, fiveYearsAgo)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['date'],
      message: validationMessages.minDate(fiveYearsAgo),
    });

    return;
  }
};
