import { List } from '@mui/material';
import {
  QuoteCalculation,
  useQuoteCalculationContext,
} from '@/features/PaymentActions/QuoteCalculation';
import { getFeeTypesName } from '@/helpers/nameMappings/feeNameMappings';
import { getSelectedAddOnByCode } from '@/helpers/data/quoteHelpers';
import { MotorQuote } from '@/api/motorquote';
import { CreateMtaResponse } from '@/api/motorpolicy';
import { selectTotalAmountPayableOverFullTerm } from '@/features/NewMta/newMta.selectors';
import {
  selectAddOnPremium,
  selectAddOnPremiumTax,
} from '@/helpers/selectors/addOnSelectors';

type NewMtaQuoteCalculationPriceBreakdownProps = {
  quote: CreateMtaResponse & MotorQuote;
};

export function NewMtaQuoteCalculationPriceBreakdown({
  quote,
}: NewMtaQuoteCalculationPriceBreakdownProps) {
  const { netPremium, insurancePremiumTax, fee, feeTotal } = useQuoteCalculationContext();

  const totalAmountPayableOverFullTerm = selectTotalAmountPayableOverFullTerm(quote);
  const protectedNcbAddOn = getSelectedAddOnByCode(quote, 'PROTECTED_NCB');
  const protectedNcbPremium = selectAddOnPremium(protectedNcbAddOn);
  const protectedNcbPremiumTax = selectAddOnPremiumTax(protectedNcbAddOn);

  return (
    <>
      <List>
        <QuoteCalculation.PriceBreakdown.Price
          label="Insurance premium"
          amount={netPremium}
        />
        <QuoteCalculation.PriceBreakdown.Price
          label="Insurance premium tax"
          amount={insurancePremiumTax}
        />
        {protectedNcbPremium !== 0 && (
          <QuoteCalculation.PriceBreakdown.Price
            label="Protected NCB Insurance premium"
            amount={protectedNcbPremium}
          />
        )}
        {protectedNcbPremiumTax !== 0 && (
          <QuoteCalculation.PriceBreakdown.Price
            label="Protected NCB Insurance premium tax"
            amount={protectedNcbPremiumTax}
          />
        )}
        <QuoteCalculation.PriceBreakdown.Price
          label={getFeeTypesName(fee.type!)}
          amount={feeTotal}
        />
      </List>
      <QuoteCalculation.PriceBreakdown.InstallmentDetails>
        <QuoteCalculation.PriceBreakdown.Price
          label="Total amount payable over full term"
          amount={totalAmountPayableOverFullTerm}
        />
      </QuoteCalculation.PriceBreakdown.InstallmentDetails>
      <QuoteCalculation.PriceBreakdown.Total />
    </>
  );
}
