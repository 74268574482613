import { TabPanel } from '@mui/lab';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NotesTab } from './notesTypes';
import { NotesList } from './components/NotesList';
import { AddNoteForm } from './components/AddNoteForm';
import { EditNoteForm } from './components/EditNoteForm';
import { Note } from '@/api/note';
import { PolicyQueryParams } from '@/features/Policies/policyTypes';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';

export function Notes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [editedNote, setEditedNote] = useState<Note | null>(null);
  const { setAlert } = useSnackbarAlert();

  const handleNoteAddSuccess = () => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams),
        [PolicyQueryParams.Tab]: NotesTab.List,
      }),
    );
    setAlert({ message: 'New note added', severity: 'success' });
  };

  const handleNoteEditSuccess = () => {
    setEditedNote(null);
    setAlert({ message: 'The note has been edited', severity: 'success' });
  };

  const handleCancelEdit = () => {
    setEditedNote(null);
  };

  return (
    <>
      <TabPanel value={NotesTab.List}>
        {editedNote ? (
          <EditNoteForm
            note={editedNote}
            onRequestSuccess={handleNoteEditSuccess}
            onCancel={handleCancelEdit}
          />
        ) : (
          <NotesList onEdit={setEditedNote} />
        )}
      </TabPanel>
      <TabPanel value={NotesTab.Add} sx={{ height: '100%' }}>
        <AddNoteForm onRequestSuccess={handleNoteAddSuccess} />
      </TabPanel>
    </>
  );
}
