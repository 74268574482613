import { CancellationReason } from '@/api/motorpolicy';

export function getCancellationReasonLabel(reason: CancellationReason): string {
  switch (reason) {
    case CancellationReason.AUTO_CANCEL_NON_PAYMENT:
      return 'Auto cancel non payment';
    case CancellationReason.CAN_NO_LONGER_AFFORD:
      return 'Can no longer afford';
    case CancellationReason.CANCEL_TO_REISSUE_ANEW_POLICY:
      return 'Cancel to reissue a new policy';
    case CancellationReason.CANNOT_MEET_THE_TRACKER_DEMANDS:
      return 'Cannot meet the tracker demands';
    case CancellationReason.COOLING_OFF_PERIOD:
      return 'Cooling off period';
    case CancellationReason.COVER_NO_LONGER_SUITABLE:
      return 'Cover no longer suitable';
    case CancellationReason.DRIVER_DISQUALIFIED:
      return 'Driver disqualified';
    case CancellationReason.ENFORCED_INSURER_CANCELLATION_NO_PHINVOLVEMENT:
      return 'Enforced insurer cancellation: No PH involvement';
    case CancellationReason.ENFORCED_INSURER_CANCELLATION_SUSPECTED_FRAUD:
      return 'Enforced Insurer cancellation: suspected fraud';
    case CancellationReason.ENFORCED_INSURER_CANCELLATION_BEHAVIOUR:
      return 'Enforced Insurer cancellation: behaviour';
    case CancellationReason.ENFORCED_INSURER_CANCELLATION_FAILED_VALIDATION:
      return 'Enforced Insurer cancellation: failed validation';
    case CancellationReason.INSURER_VOID:
      return 'Insurer void';
    case CancellationReason.REASON_NOT_DISCLOSED:
      return 'Reason not disclosed';
    case CancellationReason.SYSTEM_CORRECTION:
      return 'System correction';
    case CancellationReason.VEHICLE_HAS_BEEN_SOLD:
      return 'Vehicle has been sold';
    case CancellationReason.VEHICLE_NOT_PURCHASED:
      return 'Vehicle not purchased';
    case CancellationReason.VEHICLE_TAKEN_OFF_THE_ROAD:
      return 'Vehicle taken off the road';
  }
}
