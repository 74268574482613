import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { CancelMotorPolicyResponse, CancelMotorPolicyRequest } from '@/api/motorpolicy';

type useCancellationQuoteMutationProps = {
  policyId: string;
  onSuccess?: (calculatePaymentData: CancelMotorPolicyResponse) => void;
  onError?: () => void;
};

export function useCancellationQuoteMutation({
  policyId,
  onSuccess,
}: useCancellationQuoteMutationProps) {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      effectiveDateTimeUtc,
      cancellationReason,
      cancellationDescription,
    }: CancelMotorPolicyRequest) =>
      api.motorpolicy.motorPolicy.postMotorpoliciesCancellationMotorQuote({
        id: policyId,
        requestBody: {
          effectiveDateTimeUtc,
          cancellationReason,
          cancellationDescription,
        },
      }),
    onSuccess: async (calculatePaymentData: CancelMotorPolicyResponse) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.motorPolicy(policyId),
      });
      onSuccess?.(calculatePaymentData);
    },
  });
}
