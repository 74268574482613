import { NoteFormSubmitData } from '../hooks/note.schema';
import { CreateNoteRequestData } from '@/hooks/mutations/useCreateNoteMutation';
import { EditNoteRequestData } from '@/hooks/mutations/useEditNoteMutation';
import { Note } from '@/api/note';

const getPatchValue = <T>(originalValue: T, formValue: T): T => {
  return originalValue === formValue ? originalValue : formValue;
};

const getDeletedAttachmentsIds = (data: NoteFormSubmitData, note: Note) =>
  note.attachments
    .map(attachment => attachment.id!)
    .filter(
      id =>
        !data.existingAttachments.find(
          existingAttachment => existingAttachment.id === id,
        ),
    );

export const mapNoteFormToAddRequestData = (
  data: NoteFormSubmitData,
  parentId: string,
): CreateNoteRequestData => ({
  parentId,
  isPolicyNote: true,
  noteType: data.noteType,
  initiator: data.noteInitiator,
  communicationMethod: data.communicationMethod,
  summary: data.summary,
  description: data.description,
  dateOfReminder: data.dateOfReminder?.toISOString() ?? undefined,
  assigneeTeam: data.assigneeTeam,
  attachments: data.newAttachments,
});

export const mapNoteFormToEditRequestData = (
  data: NoteFormSubmitData,
  note: Note,
  parentId: string,
): EditNoteRequestData => ({
  noteId: note.id,
  parentId,
  noteType: getPatchValue(note.noteType, data.noteType),
  initiator: getPatchValue(note.initiator, data.noteInitiator),
  communicationMethod: getPatchValue(note.communicationMethod, data.communicationMethod),
  summary: getPatchValue(note.summary, data.summary),
  description: getPatchValue(note.description, data.description),
  dateOfReminder: getPatchValue(
    note.reminder?.dateOfReminder,
    data.dateOfReminder?.toISOString() ?? undefined,
  ),
  assigneeTeam: getPatchValue(
    note.reminder?.assigneeTeam ?? undefined,
    data.assigneeTeam,
  ),
  newAttachments: data.newAttachments,
  deletedAttachmentsIds: getDeletedAttachmentsIds(data, note),
});
