import { IconButton } from '@mui/material';
import {
  StyledBadge,
  StyledBox,
  StyledNotificationsIcon,
} from './IndividualRemindersMenuIcon.styles';
import { useIndividualRemindersQuery } from '@/hooks/queries/useIndividualRemindersQuery';
import { ReminderFilterType } from '@/api/note';
import { ReminderStatus } from '@/api/support';

type IndividualRemindersMenuIconProps = {
  onClick: () => void;
};

export function IndividualRemindersMenuIcon({
  onClick,
}: IndividualRemindersMenuIconProps) {
  const { reminders } = useIndividualRemindersQuery({
    filterType: ReminderFilterType.INCOMPLETE,
  });

  const hasActiveReminders = Boolean(
    (
      reminders?.filter(
        ({ reminderStatus }) =>
          reminderStatus === ReminderStatus.DUE ||
          reminderStatus === ReminderStatus.OVER_DUE,
      ) ?? []
    ).length,
  );

  return (
    <IconButton data-testid="individual-reminders_open-drawer-button" onClick={onClick}>
      <StyledBox>
        {hasActiveReminders && (
          <StyledBadge
            data-testid="individual-reminders_active-reminders_indicator"
            variant="dot"
          />
        )}
        <StyledNotificationsIcon />
      </StyledBox>
    </IconButton>
  );
}
