import { NewMtaCarChange } from '../newMtaTypes';

export function getNewMtaCarChangeName(carChange: NewMtaCarChange) {
  switch (carChange) {
    case NewMtaCarChange.NewCar:
      return 'The car';
    case NewMtaCarChange.Registration:
      return 'Just the reg';
    case NewMtaCarChange.ExistingCarOrNothing:
      return 'Update or keep existing car';
  }
}
