import { FormControl, RadioGroup, styled } from '@mui/material';
import { withTransientProps } from '@/helpers/utils/componentHelpers';

export type FormRadioGroupDisplayStyle = 'default' | 'tile';

export const StyledRadioFormControl = styled(
  FormControl,
  withTransientProps,
)<{ $displayStyle: FormRadioGroupDisplayStyle }>(({ theme, $displayStyle }) => {
  if ($displayStyle === 'tile') {
    return {
      '.MuiFormGroup-root': {
        display: 'flex',
        gap: theme.spacing(6),
      },
    };
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    '.MuiFormLabel-root': {
      marginBottom: theme.spacing(1.5),
    },
  };
});

export const StyledRadioGroup = styled(
  RadioGroup,
  withTransientProps,
)<{ $error?: boolean; $displayStyle: FormRadioGroupDisplayStyle }>(({
  theme,
  $error,
  $displayStyle,
}) => {
  if ($displayStyle === 'tile') {
    return {
      '.MuiFormControlLabel-root': {
        padding: theme.spacing(4),
        border: `2px solid ${
          $error ? theme.palette.error.main : theme.palette.grey[300]
        }`,
        borderRadius: theme.shape.borderRadius,
        flex: 1,
        margin: 0,
      },
      '.MuiFormControlLabel-root[aria-selected="true"]': {
        border: `2px solid ${theme.palette.info.main}`,
        background: theme.palette.info.light,
      },
      '.MuiFormControlLabel-label': {
        display: 'flex',
        flex: 1,
      },
    };
  }

  return {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(6),
    '.MuiFormControlLabel-root': {
      border: `1px solid ${$error ? theme.palette.error.main : theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      flex: 1,
      margin: 0,
    },
    '.MuiFormControlLabel-root[aria-selected="true"]': {
      border: `1px solid ${theme.palette.info.main}`,
      background: theme.palette.info.light,
    },
    '.MuiFormControlLabel-label': {
      fontSize: theme.typography.fontSize,
    },
    '.MuiSvgIcon-root': {
      fontSize: theme.typography.body1.fontSize,
    },
    '.MuiButtonBase-root': {
      paddingRight: '5px',
    },
  };
});
