import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { useVehicleByRegistrationQuery } from '@/hooks/queries/useVehicleByRegistrationQuery';
import {
  changedCarEmptyFormFields,
  mapVehicleDetailsToNewMtaCarForm,
} from '@/features/NewMta/helpers/mapPolicyToNewMtaForm/mapPolicyToNewMtaCarForm';
import { NewMtaCarChange } from '@/features/NewMta/newMtaTypes';

export function useVehicleRegQuery() {
  const {
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useFormContext<NewMtaFormSchema>();
  const carFormValues = watch('car');
  const policy = watch('_policy');
  const { _queryRegistration: queryRegistration } = carFormValues;
  const isQueryEnabled =
    Boolean(queryRegistration) &&
    queryRegistration !== carFormValues.details?.registration;

  const {
    data: queryCarDetails,
    isPending,
    isError,
  } = useVehicleByRegistrationQuery(queryRegistration, {
    enabled: isQueryEnabled,
  });

  const isQueryPending = isPending && isQueryEnabled;
  const isQueryError = isError && isQueryEnabled;
  const areCarDetailsUpdated =
    Boolean(queryRegistration) && queryRegistration !== policy.vehicle.registrationNumber;

  useEffect(() => {
    if (isError) return;

    if (queryCarDetails) {
      const shouldClearPolicyCarData =
        carFormValues.whatNeedsChanging === NewMtaCarChange.NewCar;
      setValue('car', {
        ...carFormValues,
        ...(shouldClearPolicyCarData ? changedCarEmptyFormFields : {}),
        ...mapVehicleDetailsToNewMtaCarForm(queryCarDetails),
      });
    }
  }, [queryCarDetails, isError]);

  const validateAndUpdateQueryReg = async () => {
    const isValid = await trigger('car.registration');
    if (isValid) {
      setValue('car._queryRegistration', getValues('car.registration'));

      if (errors.car?._queryRegistration) {
        trigger('car._queryRegistration');
      }
    }
  };

  return {
    isQueryPending,
    isQueryError,
    queryRegistration,
    areCarDetailsUpdated,
    validateAndUpdateQueryReg,
  };
}
