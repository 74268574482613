import { Referrer } from '@/api/motorpolicy';

export function getReferrerName(referrer: Referrer): string {
  switch (referrer) {
    case Referrer.COMPARE_THE_MARKET:
      return 'Compare the market';
    case Referrer.CONFUSED:
      return 'Confused';
    case Referrer.EVERYDAY:
      return 'Everyday';
    case Referrer.GO_COMPARE:
      return 'Go compare';
    case Referrer.MONEY_SUPER_MARKET:
      return 'Money super market';
  }
}
