import { useFieldArray, useFormContext } from 'react-hook-form';
import { useLayoutEffect } from 'react';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { YesNo } from '@/models/YesNo';

export function useNewMtaCarMods() {
  const { control, watch, setValue } = useFormContext<NewMtaFormSchema>();
  const { append, remove } = useFieldArray({
    control,
    name: 'car.modifications',
  });
  const { modifiedVehicle, modifications } = watch('car');

  const isAddingNewModification = modifications.includes(null);
  const shouldShowModificationsList = modifiedVehicle === YesNo.Yes;

  const addNewModification = () => {
    if (!isAddingNewModification) {
      append(null);
    }
  };

  const cancelNewModification = () => {
    const index = modifications.indexOf(null);
    if (index >= 0) {
      remove(index);
    }
  };

  const removeModification = (index: number) => {
    remove(index);
  };

  useLayoutEffect(() => {
    if (modifiedVehicle === YesNo.Yes && modifications.length == 0) {
      addNewModification();
    }

    if (modifiedVehicle === YesNo.No) {
      cancelNewModification();
    }
  }, [modifiedVehicle]);

  useLayoutEffect(() => {
    if (modifiedVehicle === YesNo.Yes && modifications.length === 0) {
      setValue('car.modifiedVehicle', YesNo.No);
    }
  }, [modifications]);

  return {
    modifications,
    isAddingNewModification,
    shouldShowModificationsList,
    addNewModification,
    cancelNewModification,
    removeModification,
  };
}
