import { FormProvider } from 'react-hook-form';
import { Alert, Button } from '@mui/material';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { AddNewFeeFormSchema, useAddNewFeeForm } from '../hooks/useAddNewFeeForm';
import { getTestIdForPaymentsAddNewFee } from '../helpers/paymentsTestIdHelper';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByEnum,
} from '@/helpers/utils/formHelpers';
import {
  getDebtReasonName,
  getDebtTypeName,
} from '@/helpers/nameMappings/paymentNameMappings';
import { useOrder } from '@/hooks/useOrder';
import { DebtReason } from '@/api/support';
import { DebtType } from '@/api/motorpolicy';
import { getApiErrorMessage } from '@/helpers/utils/errorHelpers';
import { Modal } from '@/components/ui/modals/Modal';

type AddNewFeeModalProps = {
  onSuccess: () => void;
  onClose: () => void;
};

const getOrderAmount = (amountFormValue: string, debtType: DebtType) => {
  const absoluteAmount = Math.abs(Number(amountFormValue));
  return debtType === DebtType.OWED_TO_THE_CUSTOMER ? -absoluteAmount : absoluteAmount;
};

export function AddNewFeeModal({ onSuccess, onClose }: AddNewFeeModalProps) {
  const formName = 'add-new-fee-form';
  const form = useAddNewFeeForm();
  const { handleSubmit, reset } = form;

  const {
    makeOrder,
    isPending: isOrderPending,
    isError: isOrderError,
    isSuccess: isOrderSuccess,
    error,
  } = useOrder();

  const onFormSubmit = (data: AddNewFeeFormSchema) => {
    makeOrder({
      type: 'add_debt',
      amount: getOrderAmount(data.feeExcludingTax, data.type!),
      reason: data.reason!,
      description: data.description,
    });
  };

  useEffect(() => {
    if (isOrderSuccess) {
      onSuccess();
    }
  }, [isOrderSuccess]);

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Add new fee</Modal.Header>
      <Modal.Content>
        <FormProvider {...form}>
          <form id={formName} onSubmit={handleSubmit(onFormSubmit)}>
            {isOrderError && <Alert severity="error">{getApiErrorMessage(error)}</Alert>}
            <FormTextField
              select
              sx={{ ...commonSx, ...maxWidthSx }}
              label="Type"
              name="type"
              testId={getTestIdForPaymentsAddNewFee('type-selectbox')}
            >
              {renderSelectOptionsByEnum(DebtType, getDebtTypeName)}
            </FormTextField>
            <FormNumberField
              sx={{ ...commonSx, ...maxWidthSx }}
              label="Fee excluding tax"
              name="feeExcludingTax"
              testId={getTestIdForPaymentsAddNewFee('fee-excluding-tax-textbox')}
              symbol="money"
            />
            <FormTextField
              select
              sx={{ ...commonSx, ...maxWidthSx }}
              label="Reason"
              name="reason"
              testId={getTestIdForPaymentsAddNewFee('reason-selectbox')}
            >
              {renderSelectOptionsByEnum(DebtReason, getDebtReasonName, { sort: true })}
            </FormTextField>
            <FormTextField
              sx={commonSx}
              name="description"
              label="Description"
              multiline
              rows={6}
              testId={getTestIdForPaymentsAddNewFee('description-textbox')}
            />
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          type="submit"
          form={formName}
          variant="contained"
          color="secondary"
          loading={isOrderPending}
        >
          Add new fee
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={() => reset()}>
          Clear form
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
