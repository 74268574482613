import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AddressDetails, AddressDetailsIAPIResult } from '@/api/retail/v1';
import { NewMtaFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { useAddressDetailsQuery } from '@/hooks/queries/useAddressDetailsQuery';

type UsePolicyholderAddressSelect = {
  onSelect: () => void;
};

export function usePolicyholderAddressSelect({ onSelect }: UsePolicyholderAddressSelect) {
  const { trigger, setValue } = useFormContext<NewMtaFormSchema>();
  const { getFieldName } = useNewMtaDriverContext();
  const [postKey, setPostKey] = useState('');

  const {
    data,
    isFetching: isAddressSelectFetching,
    isError: isAddressSelectError,
  } = useAddressDetailsQuery(
    {
      postKey,
    },
    { enabled: Boolean(postKey) },
  );

  const mapAddressDetailsToPostOfficeAddress = (detail: AddressDetails | undefined) => ({
    postCode: detail?.addressPostcode,
    town: detail?.town,
    buildingName: detail?.buildingName,
    subBuildingName: detail?.subBuildingName,
    buildingNumber: detail?.buildingNumber,
    thoroughfare: detail?.thoroughfare,
    country: detail?.countryAlpha3,
    county: detail?.county,
    organisationName: detail?.organisation,
  });

  const setAddressData = (addressData: AddressDetailsIAPIResult) => {
    const { detail } = addressData;

    setValue(
      getFieldName('postOfficeAddress'),
      mapAddressDetailsToPostOfficeAddress(detail),
      { shouldDirty: true },
    );
    setValue(getFieldName('_fullAddress'), detail?.fullAddress ?? '', {
      shouldDirty: true,
    });
    trigger(getFieldName('postOfficeAddressPostKey'));

    onSelect();
  };

  const selectAddress = (selectedPostKey: string) => {
    setPostKey(selectedPostKey);
  };

  useEffect(() => {
    if (data) {
      setAddressData(data);
    }
  }, [data]);

  return {
    isAddressSelectFetching,
    isAddressSelectError,
    selectAddress,
  };
}
