import { useContext } from 'react';
import { OverviewList } from './components/OverviewList';
import { OverviewAuditAlert } from './components/OverviewAuditAlert';
import { OverviewPendingChangeAlert } from './components/OverviewPendingChangeAlert';
import { OverviewVulnerabilityAlert } from './components/OverviewVulnerabilityAlert';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { StyledDrawerContent } from '@/components/ui/styles/StyledDrawerContent';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';

export function Overview() {
  const parentId = useContext(PolicyIdContext);
  const { isPending, isError, data: policy } = useMotorPolicyQuery(parentId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return (
    <StyledDrawerContent>
      <OverviewAuditAlert policy={policy} />
      <OverviewPendingChangeAlert policy={policy} />
      <OverviewVulnerabilityAlert />
      <OverviewList policy={policy} />
    </StyledDrawerContent>
  );
}
