import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from 'react';

type AdyenCheckoutContextProps = {
  focusedField: string;
  errors: Record<string, string>;
  setFocusedField: Dispatch<SetStateAction<string>>;
  setErrors: Dispatch<SetStateAction<Record<string, string>>>;
};

export const AdyenCheckoutContext = createContext<AdyenCheckoutContextProps | undefined>(
  undefined,
);

export function AdyenCheckoutContextProvider({ children }: PropsWithChildren) {
  const [focusedField, setFocusedField] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  return (
    <AdyenCheckoutContext.Provider
      value={{
        focusedField,
        setFocusedField,
        errors,
        setErrors,
      }}
    >
      {children}
    </AdyenCheckoutContext.Provider>
  );
}
