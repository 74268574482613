import { ListRow } from '@/components/ui/lists/ListRow';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { ConvictionFormSchema } from '@/features/NewMta/hooks';

type ConvictionProps = Omit<ConvictionFormSchema, 'type'> & {
  convictionCode: string;
};

export function Conviction({
  convictionCode,
  date,
  penaltyPoints,
  fineAmount,
  banReceived,
  disqualifiedLengthInMonths,
}: ConvictionProps) {
  return (
    <>
      <ListRow label="Conviction code">{convictionCode}</ListRow>
      <ListRow label="Date of conviction">{date ? formatDate(date) : null}</ListRow>
      <ListRow label="Penalty points">{penaltyPoints}</ListRow>
      <ListRow label="Fine amount">
        {fineAmount && formatCurrency(fineAmount.toLocaleString())}
      </ListRow>
      <ListRow label="Ban received">{banReceived}</ListRow>
      {banReceived ? (
        <ListRow label="Ban length (months)">
          {disqualifiedLengthInMonths !== '' ? disqualifiedLengthInMonths : '-'}
        </ListRow>
      ) : null}
    </>
  );
}
