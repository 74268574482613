import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { NewMtaCarDetailsRegQueryField } from './NewMtaCarDetailsRegQueryField';
import { NewMtaCarDetailsList } from './NewMtaCarDetailsList';
import { NewMtaCarRegChangeModal } from './NewMtaCarRegChangeModal';
import { useVehicleRegQuery } from '@/features/NewMta/components/NewMtaCar/hooks/useVehicleRegQuery';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { LoaderWrapper } from '@/components/ui/loaders/LoaderWrapper';

export function NewMtaCarDetailsChangeReg() {
  const [isRegChangeModalClosed, setIsRegChangeModalClosed] = useState(false);
  const [isRegChangeConfirmed, setIsRegChangeConfirmed] = useState(false);
  const { setValue, getValues, watch } = useFormContext<NewMtaFormSchema>();
  const {
    isQueryPending,
    isQueryError,
    queryRegistration,
    areCarDetailsUpdated,
    validateAndUpdateQueryReg,
  } = useVehicleRegQuery();
  const carDetails = watch('car.details');
  const listTitle = areCarDetailsUpdated ? 'New details' : 'Current details';

  const openRegChangeModal = () => setIsRegChangeModalClosed(false);
  const closeRegChangeModal = () => setIsRegChangeModalClosed(true);

  const handleRegChangeConfirm = () => {
    closeRegChangeModal();
    setIsRegChangeConfirmed(true);
    setValue('car.details.registration', getValues('car.registration'));
  };

  const handleQueryConfirm = () => {
    const isRegUpdated = getValues('car.registration') !== queryRegistration;

    if (isRegUpdated) {
      void validateAndUpdateQueryReg();
    } else {
      openRegChangeModal();
    }
  };

  useEffect(() => {
    setIsRegChangeModalClosed(false);
    setIsRegChangeConfirmed(false);
  }, [queryRegistration]);

  return (
    <>
      <NewMtaCarDetailsRegQueryField
        inputLabel="New registration"
        buttonText="Confirm reg"
        isError={isQueryError}
        isPending={isQueryPending}
        onConfirm={handleQueryConfirm}
      />
      {isQueryError && !isRegChangeConfirmed ? (
        !isRegChangeModalClosed && (
          <NewMtaCarRegChangeModal
            onConfirm={handleRegChangeConfirm}
            onClose={closeRegChangeModal}
          />
        )
      ) : (
        <LoaderWrapper isLoading={isQueryPending} repeat={3}>
          <NewMtaCarDetailsList title={listTitle} carDetails={carDetails} />
        </LoaderWrapper>
      )}
    </>
  );
}
