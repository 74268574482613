import { History } from './History';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function HistoryDrawerContent() {
  return (
    <DrawerContent>
      <DrawerHeader title="History" />
      <History />
    </DrawerContent>
  );
}
