import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/constants/queryKeys';
import { VehicleDetails } from '@/models/VehicleDetails';

export function useVehicleByRegistrationQuery(
  registration: string,
  options?: Omit<UseQueryOptions<VehicleDetails>, 'queryKey' | 'queryFn'>,
) {
  const api = useApiClient();

  const fetchVehicleDetails = () =>
    api.retailV1.vehicle
      .getVehicle({ vehicleRegistration: registration })
      .then(response => response.detail! as VehicleDetails);

  return useQuery({
    queryKey: queryKeys.vehicleReg(registration),
    queryFn: fetchVehicleDetails,
    ...options,
  });
}
