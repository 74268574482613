import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { NewMtaFormSchema } from './schemas';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useVehicleModsQuery } from '@/hooks/queries/useVehicleModsQuery';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { getProductInfo } from '@/helpers/data/retailHelpers';
import { OptionCode } from '@/api/retail/v1';

export function useVehicleModifications() {
  const parentId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(parentId);
  const { watch } = useFormContext<NewMtaFormSchema>();
  const addedModifications = watch('car.modifications');

  const selectRemainingModifications = (modifications: OptionCode[]) =>
    modifications.filter(
      modification =>
        !addedModifications.find(
          addedModification => addedModification?.code === modification.code,
        ),
    );

  return useVehicleModsQuery(getProductInfo(policy), {
    enabled: Boolean(policy),
    select: selectRemainingModifications,
  });
}
