import { PropsWithChildren, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNewMtaForm } from '../hooks';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { MotorPolicy } from '@/api/motorpolicy';

type NewMtaFormBuilderProps = PropsWithChildren<{ policy: MotorPolicy }>;

function NewMtaFormBuilder({ policy, children }: NewMtaFormBuilderProps) {
  const form = useNewMtaForm(policy);

  return <FormProvider {...form}>{children}</FormProvider>;
}

export function NewMtaFormProvider({ children }: PropsWithChildren) {
  const parentId = useContext(PolicyIdContext);
  const { isPending, isError, data: policy } = useMotorPolicyQuery(parentId);

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  return <NewMtaFormBuilder policy={policy}>{children}</NewMtaFormBuilder>;
}
