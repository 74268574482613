import { Button, Typography } from '@mui/material';
import { DriverNestedFieldDisplay } from '../DriverNestedFieldDisplay';
import { ConvictionForm } from './components/ConvictionForm';
import { Conviction } from './components/Conviction';
import { useDriverConvictionsFormFields } from './hooks/useDriverConvictionsFormFields';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { YesNo } from '@/models/YesNo';
import { useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';

export function DriverConvictionsFormFields() {
  const { getFieldName } = useNewMtaDriverContext();
  const {
    formError,
    hasConvictions,
    isAddingNewConviction,
    fields,
    remove,
    showForm,
    hideForm,
    handleAddConviction,
  } = useDriverConvictionsFormFields();

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 6 }}>
        Convictions
      </Typography>
      <FormRadioGroup
        label="Driving convictions in the last 5 years"
        name={getFieldName('hasConvictions')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio
          testId="new-mta_has-convictions-yes-radio"
          label={YesNo.Yes}
          value={YesNo.Yes}
        />
        <FormRadio
          testId="new-mta_has-convictions-no-radio"
          label={YesNo.No}
          value={YesNo.No}
        />
      </FormRadioGroup>
      {hasConvictions &&
        (isAddingNewConviction ? (
          <>
            <ConvictionForm onAddConviction={handleAddConviction} onCancel={hideForm} />
            {formError && (
              <Typography mt={2} color="error" variant="body2">
                {formError.message}
              </Typography>
            )}
          </>
        ) : (
          <>
            {fields.map(({ id, ...conviction }, index) => {
              return (
                <DriverNestedFieldDisplay
                  key={id}
                  name={`Conviction #${index + 1}`}
                  onRemove={() => remove(index)}
                >
                  <Conviction
                    {...conviction}
                    convictionCode={conviction.type?.code ?? ''}
                  />
                </DriverNestedFieldDisplay>
              );
            })}
            <Button
              data-testid="new-mta_add-new-conviction-button"
              sx={{ alignSelf: 'flex-start' }}
              variant="contained"
              size="small"
              onClick={() => showForm()}
            >
              Add another conviction
            </Button>
          </>
        ))}
    </>
  );
}
