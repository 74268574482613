import { Alert } from '@mui/material';
import { StyledDrawerContent } from '../styles/StyledDrawerContent';
import { GENERAL_ERROR_MSG } from '@/helpers/utils/errorHelpers';

type DrawerErrorProps = {
  message?: string;
};

export function DrawerError({ message }: DrawerErrorProps) {
  const errorMessage = message ?? GENERAL_ERROR_MSG;

  return (
    <StyledDrawerContent data-testid="error-alert">
      <Alert aria-label={errorMessage} severity="error">
        {errorMessage}
      </Alert>
    </StyledDrawerContent>
  );
}
