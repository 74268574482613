import { Button, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import {
  SettleBalanceFormSchema,
  useSettleBalanceForm,
} from '../hooks/useSettleBalanceForm';
import { FormattedPrice } from '@/components/ui/presentation/FormattedPrice';
import { StyledGrayBox, StyledGrayBoxItem } from '@/components/ui/styles/StyledBox';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { YesNo } from '@/models/YesNo';
import { FormNumberField } from '@/components/ui/forms/FormNumberField';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';
import { Modal } from '@/components/ui/modals/Modal';

type SettleBalanceModalProps = {
  balance: number;
  onSubmit: (amountToPay: number) => void;
  onCancel: () => void;
};

export function SettleBalanceModal({
  balance,
  onSubmit,
  onCancel,
}: SettleBalanceModalProps) {
  const formName = 'settle-balance-form';
  const form = useSettleBalanceForm({ balance });
  const { watch, handleSubmit } = form;
  const payInFullFieldValue = watch('payInFull');

  const handleValidSubmit = (data: SettleBalanceFormSchema) => {
    onSubmit(
      data.payInFull === YesNo.Yes ? data._balance : Number(data.otherAmountToPay),
    );
  };

  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Settle balance</Modal.Header>
      <Modal.Content>
        <FormProvider {...form}>
          <form id={formName} onSubmit={handleSubmit(handleValidSubmit)}>
            <StyledGrayBox>
              <StyledGrayBoxItem>
                <Typography
                  variant="subtitle1"
                  id="payments_settle-balance-modal-balance-text"
                >
                  Outstanding balance (owed to us)
                </Typography>
                <FormattedPrice
                  price={balance}
                  aria-labelledby="payments_settle-balance-modal-balance-text"
                  data-testid="payments_settle-balance-price-box"
                />
              </StyledGrayBoxItem>
            </StyledGrayBox>
            <FormRadioGroup
              name="payInFull"
              label="Pay in full?"
              sx={{ ...commonSx, ...maxWidthSx }}
            >
              <FormRadio
                label={YesNo.Yes}
                value={YesNo.Yes}
                testId="payments_settle-balance-pay-in-full-yes-radio"
              />
              <FormRadio
                label={YesNo.No}
                value={YesNo.No}
                testId="payments_settle-balance-pay-in-full-no-radio"
              />
            </FormRadioGroup>
            {payInFullFieldValue === YesNo.No && (
              <FormNumberField
                name="otherAmountToPay"
                label="Other amount to pay"
                sx={{ ...commonSx, ...maxWidthSx }}
                symbol="money"
                testId="payments_settle-balance-other-amount-to-pay-numberbox"
              />
            )}
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          form={formName}
          variant="contained"
          color="secondary"
          data-testid="payments_settle-balance-submit-button"
        >
          Continue to payment
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          data-testid="payments_settle-balance-cancel-button"
          onClick={onCancel}
        >
          Go back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
