import { Divider, List } from '@mui/material';
import { ListRow } from '@/components/ui/lists/ListRow';
import { PaymentInformation } from '@/api/support';
import {
  getDebtTypeName,
  getPaymentProviderName,
} from '@/helpers/nameMappings/paymentNameMappings';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { isMonthlyPayment } from '@/helpers/data/paymentHelpers';
import { PaymentInstallmentDetails, DebtType } from '@/api/motorpolicy';

type PaymentsOverviewProps = {
  payment: PaymentInformation;
  installmentDetails: PaymentInstallmentDetails;
  balance: number;
};

const formatUnaccountedForBalance = (balance: number) => {
  if (balance === 0) return formatCurrency(balance);

  const absoluteBalance = Math.abs(balance);
  const debtTypeName = getDebtTypeName(
    balance > 0 ? DebtType.OWED_TO_THE_COMPANY : DebtType.OWED_TO_THE_CUSTOMER,
  );
  return `${formatCurrency(absoluteBalance)} (${debtTypeName})`;
};

export function PaymentsOverview({
  payment,
  balance,
  installmentDetails,
}: PaymentsOverviewProps) {
  const isPremium = isMonthlyPayment(payment?.plan ?? '');

  return (
    <>
      {isPremium && (
        <>
          <List>
            <ListRow disablePadding label="Plan">
              {payment.plan}
            </ListRow>
            <ListRow disablePadding label="Initial payment amount">
              {installmentDetails.deposit?.amount &&
                formatCurrency(installmentDetails.deposit.amount)}
            </ListRow>
            <ListRow disablePadding label="Interest rate">
              {payment.interestRate != null
                ? `${payment.interestRate.toFixed(2)}%`
                : null}
            </ListRow>
          </List>
          <Divider sx={{ my: 4 }} />
          <List>
            <ListRow disablePadding label="Instalments">
              {payment.numberOfInstallments}
            </ListRow>
            <ListRow disablePadding label="Instalment amount">
              {installmentDetails.monthlyCost?.amount &&
                formatCurrency(installmentDetails.monthlyCost.amount)}
            </ListRow>
          </List>
          <Divider sx={{ my: 4 }} />
          <List>
            <ListRow disablePadding label="Finance House">
              {payment.financeHouse && getPaymentProviderName(payment.financeHouse)}
            </ListRow>
            <ListRow disablePadding label="Loan reference number">
              {payment.loanReferenceNumber}
            </ListRow>
            <ListRow disablePadding label="Loan status">
              {payment.loanStatus}
            </ListRow>
          </List>
          <Divider sx={{ my: 4 }} />
        </>
      )}
      <List>
        <ListRow disablePadding label="Unaccounted for balance">
          {formatUnaccountedForBalance(balance)}
        </ListRow>
      </List>
      {isPremium && (
        <>
          <Divider sx={{ my: 4 }} />
          <List sx={{ mb: 2 }}>
            <ListRow disablePadding label="Payments made">
              {payment.paymentsMade}
            </ListRow>
            <ListRow disablePadding label="Payments outstanding">
              {payment.paymentsOutstanding}
            </ListRow>
          </List>
        </>
      )}
    </>
  );
}
