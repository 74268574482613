import { Divider, List } from '@mui/material';
import { Fragment } from 'react';
import { ListRow } from '@/components/ui/lists/ListRow';
import { AddOn } from '@/api/motorpolicy';

type AddonsContentProps = {
  addOns: AddOn[];
};

export function AddonsContent({ addOns }: AddonsContentProps) {
  return (
    <>
      {addOns.map((addon, index) => {
        const addonNumber = index + 1;

        return (
          <Fragment key={index}>
            <Divider />
            <List data-testid={`addon-${addonNumber}`}>
              <ListRow label={`Add-on #${addonNumber} type`}>
                {addon.type.description}
              </ListRow>
              <ListRow label={`Add-on #${addonNumber} category`}>
                {addon.category.description}
              </ListRow>
            </List>
          </Fragment>
        );
      })}
    </>
  );
}
