import { TabPanel } from '@mui/lab';
import { NewMtaTab } from './newMtaTypes';
import { NewMtaCar } from './components/NewMtaCar';
import { NewMtaDrivers } from './components/NewMtaDrivers';
import { NewMtaCover } from './components/NewMtaCover';
import { NewMtaForm } from './components/NewMtaForm';

export function NewMta() {
  return (
    <NewMtaForm>
      <TabPanel value={NewMtaTab.Drivers} sx={{ height: '100%' }}>
        <NewMtaDrivers />
      </TabPanel>
      <TabPanel value={NewMtaTab.Car}>
        <NewMtaCar />
      </TabPanel>
      <TabPanel value={NewMtaTab.Cover} sx={{ height: '100%' }}>
        <NewMtaCover />
      </TabPanel>
    </NewMtaForm>
  );
}
