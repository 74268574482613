import {
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  Merge,
  Ref,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { NewMtaFormSchema } from '@/features/NewMta/hooks';
import { NewMtaTab } from '@/features/NewMta/newMtaTypes';
import { useNewMtaTabContext } from '@/features/NewMta/hooks/useNewMtaTabContext';

const hasFieldName = (
  fieldError: FieldError | Merge<FieldError, FieldErrorsImpl<NewMtaFormSchema>>,
): fieldError is { ref: { name: string } } => !!fieldError.ref?.name;

const getFirstErroredFormField = (
  errors: FieldErrors<NewMtaFormSchema>,
): { ref: Ref } | null => {
  const firstErroredFormField = Object.values(errors)[0] as
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<NewMtaFormSchema>>;

  if (Array.isArray(firstErroredFormField)) {
    return getFirstErroredFormField(firstErroredFormField.filter(n => n)[0]);
  }

  if (!firstErroredFormField || !hasFieldName(firstErroredFormField)) return null;

  return firstErroredFormField;
};

const getFirstErroredTab = (errors: FieldErrors<NewMtaFormSchema>) => {
  const firstErroredTab = Object.keys(errors)[0];

  if (
    !Object.values(NewMtaTab).includes(
      // @ts-expect-error need to check for any string, expected type is NewMtaTab
      firstErroredTab,
    )
  ) {
    return;
  }

  return firstErroredTab as NewMtaTab;
};

export function useNewMtaScrollToError() {
  const { currentTab, setCurrentTab } = useNewMtaTabContext();
  const {
    formState: { errors, submitCount },
  } = useFormContext<NewMtaFormSchema>();
  const firstErroredTab = getFirstErroredTab(errors);
  const firstErroredFieldName = getFirstErroredFormField(errors)?.ref.name ?? '';

  useEffect(() => {
    if (submitCount === 0) {
      return;
    }

    if (firstErroredTab && firstErroredTab !== currentTab) {
      setCurrentTab(firstErroredTab);
    }
  }, [
    // only keep track of submit count, to only switch the tab after submit
    submitCount,
  ]);

  useEffect(() => {
    if (
      submitCount === 0 ||
      !firstErroredFieldName ||
      // hasn't switched tabs yet
      (firstErroredTab && currentTab !== firstErroredTab)
    ) {
      return;
    }

    const errorElement = document.getElementsByName(firstErroredFieldName)[0];

    if (!errorElement) {
      return;
    }

    errorElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [
    // only keep track of query param and submit count, to avoid scrolling into another errored field after fixing the first one
    currentTab,
    submitCount,
  ]);
}
