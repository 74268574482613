import { Box } from '@mui/material';
import { useState } from 'react';
import { IndividualRemindersMenuIcon } from './components/IndividualRemindersMenuIcon';
import { IndividualRemindersDrawerContent } from './components/IndividualRemindersDrawerContent';
import { Drawer } from '@/components/ui/drawers/Drawer';

export function IndividualReminders() {
  const [isReminderOpen, setReminderOpen] = useState(false);

  return (
    <Box sx={{ mr: { xs: 2, lg: 4 } }}>
      <IndividualRemindersMenuIcon onClick={() => setReminderOpen(!isReminderOpen)} />
      <Drawer
        open={isReminderOpen}
        onClose={() => setReminderOpen(false)}
        data-testid="reminder-drawer"
      >
        <IndividualRemindersDrawerContent />
      </Drawer>
    </Box>
  );
}
