import { AdditionalDriverSchema } from '@/features/NewMta/hooks';

export const defaultValues: AdditionalDriverSchema = {
  _isComplete: false,
  _isPolicyholder: false,
  _isMainDriver: false,
  relationshipToPolicyholder: '',
  title: '',
  firstName: '',
  surname: '',
  dateOfBirth: null,
  maritalStatus: '',
  isUkResidentSinceBirth: null,
  ukResidentSince: null,
  employmentStatus: '',
  drivingLicenceNumber: '',
  drivingLicenceType: '',
  drivingLicenceProvided: null,
  yearsHeldLicence: null,
  ownOrDriveAnotherCar: null,
  otherVehicleUsage: '',
  hasAdvanceDrivingQualifications: null,
  advanceDrivingQualifications: [],
  medicalConditions: null,
  medicalCondition: '',
  hasClaims: null,
  claims: [],
  hasConvictions: null,
  convictions: [],
  hasNonMotoringConvictions: null,
  jobTitle: null,
  jobIndustry: null,
  anyOtherJob: null,
  secondaryJobTitle: null,
  secondaryJobIndustry: null,
  studentType: null,
};
