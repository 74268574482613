import { Alert, AlertProps } from '@mui/material';
import { useQuoteCalculationContext } from '../hooks/useQuoteCalculationContext';
import { QuoteResult } from '@/helpers/data/quoteHelpers';

type QuoteCalculationAlertProps = {
  alertCopyByQuoteResult: Record<QuoteResult, string>;
};

const alertSeverityByQuoteResult: Record<QuoteResult, AlertProps['severity']> = {
  charge: 'warning',
  refund: 'success',
  no_charge_or_refund: 'success',
};

export function QuoteCalculationAlert({
  alertCopyByQuoteResult,
}: QuoteCalculationAlertProps) {
  const { quoteResult } = useQuoteCalculationContext();
  return (
    <Alert severity={alertSeverityByQuoteResult[quoteResult]}>
      {alertCopyByQuoteResult[quoteResult]}
    </Alert>
  );
}
