import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { SettleBalanceModal } from './SettleBalanceModal';
import { RefundBalanceModal } from './RefundBalanceModal';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useDebtQuery } from '@/hooks/queries/useDebtQuery';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { formatDateTime } from '@/helpers/utils/dateHelpers';
import { getDebtReasonName } from '@/helpers/nameMappings/paymentNameMappings';
import { DebtType, DebtResponse } from '@/api/motorpolicy';
import { formatCurrency } from '@/helpers/utils/currencyHelpers';
import { TableEmptyPlaceholder } from '@/components/ui/table/TableEmptyPlaceholder';
import { useUser } from '@/hooks/useUser';
import { CardPaymentModal } from '@/features/PaymentActions/CardPaymentModal';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { useOrder } from '@/hooks/useOrder';
import { useSetAlertError } from '@/hooks/useSetAlertError';

const isValidFee = (fee: DebtResponse): fee is Required<NonNullable<DebtResponse>> =>
  !!(fee.debtType ?? fee.reasonCode);

const getFeeAmountDue = (debtType: DebtType, absoluteValue: number) => {
  const value =
    debtType === DebtType.OWED_TO_THE_CUSTOMER ? -Math.abs(absoluteValue) : absoluteValue;

  return formatCurrency(value);
};

type PaymentsAdhocFeesProps = {
  balance: number;
  policyTermCorrelationId: string;
};

export function PaymentsAdhocFees({
  balance,
  policyTermCorrelationId,
}: PaymentsAdhocFeesProps) {
  const { setAlert } = useSnackbarAlert();
  const {
    makeOrder,
    isPending: isOrderPending,
    isError: isOrderError,
    isSuccess: isOrderSuccess,
  } = useOrder();
  const policyId = useContext(PolicyIdContext);
  const [shouldShowSettleBalanceModal, setShouldShowSettleBalanceModal] = useState(false);
  const [shouldShowCardPaymentModal, setShouldShowCardPaymentModal] = useState(false);
  const [amountToPay, setAmountToPay] = useState(0);
  const {
    data: fees,
    isPending: isDebtQueryPending,
    isError: isDebtQueryError,
  } = useDebtQuery({ policyId });
  const isRefund = balance < 0;
  const { hasPermission } = useUser();
  const shouldShowSettleBalanceButton =
    balance !== 0 && hasPermission('settlebalance:support');
  const shouldShowEmptyTablePlaceholder = !fees?.length;
  const closeSettleBalanceModal = () => setShouldShowSettleBalanceModal(false);

  const handleSettleBalanceSubmit = (amount: number) => {
    setShouldShowSettleBalanceModal(false);
    setShouldShowCardPaymentModal(true);
    setAmountToPay(amount);
  };

  const handleProcessRefund = () => {
    makeOrder({
      type: 'settle_debt',
      amount: balance,
      isRefund,
    });
  };

  const handleCardPaymentSuccess = () => {
    setAlert({ message: 'Balance was settled successfully', severity: 'success' });
    setShouldShowCardPaymentModal(false);
  };

  useEffect(() => {
    if (isOrderSuccess) {
      setAlert({ message: 'Balance was settled successfully', severity: 'success' });
      closeSettleBalanceModal();
    }
  }, [isOrderSuccess]);

  useSetAlertError(isOrderError);

  if (isDebtQueryError) {
    return <DrawerError />;
  }

  return (
    <>
      {shouldShowSettleBalanceButton &&
        shouldShowSettleBalanceModal &&
        (isRefund ? (
          <RefundBalanceModal
            total={balance}
            policyTermCorrelationId={policyTermCorrelationId}
            onConfirm={handleProcessRefund}
            onCancel={closeSettleBalanceModal}
          />
        ) : (
          <SettleBalanceModal
            balance={balance}
            onSubmit={handleSettleBalanceSubmit}
            onCancel={closeSettleBalanceModal}
          />
        ))}
      {shouldShowCardPaymentModal && (
        <CardPaymentModal
          type="settle_debt"
          amount={amountToPay}
          onClose={() => setShouldShowCardPaymentModal(false)}
          onSuccess={handleCardPaymentSuccess}
        />
      )}
      {isDebtQueryPending ? (
        <DrawerLoading />
      ) : (
        <Table aria-label="ad hoc fees table">
          <TableHead>
            <TableRow>
              <TableCell>Added date</TableCell>
              <TableCell>Amount due</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          {shouldShowEmptyTablePlaceholder ? (
            <TableEmptyPlaceholder>There are no ad hoc fees</TableEmptyPlaceholder>
          ) : (
            <TableBody>
              {fees
                .filter(isValidFee)
                .map(
                  (
                    { createdDateTimeUtc, absoluteValue, reasonCode, debtType },
                    index,
                  ) => (
                    <TableRow key={index}>
                      <TableCell>{formatDateTime(createdDateTimeUtc)}</TableCell>
                      <TableCell>{getFeeAmountDue(debtType, absoluteValue)}</TableCell>
                      <TableCell>{getDebtReasonName(reasonCode)}</TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
          )}
        </Table>
      )}
      {shouldShowSettleBalanceButton ? (
        <LoadingButton
          sx={{ my: 6 }}
          size="small"
          color="secondary"
          loading={isOrderPending}
          onClick={() => setShouldShowSettleBalanceModal(true)}
        >
          Settle balance
        </LoadingButton>
      ) : null}
    </>
  );
}
