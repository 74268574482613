import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { queryKeys } from '@/helpers/constants/queryKeys';

export function useDocumentQuery(
  url: string,
  options?: Omit<UseQueryOptions<Response>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: queryKeys.document(url),
    queryFn: () => fetch(url),
    ...options,
  });
}
