import { NewMtaFormSchema } from '../../hooks/schemas/newMta.schema';
import { NewMtaCarChange } from '../../newMtaTypes';
import { isVehicleKeptAtHomeOvernight } from '@/helpers/data/carHelpers';
import { booleanAnswer } from '@/helpers/utils/textHelpers';
import { Policyholder } from '@/api/motorpolicy';
import { Vehicle } from '@/api/motorpolicy';
import { VehicleDetails } from '@/models/VehicleDetails';
import { YesNo } from '@/models/YesNo';

export const mapPolicyToNewMtaCarForm = (
  vehicle: Vehicle,
  policyholder: Policyholder,
): NewMtaFormSchema['car'] => ({
  whatNeedsChanging: NewMtaCarChange.ExistingCarOrNothing,
  registration: vehicle.registrationNumber,
  _queryRegistration: vehicle.registrationNumber,
  hasVerifiedCarNotImpounded: false,
  details: {
    abiCode: vehicle.abiCode,
    registration: vehicle.registrationNumber,
    make: vehicle.make,
    model: vehicle.model,
    engineSize: vehicle.cubicCapicityInMillilitres ?? null,
    registrationYear: vehicle.manufactureYear ?? null,
    fuelType: vehicle.primaryFuelType ?? null,
    transmission: vehicle.transmission ?? null,
    numberOfDoors: vehicle.numberOfDoors ?? null,
    vehicleType: vehicle.type.code ?? null,
  },
  steeringWheelSide: vehicle.steeringWheelSide?.code ?? null,
  numberOfSeats: vehicle.numberOfSeats ?? null,
  trackingDevice: booleanAnswer(!!vehicle.trackingDevice),
  trackingDeviceType: vehicle.trackingDevice?.code ?? null,
  alarmImmobiliser: vehicle.alarmImmobiliser?.type?.code ?? null,
  importedVehicle: booleanAnswer(vehicle.isImported),
  estimatedValue: vehicle.selfDeclaredVehicleValue?.amount.toString() ?? '',
  modifiedVehicle: booleanAnswer(!!vehicle.modifications?.length),
  modifications: vehicle.modifications ?? [],
  purchasedYet: booleanAnswer(vehicle.ownership?.isPurchased),
  datePurchased: vehicle.ownership?.selfDeclaredDateOfPurchase
    ? new Date(vehicle.ownership.selfDeclaredDateOfPurchase)
    : null,
  usage: vehicle.usage?.type?.code ?? '',
  personalMiles: vehicle.usage?.annualMileage?.toString() ?? '',
  businessMiles: vehicle.usage?.businessMileage?.toString() ?? '',
  keptAtHomeOvernight: booleanAnswer(
    isVehicleKeptAtHomeOvernight({ vehicle, policyholder }),
  ),
  postCode: vehicle.location?.overnightLocationPostcode ?? '',
  keptDuringDay: vehicle.location?.daytimeLocation?.code ?? '',
  keptAtNight: vehicle.location?.overnightLocation?.code ?? '',
  legalOwner: vehicle.ownership?.legalOwner?.code ?? null,
  registeredKeeper: vehicle.ownership?.registeredKeeper?.code ?? null,
});

type UnfilledCarFormData = Pick<
  NewMtaFormSchema['car'],
  | 'estimatedValue'
  | 'modifiedVehicle'
  | 'modifications'
  | 'purchasedYet'
  | 'datePurchased'
  | 'registeredKeeper'
  | 'legalOwner'
  | 'trackingDeviceType'
  | 'steeringWheelSide'
  | 'numberOfSeats'
  | 'trackingDevice'
  | 'alarmImmobiliser'
  | 'importedVehicle'
>;

export const changedCarEmptyFormFields: UnfilledCarFormData = {
  estimatedValue: '',
  modifiedVehicle: null,
  modifications: [],
  purchasedYet: null,
  datePurchased: null,
  legalOwner: null,
  registeredKeeper: null,
  trackingDeviceType: null,
  steeringWheelSide: null,
  numberOfSeats: null,
  trackingDevice: null,
  alarmImmobiliser: null,
  importedVehicle: null,
};

export const mapVehicleDetailsToNewMtaCarForm = (
  vehicleDetails: VehicleDetails,
): Partial<NewMtaFormSchema['car']> => ({
  registration: vehicleDetails.registration,
  _queryRegistration: vehicleDetails.registration,
  details: {
    abiCode: vehicleDetails.abiCode,
    registration: vehicleDetails.registration,
    make: vehicleDetails.make,
    model: vehicleDetails.model,
    engineSize: vehicleDetails.engineSize,
    registrationYear: vehicleDetails.registrationYear,
    fuelType: vehicleDetails.vehicleOptions.fuelType!,
    transmission: vehicleDetails.vehicleOptions.transmission!,
    numberOfDoors: vehicleDetails.doors,
    vehicleType: vehicleDetails.vehicleOptions.vehicleType!.code!,
  },
  steeringWheelSide: vehicleDetails.steeringWheelSide.code ?? null,
  numberOfSeats: vehicleDetails.seats,
  trackingDevice: vehicleDetails.hasTrackingDevice ? YesNo.Yes : YesNo.No,
  alarmImmobiliser: vehicleDetails.alarmImmobiliser.code ?? null,
  importedVehicle: vehicleDetails.isImported ? YesNo.Yes : YesNo.No,
});
