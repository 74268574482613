import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { PatchMotorQuotePriceRequest } from '@/api/motorquote';
import { queryKeys } from '@/helpers/constants/queryKeys';

type UseUpdateQuotePriceMutationProps = {
  quoteId: string;
  onSuccess?: () => void;
};

export function useUpdateQuotePriceMutation({
  quoteId,
  onSuccess,
}: UseUpdateQuotePriceMutationProps) {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  return useMutation({
    mutationFn: (requestBody: PatchMotorQuotePriceRequest) =>
      apiClient.motorquote.motorQuote.patchMotorQuotePrice({
        id: quoteId,
        requestBody,
      }),
    onSuccess: async () => {
      onSuccess?.();
      await queryClient.invalidateQueries({
        queryKey: queryKeys.motorQuote(quoteId),
      });
    },
  });
}
