import { ListItem } from '@mui/material';
import { ReactNode } from 'react';
import {
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './Drawer.styles';

type DrawerMenuListItemProps = {
  label: string;
  icon: ReactNode;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

export function DrawerMenuListItem({
  label,
  icon,
  selected,
  onClick,
  disabled,
}: DrawerMenuListItemProps) {
  return (
    <ListItem disablePadding disableGutters>
      <StyledListItemButton
        selected={selected}
        aria-selected={selected}
        disableRipple
        disableGutters
        onClick={onClick}
        disabled={disabled}
      >
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <StyledListItemText>{label}</StyledListItemText>
      </StyledListItemButton>
    </ListItem>
  );
}
