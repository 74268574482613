import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { useController, useFormContext } from 'react-hook-form';
import { addMinutes, isEqual, isToday, set, setSeconds, startOfDay } from 'date-fns';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/utils/formHelpers';
import { getDate } from '@/helpers/utils/dateHelpers';

type FormDateTimePickerProps = {
  name: string;
  testId?: string;
} & DateTimePickerProps<Date, boolean>;

const getTestIdProps = (testId?: string) =>
  testId ? { 'data-testid': testId } : undefined;

const roundUpToNearest5Minutes = (date: Date) => {
  const currentMinutes = date.getMinutes();
  const minutesToAdd = 5 - (currentMinutes % 5);
  const roundedMinutes = minutesToAdd === 5 ? 0 : minutesToAdd;
  const newDate = addMinutes(date, roundedMinutes);
  const roundedTime = setSeconds(newDate, 0);

  return roundedTime;
};

const hasDateChanged = (currentDate: Date, newDate: Date) =>
  !isEqual(startOfDay(currentDate), startOfDay(newDate));

const setTimeBasedOnDate = (newDate: Date) =>
  isToday(newDate)
    ? roundUpToNearest5Minutes(addMinutes(getDate(), 1))
    : set(newDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export function FormDateTimePicker({
  name,
  testId,
  minDate,
  ...props
}: FormDateTimePickerProps) {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleOpen = () => {
    if (!field.value) {
      const currentDate = roundUpToNearest5Minutes(addMinutes(getDate(), 1));
      const newDate = minDate && minDate > currentDate ? minDate : currentDate;

      field.onChange(newDate);
    }
  };

  const handleDateChange = (newDate: Date | null) => {
    if (!newDate) {
      field.onChange(null);
    } else {
      const currentDate = field.value ? new Date(field.value) : null;
      const updatedDate =
        currentDate && hasDateChanged(currentDate, newDate)
          ? setTimeBasedOnDate(newDate)
          : newDate;

      field.onChange(updatedDate);
    }
  };

  return (
    <DateTimePicker
      {...field}
      {...props}
      {...overrideReactHookFormRef}
      minDate={minDate}
      onOpen={handleOpen}
      onChange={handleDateChange}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          ...getErrorProps(error),
          inputProps: getTestIdProps(testId),
        },
      }}
    />
  );
}
